import React from "react";
import { createRoot } from "react-dom/client";
import { ShiftProjectModal } from "@/react/components/modals/shift-project/shift-project-modal";
import type { ShiftProjectPropTypes } from "@/react/prop-types";
import { ToastAlertProvider } from "@procore/toast-alert";

export default (params: ShiftProjectPropTypes) => {
   const container = document.getElementById("react-mount");
   const root = createRoot(container!);
   root.render(
      <>
         <ToastAlertProvider>
            <ShiftProjectModal
               I18n={I18n}
               projectId={params.projectId}
               projectName={params.projectName}
               startDate={params.startDate}
               refreshRootPage={params.refreshRootPage}
            />
         </ToastAlertProvider>
      </>,
   );
};
