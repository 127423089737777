import "./my-profile.styl"
import template from "./my-profile.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import ko from "knockout"
import { requestContext } from "@/stores/common/request-context";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { AuthManager2 } from "@/lib/managers/auth-manager-2"
import { PersonStore } from "@/stores/person-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { ResetPasswordPane } from "@/lib/components/modals/reset-password-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

export class MyProfileViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - My Profile")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeopleFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS)
      @canViewPeopleSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE)
      @enableEmbeddedAppUsers = requestContext.usingProcoreHostApp

      @person = ko.observable(authManager.authedUser())
      @personName = ko.pureComputed =>
         return '' unless @person()?
         return "#{@person().firstName()} #{@person().lastName()}"

      @permissionLevelName = ko.pureComputed =>
         return '' unless @person()?
         return @person().permissionLevel().name()
      @personsPhoto = ko.observable(@person().profilePicUrl())

      @signature = ko.observable(null)
      @signature.subscribe (newVal) =>
         return unless newVal?
         @signature(null) unless validateInput(newVal)

      @signature(@person().signature()) if @person().signature()?

      @canSave = ko.pureComputed =>
         return (@personsPhoto() != @person().profilePicUrl() or
         @signature() != @person().signature())

   handlePersonPhoto: (url) =>
      return if url == @person().profilePicUrl()
      @personsPhoto(url)

   showResetPasswordModal: ->
      pane1 = new ResetPasswordPane()
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: "reset-password-modal"}, () ->
         return

   signoutOfAllDevices: =>
      authManager.logoutAllDevices () ->
         AuthManager2.navigateToSignIn()

   saveProfile: ->
      data = {}
      if @personsPhoto() != @person().profilePicUrl()
         data['profile_pic_url'] = @personsPhoto()
         @person().profilePicUrl(@personsPhoto())

      if validateInput(@signature())
         data['signature'] = @signature()
      else
         data['signature'] = null

      try
         response = await PersonStore.updateUserSettings(data).payload
         @person().signature(response.data.signature)
      catch err
         Bugsnag.notify(err, (event) =>
            event['context'] = "settings_saveProfile"
            event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
            event.addMetadata('payload', data)
         )


MyProfileViewModel.DEFAULT_BENCH_CARD_SUBTITLE = "job-title"
MyProfileViewModel.DEFAULT_BOARD_CARD_SUBTITLE = "batch-dates"
