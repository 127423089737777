export class TotalsChartMediator
   constructor: (initCallback) ->
      @initCallback = initCallback
      @isInitialized = ko.observable(false)

   redraw: -> throw new Error "redraw not set"
   partialRedraw: -> throw new Error "partialRedraw not set"


   initialize: (component) =>
      @redraw = component.redraw if component.redraw?
      @partialRedraw = component.partialRedraw if component.partialRedraw?

      @isInitialized(true)
      @initCallback() if @initCallback?

