import "./notifications.styl";
import template from "./notifications.pug";
import ko from "knockout";

// Auth, Real-Time & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { SettingsStore } from "@/stores/settings-store.core";
import { NotificationProfileStore } from "@/stores/notification-profile-store.core";

// Modals
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { ManageNotificationProfilePane } from "@/views/settings/modals/manage-notification-profile-pane";

// Models
import { PermissionLevel } from "@/models/permission-level";
import { NotificationProfile } from "@/models/notification-profile";

// Api
import type { UpdateNotificationThresholdsPayload } from "@laborchart-modules/lc-core-api/dist/api/company/update-notification-thresholds";

// Utils
import { ValidationUtils } from "@/lib/utils/validation";
import { Format as FormatUtils } from "@/lib/utils/format";
import type { Callback } from "@/lib/type-utils";

// Viewmodels
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

export class NotificationsViewModel extends PageContentViewModel {
   private readonly canManageNotificationsSettings: boolean;
   private readonly searchQuery: ko.Observable<string | undefined>;
   private readonly notificationProfiles: ko.ObservableArray<NotificationProfile>;
   private readonly displayProfiles: ko.PureComputed<NotificationProfile[]>;
   private readonly assignmentStartWarning: ko.Observable<number>;
   private readonly assignmentEndWarning: ko.Observable<number>;
   private readonly requestStartWarning: ko.Observable<number>;
   private readonly requestEndWarning: ko.Observable<number>;
   private readonly timeOffStartWarning: ko.Observable<number>;
   private readonly timeOffEndWarning: ko.Observable<number>;

   constructor() {
      super(template(), "Settings - Notifications");
      /*------------------------------------
         Permissions
      ------------------------------------*/
      this.canManageNotificationsSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_NOTIFICATIONS_SETTINGS,
      );
      this.searchQuery = ko.observable();
      this.notificationProfiles = ko.observableArray();
      this.displayProfiles = ko.pureComputed(() => {
         if (!ValidationUtils.validateInput(this.searchQuery())) {
            return FormatUtils.keyableSort(this.notificationProfiles(), "name");
         }
         const filteredProfiles: NotificationProfile[] = [];
         for (const profile of this.notificationProfiles()) {
            if (profile.name().toLowerCase().indexOf(this.searchQuery()!.toLowerCase()) !== -1) {
               filteredProfiles.push(profile);
            }
         }
         return FormatUtils.keyableSort(filteredProfiles, "name");
      });
      this.assignmentStartWarning = ko.observable(3);
      this.assignmentEndWarning = ko.observable(3);
      this.requestStartWarning = ko.observable(3);
      this.requestEndWarning = ko.observable(3);
      this.timeOffStartWarning = ko.observable(3);
      this.timeOffEndWarning = ko.observable(3);
      this.loadData();
   }

   showNewProfileModal(): void {
      const pane1 = new ManageNotificationProfilePane();
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal<any>(
         modal,
         null,
         {
            class: "manage-notification-profile-modal",
         },
         (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") {
               return;
            }
            const newProfile = observableData.data.newProfile;
            if (newProfile != null) {
               return this.notificationProfiles.push(newProfile);
            }
         },
      );
   }

   editProfile(data: NotificationProfile): void {
      const pane1 = new ManageNotificationProfilePane(data);
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal<any>(
         modal,
         null,
         {
            class: "manage-notification-profile-modal",
         },
         (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") {
               return;
            }
            const updatedProfile = observableData.data.updatedProfile;
            if (updatedProfile != null) {
               data.mapProperties(updatedProfile);
            }
         },
      );
   }

   deleteProfile = async (data: NotificationProfile): Promise<void> => {
      try {
         await SettingsStore.deleteNotificationProfile(data.id).payload;
         this.notificationProfiles.remove(data);
      } catch (err) {
         return console.log("Error: ", err);
      }
   };

   private setSearchQuery = (query: ko.Observable<string>): void => {
      this.searchQuery(query());
   };

   saveThresholds(): Promise<void> {
      const thresholds = {
         assignment_starting: Number(this.assignmentStartWarning()),
         assignment_ending: Number(this.assignmentEndWarning()),
         request_starting: Number(this.requestStartWarning()),
         request_ending: Number(this.requestEndWarning()),
         time_off_starting: Number(this.timeOffStartWarning()),
         time_off_ending: Number(this.timeOffEndWarning()),
      };
      return this.updateNotificationThresholds(thresholds, (err) => {
         if (err) {
            return console.log("Error: ", err);
         }
      });
   }

   async loadData(): Promise<void> {
      try {
         const stream = await NotificationProfileStore.findNotificationProfilesStream({}).stream;
         for await (const notificationProfile of stream) {
            this.notificationProfiles.push(new NotificationProfile(notificationProfile));
         }
      } catch (err) {
         return console.log("Error: ", err);
      }

      this.getNotificationThresholds((err, thresholds) => {
         if (err) {
            return console.log("error: ", err);
         }
         if (thresholds == null) {
            return;
         }
         this.assignmentStartWarning(thresholds.assignmentStarting);
         this.assignmentEndWarning(thresholds.assignmentEnding);
         this.requestStartWarning(thresholds.requestStarting);
         this.requestEndWarning(thresholds.requestEnding);
         this.timeOffStartWarning(thresholds.timeOffStarting);
         this.timeOffEndWarning(thresholds.timeOffEnding);
      });
   }

   async updateNotificationThresholds(
      thresholds: UpdateNotificationThresholdsPayload,
      callback: Callback<boolean>,
   ): Promise<void> {
      try {
         await SettingsStore.updateNotificationThresholds(thresholds).payload;
         callback(null);
      } catch (err) {
         callback(err as Error);
      }
   }

   async getNotificationThresholds(
      callback: Callback<{
         assignmentStarting: number;
         assignmentEnding: number;
         requestStarting: number;
         requestEnding: number;
         timeOffStarting: number;
         timeOffEnding: number;
      }>,
   ): Promise<void> {
      try {
         const data = (await SettingsStore.getNotificationThresholds().payload).data;
         if (data == null) {
            return;
         }
         const thresholds = {
            assignmentStarting: data.assignment_starting,
            assignmentEnding: data.assignment_ending,
            requestStarting: data.request_starting,
            requestEnding: data.request_ending,
            timeOffStarting: data.time_off_starting,
            timeOffEnding: data.time_off_ending,
         };
         callback(null, thresholds);
      } catch (err) {
         callback(err as Error);
      }
   }
}
