import template from "./confirm-delete-position-pane.pug"
import ko from "knockout"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmDeletePositionPaneViewModel extends ModalPane
   constructor: (positionName) ->
      assertArgs(arguments, String)
      super("Delete Job Title", "", template())
      @positionName = positionName
      @confirmationText = ko.observable()
      @isDeleting = ko.observable(false)
      @canDelete = ko.pureComputed =>
         return unless @confirmationText()?
         return @confirmationText().toLowerCase() == "yes"

   confirmDelete: ->
      return unless @canDelete()
      @isDeleting(true)
      modalManager.callback(modalManager.modal, 'test', modalManager.modalData)

