import type { BatchEditor } from "@/lib/components/batch-actions/batch-edit/batch-edit";
import type { ComponentArgs } from "@/lib/components/common";
import { PersonDropDownPane } from "@/lib/components/drop-downs/panes/person-drop-down-pane";
import { ProjectDropDownPane } from "@/lib/components/drop-downs/panes/project-drop-down-pane";
import type { DetachedDayEditorParams } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import { DetachedDayEditor } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import type { DropDownEditorParams } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { DropDownEditor } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import type { PercentEditorParams } from "@/lib/components/editors/percent-editor/percent-editor";
import { PercentEditor } from "@/lib/components/editors/percent-editor/percent-editor";
import type { TimeEditorParams } from "@/lib/components/editors/time-editor/time-editor";
import { TimeEditor } from "@/lib/components/editors/time-editor/time-editor";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type {
   KeysetGridStoreParams,
   ResponsePayload,
} from "@/lib/components/grid/keyset-grid-store";
import { KeysetGridStore } from "@/lib/components/grid/keyset-grid-store";
import { DateUtils } from "@/lib/utils/date";
import { formatName } from "@/lib/utils/preferences";
import type { AssignmentWorkDays } from "@/models/assignment";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { defaultStore } from "@/stores/default-store";
import type { Observable, PureComputed } from "knockout";
import { observable } from "knockout";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import type {
   FindAssignmentSerializedProject,
   FindAssignmentsPaginatedQueryParams,
   FindAssignmentsStreamQueryParams,
   SerializedFindAssignment,
   UpdateAssignmentPayload,
   UpdateAssignmentsPayload,
} from "@laborchart-modules/lc-core-api/dist/api/assignments";
import { PersonStore } from "@/stores/person-store.core";
import { GridStoreRowsUpdater } from "@/lib/utils/grid-store/grid-store-rows-updater";
import type { StoreStreamResponse } from "@/stores/common";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { ProjectStore } from "@/stores/project-store.core";
import { authManager } from "@/lib/managers/auth-manager";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import type { CustomFieldMeta } from "@/models/column-header";
import { customFieldUpdateRowApplier } from "@/lib/utils/custom-field-instance";
import type { AssignmentPaySplit, CustomFieldInstance } from "@laborchart-modules/common";
import { ColumnEntityType } from "@laborchart-modules/common";
import { PermissionLevel } from "@/models/permission-level";
import type {
   CreateAssignmentPayload,
   CreateAssignmentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type { AssignmentDetailsModalStagedUpdate } from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import type { SerializedCostCode } from "@laborchart-modules/common/dist/rethink/serializers/cost-code-serializer";
import type { SerializedCostCodeLabel } from "@laborchart-modules/common/dist/rethink/serializers/cost-code-label-serializer";
import type { WorkDaySelection } from "@/lib/components/modals/editors/work-day-editor-element/work-day-editor-element";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import { PositionStore } from "@/stores/position-store.core";
import type { ModalData } from "@/lib/managers/alert-manager";
import { alertManager } from "@/lib/managers/alert-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import type { OvertimeRates } from "@/lib/components/modals/editors/overtime-rates-editor-element/overtime-rates-editor-element";
import type { SerializedGetProject } from "@laborchart-modules/lc-core-api/dist/api/projects/get-project";
import type { StatusOption } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import { StatusDropDownPane } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import type { FindProjectsFilter } from "@laborchart-modules/common/dist/reql-builder/procedures/find-projects";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { BatchDeleteAssignmentsPayload } from "@laborchart-modules/lc-core-api/dist/api/assignments/delete-assignment";

export const NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE =
   "You do not have permission to edit this assignment.";

type DropDownOption = {
   id: string;
   name: string;
};

type RowUpdate<TFields extends keyof SerializedFindAssignment> = {
   assignment: Omit<SerializedFindAssignment, TFields>;
   update: Pick<SerializedFindAssignment, TFields>;
};

export interface AssignmentsList2GridStoreParams
   extends KeysetGridStoreParams<FindAssignmentsPaginatedQueryParams> {
   customFields: PureComputed<SerializedCustomField[]>;
   errorModalColumnGroups: Array<GridColumnGroup<SerializedFindAssignment>>;
}

export class AssignmentsList2GridStore extends KeysetGridStore<
   SerializedFindAssignment,
   FindAssignmentsPaginatedQueryParams
> {
   private readonly rowsUpdater: GridStoreRowsUpdater<
      SerializedFindAssignment,
      UpdateAssignmentsPayload[]
   >;
   private readonly rowsRemover: GridStoreRowsUpdater<
      SerializedFindAssignment,
      BatchDeleteAssignmentsPayload
   >;
   private readonly customFields: AssignmentsList2GridStoreParams["customFields"];

   constructor(params: AssignmentsList2GridStoreParams) {
      super(params);
      this.customFields = params.customFields;
      this.rowsUpdater = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => Assignment2Store.updateAssignmentsStream(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
      this.rowsRemover = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => Assignment2Store.batchDelete(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
   }

   protected async loadRows(
      params: FindAssignmentsPaginatedQueryParams,
   ): Promise<ResponsePayload<SerializedFindAssignment>> {
      return Assignment2Store.getAssignments(params).payload;
   }

   private replaceFields = <TFields extends keyof SerializedFindAssignment>({
      assignment,
      update,
   }: RowUpdate<TFields>): SerializedFindAssignment =>
      ({
         ...assignment,
         ...update,
      } as SerializedFindAssignment);

   protected createLoadAllRowsStream(
      queryParams: FindAssignmentsStreamQueryParams,
   ): StoreStreamResponse<SerializedFindAssignment> {
      return Assignment2Store.getAssignmentsStream({
         custom_field_id: queryParams.custom_field_id,
         filters: queryParams.filters,
         sort_by: queryParams.sort_by,
         timezone: queryParams.timezone,
         group_id: queryParams.group_id,
         sort_direction: queryParams.sort_direction,
         // TODO: Add starting_at when we don't have previous records.
      });
   }

   removeAssignment(id: string): void {
      const index = this.rows().findIndex((a) => a.id == id);
      if (index != -1) {
         this.rows.splice(index, 1);
      }
   }

   private personRowApplier = ({
      assignment,
      person,
   }: {
      assignment: SerializedFindAssignment;
      person: SerializedFindAssignment["person"];
   }) =>
      this.replaceFields<"person" | "resource_id">({
         assignment,
         update: {
            // TODO: Needs to have the person's full job_title merged on.
            person: person as SerializedFindAssignment["person"],
            resource_id: person.id,
         },
      });
   personEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DropDownEditorParams<DropDownOption & { job_title_id: string | null }>> => {
      const selectedItem: (DropDownOption & { job_title_id: string | null }) | null =
         assignments.length == 1
            ? {
                 id: assignments[0].person.id,
                 name: formatName(assignments[0].person.name),
                 job_title_id: assignments[0].person.job_title_id,
              }
            : null;
      return DropDownEditor.factory<
         SerializedFindAssignment,
         DropDownOption & { job_title_id: string | null }
      >(() => ({
         title: "Person",
         value: assignments.length == 1 ? new Set([assignments[0].person.id]) : new Set(),
         selectedItem: selectedItem,
         pane: new PersonDropDownPane({
            transformer: (person) => ({
               id: person.id,
               name: formatName(person.name),
               job_title_id: person.job_title_id,
            }),
            status: PersonStatus.ACTIVE,
            groupIds:
               authManager.selectedGroupId() != "my-groups"
                  ? new Set([authManager.selectedGroupId()])
                  : undefined,
            isAssignable: true,
         }) as any,
         cellFactory: TextCell.factory<DropDownOption>((item) => item.name),
         saveProvider: async ([person]) => {
            // First retrieve the person before attempting to update.
            const [personPayload, jobTitlePayload] = await Promise.all([
               PersonStore.getPerson(person.id).payload,
               person.job_title_id != null
                  ? PositionStore.getPosition(person.job_title_id).payload
                  : null,
            ]);
            const fullPerson: SerializedFindAssignment["person"] = {
               ...personPayload.data,
               job_title: jobTitlePayload?.data ?? null,
               job_title_id: jobTitlePayload?.data?.id ?? null,
            };
            await this.updateRows({
               assignments,
               update: { resource_id: personPayload.data.id },
               rowApplier: (assignment) =>
                  this.personRowApplier({ assignment, person: fullPerson }),
            });
         },
         isRequired: true,
      }))(assignments);
   };

   private projectRowApplier = ({
      assignment,
      project,
   }: {
      assignment: SerializedFindAssignment;
      project: FindAssignmentSerializedProject;
   }) =>
      this.replaceFields<
         "category" | "category_id" | "project" | "project_id" | "subcategory" | "subcategory_id"
      >({
         assignment,
         update: {
            category: null,
            category_id: null,
            project: project,
            project_id: project.id,
            subcategory: null,
            subcategory_id: null,
         },
      });
   projectEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DropDownEditorParams<DropDownOption>> => {
      const selectedItem: Observable<DropDownOption | null> = observable(
         assignments.length == 1
            ? { id: assignments[0].project.id, name: assignments[0].project.name }
            : null,
      );
      return DropDownEditor.factory<SerializedFindAssignment, DropDownOption>(() => ({
         title: "Project",
         value: assignments.length == 1 ? new Set([assignments[0].project.id]) : new Set([]),
         selectedItem: selectedItem,
         pane: new ProjectDropDownPane({
            transformer: (project) => ({ id: project.id, name: project.name }),
            filters: [
               {
                  name: "Status",
                  // TODO: Update FindProjectsFilter to be in a full-import-friendly location.
                  property: "status" as FindProjectsFilter,
                  type: FilterFieldType.SELECT,
                  value_sets: [{ value: ProjectStatus.ACTIVE }, { value: ProjectStatus.PENDING }],
               },
            ],
            groupId:
               authManager.selectedGroupId() != "my-groups"
                  ? authManager.selectedGroupId()
                  : undefined,
         }) as any,
         cellFactory: TextCell.factory<DropDownOption>((item) => item.name),
         saveProvider: async ([project]) => {
            // First retrieve the project before attempting to update.
            const payload = await ProjectStore.getProject(project.id).payload;
            await this.updateRows({
               assignments,
               update: { project_id: payload.data.id },
               rowApplier: (assignment) =>
                  this.projectRowApplier({ assignment, project: payload.data }),
            });
         },
         isRequired: true,
      }))(assignments);
   };

   private startDayRowApplier = ({
      assignment,
      startDay,
   }: {
      assignment: SerializedFindAssignment;
      startDay: number;
   }) =>
      this.replaceFields({
         assignment,
         update: {
            start_day: startDay,
         },
      });
   startDayEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      return DetachedDayEditor.factory(() => ({
         title: "Start Date",
         value: assignments.length == 1 ? assignments[0].start_day : null,
         onOrBeforeDay: assignments.length == 1 ? assignments[0].end_day : undefined,
         isRequired: true,
         validators:
            assignments.length == 1
               ? [(startDay) => isWorkDayValidator("Start Date", assignments[0], startDay)]
               : [],
         saveProvider: async (startDay): Promise<void> => {
            if (!startDay) return;
            await this.updateRows({
               assignments,
               update: { start_day: startDay },
               rowApplier: (assignment) => this.startDayRowApplier({ assignment, startDay }),
            });
         },
      }))(assignments);
   };

   private endDayRowApplier = ({
      assignment,
      endDay,
   }: {
      assignment: SerializedFindAssignment;
      endDay: number;
   }) =>
      this.replaceFields({
         assignment,
         update: {
            end_day: endDay,
         },
      });
   endDayEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      return DetachedDayEditor.factory(() => ({
         title: "End Date",
         value: assignments.length == 1 ? assignments[0].end_day : null,
         onOrAfterDay: assignments.length == 1 ? assignments[0].start_day : undefined,
         isRequired: true,
         validators:
            assignments.length == 1
               ? [(endDay) => isWorkDayValidator("End Date", assignments[0], endDay)]
               : [],
         saveProvider: async (endDay): Promise<void> => {
            if (!endDay) return;
            await this.updateRows({
               assignments,
               update: { end_day: endDay },
               rowApplier: (assignment) => this.endDayRowApplier({ assignment, endDay }),
            });
         },
      }))(assignments);
   };

   private startTimeRowApplier = ({
      assignment,
      startTime,
   }: {
      assignment: SerializedFindAssignment;
      startTime: SerializedFindAssignment["start_time"];
   }) =>
      this.replaceFields({
         assignment,
         update: {
            start_time: startTime,
         },
      });
   startTimeEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<TimeEditorParams> => {
      const isOne = assignments.length === 1;
      return TimeEditor.factory(() => ({
         title: "Start Time",
         value: isOne ? assignments[0].start_time ?? null : null,
         isRequired: true,
         saveProvider: async (startTime) => {
            if (startTime == null) return;
            await this.updateRows({
               assignments,
               update: { start_time: startTime },
               rowApplier: (assignment) => this.startTimeRowApplier({ assignment, startTime }),
            });
         },
      }))(assignments);
   };

   private endTimeRowApplier = ({
      assignment,
      endTime,
   }: {
      assignment: SerializedFindAssignment;
      endTime: SerializedFindAssignment["end_time"];
   }) =>
      this.replaceFields({
         assignment,
         update: {
            end_time: endTime,
         },
      });
   endTimeEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<TimeEditorParams> => {
      const isOne = assignments.length === 1;
      return TimeEditor.factory(() => ({
         title: "End Time",
         value: isOne ? assignments[0].end_time ?? null : null,
         isRequired: true,
         saveProvider: async (endTime) => {
            if (endTime == null) return;
            await this.updateRows({
               assignments,
               update: { end_time: endTime },
               rowApplier: (assignment) => this.endTimeRowApplier({ assignment, endTime }),
            });
         },
      }))(assignments);
   };

   private percentAllocatedRowApplier = ({
      assignment,
      percentAllocated,
   }: {
      assignment: SerializedFindAssignment;
      percentAllocated: SerializedFindAssignment["percent_allocated"];
   }) =>
      this.replaceFields({
         assignment,
         update: {
            percent_allocated: percentAllocated,
         },
      });
   percentAllocatedEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<PercentEditorParams> => {
      const isOne = assignments.length === 1;
      return PercentEditor.factory(() => ({
         title: "Percent Allocation",
         value: isOne ? assignments[0].percent_allocated : null,
         isRequired: true,
         validators: isOne
            ? [percentAllocatedValidator(assignments[0])]
            : [isPercentAllocatedGtZero],
         saveProvider: async (percentAllocated) => {
            if (!percentAllocated) return;
            await this.updateRows({
               assignments,
               update: { percent_allocated: percentAllocated },
               rowApplier: (assignment) =>
                  this.percentAllocatedRowApplier({ assignment, percentAllocated }),
            });
         },
      }))(assignments);
   };

   private statusRowApplier = ({
      assignment,
      status,
   }: {
      assignment: SerializedFindAssignment;
      status: StatusOption | null;
   }) => {
      const update =
         status == null
            ? {
                 status: null,
                 status_id: null,
              }
            : {
                 status: {
                    company_id: authManager.companyId(),
                    ...status,
                 },
                 status_id: status.id,
              };
      return this.replaceFields({
         assignment,
         update,
      });
   };
   statusEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DropDownEditorParams<StatusOption>> => {
      const selectedItem =
         assignments.length == 1 && assignments[0].status
            ? {
                 abbreviation: assignments[0].status.abbreviation,
                 color: assignments[0].status.color,
                 id: assignments[0].status.id,
                 name: assignments[0].status.name,
                 sequence: assignments[0].status.sequence,
              }
            : null;
      return DropDownEditor.factory(() => ({
         pane: new StatusDropDownPane(),
         cellFactory: ColorCircleTextCell.factory<StatusOption>((status) => {
            return {
               text: status.name,
               color: status.color,
            };
         }),
         title: "Status",
         placeholder: "Select Status...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem: selectedItem,
         isClearable:
            (assignments.length > 1 || selectedItem != null) &&
            authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES),
         saveProvider: async ([status = null]) => {
            const assignmentTransformer = !status
               ? (assignment: SerializedFindAssignment) => {
                    assignment.status = null;
                    return assignment;
                 }
               : (assignment: SerializedFindAssignment) => {
                    // We are required to mutate the primary object to propagate updates
                    assignment.status = {
                       ...assignment.status!,
                       id: status.id,
                       color: status.color,
                       name: status.name,
                    };
                    return assignment;
                 };

            return this.updateRows({
               assignments: assignments.map(assignmentTransformer),
               update: { status_id: status?.id ?? null },
               rowApplier: (assignment) => this.statusRowApplier({ assignment, status }),
            });
         },
      }))(assignments);
   };

   private costCodeRowApplier = ({
      assignment,
      costCode,
   }: {
      assignment: SerializedFindAssignment;
      costCode: SerializedCostCode | null;
   }) =>
      this.replaceFields<"category" | "category_id" | "subcategory" | "subcategory_id">({
         assignment,
         update: {
            category: costCode,
            category_id: costCode?.id ?? null,
            subcategory: null,
            subcategory_id: null,
         },
      });
   costCodeEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DropDownEditorParams<SerializedCostCode>> => {
      const [assignment] = assignments;
      const selectedItem = (() => {
         if (!assignment.category_id) return null;
         return assignment.project.categories.find((c) => c.id === assignment.category_id) ?? null;
      })();

      return DropDownEditor.factory<SerializedFindAssignment, SerializedCostCode>(() => ({
         pane: new ArrayDropDownPane<SerializedCostCode>({
            items: assignment.project.categories.sort((a, b) => a.sequence - b.sequence),
            searchTextProvider: (item) => item.name,
         }),
         cellFactory: TextCell.factory<SerializedCostCode>((item) => item.name),
         title: "Category",
         placeholder: "Select Category...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem,
         isClearable: assignments.length > 1 || selectedItem != null,
         saveProvider: async ([category = null]) => {
            await this.updateRows({
               assignments: assignments,
               update: { category_id: category?.id ?? null, subcategory_id: null },
               rowApplier: (assignment) =>
                  this.costCodeRowApplier({ assignment, costCode: category }),
            });
         },
      }))(assignments);
   };

   private costCodeLabelRowApplier = ({
      assignment,
      costCodeLabel,
   }: {
      assignment: SerializedFindAssignment;
      costCodeLabel: SerializedCostCodeLabel | null;
   }) =>
      this.replaceFields<"subcategory" | "subcategory_id">({
         assignment,
         update: {
            subcategory: costCodeLabel,
            subcategory_id: costCodeLabel?.id ?? null,
         },
      });
   costCodeLabelEditorFactory = (
      assignments: SerializedFindAssignment[],
   ): ComponentArgs<DropDownEditorParams<SerializedCostCodeLabel>> => {
      const [assignment] = assignments;
      const subCategories: SerializedCostCodeLabel[] = [];

      const selectedItem = (() => {
         if (!assignment.category_id) return null;
         const category = assignment.project.categories.find(
            (c) => c.id === assignment.category_id,
         );
         if (!category) return null;
         subCategories.push(...category.subcategories.sort((a, b) => a.sequence - b.sequence));

         return category.subcategories.find((s) => s.id === assignment.subcategory_id) ?? null;
      })();

      return DropDownEditor.factory(() => ({
         pane: new ArrayDropDownPane({
            items: subCategories,
            searchTextProvider: (item) => item.name,
         }),
         cellFactory: TextCell.factory<SerializedCostCodeLabel>((item) => item.name),
         title: "Subcategory",
         placeholder: "Select Subcategory...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem,
         isClearable: assignments.length > 1 || selectedItem != null,
         saveProvider: async ([subcategory = null]) => {
            await this.updateRows({
               assignments: assignments,
               update: { subcategory_id: subcategory?.id ?? null },
               rowApplier: (assignment) =>
                  this.costCodeLabelRowApplier({ assignment, costCodeLabel: subcategory }),
            });
         },
      }))(assignments);
   };

   // Not currently visible in a row, but used for
   // applying and keeping state changes in the assignment-details-modal.
   private workDaysRowApplier = ({
      assignment,
      workDays,
   }: {
      assignment: SerializedFindAssignment;
      workDays: WorkDaySelection;
   }) =>
      this.replaceFields<"work_days">({
         assignment,
         update: {
            work_days: workDays,
         },
      });
   // Not currently visible in a row, but used for
   // applying and keeping state changes in the assignment-details-modal.
   private overtimeRowApplier = ({
      assignment,
      overtime,
   }: {
      assignment: SerializedFindAssignment;
      overtime: boolean;
   }) =>
      this.replaceFields<"overtime">({
         assignment,
         update: {
            overtime: overtime,
         },
      });
   // Not currently visible in a row, but used for
   // applying and keeping state changes in the assignment-details-modal.
   private overtimePaysplitRowApplier = ({
      assignment,
      paysplit,
   }: {
      assignment: SerializedFindAssignment;
      paysplit: AssignmentPaySplit | null;
   }) =>
      this.replaceFields<"pay_split">({
         assignment,
         update: {
            pay_split: paysplit,
         },
      });
   // Not currently visible in a row, but used for
   // applying and keeping state changes in the assignment-details-modal.
   private overtimeRatesRowApplier = ({
      assignment,
      overtimeRates,
   }: {
      assignment: SerializedFindAssignment;
      overtimeRates: OvertimeRates | null;
   }) =>
      this.replaceFields<"overtime_rates">({
         assignment,
         update: {
            overtime_rates: overtimeRates,
         },
      });

   batchEditFields(): Array<BatchEditor<SerializedFindAssignment>> {
      return [
         {
            factory: this.personEditorFactory,
            validator: (assignment) => {
               return !this.canEditAssignment(assignment)
                  ? NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE
                  : null;
            },
         },
         {
            factory: this.projectEditorFactory,
            validator: (assignment) => {
               return !this.canEditAssignment(assignment)
                  ? NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE
                  : null;
            },
         },
         {
            factory: this.startDayEditorFactory,
            validator: (assignment, startDay) => {
               if (!this.canEditAssignment(assignment))
                  return NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE;
               const result = isWorkDayValidator("Start day", assignment, startDay);
               if (!result.status) return result.message;
               return !startDay || assignment.end_day < startDay
                  ? `Start day must be on or before ${this.formatDayLong(assignment.end_day)}`
                  : null;
            },
         } as BatchEditor<SerializedFindAssignment, number | null>,
         {
            factory: this.endDayEditorFactory,
            validator: (assignment, endDay) => {
               if (!this.canEditAssignment(assignment))
                  return NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE;
               const result = isWorkDayValidator("End day", assignment, endDay);
               if (!result.status) return result.message;
               return !endDay || assignment.start_day > endDay
                  ? `End day must be on or after ${this.formatDayLong(assignment.start_day)}`
                  : null;
            },
         } as BatchEditor<SerializedFindAssignment, number | null>,
         {
            factory: this.startTimeEditorFactory,
            validator: (assignment) => {
               if (!this.canEditAssignment(assignment))
                  return NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE;
               const constraint = timeValidator("Start time", assignment);
               return !constraint.status ? constraint.message : null;
            },
         } as BatchEditor<SerializedFindAssignment, number>,
         {
            factory: this.endTimeEditorFactory,
            validator: (assignment) => {
               if (!this.canEditAssignment(assignment))
                  return NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE;
               const constraint = timeValidator("End time", assignment);
               return !constraint.status ? constraint.message : null;
            },
         } as BatchEditor<SerializedFindAssignment, number>,
         {
            factory: this.percentAllocatedEditorFactory,
            validator: (assignment, percentAllocated) => {
               if (!this.canEditAssignment(assignment))
                  return NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE;
               const validator = percentAllocatedValidator(assignment);
               const constraint = validator(percentAllocated);
               return constraint.status ? null : constraint.message;
            },
         } as BatchEditor<SerializedFindAssignment, number | null>,
         {
            factory: this.statusEditorFactory,
            validator: (assignment) => {
               return !this.canEditAssignment(assignment)
                  ? NO_PERMISSON_TO_EDIT_ASSIGNMENT_MESSAGE
                  : null;
            },
         },
      ];
   }

   createAssignmentFromModal = async (
      assignment: CreateAssignmentPayload,
      showAlertModal: boolean = false,
   ): Promise<CreateAssignmentResponse> => {
      const newAssignment = await Assignment2Store.createAssignment(assignment).payload;

      // NOTE: Alerting must be AFTER the creation occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: newAssignment.data.id,
            notifyProjectId: assignment.project_id,
            notifyResourceId: assignment.resource_id,
            notifyContext: "assignment-new",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }
      return newAssignment;
   };

   updateAssignmentFromModal = async ({
      assignment,
      update,
      showAlertModal,
   }: {
      assignment: SerializedFindAssignment;
      update: Partial<AssignmentDetailsModalStagedUpdate>;
      showAlertModal: boolean;
   }): Promise<void> => {
      const {
         category: costCode,
         person,
         project,
         subcategory: costCodeLabel,
         ...strippedUpdate
      } = update;

      // TODO: Refactor to avoid additional network request.
      const getJobTitlePayload = async function () {
         return person != null && person.job_title_id != null
            ? await PositionStore.getPosition(person.job_title_id).payload
            : null;
      };
      const getFullProject = async function (): Promise<SerializedGetProject | null> {
         if (project == null) return null;
         return project.roles != null && project.roles.length > 0
            ? (await ProjectStore.getProject(project.id).payload).data
            : (project as SerializedGetProject);
      };
      const [jobTitlePayload, fullProjectPayload] = await Promise.all([
         getJobTitlePayload(),
         getFullProject(),
      ]);

      const fullPerson: SerializedFindAssignment["person"] | null =
         person != null
            ? {
                 ...person,
                 job_title: jobTitlePayload?.data ?? null,
                 job_title_id: jobTitlePayload?.data.id ?? null,
              }
            : null;

      await this.updateRows({
         assignments: [assignment],
         update: strippedUpdate,
         rowApplier: (assignment) => {
            let newAssignment: SerializedFindAssignment = { ...assignment };

            // NOTE: Order of operations matters here, as some row appliers update the same elements. Be thoughtful.
            if (update.project_id != null && fullProjectPayload != null) {
               newAssignment = this.projectRowApplier({
                  assignment: newAssignment,
                  project: fullProjectPayload,
               });
            }
            if (update.category_id !== undefined) {
               newAssignment = this.costCodeRowApplier({
                  assignment: newAssignment,
                  costCode: costCode ?? null,
               });
            }
            if (update.subcategory_id !== undefined) {
               newAssignment = this.costCodeLabelRowApplier({
                  assignment: newAssignment,
                  costCodeLabel: costCodeLabel ?? null,
               });
            }

            // Remaining fields are unordered.
            if (update.resource_id != null && fullPerson != null) {
               newAssignment = this.personRowApplier({
                  assignment: newAssignment,
                  person: fullPerson,
               });
            }
            if (update.start_day != null) {
               newAssignment = this.startDayRowApplier({
                  assignment: newAssignment,
                  startDay: update.start_day,
               });
            }
            if (update.end_day != null) {
               newAssignment = this.endDayRowApplier({
                  assignment: newAssignment,
                  endDay: update.end_day,
               });
            }
            if (update.start_time !== undefined) {
               newAssignment = this.startTimeRowApplier({
                  assignment: newAssignment,
                  startTime: update.start_time,
               });
            }
            if (update.end_time !== undefined) {
               newAssignment = this.endTimeRowApplier({
                  assignment: newAssignment,
                  endTime: update.end_time,
               });
            }
            if (update.status !== undefined) {
               newAssignment = this.statusRowApplier({
                  assignment: newAssignment,
                  status: update.status,
               });
            }
            if (update.percent_allocated !== undefined) {
               newAssignment = this.percentAllocatedRowApplier({
                  assignment: newAssignment,
                  percentAllocated: update.percent_allocated,
               });
            }
            if (update.work_days != null) {
               newAssignment = this.workDaysRowApplier({
                  assignment: newAssignment,
                  workDays: update.work_days,
               });
            }
            if (update.overtime != null) {
               newAssignment = this.overtimeRowApplier({
                  assignment: newAssignment,
                  overtime: update.overtime,
               });
            }
            if (update.overtime_rates != null) {
               newAssignment = this.overtimeRatesRowApplier({
                  assignment: newAssignment,
                  overtimeRates: update.overtime_rates,
               });
            }
            if (update.pay_split != null) {
               newAssignment = this.overtimePaysplitRowApplier({
                  assignment: newAssignment,
                  paysplit: update.pay_split,
               });
            }
            if (update.custom_fields != null) {
               Object.entries(update.custom_fields).forEach(async ([id, value]) => {
                  const field = this.customFields().find((field) => field.id == id)!;
                  newAssignment = customFieldUpdateRowApplier({
                     row: newAssignment,
                     customFieldMeta: {
                        field_entity: ColumnEntityType.ASSIGNMENTS,
                        field_id: id,
                        field_property: field.integration_name,
                        field_type: field.type,
                     },
                     value,
                  });
               });
            }
            return newAssignment;
         },
      });

      modalManager.clearModal();

      // NOTE: Alerting must be AFTER the update occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: assignment.id,
            notifyProjectId: update.project_id ?? assignment.project_id,
            notifyResourceId: update.resource_id ?? assignment.resource_id,
            notifyContext: "assignment-edit",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }
   };

   deleteAssignmentFromModal = async ({
      assignment,
      showAlertModal,
   }: {
      assignment: SerializedFindAssignment;
      showAlertModal: boolean;
   }): Promise<void> => {
      await this.deleteRow(assignment);

      modalManager.clearModal();

      // NOTE: Alerting must be AFTER the deletion occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: assignment.id,
            notifyProjectId: assignment.project_id,
            notifyResourceId: assignment.resource_id,
            notifyContext: "assignment-delete",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }
   };

   showAssignmentMessageModal = (modalData: ModalData, callback: () => void): void => {
      alertManager.showAssignmentMessageModal(modalData, null, callback);
   };

   async updateCustomFields(
      assignments: SerializedFindAssignment[],
      customFieldMeta: CustomFieldMeta,
      value: CustomFieldInstance["value"] | null,
   ): Promise<void> {
      await this.updateRows({
         assignments,
         update: {
            custom_fields: {
               [customFieldMeta.field_id]: value,
            },
         },
         rowApplier: (assignment) => {
            return customFieldUpdateRowApplier({
               customFieldMeta,
               row: assignment,
               value,
            });
         },
      });
   }

   private formatDayLong(day: number) {
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat(), {
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
         weekdayFormat: DateUtils.WeekDayFormat.NONE,
      });
   }

   private async updateRows({
      assignments,
      update,
      rowApplier,
   }: {
      assignments: SerializedFindAssignment[];
      update: Omit<Partial<UpdateAssignmentPayload>, "id">;
      rowApplier: (assignment: SerializedFindAssignment) => SerializedFindAssignment | null;
   }) {
      await this.rowsUpdater.update({
         size: assignments.length,
         updatePayload: assignments.map((a) => ({ id: a.id, ...update })),
         rowApplier,
      });
   }

   async batchDeleteRows(assignments: SerializedFindAssignment[]): Promise<string[]> {
      const ids = assignments.map((a) => a.id);
      await this.rowsRemover.delete({ ids });
      this.totalPossible(this.totalPossible() - assignments.length);
      return ids;
   }

   private async deleteRow(assignment: SerializedFindAssignment) {
      await Assignment2Store.deleteAssignment(assignment.id).payload;
      this.rows.remove(assignment);
      this.totalPossible(this.totalPossible() - 1);
   }

   canAccessAssignmentProject(assignment: SerializedFindAssignment): boolean {
      return (
         authManager.isAdmin() ||
         assignment.project.group_ids.some((groupId) =>
            authManager.getContextAccessibleGroupIds().has(groupId),
         )
      );
   }

   canAccessAssignmentPerson(assignment: SerializedFindAssignment): boolean {
      const groupIds = assignment.person.group_ids;
      // People without group_ids are admins, and are always accessible.
      if (groupIds == null || groupIds.length === 0) return true;
      return (
         authManager.isAdmin() ||
         groupIds.some((groupId) => authManager.getContextAccessibleGroupIds().has(groupId))
      );
   }

   private canManageAssignments(): boolean {
      return authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ASSIGNMENTS);
   }

   canEditAssignment = (assignment: SerializedFindAssignment): boolean => {
      return (
         this.canManageAssignments() &&
         this.canAccessAssignmentPerson(assignment) &&
         this.canAccessAssignmentProject(assignment)
      );
   };
}

function isWorkDayValidator(
   field: string,
   assignment: SerializedFindAssignment,
   detachedDay: number | null,
) {
   const dayOfWeek = detachedDay ? DateUtils.getAttachedDate(detachedDay).getDay() : -1;
   if (!assignment.work_days[dayOfWeek as keyof AssignmentWorkDays]) {
      return {
         status: false,
         message: `${field} must be on an active workday.`,
      };
   }
   return { status: true, message: null };
}

function isPercentAllocatedGtZero(val: number | null) {
   if (val === null || val <= 0) {
      return {
         status: false,
         message: "Percent Allocation must be greater than 0.",
      };
   }
   return {
      status: true,
      message: null,
   };
}

function timeValidator(field: string, assignment: SerializedFindAssignment) {
   if (assignment.percent_allocated) {
      return {
         status: false,
         message: `${field} cannot be set when Percent Allocation is set.`,
      };
   }

   return {
      status: true,
      message: null,
   };
}

function percentAllocatedValidator(
   assignment: SerializedFindAssignment,
): (val: number | null) => { status: boolean; message: null | string } {
   return (val: number | null) => {
      const constraint = isPercentAllocatedGtZero(val);
      if (!constraint.status) return constraint;

      if (assignment.start_time || assignment.end_time) {
         return {
            status: false,
            message: "Percent Allocation can not be set when a start time or end time is set.",
         };
      }

      return {
         status: true,
         message: null,
      };
   };
}
