import template from "./gantt.pug";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import renderReactComponent from "@/react/render-react-component";
import type { Root } from "react-dom/client";

export class NewGanttViewModel extends PageContentViewModel {
   private root: Root | null;
   constructor() {
      super(template(), "New Gantt (Beta)");
      this.root = null;
   }

   addedToParent(): void {
      const root = renderReactComponent("gantt-react-mount", "GanttContainer", {});
      this.root = root;
   }

   removedFromParent(): void {
      // When this VM is removed (ex. user navs to a new page) we need to
      // unmount the react root from the DOM. This will clean up subscriptions and
      // fire useEffect callbacks as necessary.
      if (this.root) {
         this.root.unmount();
      }
   }
}
