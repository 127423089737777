import type { DropDownItem } from "@/lib/components/drop-downs/drop-down";
import { authManager } from "@/lib/managers/auth-manager";
import type { Person } from "@/models/person";
import { UserPreferences } from "@/models/person";
import { ValueSet } from "@/models/value-set";
import { GroupStore } from "@/stores/group-store.core";
import type { CardSubtitle } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type { Observable, ObservableArray, PureComputed } from "knockout";
import { pureComputed } from "knockout";
import { observable, observableArray } from "knockout";
import type { UpdateUserPreferencePayload } from "@laborchart-modules/lc-core-api/dist/api/people/update-user";
import { SettingsStore } from "@/stores/settings-store.core";
import { MyProfileViewModel } from "../my-profile/my-profile";
import { GroupDropDownPane } from "@/lib/components/drop-downs/panes/group-drop-down-pane";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import type { Group } from "@laborchart-modules/common";

class ViewPreferencesViewModelCoreState {
   readonly groupOptions: ObservableArray<ValueSet>;
   readonly person: Observable<Person>;
   readonly selectedDefaultGroup: Observable<ValueSet | null>;
   readonly displayLastNamesFirst: Observable<boolean>;
   readonly requestPerDay: Observable<boolean | null>;
   readonly benchSelectedSubtitle: Observable<DropDownItem<string> | null>;
   readonly boardSelectedSubtitle: Observable<DropDownItem<string> | null>;
   readonly canSave: PureComputed<boolean> = pureComputed(
      () =>
         this.displayLastNamesFirst() != this.person()!.preferences()!.displayLastNamesFirst() ||
         this.requestPerDay() != this.person()!.preferences()?.requestPerDay() ||
         (!this.selectedDefaultGroup()?.value() &&
            this.person()!.preferences()?.defaultGroupId()) ||
         (this.selectedDefaultGroup()?.value() &&
            this.selectedDefaultGroup()?.value() !=
               this.person()!.preferences()?.defaultGroupId()) ||
         ((this.person()!.preferences()?.benchCardsSubtitle() ||
            this.benchSelectedSubtitle()?.value() !=
               MyProfileViewModel.DEFAULT_BENCH_CARD_SUBTITLE) &&
            this.person()!.preferences()?.benchCardsSubtitle() !=
               this.benchSelectedSubtitle()?.value()) ||
         ((this.person()!.preferences()?.boardCardsSubtitle() ||
            this.boardSelectedSubtitle()?.value() !=
               MyProfileViewModel.DEFAULT_BOARD_CARD_SUBTITLE) &&
            this.person()!.preferences()?.boardCardsSubtitle() !=
               this.boardSelectedSubtitle()?.value()),
   );
   public groupDropdownParams;

   constructor() {
      this.groupOptions = observableArray();
      this.person = observable(authManager.authedUser()!);
      this.selectedDefaultGroup = observable(null);
      this.displayLastNamesFirst = observable<boolean>(
         this.person().preferences()?.displayLastNamesFirst() ?? false,
      );
      this.requestPerDay = observable<boolean | null>(this.person().preferences()!.requestPerDay());
      this.benchSelectedSubtitle = observable(null);
      this.boardSelectedSubtitle = observable(null);
      this.groupDropdownParams = {
         panes: [
            new GroupDropDownPane({
               transformer: (group) => group,
            }),
         ],
         placeholder: "Select a Group",
         cellFactory: ColorCircleTextCell.factory((group: Group) => ({
            text: group.name,
            color: group.color,
         })),
         isClearable: false,
         selectedItem: pureComputed({
            read: () => this.selectedDefaultGroup(),
            write: (group) => {
               if (group) {
                  this.selectedDefaultGroup(
                     new ValueSet({
                        name: String(group.name),
                        value: group.id,
                        id: group.id,
                        color: group.color ? String(group.color) : undefined,
                        selected: true,
                     }),
                  );
               }
            },
         }),
      };

      this.getSelectedGroup();
   }

   saveProfile = async (): Promise<void> => {
      const preferences: UpdateUserPreferencePayload = {};
      if (this.displayLastNamesFirst() != this.person().preferences()?.displayLastNamesFirst()) {
         preferences.display_last_names_first = this.displayLastNamesFirst()!;
      }

      if (this.requestPerDay() != this.person().preferences()?.requestPerDay()) {
         preferences.request_per_day = this.requestPerDay()!;
      }

      if (
         (this.person().preferences()?.benchCardsSubtitle() ||
            this.benchSelectedSubtitle()?.value()) &&
         this.person().preferences()?.benchCardsSubtitle() != this.benchSelectedSubtitle()?.value()
      ) {
         preferences.bench_cards_subtitle = this.benchSelectedSubtitle()?.value() as CardSubtitle;
      }

      if (
         (this.person().preferences()?.boardCardsSubtitle() ||
            this.boardSelectedSubtitle()?.value()) &&
         this.person().preferences()?.boardCardsSubtitle() != this.boardSelectedSubtitle()?.value()
      ) {
         preferences.board_cards_subtitle = this.boardSelectedSubtitle()?.value() as CardSubtitle;
      }

      if (!this.selectedDefaultGroup()?.value() && this.person().preferences()?.defaultGroupId()) {
         preferences.default_group_id = null;
      } else if (
         this.selectedDefaultGroup()?.value() &&
         this.person().preferences()?.defaultGroupId() != this.selectedDefaultGroup()?.value()
      ) {
         preferences.default_group_id = this.selectedDefaultGroup()?.value();
      }

      try {
         const result = await SettingsStore.updateUser({ preferences }).payload;
         const updatedPreferences = new UserPreferences(result.data.preferences);
         this.person().preferences(updatedPreferences);
         this.getSelectedGroup();
      } catch (err) {
         return console.log("Error: ", err);
      }
   };

   async getSelectedGroup(): Promise<void> {
      if (this.person().preferences()?.defaultGroupId() != null) {
         if (this.person().preferences()?.defaultGroupId() == "my-groups") {
            this.selectedDefaultGroup(
               new ValueSet({
                  name: "My Groups",
                  value: "my-groups",
                  id: "my-groups",
                  selected: true,
               }),
            );
         } else {
            const response = await GroupStore.getGroup(
               String(this.person().preferences()?.defaultGroupId()),
            ).payload;
            this.selectedDefaultGroup(
               new ValueSet({
                  name: response.data.name,
                  value: response.data.id,
                  id: response.data.id,
                  color: response.data.color ?? undefined,
                  selected: true,
               }),
            );
         }
      }
   }
}

export class ViewPreferencesViewModelCore {
   readonly state: ViewPreferencesViewModelCoreState;
   constructor() {
      this.state = new ViewPreferencesViewModelCoreState();
   }
}
