import type { Drake } from "dragula";
import dragula from "dragula";
import type { Observable, ObservableArray } from "knockout";
import { observable, observableArray } from "knockout";

const NO_DRAG_DESCENDENTS_CLASS_NAME = "dragula-no-drag-descendents";
const DRAGULA_NO_DRAG_CLASS_NAME = "dragula-no-drag";

export enum SignificantClasses {
   SOURCE_ONLY = "dragula-source-destination-only",
   NO_DROP = "dragula-no-drop",
}

export type ContainerCallbacks = {
   callback: (
      droppedElement: Element,
      endContainer: Element,
      endRegisteredData: unknown,
      sourceContainer: Element,
      sourceRegisteredData: unknown,
   ) => void;
   onDrag: (el: Element, onDrag: () => boolean) => void;
   data: unknown | null;
};

export class DragManager {
   readonly noDragClasses: ObservableArray<string>;
   readonly containerCallbacks: Observable<Record<string, ContainerCallbacks>>;
   drake: Drake | null = null;

   constructor() {
      this.noDragClasses = observableArray(["dragula-no-drag"]);
      this.containerCallbacks = observable({});
   }

   readonly initDragula = (): Drake => {
      // This needs to be done here instead of the constructor so that it can have access to the
      // DOM when it is instantiated.
      this.drake = dragula({
         moves: (el, container, handle) => {
            return (
               this.checkMoveHandle(handle?.classList ?? []) &&
               this.checkMoveHandleTree(container ?? null, handle ?? null)
            );
         },
         accepts: (el, target) => {
            return (
               target?.className.indexOf(SignificantClasses.SOURCE_ONLY) === -1 &&
               target.className.indexOf(SignificantClasses.NO_DROP) === -1
            );
         },
         revertOnSpill: true,
      });
      return this.setupDrakeHandlers(this.drake);
   };

   readonly addContainer = (containerElement: Element): number => {
      return this.drake!.containers.push(containerElement);
   };

   readonly setupDrakeHandlers = (drake: Drake): Drake => {
      drake.on("drop", this.fireContainerCallback);
      return drake.on("drag", this.fireContainerOnDrag);
   };

   readonly registerContainerCallback = (
      containerId: string,
      options: Omit<ContainerCallbacks, "data">,
      containerData?: unknown,
   ): void => {
      const registeringData = {
         callback: options.callback,
         onDrag: options.onDrag,
         data: containerData ?? null,
      };
      this.containerCallbacks()[containerId] = registeringData;
   };

   readonly fireContainerOnDrag = (elementPickedUp: Element, sourceContainer: Element): void => {
      const containerCallbacks = this.containerCallbacks();
      const sourceContainerId =
         Object.values(sourceContainer.classList).find(
            (className: string) => className in containerCallbacks,
         ) ?? null;

      if (sourceContainerId != null) {
         const registeredData = this.containerCallbacks()[sourceContainerId];
         const isDragging = () => {
            return this.drake!.dragging;
         };
         const onDrag = registeredData?.onDrag ?? null;
         if (onDrag != null) {
            return onDrag(elementPickedUp, isDragging);
         }
      }
   };

   readonly fireContainerCallback = (
      droppedElement: Element,
      endContainer: Element,
      sourceContainer: Element,
   ): void => {
      const containerCallbacks = this.containerCallbacks();
      const endContainerId =
         Object.values(endContainer.classList).find(
            (className) => className in containerCallbacks,
         ) ?? null;
      const sourceContainerId =
         Object.values(sourceContainer.classList).find(
            (className) => className in containerCallbacks,
         ) ?? null;
      if (endContainerId != null) {
         const endRegisteredData = this.containerCallbacks()[endContainerId];
         const sourceRegisteredData =
            sourceContainerId != null ? this.containerCallbacks()[sourceContainerId].data : null;
         return endRegisteredData.callback(
            droppedElement,
            endContainer,
            endRegisteredData.data,
            sourceContainer,
            sourceRegisteredData,
         );
      }
   };

   readonly checkMoveHandle = (classNames: DOMTokenList | string[]): boolean => {
      return Object.values(classNames).every((name) => this.noDragClasses.indexOf(name) === -1);
   };

   readonly checkMoveHandleTree = (container: Element | null, handle: Element | null): boolean => {
      if (handle === null || handle === container) {
         return true;
      } else if (handle.classList.contains(NO_DRAG_DESCENDENTS_CLASS_NAME)) {
         return false;
      } else {
         // Recurse.
         return this.checkMoveHandleTree(container, handle.parentElement);
      }
   };

   readonly maybeAddNoDragClass = (classNames: DOMTokenList | string[]): Array<number | null> => {
      const results: Array<number | null> = [];
      classNames.forEach((className: string) => {
         if (this.noDragClasses.indexOf(className) === -1) {
            results.push(this.noDragClasses.push(className));
         } else {
            results.push(null);
         }
      });
      return results;
   };

   readonly clearData = (): unknown => {
      this.noDragClasses([DRAGULA_NO_DRAG_CLASS_NAME, NO_DRAG_DESCENDENTS_CLASS_NAME]);
      return this.containerCallbacks({});
   };
}

export const dragManager = new DragManager();
