import "./create-edit-group-pane.styl"
import template from "./create-edit-group-pane.pug"
import ko from "knockout"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"
import { IconPickerPane } from "@/lib/components/popup/icon-picker-pane"

### Models ###
import { Group } from "@/models/group"

export class CreateEditGroupPane extends ModalPane
   constructor: (editingGroup) ->
      super("Create Group", "Save", template())
      @title("Edit Group") if editingGroup?
      @overflowable(true)
      @group = ko.observable(if editingGroup? then editingGroup else new Group({}, true))
      @selectedTimezone = ko.observable(@group().timezone() or null)

      @colorOptions = ko.observableArray()
      @selectedColor = ko.observable(@group().color() or null)
      @groupPhoto = ko.observable(@group().picUrl() or null)
      @groupIcon = ko.observable(@group().iconName() or null)

      @colorSelectorPopupBuilder = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@selectedColor)],
            ['cp-select-color-btn', 'cp-select-color-btn__color'],
            ['create-project__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['cp-select-color-btn__color']}
      }

      @iconPickerPopupBuilder = =>
         return new Popup("Group Icons", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new IconPickerPane(@groupIcon)],
            ['create-group__choose-icon-btn'],
            ['create-project__color-popup'])

      @iconPickerPopupWrapper = {
         popupBuilder: @iconPickerPopupBuilder
         options: {triggerClasses: ['create-group__choose-icon-btn']}
      }

      @loadData()

   clearIcon: ->
      @groupIcon(null)

   clearColor: ->
      @selectedColor(null)

   handlePhoto: (url) =>
      return if url == @group().picUrl()
      @groupPhoto(url)

   maybeShowValidationError: (next) =>
      return @displayingNotice(CreateEditGroupPane.Notice.NAME) unless validateInput(@group().name())
      return @displayingNotice(CreateEditGroupPane.Notice.TIMEZONE) unless validateInput(@selectedTimezone())
      @displayingNotice(null)
      next()

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      @maybeShowValidationError =>
         @data.set("name", @group().name())
         @data.set("timezone", @selectedTimezone())
         @data.set("color", @selectedColor())
         @data.set("pic_url", @groupPhoto())
         @data.set("icon_name", @groupIcon())
         @data.set("address_1", @group().address1())
         @data.set("address_2", @group().address2())
         @data.set("city_town", @group().cityTown())
         @data.set("state_province", @group().stateProvince())
         @data.set("zipcode", @group().zipcode())
         @data.set("country", @group().country())
         @data.set("contact_name", @group().contactName())
         @data.set("contact_phone", @group().contactPhone())
         @data.set("contact_email", @group().contactEmail())

         modalManager.modalFinished()

   loadData: ->
      defaultStore.getResourceColorStrings (err, colors) =>
         @colorOptions(colors)

CreateEditGroupPane.Notice = {
   NAME: {
      text: 'Your group needs a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   TIMEZONE: {
      text: 'Your group needs a timezone.'
      info: null
      color: 'red'
      dissmissable: true
   }
}