import type { CustomFieldMeta } from "@/models/column-header";
import type { LegacyCustomFieldInstance } from "@/models/custom-field-instance";
import type { CustomFieldInstance, HasCustomFieldsSchema } from "@laborchart-modules/common";
import type { ObservableArray } from "knockout";

export function customFieldUpdateRowApplier<TRow extends HasCustomFieldsSchema>({
   row,
   customFieldMeta,
   value,
}: {
   row: TRow;
   customFieldMeta: CustomFieldMeta;
   value: CustomFieldInstance["value"] | null;
}): TRow {
   const customFieldInstances: CustomFieldInstance[] = [...row.custom_fields];
   const customFieldIndex = customFieldInstances.findIndex(
      (field) => field.field_id == customFieldMeta.field_id,
   );
   if (customFieldIndex == -1 && value == null) {
      // No changes.
      return row;
   }

   const customField = customFieldInstances[customFieldIndex];
   if (customFieldIndex == -1) {
      // Create new custom field instance.
      customFieldInstances.push({
         field_id: customFieldMeta.field_id,
         name: customFieldMeta.field_property,
         type: customFieldMeta.field_type,
         // TODO: Find a way to type this if possible, but it is a pain.
         value: value as any,

         // TODO: Get real integration_name here.
         // Currently not defining this has no actual affect, since this is only for the frontend.
         integration_name: "",
      });
   } else if (value == null) {
      // Delete existing custom field instance.
      customFieldInstances.splice(customFieldIndex, 1);
   } else {
      // Update existing custom field instance.
      customField.value = value;
      customFieldInstances.splice(customFieldIndex, 1, customField);
   }
   return {
      ...row,
      custom_fields: customFieldInstances,
   };
}

export function updateCustomFieldValue({
   instances,
   field,
}: {
   instances: ObservableArray<LegacyCustomFieldInstance>;
   field: LegacyCustomFieldInstance;
}): void {
   const index = instances().findIndex((f) => f.fieldId == field.fieldId);
   if (index == -1) {
      // Add new custom field.
      instances.push(field);
   } else {
      // Update the custom field.
      instances.splice(index, 1, {
         ...instances()[index],
         value: field.value,
      });
   }
}

export function removeCustomFieldValue({
   instances,
   fieldId,
}: {
   instances: ObservableArray<LegacyCustomFieldInstance>;
   fieldId: string;
}): void {
   const index = instances().findIndex((f) => f.fieldId == fieldId);
   if (index != -1) {
      instances.splice(index, 1);
   }
}
