import template from "./confirm-position-group-change-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmPositionGroupChangePaneViewModel extends ModalPane
   constructor: (positionName) ->
      assertArgs(arguments, String)
      super("Job Title's Groups Change", "", template())
      @positionName = positionName
      @confirmationText = ko.observable()
      @canDelete = ko.pureComputed =>
         return unless @confirmationText()?
         return @confirmationText().toLowerCase() == "yes"

   confirmDelete: ->
      return unless @canDelete()
      modalManager.modalFinished()

