// Markup & Styles
import template from "./people-list-2.pug";
import reactTemplate from "./react-people-list.pug";
import "./people-list-2.styl";

// Base & Utils
import { App } from "../app";
import { router } from "@/lib/router";
import type { Observable, ObservableArray, Subscription } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import * as BrowserStorageUtils from "@/lib/utils/browser-storage";
import { DateUtils } from "@/lib/utils/date";
import { Format } from "@/lib/utils/format";
import { serializedFilters, serializeLegacyFilters } from "@/lib/utils/chip-filter-helper";
import { ValidationUtils } from "@/lib/utils/validation";
import { accumulateCustomFieldChipFilters } from "@/lib/utils/custom-field-helper";
import { formatName } from "@/lib/utils/preferences";
import { Flag } from "@/flags";

// Filters
import type { Filter, LabeledFilterOptions } from "@/lib/components/chip-filter/chip-filter";
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator";

// Auth & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { defaultStore } from "@/stores/default-store";
import { LoadingState } from "@/lib/components/grid/grid-store";
import { PeopleList2GridStore } from "./people-list-2-grid-store";
// Legacy Stores
import type { IntegratedField } from "@/stores/company-store";
import type { GetGroupEntitiesData } from "@/stores/group-store";
// istanbul ignore next
import { PeopleStore } from "@/stores/people-store";
import type { GetFilteredPeopleParams } from "@/stores/people-store";
// Core-Api Stores
import { CompanyStore } from "@/stores/company-store.core";
import { SavedViewStore } from "@/stores/saved-view-store.core";

// Modules, Api, & Schemas
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

// Models
import { PermissionLevel } from "@/models/permission-level";
import { ValueSet } from "@/models/value-set";
import type { ColumnHeaderData } from "@/models/column-header";
import { ColumnHeader } from "@/models/column-header";

// Grid & Editors
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { LinkedTextCell } from "@/lib/components/grid/cells/linked-text-cell";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { CheckboxColumnGroupManager } from "@/lib/components/grid/column-groups/checkbox-column-group-manager";
import type { ColumnTemplate } from "@/lib/components/grid/grid-column-manager";
import { GridColumnManager } from "@/lib/components/grid/grid-column-manager";
import type { GridSortOrder, GridCellFocus } from "@/lib/components/grid/virtual-grid/virtual-grid";
import { MultilineLinkedTextCell } from "@/lib/components/grid/cells/multiline-linked-text-cell";
import { MultilineTextCell } from "@/lib/components/grid/cells/multiline-text-cell";
import { TagsCell } from "@/lib/components/grid/cells/tags-cell";
import { PersonTypeCell } from "@/lib/components/grid/cells/person-type-cell";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { createColumnGroupForEach } from "@/lib/components/grid/grid-column-group";
import { GridCursorState, isActionableCursor } from "@/lib/components/grid/grid-column";
import { ColumnWidthDefault, ColumnWidthMin } from "@/lib/components/grid/column-defaults";
import { DisabledEditor } from "@/lib/components/editors/disabled-editor/disabled-editor";
import { GroupsEditor } from "@/lib/components/editors/groups-editor/groups-editor";
import { DisabledGroupsEditor } from "@/lib/components/editors/disabled-groups-editor/disabled-groups-editor";

// Modals
import { Modal } from "@/lib/components/modals/modal";
import { modalManager } from "@/lib/managers/modal-manager";
import { CreatePersonPaneViewModel } from "@/lib/components/modals/create-person-pane";
import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane";
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";

// Managers
import {
   notificationManagerInstance,
   Icons,
   Notification,
} from "@/lib/managers/notification-manager";

// Reports
// istanbul ignore next
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";

// Tags
import { TagExpirationState } from "@/lib/components/tags/tag-chip";
import type { BatchDeleteValidator } from "@/lib/components/batch-actions/batch-delete/batch-delete";
import type { Conflict } from "@/lib/components/batch-actions/batch-actions";

// Flags
import type {
   FindPeoplePaginatedQueryParams,
   SerializedPeopleListPerson,
} from "@laborchart-modules/lc-core-api/dist/api/people/find-people";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import type {
   SerializedTag,
   SerializedTagInstance,
} from "@laborchart-modules/common/dist/rethink/serializers";
import { Person } from "@/models/person";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import { PositionStore } from "@/stores/position-store.core";
import { TagStore } from "@/stores/tag-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import {
   IndexedFindPeopleSortBy,
   NonIndexedFindPeopleSortBy,
} from "@laborchart-modules/common/dist/reql-builder/procedures/enums/find-people";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { LinkToProcoreCell } from "@/lib/components/grid/cells/link-to-procore-cell";
import { ListViewExportModalPane } from "@/lib/components/modals/list-view-export-modal-pane";
import { ReportType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";
import type { Root } from "react-dom/client";
import renderReactComponent from "@/react/render-react-component";

const ACTIVE_PERSON_CHIP_FILTER: Filter = {
   classifier: undefined,
   classifierLabel: undefined,
   type: "select",
   customFieldId: null,
   filterName: "Status",
   property: "status",
   value: "active",
   valueName: "Active",
};

const COLUMN_KEY_TO_SORT_KEY_MAPPING: Record<string, NonIndexedFindPeopleSortBy> = {
   "current_assignments|job_number": NonIndexedFindPeopleSortBy.CURRENT_ASSIGNMENTS_JOB_NUMBER,
   "current_assignments|start_date": NonIndexedFindPeopleSortBy.CURRENT_ASSIGNMENTS_START_DATE,
   "current_assignments|end_date": NonIndexedFindPeopleSortBy.CURRENT_ASSIGNMENTS_END_DATE,
   "next_assignment|job_number": NonIndexedFindPeopleSortBy.NEXT_ASSIGNMENT_JOB_NUMBER,
   "next_assignment|start_date": NonIndexedFindPeopleSortBy.NEXT_ASSIGNMENT_START_DATE,
   "next_assignment|end_date": NonIndexedFindPeopleSortBy.NEXT_ASSIGNMENT_END_DATE,
};

export class PeopleList2ViewModel extends PageContentViewModel {
   readonly allowExportingData = authManager.checkAuthAction(
      PermissionLevel.Action.ALLOW_EXPORTING_DATA,
   );
   readonly canViewPeopleSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE,
   );
   readonly canViewPeopleFinancials = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS,
   );
   readonly canEditPeopleDetails = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PEOPLE_DETAILS,
   );
   readonly canDeletePeople = authManager.checkAuthAction(PermissionLevel.Action.DELETE_PEOPLE);
   readonly canViewPeopleTags = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_PEOPLE_TAGS,
   );
   readonly canEditPeopleTags = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PEOPLE_TAGS,
   );
   readonly canEditPeopleSensitive = authManager.checkAuthAction(
      PermissionLevel.Action.EDIT_PEOPLE_SENSITIVE,
   );
   readonly hasBatchEditPermssions = this.canEditPeopleDetails || this.canEditPeopleTags;

   readonly hasTagCategoriesEnabled = Boolean(authManager.companyModules()?.tagCategories);

   readonly canCreateMessages = authManager.checkAuthAction(PermissionLevel.Action.CREATE_MESSAGES);
   readonly canCreatePeople = authManager.checkAuthAction(PermissionLevel.Action.CREATE_PEOPLE);

   // TODO: Put a real type here.
   private savedView: any;

   readonly sortOrder = observable<GridSortOrder>({
      columnKey: "name",
      direction: Order.ASCENDING,
   });
   readonly store: Observable<PeopleList2GridStore>;
   readonly selectedIds = observableArray<string>();
   readonly viewConfigured = observable(false);

   readonly chipFilterMediator = new ChipFilterMediator();
   readonly defaultChips = [
      {
         ...ACTIVE_PERSON_CHIP_FILTER,
      },
   ];
   readonly filterChips: ObservableArray<Filter>;
   readonly cellFocus = observable<GridCellFocus | null>(null);
   readonly labeledFilterOptions = pureComputed(() => this.createLabeledFilterOptions());
   readonly noPeopleMessage = pureComputed(() => {
      return this.filterChips().length ? "No Matching People" : "No People";
   });
   readonly paddingAroundColumns = pureComputed(() => {
      return this.canCreateMessages || this.canCreatePeople ? 8 : 16;
   });
   readonly batchEditors = pureComputed(() => {
      return this.store()
         .batchEditFields(this.isFieldEditable.bind(this))
         .concat(this.columnManager()?.getCustomColumnBatchEditors() ?? []);
   });
   readonly conflictModalColumnGroups = pureComputed(() => {
      return this.createRowIdentifierColumnGroups();
   });

   readonly messagePeopleDisabled = pureComputed(() => {
      return this.selectedPeople().length <= 0 || !this.canCreateMessages;
   });

   //#region Batch Delete
   readonly batchDeleteValidator: BatchDeleteValidator<SerializedPeopleListPerson> = (
      selectedPeople,
   ) => {
      const conflicts: Array<Conflict<SerializedPeopleListPerson>> = [];

      const valid = selectedPeople.filter((person) => {
         if (person.id === authManager.authedUserId()) {
            conflicts.push({
               record: person,
               reason: "Cannot batch-delete yourself",
            });
            return false;
         } else if (person.permission_level?.is_admin) {
            conflicts.push({
               record: person,
               reason: "Cannot batch-delete admin users",
            });
            return false;
         }

         return true;
      });

      return {
         conflicts,
         valid,
      };
   };

   readonly batchDeleteAction = async (
      stagedForDeletion: SerializedPeopleListPerson[],
   ): Promise<void> => {
      const deletedIds = await this.store().batchDeleteRows(stagedForDeletion);
      this.selectedIds.removeAll(deletedIds);
   };

   private readonly batchDeleteModalNote: string =
      "Are you absolutely sure you want to permanently delete the selected People? Doing so will truncate any current Assignments, as well as cancel all future Assignments for these People.";
   //#endregion

   readonly selectedPeople = pureComputed<SerializedPeopleListPerson[]>(() => {
      return this.selectedIds()
         .map(
            (id) =>
               this.store()
                  .rows()
                  .find((person) => person.id == id)!,
         )
         .filter((person) => person != null);
   });

   readonly peopleCountText = pureComputed(() => {
      if (
         this.store().loadingState() == LoadingState.INITIAL ||
         this.columnManager()!.loadingState() != "loaded"
      )
         return "Showing ...";
      const selectedCount = this.selectedPeople().length;
      const totalPossible = this.store().totalPossible();
      if (this.columnManager()?.columnGroups().length === 0 || totalPossible === 0)
         return "Showing 0";
      if (selectedCount === totalPossible) {
         return `All ${totalPossible} People Selected`;
      }
      if (selectedCount > 0 && totalPossible > 0) {
         return `${selectedCount}/${totalPossible} Selected`;
      }
      return `Showing ${totalPossible}`;
   });

   private readonly groupEntities = observable<GetGroupEntitiesData | null>();
   private readonly subscriptions: Subscription[] = [];
   private isSelectedGroupIdChanging = false;
   private readonly integratedFields: Observable<Map<string, IntegratedField>> = observable(
      new Map(),
   );
   private readonly checkboxColumnGroupManager: CheckboxColumnGroupManager<SerializedPeopleListPerson>;

   readonly columnManager = observable<GridColumnManager<SerializedPeopleListPerson> | null>(null);

   readonly searchQuery = observable();

   readonly disableExportBtn = pureComputed(() => {
      const totalRows = this.store().rows().length;
      const totalColumns = this.columnManager()?.columnGroups().length ?? 0;
      return totalColumns === 0 || totalRows === 0;
   });

   // TODO: Remove when ENABLE_BATCH_DELETE flag is no longer needed
   private readonly batchDeleteEnabled: boolean = Flag.ENABLE_BATCH_DELETE;

   private readonly reactEnabled: boolean;
   private reactRoot: Root | null;

   constructor(queryParams: Record<string, string | boolean> | null = {}) {
      super(
         LaunchDarklyClient.getFlagValue("people-list-react-enabled")
            ? reactTemplate()
            : template(),
         "List",
      );
      this.reactEnabled = LaunchDarklyClient.getFlagValue("people-list-react-enabled");
      this.reactRoot = null;

      this.filterChips = observableArray();

      this.store = observable(this.createStore({ startFromCurrentCursor: true }));

      this.checkboxColumnGroupManager = new CheckboxColumnGroupManager({
         selectedIds: this.selectedIds,
         allIds: pureComputed<string[]>(() => {
            return this.store()
               .rows()
               .map((person) => person.id);
         }),
      });

      this.columnManager(
         new GridColumnManager({
            listViewType: PeopleStore.ListViewType.People,
            templates: this.createColumnTemplates(),
            customFieldConfig: {
               fieldEntity: ColumnEntityType.PEOPLE,
               // NOTE: Integrated fields use the custom field ID, while sensitive fields use the custom field property integration name.
               isEditableProvider: (meta) =>
                  this.isFieldEditable(meta.field_property) && this.isFieldEditable(meta.field_id),
               isVisibleProvider: (columnHeader) => this.customFieldIsVisible(columnHeader),
               valueExtractor: (person, columnHeader) =>
                  this.customFieldValueExtractor(person, columnHeader),
               ...(this.canEditPeopleDetails
                  ? {
                       fieldEntity: ColumnEntityType.PEOPLE,
                       saveProvider: (rows, columnHeader, value) => {
                          return this.store().updateCustomFields(rows, columnHeader.meta()!, value);
                       },
                    }
                  : {}),
            },
            projectRolesConfig: null,
            hasSortableCustomColumns: true,
         }),
      );

      // Check if we want to load from saved view or not.
      if (queryParams && queryParams.viewId) {
         this.columnManager()!.load(true);
         this.loadSavedView(queryParams.viewId as string);
      } else {
         const viewOptions: { [key: string]: any } = {};

         if (queryParams && (queryParams.sortBy || queryParams.sortDirection)) {
            const sortOrder = this.sortOrder();
            viewOptions["sortOrder"] = {
               columnKey: queryParams.sortBy || sortOrder.columnKey,
               direction: queryParams.sortDirection
                  ? queryParams.sortDirection == Order.DESCENDING
                     ? Order.DESCENDING
                     : Order.ASCENDING
                  : sortOrder.direction,
            };
         }
         if (queryParams && ValidationUtils.validateInput(queryParams.query)) {
            viewOptions["search"] = decodeURIComponent(queryParams.query);
         }

         viewOptions["filters"] = BrowserStorageUtils.getPageFilterChips() || this.defaultChips;

         this.setupViewConfig(viewOptions);
         this.columnManager()!.load();
      }

      this.loadGroupEntities();
      this.loadIntegratedFields();
   }

   addedToParent(): void {
      // If react is enabled, attach the PeopleListContainer component to the DOM
      if (this.reactEnabled) {
         this.reactRoot = renderReactComponent(
            "react-people-list-mount",
            "PeopleListContainer",
            {},
         );
      }
   }

   removedFromParent(): void {
      if (this.reactRoot) {
         this.reactRoot.unmount();
      }
   }

   onMessagePeople = (): void => {
      if (this.messagePeopleDisabled()) return;
      const people = this.selectedPeople();
      const selectedPeopleObservable = observableArray(
         people.map(
            (person) =>
               new Person({
                  ...person,
                  first_name: person.name.first,
                  last_name: person.name.last,
                  can_recieve_email: person.can_receive_email,
                  can_recieve_sms: person.can_receive_sms,
                  can_recieve_mobile: person.can_receive_mobile,
                  address_1: person.address_1 ?? null,
                  address_2: person.address_2 ?? null,
                  state_province: person.state_province ?? null,
                  city_town: person.city_town ?? null,
                  country: person.country ?? null,
                  phone: person.phone ?? null,
                  email: person.email ?? null,
                  hourly_wage: person.hourly_wage ?? null,
                  tag_instances: [],
               }),
         ),
      );
      const panes = [
         new CreateMessageOrAlertPane(
            {},
            "Message People",
            selectedPeopleObservable,
            true,
            null,
            null,
         ),
      ];
      const modal = new Modal();
      modal.setPanes(panes);
      modalManager.showModal(modal, null, { class: "create-message-modal" });
   };

   onExportClicked = (): void => {
      // lc-core-api version
      const queryParams = this.createQueryParams();
      const pane = new ListViewExportModalPane(
         {
            ...queryParams,
            group_id: authManager.selectedGroupId() || "my-groups",
            column_headers: this.columnManager()
               ?.getActiveColumnHeaders()
               .map((header) => {
                  const value = header.allToJson();
                  delete value.baggage;
                  // Make sure null values are undefined.
                  if (value.key_id == null) {
                     delete value.key_id;
                  }
                  return value;
               }),
            display_last_names_first:
               authManager.authedUser()?.preferences()?.displayLastNamesFirst() || false,
         },
         "People List Report", // title
         "people-list-report", // reportTargetPath
         ReportType.PEOPLE_LIST, // reportType
      );
      const modal = new Modal();
      modal.setPanes([pane]);
      modalManager.showModal(modal, null, { class: "list-view-export-modal-pane" });
   };

   onSearchPeople = (): void => {
      this.reload({ startFromCurrentCursor: false });
   };

   onCreateNewPerson(): void {
      const createPersonModal = new Modal();
      const createPersonPane = new CreatePersonPaneViewModel();
      createPersonModal.setPanes([createPersonPane]);
      modalManager.showModal<{ person: SerializedPeopleListPerson }>(
         createPersonModal,
         null,
         { class: "create-person-modal" },
         (_, exitStatus, data) => {
            if (exitStatus != "finished") {
               return;
            }
            const { person } = data.data;

            router.navigate(
               App.RouteName.PERSON_DETAIL,
               `/groups/${authManager.selectedGroupId()}/people/${person.id}`,
            );
         },
      );
   }

   dispose(next: (() => void) | null): void {
      this.subscriptions.forEach((s) => s.dispose());
      this.columnManager()?.dispose();
      this.checkboxColumnGroupManager.dispose();
      if (next) next();
   }

   // TODO: Sort all these types out.
   private setupViewConfig = (options: {
      sortOrder?: { columnKey: string; direction: Order };
      filters?: Filter[];
      search?: string;
      columnHeaders?: ColumnHeader[];
   }) => {
      if (options.sortOrder) {
         this.sortOrder(options.sortOrder);
      }
      if (options.filters) {
         this.filterChips(options.filters);
         setTimeout(() => {
            this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));
         }, 0);
      }

      if (options.search) {
         this.searchQuery(options.search);
      }

      if (options.columnHeaders) {
         // This should only get hit by saved views.
         this.columnManager()?.updateColumnHeaders(options.columnHeaders, true);
      }

      this.reload({ startFromCurrentCursor: false });
      this.setupSubscriptions();
   };

   private setupSubscriptions = () => {
      this.subscriptions.push(
         authManager.selectedGroupId.subscribe(this.onSelectedGroupIdChanged, this),
         this.filterChips.subscribe(this.onFilterChipsChanged, this),
         this.sortOrder.subscribe(this.onSortOrderChanged, this),
         this.checkboxColumnGroupManager.hasAllChecked.subscribe(
            this.onHasAllRowsCheckedChanged,
            this,
         ),
         this.searchQuery.subscribe(this.onSearchQueryChanged, this),
      );
   };

   private async loadSavedView(savedViewId: string) {
      const savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload;
      this.savedView = savedViewPayload.data;

      // TODO: Check that the view is valid.

      this.setTitle(this.savedView.name);

      const viewOptions: { [key: string]: any } = {};

      if (this.savedView.view_config.sort_by && this.savedView.view_config.sort_direction) {
         viewOptions["sortOrder"] = {
            columnKey: this.savedView.view_config.sort_by,
            direction: this.savedView.view_config.sort_direction,
         };
      }

      if (this.savedView.chip_filters) {
         viewOptions["filters"] = this.savedView.chip_filters.map((item: any) => {
            return Format.snakeCaseObjectToCamelCase(item);
         });
      }

      if (this.savedView.search) {
         viewOptions["search"] = this.savedView.search;
      }

      if (this.savedView.view_config.column_headers) {
         const headers = this.savedView.view_config.column_headers;
         viewOptions["columnHeaders"] = headers.map((item: ColumnHeaderData) => {
            return new ColumnHeader(item);
         });
      }

      this.setupViewConfig(viewOptions);
   }

   private onHasAllRowsCheckedChanged(hasAllRowsChecked: boolean) {
      if (hasAllRowsChecked) {
         this.store().loadAll();
      } else {
         this.store().cancelLoadAll();
      }
   }

   private getTagInstanceState(
      tagInstance: SerializedTagInstance & { tag: SerializedTag },
   ): TagExpirationState {
      const expirationDate = tagInstance.expr_date;
      if (!expirationDate) return TagExpirationState.NOT_EXPIRING_SOON;
      const detachedDayNow = DateUtils.getDetachedDay(new Date());
      if (expirationDate <= detachedDayNow) return TagExpirationState.EXPIRED;
      const daysBetween = DateUtils.getDaysBetweenDetachedDays(detachedDayNow, expirationDate);
      const numOfDaysToWarn = tagInstance.tag.expr_days_warning;
      if (!numOfDaysToWarn) return TagExpirationState.NOT_EXPIRING_SOON;

      if (daysBetween <= numOfDaysToWarn) {
         return TagExpirationState.EXPIRING_SOON;
      }

      return TagExpirationState.NOT_EXPIRING_SOON;
   }

   private async loadGroupEntities() {
      const positionOptions: ValueSet[] = [];
      const tagOptions: ValueSet[] = [];
      const categorizedTagOptions: Record<string, ValueSet[]> = { Uncategorized: [] };
      if (authManager.companyModules()?.tagCategories) {
         categorizedTagOptions;
      }
      const peopleCustomFieldFilters: SerializedCustomField[] = [];
      const query = {
         ...(authManager.selectedGroupId() == "my-groups"
            ? {}
            : { group_id: authManager.selectedGroupId() }),
      };

      const loadPositions = async () => {
         const positionStream = await PositionStore.findPositionsStream(query).stream;
         for await (const position of positionStream) {
            positionOptions.push(
               new ValueSet({
                  name: position.name,
                  value: position.id,
                  color: position.color,
               }),
            );
         }
      };

      const loadTags = async () => {
         const tagStream = await TagStore.findTagsStream({}).stream;
         if (authManager.companyModules()?.tagCategories) {
            for await (const tag of tagStream) {
               if (tag.categories.length != 0) {
                  for (const category of tag.categories) {
                     const valueSet = new ValueSet({
                        name: tag.name,
                        color: tag.color,
                        value: tag.id,
                     });
                     if (categorizedTagOptions[category] == null)
                        categorizedTagOptions[category] = [];
                     categorizedTagOptions[category].push(valueSet);
                  }
               } else {
                  categorizedTagOptions["Uncategorized"].push(
                     new ValueSet({ name: tag.name, value: tag.id, color: tag.color }),
                  );
               }
            }
         } else {
            for await (const tag of tagStream) {
               tagOptions.push(new ValueSet({ color: tag.color, name: tag.name, value: tag.id }));
            }
         }
      };

      const loadCustomFields = async () => {
         const customFieldStream = await CustomFieldStore.findCustomFieldsStream({
            is_on_entities: [CustomFieldEntity.PERSON],
         }).stream;
         for await (const customField of customFieldStream) {
            if (customField.can_filter === true) peopleCustomFieldFilters.push(customField);
         }
      };

      try {
         await Promise.all([loadPositions(), loadTags(), loadCustomFields()]);

         this.groupEntities({
            positionOptions,
            peopleCustomFieldFilters,
            ...(authManager.companyModules()?.tagCategories
               ? { categorizedTagOptions }
               : { tagOptions }),
         });
      } catch (e) {
         Bugsnag.notify(e as NotifiableError, (event) => {
            event.context = "people-list_loadGroupEntities";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("groupEntities", this.groupEntities);
         });
         return notificationManagerInstance.show(
            new Notification({
               icon: Icons.WARNING,
               text: "An unexpected error prevented the filters from loading.",
            }),
         );
      }
   }

   private async loadIntegratedFields() {
      const response = await CompanyStore.getIntegratedFields().payload;
      const integratedFields = new Map<string, IntegratedField>();

      if (Array.isArray(response.data.people_integrated_fields)) {
         response.data.people_integrated_fields.forEach((integratedField) => {
            integratedFields.set(integratedField.property, integratedField);
         });
      }

      this.integratedFields(integratedFields);
   }

   private onSelectedGroupIdChanged(groupId: string) {
      // Set isSelectedGroupIdChanging to notify the rest of the listeners that
      // the group ID is changing and they should respond accordingly.
      this.isSelectedGroupIdChanging = true;

      // Update the filter chips from browser storage. Specify the path manually because the URL
      // has not yet been updated.
      this.filterChips(
         BrowserStorageUtils.getPageFilterChips(
            `${BrowserStorageUtils.BrowserLocalStorageKey.CHIP_FILTERS}_/groups/${groupId}/people-list`,
         ) || this.defaultChips,
      );
      this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));

      // Create a new store to use the new group ID and filter chips.
      this.reload({ startFromCurrentCursor: true });
      this.loadGroupEntities();
      this.isSelectedGroupIdChanging = false;
   }

   private onFilterChipsChanged(filterChips: Filter[]) {
      if (!this.isSelectedGroupIdChanging) {
         this.reload({ startFromCurrentCursor: false });
         BrowserStorageUtils.storePageFilterChips(filterChips);
      }
   }

   private createLabeledFilterOptions(): LabeledFilterOptions {
      const groupEntities = this.groupEntities();
      if (!groupEntities) {
         return {};
      }

      const filterOptions: LabeledFilterOptions = {
         Status: observable<any>({
            property: "status",
            values: [
               new ValueSet({ name: "Active", value: "active" }),
               new ValueSet({ name: "Inactive", value: "inactive" }),
            ],
         }),
         "Person Type": observable<any>({
            property: "person_type",
            values: [
               new ValueSet({ name: "Assignable", value: "assignable" }),
               new ValueSet({ name: "Assignable User", value: "assignable-user" }),
               new ValueSet({ name: "User", value: "user" }),
            ],
         }),
      };

      if (this.canViewPeopleSensitive) {
         Object.assign(filterOptions, {
            Gender: observable({
               property: "is_male",
               values: [
                  new ValueSet({ name: "Female", value: false }),
                  new ValueSet({ name: "Male", value: true }),
               ],
               type: FilterFieldType.BOOL,
            }),
         });
      }

      if (this.canViewPeopleFinancials) {
         Object.assign(filterOptions, {
            Wage: observable({
               property: "hourly_wage",
               disableSearch: false,
               classifiers: [
                  { listLabel: "< (Less Than)", chipLabel: "<", value: "<" },
                  { listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<=" },
                  { listLabel: "= (Equal To)", chipLabel: "=", value: "=" },
                  { listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">=" },
                  { listLabel: "> (Greater Than)", chipLabel: ">", value: ">" },
               ],
               type: "number",
            }),
         });
      }

      if (groupEntities.positionOptions && groupEntities.positionOptions?.length > 0) {
         Object.assign(filterOptions, {
            "Job Titles": observable({
               property: "position_id",
               values: groupEntities.positionOptions,
            }),
         });
      }

      if (authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)) {
         if (groupEntities.tagOptions && groupEntities.tagOptions.length) {
            filterOptions["Tags"] = observable<any>({
               property: "tag_instances",
               type: "multi-select",
               values: Format.keyableSort(groupEntities.tagOptions || [], "name"),
            });
         } else if (groupEntities.categorizedTagOptions) {
            filterOptions["Tags"] = observable<any>({
               property: "tag_instances",
               type: "multi-select",
               classifiers: Object.keys(groupEntities.categorizedTagOptions)
                  .map((key) => ({ listLabel: key, chipLabel: null, value: key }))
                  .sort((a, b) =>
                     a.listLabel.toLowerCase().localeCompare(b.listLabel.toLowerCase()),
                  ),
               classifierPaneName: "Tag Category",
               values: groupEntities.categorizedTagOptions,
               backEnabled: true,
            });
         }
      }

      if (authManager.companyModules()?.customFields && groupEntities.peopleCustomFieldFilters) {
         accumulateCustomFieldChipFilters(filterOptions, groupEntities.peopleCustomFieldFilters, {
            propertyName: "custom_fields",
            sensitiveFields: authManager.peopleSensitiveFields(),
            canViewSensitiveFields: this.canViewPeopleSensitive,
            canViewFinancials: this.canViewPeopleFinancials,
         });
      }

      return filterOptions;
   }

   private createColumnTemplates(): Array<ColumnTemplate<SerializedPeopleListPerson>> {
      const isSensitiveFieldVisible = (field: string) => {
         return this.canViewPeopleSensitive || !authManager.peopleSensitiveFields().includes(field);
      };

      const templateVisitors: Array<{
         visit: () => boolean;
         accept: () => ColumnTemplate<SerializedPeopleListPerson, unknown, unknown, any>; // TODO: Remove any.
      }> = [
         {
            visit: () => this.canCreateMessages || this.batchEditors().length > 0,
            accept: () => ({
               ...this.checkboxColumnGroupManager.columnGroup.columns[0],
               isFixed: true,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: {
                  name: "person-type-heading-cell",
                  params: {
                     width: 46,
                  },
               },
               key: "person-type",
               width: 46,
               isFixed: true,
               cellFactory: PersonTypeCell.factory((data) => ({
                  isAssignable: data.is_assignable,
                  isInvitePending: data.invite_pending ?? false,
                  isUser: data.is_user,
               })),
               cursorStateProvider: () =>
                  isActionableCursor(
                     this.isFieldEditable("person_type") &&
                        authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_PERMISSIONS),
                  ),
               editorFactory: ({ row }) => this.store().personTypeEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: {
                  name: "link-to-procore-heading-cell",
                  textName: "Linked to Procore Directory",
                  params: {},
               },
               key: "has_procore_mapping",
               width: 40,
               isResizable: false,
               isSortable: true,
               cellFactory: LinkToProcoreCell.factory((person) => ({
                  entityType: "person",
                  entityId: person.id,
                  procoreId: person.procore_id,
                  procoreTargetType: person.procore_target_type ?? undefined,
                  searchTerm: person.name.last,
               })),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Name",
               key: "name",
               width: ColumnWidthDefault.PERSON_NAME,
               minWidth: ColumnWidthMin.PERSON_NAME,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...LinkedTextCell.columnProviders((person) => ({
                  text: formatName({
                     first: person.name.first,
                     last: person.name.last,
                  }),
                  href: `/groups/${authManager.selectedGroupId()}/people/${person.id}`,
               })),
               cursorStateProvider: () =>
                  isActionableCursor(
                     this.isFieldEditable("first_name") && this.isFieldEditable("last_name"),
                  ),
               editorFactory: ({ row }) => this.store().personNameEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Job Title",
               key: "position",
               width: ColumnWidthDefault.JOB_TITLE,
               minWidth: ColumnWidthMin.JOB_TITLE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...ColorCircleTextCell.columnProviders((person) => {
                  const position = person.position;
                  return {
                     text: position?.name || "",
                     color: position?.color || null,
                  };
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("position_id")),
               editorFactory: ({ row }) => this.store().jobTitleEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Current Assignment(s)",
               key: "current_assignments",
               width: 170,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...MultilineLinkedTextCell.columnProviders((person) => {
                  const { current_assignments = [] } = person;
                  return {
                     values: current_assignments.map((a) => ({
                        text: a.project_name,
                        href:
                           a.is_restricted_project != true &&
                           this.store().canAccessAssignmentProject(a)
                              ? `/groups/${authManager.selectedGroupId()}/projects/${a.project_id}`
                              : null,
                     })),
                  };
               }),
               groupedColumns: [
                  {
                     header: "Project Number",
                     key: "job_number",
                     isDefault: true,
                     isEnabled: true,
                     isSortable: true,
                     isResizable: true,
                     width: ColumnWidthDefault.PROJECT_NUMBER,
                     minWidth: ColumnWidthMin.PROJECT_NUMBER,
                     ...MultilineTextCell.columnProviders((person) => {
                        const { current_assignments = [] } = person;
                        return {
                           values: current_assignments.map((a) => ({
                              text:
                                 a.is_restricted_project != true && a.job_number != null
                                    ? a.job_number
                                    : "",
                           })),
                        };
                     }),
                  },
                  {
                     header: "Start Date",
                     key: "start_date",
                     isDefault: true,
                     isEnabled: true,
                     isSortable: true,
                     isResizable: true,
                     width: 90,
                     minWidth: ColumnWidthMin.SHORT_DATE,
                     ...MultilineTextCell.columnProviders((person) => {
                        const { current_assignments = [] } = person;
                        return {
                           values: current_assignments.map((a) => ({
                              text:
                                 a.is_restricted_project != true && a.start_day != null
                                    ? DateUtils.formatDate(
                                         DateUtils.getAttachedDate(a.start_day),
                                         defaultStore.getDateFormat(),
                                      )
                                    : "",
                           })),
                        };
                     }),
                  },
                  {
                     header: "End Date",
                     key: "end_date",
                     isDefault: true,
                     isEnabled: true,
                     isSortable: true,
                     isResizable: true,
                     width: 90,
                     minWidth: ColumnWidthMin.SHORT_DATE,
                     ...MultilineTextCell.columnProviders((person) => {
                        const { current_assignments = [] } = person;
                        return {
                           values: current_assignments.map((a) => ({
                              text:
                                 a.is_restricted_project != true && a.end_day != null
                                    ? DateUtils.formatDate(
                                         DateUtils.getAttachedDate(a.end_day),
                                         defaultStore.getDateFormat(),
                                      )
                                    : "",
                           })),
                        };
                     }),
                  },
               ],
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Next Assignment",
               key: "next_assignment",
               width: 170,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...LinkedTextCell.columnProviders((person) => {
                  const { next_assignment = null } = person;
                  return {
                     text: next_assignment ? next_assignment.project_name || "" : "",
                     isItalicized:
                        next_assignment?.is_restricted_project === true || next_assignment != null
                           ? !this.store().canAccessAssignmentProject(next_assignment)
                           : false,
                     href:
                        next_assignment != null &&
                        next_assignment.is_restricted_project != true &&
                        this.store().canAccessAssignmentProject(next_assignment)
                           ? `/groups/${authManager.selectedGroupId()}/projects/${
                                next_assignment.project_id
                             }`
                           : null,
                  };
               }),
               groupedColumns: [
                  {
                     header: "Project Number",
                     key: "job_number",
                     isDefault: true,
                     isEnabled: true,
                     width: ColumnWidthDefault.PROJECT_NUMBER,
                     minWidth: ColumnWidthMin.PROJECT_NUMBER,
                     isSortable: true,
                     isResizable: true,
                     ...TextCell.columnProviders((person) => {
                        const { next_assignment = null } = person;
                        return next_assignment != null &&
                           next_assignment.is_restricted_project != true
                           ? next_assignment.job_number ?? ""
                           : "";
                     }),
                  },
                  {
                     header: "Start Date",
                     key: "start_date",
                     isDefault: true,
                     isEnabled: true,
                     width: 90,
                     minWidth: ColumnWidthMin.SHORT_DATE,
                     isSortable: true,
                     isResizable: true,
                     ...TextCell.columnProviders((person) => {
                        const { next_assignment = null } = person;
                        return next_assignment &&
                           next_assignment.is_restricted_project != true &&
                           next_assignment.start_day
                           ? DateUtils.formatDate(
                                DateUtils.getAttachedDate(next_assignment.start_day),
                                defaultStore.getDateFormat(),
                             )
                           : "";
                     }),
                  },
                  {
                     header: "End Date",
                     key: "end_date",
                     isDefault: true,
                     isEnabled: true,
                     width: 90,
                     minWidth: ColumnWidthMin.SHORT_DATE,
                     isSortable: true,
                     isResizable: true,
                     ...TextCell.columnProviders((person) => {
                        const { next_assignment = null } = person;
                        return next_assignment &&
                           next_assignment.is_restricted_project != true &&
                           next_assignment.end_day
                           ? DateUtils.formatDate(
                                DateUtils.getAttachedDate(next_assignment.end_day),
                                defaultStore.getDateFormat(),
                             )
                           : "";
                     }),
                  },
               ],
            }),
         },
         {
            visit: () => this.canViewPeopleTags,
            accept: () => ({
               header: "Tags",
               key: "tag_instances",
               isDefault: true,
               width: ColumnWidthDefault.TAGS,
               minWidth: ColumnWidthMin.TAGS,
               isSortable: false,
               isResizable: true,
               ...TagsCell.columnProviders((person) => {
                  return {
                     tagInstances: person.tag_instances.map((instance) => {
                        return {
                           abbreviation: instance.tag.abbreviation,
                           color: instance.tag.color,
                           name: instance.tag.name,
                           expirationState: this.getTagInstanceState(instance),
                        };
                     }),
                  };
               }),
               editorFactory: ({ row }) => this.store().tagInstancesEditorFactory([row]),
               cursorStateProvider: () => isActionableCursor(this.canEditPeopleTags),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Status",
               key: "status",
               width: ColumnWidthDefault.PERSON_STATUS,
               minWidth: ColumnWidthMin.PERSON_STATUS,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => Format.capitalize(person.status)),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("status")),
               editorFactory: ({ row }) => this.store().statusEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Employee ID",
               key: "employee_number",
               width: ColumnWidthDefault.EMPLOYEE_NUMBER,
               minWidth: ColumnWidthMin.EMPLOYEE_NUMBER,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.employee_number || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("employee_number")),
               editorFactory: ({ row }) => this.store().employeeIdEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Permission",
               key: "permission",
               width: 105,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  const permission = person.permission_level;
                  if (!permission) return "";
                  return permission.name;
               }),
               cursorStateProvider: (row) =>
                  isActionableCursor(
                     row.is_user &&
                        this.isFieldEditable("permission_level_id") &&
                        authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_PERMISSIONS),
                  ),
               editorFactory: ({ row }) => this.store().permissionEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Notification Profile",
               key: "notification_profile_id",
               width: 155,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  const profile = person.notification_profile;
                  return profile ? profile.name : "";
               }),
               cursorStateProvider: (row) =>
                  isActionableCursor(
                     row.is_user && this.isFieldEditable("notification_profile_id"),
                  ),
               editorFactory: ({ row }) => this.store().notificationProfileEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("phone"),
            accept: () => ({
               header: "Phone Number",
               key: "phone",
               width: 105,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("phone")),
               editorFactory: ({ row }) => this.store().phoneEditorFactory([row]),
               ...TextCell.columnProviders((person) => person.phone || ""),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("email"),
            accept: () => ({
               header: "Email",
               key: "email",
               width: 190,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("email")),
               ...TextCell.columnProviders((person) => person.email || ""),
               editorFactory: ({ row }) => this.store().emailEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("is_male"),
            accept: () => ({
               header: "Gender",
               key: "is_male",
               width: 110,
               isDefault: false,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  const gender = person.gender;
                  return gender == "male" ? "Male" : gender == "female" ? "Female" : "";
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("is_male")),
               editorFactory: ({ row }) => this.store().genderEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Language",
               key: "language",
               isDefault: false,
               isSortable: true,
               width: 100,
               isResizable: true,
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("language")),
               ...TextCell.columnProviders((person) => Format.capitalize(person.language)),
               editorFactory: ({ row }) => this.store().languageEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("hired_date"),
            accept: () => ({
               header: "Hired Date",
               key: "hired_date",
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  return this.convertToFormattedDetachedDay(person.hired_date);
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("hired_date")),
               editorFactory: ({ row }) => this.store().hiredDateEditorFactory([row]),
            }),
         },
         {
            visit: () => this.canViewPeopleFinancials,
            accept: () => ({
               header: "Hourly Wage",
               key: "hourly_wage",
               width: 130,
               minWidth: 100,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  const wage = person.hourly_wage;
                  return wage != null ? `${Format.formatCurrency(wage)}/hr` : "";
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("hourly_wage")),
               editorFactory: ({ row }) => this.store().hourlyWageEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("address_1"),
            accept: () => ({
               header: "Address",
               key: "address_1",
               width: ColumnWidthDefault.ADDRESS_1,
               minWidth: ColumnWidthMin.ADDRESS_1,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("address_1")),
               ...TextCell.columnProviders((person) => person.address_1 || ""),
               editorFactory: ({ row }) => this.store().addressEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("address_2"),
            accept: () => ({
               header: "Address 2",
               key: "address_2",
               width: ColumnWidthDefault.ADDRESS_2,
               minWidth: ColumnWidthMin.ADDRESS_2,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("address_2")),
               ...TextCell.columnProviders((person) => person.address_2 || ""),
               editorFactory: ({ row }) => this.store().address2EditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("city_town"),
            accept: () => ({
               header: "City",
               key: "city_town",
               width: ColumnWidthDefault.CITY_TOWN,
               minWidth: ColumnWidthMin.CITY_TOWN,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  return person.city_town || "";
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("city_town")),
               editorFactory: ({ row }) => this.store().cityEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("state_province"),
            accept: () => ({
               header: "State",
               key: "state_province",
               width: ColumnWidthDefault.STATE_PROVINCE,
               minWidth: ColumnWidthMin.STATE_PROVINCE,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.state_province || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("state_province")),
               editorFactory: ({ row }) => this.store().stateEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("zipcode"),
            accept: () => ({
               header: "Postal",
               key: "zipcode",
               width: ColumnWidthDefault.ZIPCODE,
               minWidth: ColumnWidthMin.ZIPCODE,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.zipcode || ""),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("zipcode")),
               editorFactory: ({ row }) => this.store().postalEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("country"),
            accept: () => ({
               header: "Country",
               key: "country",
               width: ColumnWidthDefault.COUNTRY,
               minWidth: ColumnWidthMin.COUNTRY,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.country || ""),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("country")),
               editorFactory: ({ row }) => this.store().countryEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("dob"),
            accept: () => ({
               header: "Birth Date",
               key: "dob",
               width: 100,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => {
                  return this.convertToFormattedDetachedDay(person.dob);
               }),
               cursorStateProvider: () => isActionableCursor(this.isFieldEditable("dob")),
               editorFactory: ({ row }) => this.store().birthDateEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Receive Email",
               key: "can_recieve_email", // Note: Matches misspelling in the database.
               width: 100,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) =>
                  person.can_receive_email ? "TRUE" : "FALSE",
               ),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("can_recieve_email")),
               editorFactory: ({ row }) => this.store().canReceiveEmailEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Receive Mobile",
               key: "can_recieve_mobile", // Note: Matches misspelling in the database.
               width: 100,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) =>
                  person.can_receive_mobile ? "TRUE" : "FALSE",
               ),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("can_recieve_mobile")),
               editorFactory: ({ row }) => this.store().canReceiveMobileEditorFactory([row]),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Receive SMS",
               key: "can_recieve_sms", // Note: Matches misspelling in the database.
               width: 100,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => (person.can_receive_sms ? "TRUE" : "FALSE")),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("can_recieve_sms")),
               editorFactory: ({ row }) => this.store().canReceiveSmsEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("emergency_contact_name"),
            accept: () => ({
               header: "EMR. CT. Name",
               key: "emergency_contact_name",
               width: 120,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.emergency_contact_name || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("emergency_contact_name")),
               editorFactory: ({ row }) => this.store().emergencyContactNameEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("emergency_contact_email"),
            accept: () => ({
               header: "EMR. CT. Email",
               key: "emergency_contact_email",
               width: 120,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.emergency_contact_email || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("emergency_contact_email")),
               editorFactory: ({ row }) => this.store().emergencyContactEmailEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("emergency_contact_number"),
            accept: () => ({
               header: "EMR. CT. Phone",
               key: "emergency_contact_number",
               width: 120,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.emergency_contact_number || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("emergency_contact_number")),
               editorFactory: ({ row }) => this.store().emergencyContactPhoneEditorFactory([row]),
            }),
         },
         {
            visit: () => isSensitiveFieldVisible("emergency_contact_relation"),
            accept: () => ({
               header: "EMR. CT. Relation",
               key: "emergency_contact_relation",
               width: 120,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((person) => person.emergency_contact_relation || ""),
               cursorStateProvider: () =>
                  isActionableCursor(this.isFieldEditable("emergency_contact_relation")),
               editorFactory: ({ row }) =>
                  this.store().emergencyContactRelationEditorFactory([row]),
            }),
         },
         {
            visit: () => !authManager.usingTypedGroups(),
            accept: () => ({
               width: 120,
               isDefault: false,
               isSortable: false,
               isResizable: true,
               header: "Groups",
               key: "group_ids",
               ...GroupsEditor.getColumnProviders({
                  authManager,
                  groupIdsSelector: (person) =>
                     person.permission_level?.is_admin
                        ? Object.keys(authManager.companyGroupNames())
                        : person.group_ids!,
               }),
               cursorStateProvider: (row) =>
                  this.isFieldEditable("group_ids") && row.permission_level?.is_admin != true
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().groupsInlineEditorFactory([row])
                     : row.permission_level?.is_admin == true
                     ? DisabledGroupsEditor.create({
                          errorText: "Admins cannot be added or removed from groups.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(),
                       })
                     : DisabledGroupsEditor.create({
                          errorText: "Groups cannot be edited for this person.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(
                             new Set(row.group_ids),
                          ),
                       }),
            }),
         },
         {
            visit: () => authManager.usingTypedGroups(),
            accept: () => ({
               width: 120,
               isDefault: false,
               isSortable: false,
               isResizable: true,
               header: "Access Groups",
               key: "access_group_ids",
               ...GroupsEditor.getColumnProviders({
                  authManager,
                  groupIdsSelector: (person) =>
                     person.permission_level?.is_admin
                        ? Object.keys(authManager.companyGroupNames())
                        : person.access_group_ids!,
               }),
               cursorStateProvider: (row) =>
                  this.isFieldEditable("access_group_ids") &&
                  row.permission_level?.is_admin != true &&
                  row.is_user
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().accessGroupsInlineEditorFactory([row])
                     : row.permission_level?.is_admin == true
                     ? DisabledGroupsEditor.create({
                          errorText: "Admins cannot be added or removed from groups.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(),
                       })
                     : row.is_user != true
                     ? DisabledEditor.create({
                          text: "Cannot edit Access Groups for non-users.",
                       })
                     : DisabledGroupsEditor.create({
                          errorText: "Access Groups cannot be edited for this person.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(
                             new Set(row.access_group_ids),
                          ),
                       }),
            }),
         },
         {
            visit: () => authManager.usingTypedGroups(),
            accept: () => ({
               width: 120,
               isDefault: false,
               isSortable: false,
               isResizable: true,
               header: "Assignable Groups",
               key: "assignable_group_ids",
               ...GroupsEditor.getColumnProviders({
                  authManager,
                  groupIdsSelector: (person) =>
                     person.permission_level?.is_admin
                        ? Object.keys(authManager.companyGroupNames())
                        : person.assignable_group_ids!,
               }),
               cursorStateProvider: (row) =>
                  this.isFieldEditable("assignable_group_ids") &&
                  row.permission_level?.is_admin != true &&
                  row.is_assignable
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED,
               editorFactory: ({ row, cursorState }) => {
                  return cursorState == GridCursorState.ACTIONABLE
                     ? this.store().assignableGroupsInlineEditorFactory([row])
                     : row.permission_level?.is_admin == true
                     ? DisabledGroupsEditor.create({
                          errorText: "Admins cannot be added or removed from groups.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(),
                       })
                     : row.is_assignable != true
                     ? DisabledEditor.create({
                          text: "Cannot edit Assignable Groups for non-assignable people.",
                       })
                     : DisabledGroupsEditor.create({
                          errorText: "Assignable Groups cannot be edited for this person.",
                          groupNameList: authManager.getVisibilitySortedGroupNames(
                             new Set(row.assignable_group_ids),
                          ),
                       });
               },
            }),
         },
      ];
      return templateVisitors
         .filter((visitor) => visitor.visit())
         .map((visitor) => visitor.accept());
   }

   private reload(params: { startFromCurrentCursor: boolean }) {
      this.store(this.createStore(params));
      // This is an async control flow for the inital load
      // and then is useless past that.
      this.viewConfigured(true);
      this.selectedIds([]);
   }

   private createStore({ startFromCurrentCursor }: { startFromCurrentCursor: boolean }) {
      return new PeopleList2GridStore({
         queryParams: this.createQueryParams(),
         cacheKey: `${authManager.selectedGroupId()}-people-list`,
         startFromCurrentCursor,
         errorModalColumnGroups: this.createRowIdentifierColumnGroups(),
         columnManager: this.columnManager,
      });
   }

   private createRowIdentifierColumnGroups(): Array<GridColumnGroup<SerializedPeopleListPerson>> {
      return createColumnGroupForEach({
         header: "Name",
         key: "name",
         width: 140,
         autoResizable: true,
         cellFactory: TextCell.factory((person) =>
            formatName({ first: person.name.first, last: person.name.last }),
         ),
      });
   }

   private createQueryParams(): FindPeoplePaginatedQueryParams {
      const sortOrder = this.sortOrder();
      const customFieldId = this.sortOrder().columnKey.split(":")[1];
      const selectedGroupId = authManager.selectedGroupId();

      let sortBy: NonIndexedFindPeopleSortBy | IndexedFindPeopleSortBy;

      const mappedColumnKey = COLUMN_KEY_TO_SORT_KEY_MAPPING[sortOrder.columnKey];
      if (mappedColumnKey != null) {
         sortBy = mappedColumnKey;
      } else if (customFieldId != null) {
         sortBy = NonIndexedFindPeopleSortBy.CUSTOM_FIELD;
      } else if (sortOrder.columnKey === "name") {
         sortBy = authManager.authedUser()?.preferences()?.displayLastNamesFirst()
            ? IndexedFindPeopleSortBy.PERSON_NAME_LAST
            : IndexedFindPeopleSortBy.PERSON_NAME_FIRST;
      } else {
         sortBy = sortOrder.columnKey as NonIndexedFindPeopleSortBy | IndexedFindPeopleSortBy;
      }

      return {
         filters: serializedFilters(this.filterChips(), "name"),
         sort_by: sortBy as NonIndexedFindPeopleSortBy | IndexedFindPeopleSortBy,
         sort_direction: sortOrder.direction,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         search: this.searchQuery(),
         limit: 40,
         ...(selectedGroupId == "my-groups"
            ? {}
            : {
                 group_ids: [selectedGroupId],
                 group_id: selectedGroupId,
              }),
         ...(customFieldId ? { custom_field_id: customFieldId } : {}),
      };
   }

   private createExportQueryParams(): GetFilteredPeopleParams {
      const sortOrder = this.sortOrder();
      return {
         filters: serializeLegacyFilters(this.filterChips()),
         sortBy: COLUMN_KEY_TO_SORT_KEY_MAPPING[sortOrder.columnKey] ?? sortOrder.columnKey,
         sortAscending: sortOrder.direction === Order.ASCENDING,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         search: this.searchQuery(),
         dayFilter: getDetachedDay(new Date()),
      };
   }
   private customFieldIsVisible(columnHeader: ColumnHeader): boolean {
      const { meta } = columnHeader;
      if (!meta) return false;
      if (meta()?.field_type == "currency") {
         const canViewFinancials = this.canViewPeopleFinancials;
         if (!canViewFinancials) {
            return false;
         }
         // Intentionally fall through to check sensitivity even after passing
         // the financials check.
      }
      const canViewSensitive = this.canViewPeopleSensitive;
      if (canViewSensitive) {
         return true;
      }
      const sensitiveFields = authManager.peopleSensitiveFields();
      return !sensitiveFields.includes(meta()?.field_property as string);
   }

   private customFieldValueExtractor(
      person: SerializedPeopleListPerson,
      columnHeader: ColumnHeader,
   ) {
      const fieldId = columnHeader.meta()?.field_id;
      return person.custom_fields.find((f) => f.field_id == fieldId)?.value ?? null;
   }

   private convertToFormattedDetachedDay(day?: number | null) {
      let detachedDay = day;
      if (detachedDay == null) return "";
      if (String(detachedDay).length > 8) {
         detachedDay = getDetachedDay(new Date(detachedDay), true);
      }
      return DateUtils.formatDetachedDay(detachedDay, defaultStore.getDateFormat());
   }

   private onSortOrderChanged(sortOrder: GridSortOrder) {
      router.updateUrlQueryParam(
         App.RouteName.PEOPLE_LIST,
         "sortBy",
         COLUMN_KEY_TO_SORT_KEY_MAPPING[sortOrder.columnKey] ?? sortOrder.columnKey,
      );
      router.updateUrlQueryParam(
         App.RouteName.PEOPLE_LIST,
         Order.ASCENDING,
         (sortOrder.direction == Order.ASCENDING).toString(),
      );
      this.reload({ startFromCurrentCursor: false });
   }

   showSaveViewModal = (): void => {
      // Not using query params since it's on legacy pattern still.
      const modal = new Modal();
      const pane = new SaveViewPane(App.PageName.PEOPLE_LIST, {
         search: this.searchQuery(),
         columnHeaders: this.columnManager()?.getActiveColumnHeaders(),
         filters: this.filterChips(),
         sortBy: this.sortOrder().columnKey,
         sortDirection: this.sortOrder().direction,
      });
      modal.setPanes([pane]);
      modalManager.showModal(modal, null, { class: "save-view-modal" });
   };

   private onSearchQueryChanged(query: string | null) {
      if (ValidationUtils.validateInput(query)) {
         router.updateUrlQueryParam(
            App.RouteName.PEOPLE_LIST,
            "query",
            encodeURIComponent(query.trim().toLowerCase()),
         );
      } else {
         router.removeQueryParam(App.RouteName.PEOPLE_LIST, "query");
      }
   }

   private isSensitiveFieldEditable(field: string) {
      return this.canEditPeopleSensitive || !authManager.peopleSensitiveFields().includes(field);
   }

   private isIntegratedFieldEditable(field: string) {
      const integratedFields = this.integratedFields();
      const integratedField = integratedFields.get(field) || { locked: false };
      return !integratedField.locked;
   }

   private isFieldEditable(field: string) {
      return (
         this.canEditPeopleDetails &&
         this.isSensitiveFieldEditable(field) &&
         this.isIntegratedFieldEditable(field)
      );
   }
}
