import { Model } from "@/models/model"
import ko from "knockout"

### UI Assets ###
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"

export class CustomField extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.can_filter, Boolean)
         assertOfType(data.id, String)
         assertOfType(data.integration_name, String)
         assertOfType(data.integration_only, Boolean)
         assertOfType(data.name, String)
         assertOfType(data.type, String)

         assertOfType(data.on_assignments, optional(Boolean))
         assertOfType(data.on_people, Boolean)
         assertOfType(data.on_projects, Boolean)
         assertOfType(data.on_requests, optional(Boolean))
         assertOfType(data.on_positions, optional(Boolean))

         # Optional
         assertOfType(data.description, optional(nullable(String)))
         assertOfType(data.sort_by, optional(nullable(String)))
         assertOfType(data.values, optional(nullable(arrayOf(String))))
        
      ###------------------------------------
         Model ID
      ------------------------------------###
      @canFilter = ko.observable(data.can_filter)
      @id = data.id
      @integrationName = ko.observable(data.integration_name)
      @integrationOnly = ko.observable(data.integration_only)
      @name = ko.observable(data.name)
      @type = ko.observable(data.type)

      @onAssignments = ko.observable(data.on_assignments || false)
      @onPeople = ko.observable(data.on_people)
      @onProjects = ko.observable(data.on_projects)
      @onRequests = ko.observable(data.on_requests || false)
      @onPositions = ko.observable(data.on_positions || false)

      @description = ko.observable(data.description || null)
      @sortBy = ko.observable(data.sort_by || null)
      @values = ko.observableArray([])

      @options = ko.observableArray()
      if data.values?
         if data.sort_by == "alpha"
            sortedValues = data.values.sort (a, b) ->
               return if String(a).toLowerCase() > String(b).toLowerCase() then 1 else -1
         else
            sortedValues = data.values
         @values(sortedValues)
         @options(sortedValues.map (item) -> return new MultiDropDownItem(item, item, false))
        

CustomField.Type = {
   TEXT: "text"
   NUMBER: "number"
   CURRENCY: "currency"
   HEX_COLOR: "hex-color"
   BOOL: "bool"
   SELECT: "select"
   MULTI_SELECT: "multi-select"
   DATE: "date"
   PARAGRAPH: "paragraph"
}

CustomField.SortBy = {
   ALPHA: "alpha"
   LISTED: "listed"
}
