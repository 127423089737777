import type {
   RequestDetailsModalParams,
   RequestDetailsModalStagedUpdate,
   StagedRequest,
} from "@/lib/components/modals/request-details-modal/request-details-modal";
import {
   RequestDetailsModal,
   RequestDetailsModalPane,
} from "@/lib/components/modals/request-details-modal/request-details-modal";
import { authManager } from "@/lib/managers/auth-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { PermissionLevel } from "@/models/permission-level";
import type { Placeholder } from "@/models/placeholder";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { RequestStore } from "@/stores/request-store.core";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { CreateRequestPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import {
   notificationManagerInstance,
   Icons,
   Notification,
} from "@/lib/managers/notification-manager";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers";
import { TagStore } from "@/stores/tag-store.core";
import { LegacyStore } from "@/stores/legacy-store.core";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

class BoardsPageState {
   private readonly internalState: {
      assignmentSupportData: Observable<AssignmentSupportData | null>;
      customFields: Observable<SerializedCustomField[]>;
      isLoading: Observable<boolean>;
      tags: Observable<SerializedTag[]>;
   };

   readonly assignmentSupportData: NestedComputedAssignmentSupportData = {
      companyTbdWeeks: pureComputed(
         () => this.internalState.assignmentSupportData()?.companyTbdWeeks ?? 0,
      ),
      groupedCostCodeOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.groupedCostCodeOptions ?? {},
      ),
      groupedLabelOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.groupedLabelOptions ?? {},
      ),
      overtimeDayRates: pureComputed(
         () => this.internalState.assignmentSupportData()?.overtimeDayRates ?? null,
      ),
      paidShiftHours: pureComputed(
         () => this.internalState.assignmentSupportData()?.paidShiftHours ?? 8,
      ),
      projectOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.projectOptions ?? [],
      ),
      resourceOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.resourceOptions ?? [],
      ),
      statusOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.statusOptions ?? [],
      ),
   };
   readonly isSupportDataLoaded = pureComputed(
      () => this.internalState.assignmentSupportData() != null,
   );
   readonly requestCustomFields = pureComputed(() => this.internalState.customFields());
   readonly isLoading = pureComputed(() => this.internalState.isLoading());
   readonly tags = pureComputed(() => this.internalState.tags());

   constructor() {
      this.internalState = {
         assignmentSupportData: observable<AssignmentSupportData | null>(null),
         customFields: observable<SerializedCustomField[]>([]),
         isLoading: observable(true),
         tags: observable<SerializedTag[]>([]),
      };

      this.load();
   }

   async reload() {
      this.internalState.isLoading(true);
      await this.load();
   }

   private async load() {
      await Promise.all([this.loadAdmSupportData(), this.loadCustomFields(), this.loadTags()]);
      this.internalState.isLoading(false);
   }

   private async loadAdmSupportData() {
      let result: any = null;
      const group_id =
         authManager.selectedGroupId() === "my-groups" ? undefined : authManager.selectedGroupId();

      const loadAttempt = async (id: string | undefined) => {
         result = await LegacyStore.getAssignmentCreationSupportData({ group_id: id }).payload;
         this.internalState.assignmentSupportData(
            LegacyStore.formatAssignmentCreationSupportData(result.data),
         );
      };

      const logToBugsnag = (err: unknown, context: string) => {
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = context;
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("getAssignmentCreationSupportData", { result });
            event.addMetadata("group_id", { group_id });
         });
      };

      // First attempt: load support data for the specific group
      try {
         await loadAttempt(group_id);
      } catch (err) {
         // Second attempt: load support data for the specific group
         try {
            await new Promise((res) => setTimeout(res, 250));
            await loadAttempt(group_id);
            console.info("second attempt to load support data was successful");
         } catch (err) {
            logToBugsnag(err, "boards-page__loadAdmSupportData__1__group-attempt");

            // Third attempt: If we can't load the specific group support data, load all support data
            try {
               await new Promise((res) => setTimeout(res, 250));
               await loadAttempt(undefined);
               console.info("third attempt to load support data was successful");
            } catch (err) {
               console.error("third attempt to load support data failed:", err);
               logToBugsnag(err, "boards-page__loadAdmSupportData__2__global-attempt");

               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "An unexpected error occurred while loading data.",
                  }),
               );
            }
         }
      }
   }

   private async loadCustomFields() {
      const stream = await CustomFieldStore.findCustomFieldsStream({
         is_on_entities: [CustomFieldEntity.REQUEST],
      }).stream;

      const fields = [];

      for await (const f of stream) {
         fields.push(f);
      }
      this.internalState.customFields(fields);
   }

   private async loadTags() {
      const stream = await TagStore.findTagsStream({}).stream;

      const tags = [];

      for await (const t of stream) {
         tags.push(t);
      }
      this.internalState.tags(tags);
   }

   async createRequest(request: CreateRequestPayload) {
      await RequestStore.createRequest(request).payload;
      modalManager.clearModal();
   }

   deleteRequest(request: SerializedFindRequest) {
      return RequestStore.deleteRequest(request.id).payload;
   }

   updateRequest: RequestDetailsModalParams["updateRequest"] = async ({
      request,
      update,
   }: {
      request: SerializedFindRequest;
      update: RequestDetailsModalStagedUpdate;
   }) => {
      await RequestStore.updateSingleRequest(request.id!, update).payload;
      modalManager.clearModal();
   };
}

export class BoardsPage {
   private readonly state: BoardsPageState;
   private readonly canManageRequests: boolean;

   constructor() {
      this.state = new BoardsPageState();
      this.canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS);
   }

   async legacyOnCreateOrEditRequest({
      request,
      newRequestSeedData,
      initialPane = RequestDetailsModalPane.EDIT_REQUEST,
   }: {
      request: Placeholder | null;
      newRequestSeedData?: Partial<StagedRequest>;
      initialPane?: RequestDetailsModalPane;
   }): Promise<void> {
      const foundRequest: SerializedFindRequest | null =
         request != null
            ? (
                 await RequestStore.findRequestsPaginated({
                    limit: 1,
                    sort_by: "id",
                    starting_at: request.id,
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                 }).payload
              ).data[0] ?? null
            : null;

      this.onCreateOrEditRequest({
         newRequestSeedData,
         request: foundRequest == null ? null : pureComputed(() => foundRequest),
         initialPane,
      });
   }

   onCreateOrEditRequest({
      request,
      newRequestSeedData,
      initialPane = RequestDetailsModalPane.EDIT_REQUEST,
   }: {
      request: PureComputed<SerializedFindRequest> | null;
      newRequestSeedData?: Partial<StagedRequest>;
      initialPane?: RequestDetailsModalPane;
   }): void {
      if (!this.canManageRequests) return;

      modalManager.setModal({
         modal: RequestDetailsModal.factory({
            createRequest: this.state.createRequest,
            customFields: this.state.requestCustomFields,
            deleteRequest: this.state.deleteRequest,
            initialPane,
            newRequestSeedData,
            request,
            requestSize: modalManager.requestSize,
            supportData: this.state.assignmentSupportData,
            tags: this.state.tags,
            updateRequest: this.state.updateRequest,
         }),
      });
   }
}
