import $ from "jquery";
import { requestContext } from "@/stores/common/request-context";
import { createI18njs } from "@procore/core-i18n-js";

export async function maybeInjectTranslations(): Promise<void> {
   // In embedded experience, we get I18n from the procore monolith. If it's not present,
   // We create it ourselves with the locale en.
   if (!window.I18n) {
      const I18n = createI18njs({
         locale: "en",
         translations: {},
      });
      window.I18n = I18n;
   }

   // Only get Translations script from Procore if they aren't loaded yet
   if (Object.keys(window.I18n.translations).length == 0) {
      try {
         const data = $.ajax({
            url: requestContext.baseUrl + `/api/v3/settings/translations_url/${I18n.locale}`,
            method: "GET",
            contentType: "application/json",
            crossDomain: true,
            async: false,
            xhrFields: {
               withCredentials: true,
            },
         });
         const { url } = data.responseJSON.data;

         const promise: Promise<void> = new Promise((resolve, reject) => {
            const tag = document.createElement("script");
            tag.type = "text/javascript";
            tag.src = url;
            tag.onload = function () {
               console.log("translations loaded");
               resolve();
            };
            tag.onerror = function () {
               console.log("failed to load translations");
               reject();
            };
            document.head.appendChild(tag);
         });
         return promise;
      } catch (err) {
         console.error(`Error getting translations file from Procore. Error: ${err}`);
         return;
      }
   }
}
