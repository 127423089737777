import template from "./create-edit-tag-pane.pug"
import ko from "knockout"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { GroupStore } from "@/stores/group-store.core"
import { DefaultStore } from "@/stores/default-store"

### Models ###
import { Tag } from "@/models/tag"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### UI Assets ###
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane"
import { TextCell } from "@/lib/components/grid/cells/text-cell"

export class CreateEditTagPane extends ModalPane
   constructor: (editingTag, categories) ->
      super("Create Tag", "Save", template())

      @selectedGroupIdsCore = ko.observable(new Set())

      @title("Edit Tag") if editingTag?
      @overflowable(true)
      @editingTag = editingTag
      @tag = ko.observable(if editingTag? then editingTag else new Tag({}, true))
      @blockAbbrSub = false
      @tag().abbreviation.subscribe (newVal) =>
         return @blockAbbrSub = false if @blockAbbrSub
         if newVal.length > 5
            @blockAbbrSub = true
            @tag().abbreviation(newVal.slice(0,5))

      @restrictedGroupIds = ko.observableArray([])
      @groupOptions = ko.observableArray()

      @globallyAccessible = ko.observable(false)

      @selectedColor = ko.observable(DefaultStore.TagColors[0])
      @colorOptions = ko.observableArray(DefaultStore.TagColors)

      @requireExprDate = ko.observable(false)

      if categories?
         @categories = categories.map (item) ->
            return new MultiDropDownItem(item, item, false)
      else
         @categories = false
      @selectedCategories = ko.observableArray()

      if editingTag?.categories? and editingTag.categories().length > 0
         for category in @categories
            if editingTag.categories().indexOf(category.value()) != -1
               category.selected(true)
               @selectedCategories.push(category)

      if editingTag?
         @selectedColor(editingTag.color())
         @globallyAccessible(editingTag.globallyAccessible())
         @requireExprDate(editingTag.requireExprDate())

      @colorSelectorPopupBuilder = =>
         return new Popup("Select Tag Color", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new ColorSelectorPane(@selectedColor)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['settings-pane__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @groupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=> @groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIdsCore()
            write: (groups) => @selectedGroupIdsCore(groups)
         })
         textProvider: (group) => group.name,
      });
      @groupDropdownParams = {
         actionInterceptor: @groupDropdownPane.actionInterceptor,
         cellFactory: @groupDropdownPane.cellFactory,
         panes: [@groupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIdsCore()
            write: (groups) => @selectedGroupIdsCore(groups)
         })
         placeholder: "Select Groups"
         isClearable: true
         selectedItemCellFactory: TextCell.factory((group) => group.id),
      };

      @loadData()

   maybeShowValidationError: (next) =>
      return @displayingNotice(CreateEditTagPane.Notice.NAME) unless validateInput(@tag().name())
      return @displayingNotice(CreateEditTagPane.Notice.GROUP) unless @globallyAccessible() or (@selectedGroupIdsCore().size != 0)
      @displayingNotice(null)
      next()

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      @maybeShowValidationError =>
         @data.set("name", @tag().name())
         @data.set("color", @selectedColor())
         @data.set("require_expr_date", @requireExprDate())
         @data.set("expr_days_warning", @tag().exprDaysWarning()) if @requireExprDate() and @tag().exprDaysWarning()?
         @data.set("abbreviation", @tag().abbreviation()) if validateInput(@tag().abbreviation())
         if @selectedCategories().length > 0
            @data.set("categories", @selectedCategories().map (i) -> return i.value())
         @data.set("globally_accessible", @globallyAccessible())
         groupIds = []
         for group from @selectedGroupIdsCore()
            groupIds.push(group)
         @data.set("group_ids", if @globallyAccessible() then [] else groupIds)
         modalManager.modalFinished()

   loadData: ->
      groupOptions = []
      results = await GroupStore.findGroupsSettingsStream({}).stream
      for await row from results
         groupOptions.push(row)
      if @editingTag? and @editingTag.groupIds().length != 0
         @selectedGroupIdsCore(new Set(@editingTag.groupIds()))
      @groupOptions(groupOptions)


CreateEditTagPane.Notice = {
   NAME: {
      text: 'Your Tag needs a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   GROUP: {
      text: 'Your Tag needs to be accessible to at least 1 group.'
      info: null
      color: 'red'
      dissmissable: true
   }
}
