import "./view-preferences.styl"
import template from "./view-preferences.pug"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { MyProfileViewModel } from "@/views/settings/vm/my-profile/my-profile"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { ViewPreferencesViewModelCore } from "@/views/settings/vm/view-preferences/view-preferences.core"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

export class ViewPreferencesViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - View Preferences")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeopleFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_FINANCIALS)
      @canViewPeopleSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE)

      @core = new ViewPreferencesViewModelCore()

      @benchSubtitleOptions = ko.observableArray([
         new DropDownItem("Job Title", "job-title")
         new DropDownItem("Employee Number", "employee-number")
      ])

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("phone") == -1
         @benchSubtitleOptions.push(new DropDownItem("Person's Phone #", "phone"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("email") == -1
         @benchSubtitleOptions.push(new DropDownItem("Person's Email", "email"))

      if @canViewPeopleFinancials
         @benchSubtitleOptions.push(new DropDownItem("Hourly Rate", "hourly-rate"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("city_town") == -1
         @benchSubtitleOptions.push(new DropDownItem("Person's City", "city"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("state_province") == -1
         @benchSubtitleOptions.push(new DropDownItem("Person's State", "state"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("zipcode") == -1
         @benchSubtitleOptions.push(new DropDownItem("Person's Postal Code", "postal"))

      if @core.state.person().preferences()?.benchCardsSubtitle()?
         for option in @benchSubtitleOptions()
            if option.value() == @core.state.person().preferences().benchCardsSubtitle()
               @core.state.benchSelectedSubtitle(option)
               break
         @core.state.benchSelectedSubtitle(@benchSubtitleOptions()[0]) unless @core.state.benchSelectedSubtitle()?
      else
         @core.state.benchSelectedSubtitle(@benchSubtitleOptions()[0])

      @boardSubtitleOptions = ko.observableArray([
         new DropDownItem("Total Assignment Date Range", "batch-dates")
         new DropDownItem("Status", "assignment-status")
         new DropDownItem("Job Title", "job-title")
         new DropDownItem("Employee Number", "employee-number")
         new DropDownItem("Person's Phone #", "phone")
         new DropDownItem("Person's Email", "email")
      ])

      if @canViewPeopleFinancials
         @boardSubtitleOptions.push(new DropDownItem("Hourly Rate", "hourly-rate"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("city_town") == -1
         @boardSubtitleOptions.push(new DropDownItem("Person's City", "city"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("state_province") == -1
         @boardSubtitleOptions.push(new DropDownItem("Person's State", "state"))

      if @canViewPeopleSensitive or authManager.peopleSensitiveFields().indexOf("zipcode") == -1
         @boardSubtitleOptions.push(new DropDownItem("Person's Postal Code", "postal"))

      if @core.state.person().preferences()?.boardCardsSubtitle()?
         for option in @boardSubtitleOptions()
            if option.value() == @core.state.person().preferences().boardCardsSubtitle()
               @core.state.boardSelectedSubtitle(option)
               break
         @core.state.boardSelectedSubtitle(@boardSubtitleOptions()[0]) unless @core.state.boardSelectedSubtitle()?
      else
         @core.state.boardSelectedSubtitle(@boardSubtitleOptions()[0])

      @canSave = ko.pureComputed =>
         return (@core.state.displayLastNamesFirst() != @core.state.person().preferences().displayLastNamesFirst() or
         @core.state.requestPerDay() != @core.state.person().preferences().requestPerDay() or
         ((!@core.state.selectedDefaultGroup()?.value() and @core.state.person().preferences().defaultGroupId()?) or
         (@core.state.selectedDefaultGroup()?.value() and
         @core.state.selectedDefaultGroup().value() != @core.state.person().preferences().defaultGroupId())) or 
         ((@core.state.person().preferences()?.benchCardsSubtitle()? or 
         @core.state.benchSelectedSubtitle().value() != MyProfileViewModel.DEFAULT_BENCH_CARD_SUBTITLE) and 
         @core.state.person().preferences().benchCardsSubtitle() != @core.state.benchSelectedSubtitle().value()) or
         ((@core.state.person().preferences()?.boardCardsSubtitle()? or 
         @core.state.boardSelectedSubtitle().value() != MyProfileViewModel.DEFAULT_BOARD_CARD_SUBTITLE) and 
         @core.state.person().preferences().boardCardsSubtitle() != @core.state.boardSelectedSubtitle().value())
         )
