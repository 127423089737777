import "./message-list.styl"
import template from "./message-list.pug"
import { App } from "@/views/app"
import { ValidationUtils } from "@/lib/utils/validation"
import { router } from "@/lib/router"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { MessageStore } from "@/stores/message-store.core"
import { ConversationStore } from "@/stores/conversation-store.core"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { Conversation } from "@/models/conversation"
import { Message } from "@/models/message"

### LaunchDarkly ###
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

import ko from "knockout"

export class MessageListViewModel extends PageContentViewModel
   constructor: (queryParams) ->
      assertArgs(arguments, nullable(Object))
      super(template(), "Messages")

      @canCreateMessages = authManager.checkAuthAction(PermissionLevel.Action.CREATE_MESSAGES)

      @authedId = authManager.authedUser().id
      @recipientQuery = ko.observable(null)
      @selectedContext = ko.observable(MessageListViewModel.MessageContext.INBOX)
      @contextCounts = ko.observable(null)
      if queryParams?.query? and ValidationUtils.validateInput(queryParams.query)
         @recipientQuery(decodeURIComponent(queryParams.query))
      if queryParams?.context? and ValidationUtils.validateInput(queryParams.context)
         @selectedContext(decodeURIComponent(queryParams.context))
      @recipientQuery.subscribe (newVal) ->
         if ValidationUtils.validateInput(newVal)
            router.updateUrlQueryParam(App.RouteName.MESSAGE_LIST, "query", encodeURIComponent(newVal.trim().toLowerCase()))
         else
            router.removeQueryParam(App.RouteName.MESSAGE_LIST, 'query')
      @selectedContext.subscribe (newVal) ->
         router.updateUrlQueryParam(App.RouteName.MESSAGE_LIST, "context", newVal)

      @nextStartingAfter = ko.observable(null)
      @isLoading = ko.observable(false)
      @areAllConversationsLoaded = ko.pureComputed(() => @nextStartingAfter() == null)

      @conversations = ko.observableArray([])
      @detailConversation = ko.observable(null)
      @detailMessages = ko.observableArray([])
      @detailConversation.subscribe (newVal) =>
         if newVal
            router.removeQueryParam(App.RouteName.MESSAGE_LIST, 'context')
            router.updateUrlQueryParam(App.RouteName.MESSAGE_LIST, "dcid", encodeURIComponent(newVal.id))
         else
            router.removeQueryParam(App.RouteName.MESSAGE_LIST, 'dcid')

      @currentConversationsRequest = ko.observable(null)
      @showContextLabels = ko.pureComputed =>
         return @selectedContext() == MessageListViewModel.MessageContext.STARRED

      @inboxTitle = ko.pureComputed =>
         return "Inbox" unless @contextCounts()? and @contextCounts().inbox > 0
         return "Inbox (#{@contextCounts().inbox})"

      @starredTitle = ko.pureComputed =>
         return "Starred" unless @contextCounts()? and @contextCounts().starred > 0
         return "Starred (#{@contextCounts().starred})"

      @scheduledTitle = ko.pureComputed =>
         return "Scheduled" unless @contextCounts()? and @contextCounts().scheduled > 0
         return "Scheduled (#{@contextCounts().scheduled})"

      @draftsTitle = ko.pureComputed =>
         return "Drafts" unless @contextCounts()? and @contextCounts().drafts > 0
         return "Drafts (#{@contextCounts().drafts})"

      @replyContent = ko.observable()
      @includeReplySignature = ko.observable(true)
      @replyHasContent = ko.pureComputed =>
         return ValidationUtils.validateInput(@replyContent())

      @loadData()
      if queryParams?.dcid?
         @loadConversation(queryParams.dcid)
         @loadMessagesForConvo_(queryParams.dcid)
      else
         @loadConversationsForContext()


   selectContext: (context) ->
      @selectedContext(context)
      @loadConversationsForContext()
      @replyContent(null)
      @detailConversation(null)
      @detailMessages([])

   backToContext: ->
      # TODO: Not working propely if you reload the page while in detial then go back.
      @loadConversationsForContext()
      @replyContent(null)
      @detailConversation(null)
      @detailMessages([])
      router.updateUrlQueryParam(App.RouteName.MESSAGE_LIST, "context", @selectedContext())

   archiveConvoContext: ->
      try
         await ConversationStore.archiveConversation(@detailConversation().id).payload
         @detailConversation().context(MessageListViewModel.MessageContext.ARCHIVED)
         for convo in @conversations()
            if convo.id == @detailConversation().id
               @conversations.remove(convo)
               break
      catch err
         return console.error("MessageListViewModel archiveConvoContext - Error: ", err)

   unarchiveConvo: ->
      try
         payload = await ConversationStore.unarchiveConversation(@detailConversation().id).payload
         @detailConversation().context(payload.data.context) if payload?
         if @selectedContext() == MessageListViewModel.MessageContext.ARCHIVED
            for convo in @conversations()
               if convo.id == @detailConversation().id
                  @conversations.remove(convo)
                  break
      catch err
         return console.error("MessageListViewModel unarchiveConvo - Error: ", err)

   deleteConvo: ->
      try
         await ConversationStore.deleteConversation(@detailConversation().id).payload
         for convo in @conversations()
            if convo.id == @detailConversation().id
               @conversations.remove(convo)
               break
         @detailConversation(null)
         @detailMessages([])
      catch err
         return console.error("MessageListViewModel deleteConvo - Error: ", err)

   getConvoNames: (convo) =>
      processToName_ = ->
         toString = "To: "
         for set in convo.participantNames()
            if convo.originalRecipientIds().indexOf(set.id) != -1
               toString += "#{set.name.first} #{set.name.last}, "
         return toString.slice(0, -2)
      processFullNames_ = =>
         seen = {}
         nameString = if convo.messageCount() > 1 then "(#{convo.messageCount()}) " else ''
         for set in convo.participantNames()
            continue if seen[set.id]?
            seen[set.id] = true
            if set.id == @authedId
               nameString += "me, "
            else
               nameString += "#{set.name.first} #{set.name.last}, "
         return nameString.slice(0, -2)

      return switch @selectedContext()
         when MessageListViewModel.MessageContext.INBOX then processFullNames_()
         when MessageListViewModel.MessageContext.STARRED then processFullNames_()
         when MessageListViewModel.MessageContext.ARCHIVED then processFullNames_()
         when MessageListViewModel.MessageContext.SENT then processToName_()
         when MessageListViewModel.MessageContext.SCHEDULED then processToName_()
         when MessageListViewModel.MessageContext.DRAFTS then processToName_()

   getDetailConvoNames: ->
      convo = @detailConversation()
      nameString = if convo.messageCount() > 1 then "(#{convo.messageCount()}) " else ''
      seen = {}
      for set in convo.participantNames()
         continue if seen[set.id]?
         seen[set.id] = true
         if set.id == @authedId
            nameString += "me, "
         else
            nameString += "#{set.name.first} #{set.name.last}, "
      return nameString.slice(0, -2)

   getConvoDate: (convo) ->
      if @selectedContext() == MessageListViewModel.MessageContext.SCHEDULED and convo.lastMessage().sendAt()?
         date = new Date(convo.lastMessage().sendAt())
      else
         timestamp = if convo.lastMessage().sentAt() then Math.max(convo.lastMessage().sentAt(), convo.lastUpdated()) else convo.lastUpdated()
         date = new Date(timestamp)
      return "#{DateUtils.getShortNumericDate(date, defaultStore.getDateFormat())} #{DateUtils.getTime(date)}"

   toggleConvoStar: (convo) =>
      try
         await ConversationStore.toggleConversationStarred(convo.id).payload
         convo.starred(!convo.starred())
         @contextCounts({
            ...@contextCounts(),
            starred: if convo.starred() then @contextCounts().starred += 1 else @contextCounts().starred -= 1
         })
      catch err
         return console.error("MessageListViewModel toggleConvoStar - Error: ", err)

   toggleDetailConvoStar: ->
      try
         await ConversationStore.toggleConversationStarred(@detailConversation().id).payload
         @detailConversation().starred(!@detailConversation().starred())
         @contextCounts({
            ...@contextCounts(),
            starred: if @detailConversation().starred() then @contextCounts().starred += 1 else @contextCounts().starred -= 1
         })
      catch err
         return console.error("MessageListViewModel toggleConvoDetailStar - Error: ", err)

   loadMessagesForConvo_: (convoId) ->
      try
         payload = await MessageStore.getMessagesForConversation(convoId).payload
         @detailMessages(payload.data.map((m) => new Message(m)))
      catch err
         return console.error("loadMessagesForConvo_ error:", err)

   showReadReceipts:  -> 
      flagValue = LaunchDarklyBrowser.getFlagValue("enable-read-receipts")
      if !flagValue
         return false
      return @detailConversation().originalRecipientIds().length == 1

   goToConvo: (convo) =>
      @replyContent(null)
      convo.viewed(true)
      if convo.context() == MessageListViewModel.MessageContext.INBOX or
      convo.context() == MessageListViewModel.MessageContext.SENT or
      convo.context() == MessageListViewModel.MessageContext.ARCHIVED
         @detailConversation(convo)
         @loadMessagesForConvo_(convo.id)
      else
         messageData = {
            subject: convo.originalSubject()
            content: convo.lastMessage().content()
            includeSignature: convo.lastMessage().includeSignature()
            isPrivate: convo.isPrivate()
            isGroup: convo.originalRecipientIds().length > 1
            existingDateTime: convo.lastMessage().sendAt() or null
         }
         existingData = {
            recipientIds: convo.originalRecipientIds()
            conversationId: convo.id
            messageId: convo.lastMessage().id
            context: convo.context()
         }
         pane1 = new CreateMessageOrAlertPane(messageData, 'Edit Message', null, false, existingData, null)
         modal = new Modal()
         modal.setPanes([pane1])
         modalManager.showModal modal, null, {class: 'create-message-modal'}, (modal, modalStatus, observableData) =>
            return if modalStatus == "cancelled"
            if observableData.data.deletedConvoId?
               for convo in @conversations()
                  if convo.id == observableData.data.deletedConvoId
                     @conversations.remove(convo)
                     break

            if observableData.data.updatedConvo?
               for convo in @conversations()
                  if convo.id == observableData.data.updatedConvo.id
                     convo.mapProperties(observableData.data.updatedConvo)
                     break

   getSenderName: (message) ->
      return "#{message.sender().firstName()} #{message.sender().lastName()}"

   getSenderInitials: (message) ->
      return "#{message.sender().firstName().charAt(0)}#{message.sender().lastName().charAt(0)}"

   getSentAt: (message) ->
      date = if message.sentAt() then new Date(message.sentAt()) else new Date(message.createdAt())
      return "#{DateUtils.getShortNumericDate(date, defaultStore.getDateFormat())} - #{DateUtils.getTime(date)}"
   
   getReadStatus: (message) -> 
      if (message.sender().id != @authedId)
         return ""
      if message.read()
         return "Read"
      
      return "Unread"

   showNewMessageModal: ->
      modal = new Modal()
      pane1 = new CreateMessageOrAlertPane({}, 'New Message', null, false, null, null)
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'create-message-modal'}, (modal, modalStatus, observableData) =>
         if observableData.data.newConvos? and observableData.data.newConvos[0].context() == @selectedContext()
            joinedConvos = @conversations().concat(observableData.data.newConvos)
            if @selectedContext() == MessageListViewModel.MessageContext.SCHEDULED
               @conversations(joinedConvos.sort (a, b) ->
                  return a.lastMessage().sendAt() - b.lastMessage().sendAt()
               )
            else
               @conversations(joinedConvos.sort (a, b) ->
                  return b.createdAt() - a.createdAt()
               )

   saveReply: ->
      return unless @replyHasContent()
      return if @selectedContext() == MessageListViewModel.MessageContext.ARCHIVED
      reply = {
         content: @replyContent()
         include_signature: @includeReplySignature()
      }
      try
         payload = await ConversationStore.replyToConversation(@detailConversation().id, reply).payload
         @replyContent(null)
         @detailMessages.push(new Message(payload.data))
      catch err
         return console.error("MessageListViewModel saveReply - Error: ", err)

   loadData: ->
      try
         payload = await MessageStore.getMessageContextCounts().payload
         @contextCounts(payload.data)
      catch err
         return console.error("loadData error:", err)

   loadConversation: (convoId) ->
      try
         payload = await ConversationStore.getConversation(convoId).payload
         @detailConversation(new Conversation(payload.data))
      catch err
         return console.error("MessageListViewModel loadConversation - Error: ", err)

   loadConversationsForContext: =>
      query = {
         conversation_context: @selectedContext(),
         participantName: @recipientQuery()
         limit: 40
      }
      @conversations([])
      try
         @isLoading(true)
         payload = await ConversationStore.findConversationsForContextPaginated(query).payload
         convos = payload.data.map (convo) -> new Conversation(convo)
         @nextStartingAfter(payload.pagination.next_starting_after)
         @conversations(convos)
         setTimeout(@maybeFillView, 0)
      catch err
         return console.error("MessageListViewModel loadConversationsForContext - Error: ", err)
      finally
         @isLoading(false)

   maybeFillView: =>
      parent = document.getElementsByClassName(MessageListViewModel.ElementClass.CONTENT_CONTAINER)[0]
      child = document.getElementsByClassName(MessageListViewModel.ElementClass.SCROLLING_CONTENT)[0]
      if parent and child
         parentHeight = parent.offsetHeight
         childHeight = child.offsetHeight
         @loadMoreConversations() if childHeight <= parentHeight

   loadMoreConversations: =>
      if @isLoading() == true || @nextStartingAfter() == null
         return
      
      lastConversation = @conversations().slice(-1)
      if lastConversation == null
         return
      
      try
         @isLoading(true)
         query = {
            conversation_context: @selectedContext(),
            participantName: @recipientQuery()
            limit: 40
            starting_after: @nextStartingAfter()
         }
         payload = await ConversationStore.findConversationsForContextPaginated(query).payload
         newConvos = payload.data.map (convo) -> new Conversation(convo)
         @nextStartingAfter(payload.pagination.next_starting_after)
         @conversations.push(...newConvos)
         setTimeout(@maybeFillView, 0)
      catch err
         return console.error("MessageListViewModel loadMoreConversations - Error: ", err)
      finally
         @isLoading(false)

MessageListViewModel.ElementClass = {
   CONTENT_CONTAINER: "message-list__list-container"
   SCROLLING_CONTENT: "message-list__convo-list"
}

MessageListViewModel.MessageContext = {
   INBOX: "inbox"
   STARRED: "starred"
   SENT: "sent"
   SCHEDULED: "scheduled"
   DRAFTS: "drafts"
   ARCHIVED: "archived"
}
