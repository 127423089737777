import "./reports.styl"
import template from "./reports.pug"
import { App } from "@/views/app"
import { router } from "@/lib/router"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { PageCursor } from "@/lib/utils/page-cursor"
import { Notification } from "@/lib/managers/notification-manager"
import { Icons } from "@/lib/managers/notification-manager"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { defaultStore } from "@/stores/default-store"

import { fanoutManager } from "@/lib/managers/fanout-manager"
import { GeneratedReportStore } from "@/stores/generated-report-store.core"

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { ColumnGroup } from "@/models/column-group"
import { GeneratedReport } from "@/models/generated-report"

ITEMS_PER_REQUEST = 20
ACTIONS_COLUMN_GROUP = new ColumnGroup({
   key: 'actions',
   disableDrag: true
   columns: [{
      key: 'actions'
      title: 'Actions'
      isSortable: false
      cellTemplate: "grid-row-cell-actions"
      width: 160
   }]
})

export class GeneratedReportRow
   constructor: (generatedReport) ->
      @report = ko.observable(generatedReport)
      @progressStage = ko.observable(null)
      @progressPercentage = ko.observable(null)
      @estimatedTimeRemaining = ko.observable(null)
      @statusText = ko.pureComputed () => 
         if @report().reportStatus() != GeneratedReport.ReportStatus.PENDING
            return @report().reportStatus().toUpperCase()
         stage = @progressStage()
         percentage = @progressPercentage()
         estimate = @estimatedTimeRemaining()
         if !stage
            return "PENDING"
         else if stage == GeneratedReport.ProgressStage.PREPARING
            return "PENDING - Preparing to generate..."
         else if stage == GeneratedReport.ProgressStage.AGGREGATING
            return "PENDING - Aggregating data..."               
         else if stage == GeneratedReport.ProgressStage.RENDERING
            percent = Math.min(Math.floor(percentage * 100) || 0, 99)
            timeRemaining = switch
               when estimate < 45 * 1000 then "less than a minute"
               when estimate < 90 * 1000 then "1 minute"
               else "#{Math.ceil(estimate / 1000 / 60)} minutes"
            return "PENDING - Generating PDF... #{percent}% (#{timeRemaining})"

export class ReportsViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Queued Downloads")
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canViewAssignments = authManager.checkAuthAction(PermissionLevel.Action.VIEW_ASSIGNMENTS)
      
      @pageCursor = new PageCursor({
         defaultLimit: ITEMS_PER_REQUEST,
         loader: (parameters, callback) => 
            try
               parameters['groupId'] = authManager.selectedGroupId()
               result = await GeneratedReportStore.search(parameters).payload;
               reports = result.data.reports.map (report) => new GeneratedReport(report)
               return callback(null, {reports: reports})
            catch err
               return callback(err)
         
         sizeOf: (data) => data.reports.length
         
      })

      @currentRequest = ko.observable();
      @isLoading = ko.pureComputed =>
         return Boolean(@currentRequest())

      @columnGroups = ko.observableArray @getDefaultColumns()
      @rows = ko.observableArray()

      @progressMediator = new PageableLoadingMediator =>
         @refresh()

      @groupIdSubscription = authManager.selectedGroupId.subscribeChange (newVal) =>
         if newVal
            @refresh(0)
      @pendingReportSubscriptions = {}

   getDefaultColumns: () =>
      columnGroups = ReportsViewModel.DefaultColumnGroups.map (columnGroup) =>
         subColumns = columnGroup.columns.map (column) => {
            key: column.key
            title: column.name
            isSortable: column.sortable
            cellTemplate: "grid-row-cell-#{column.key}"
            width: column.width,
            resize: column.resize
         }

         return new ColumnGroup({
            key: columnGroup.key
            disableDrag: true
            columns: subColumns
         })
      return columnGroups.concat([ACTIONS_COLUMN_GROUP])

   refresh: (skip) =>
      if @isLoading()
         @currentRequest().cancel()
         @currentRequest(null)

      @pageCursor.startingIndex(skip) if skip?
      @rows.removeAll()
      @onReachedBottom()

   mapToRows: (reports = []) =>
      return reports.map (report) =>
         { isChecked: ko.observable(), data: new GeneratedReportRow(report) }

   disposeOfSubscription: (reportId, subscriptionId) => 
      fanoutManager.unsubscribe(
         subscriptionId, FanoutManager.Channel.GENERATED_REPORT, generatedReportId: reportId
      )

   subscribeToPendingStatusUpdates: (rows) => 
      pendingReports = rows.filter (row) -> row.data.report().reportStatus() ==  GeneratedReport.ReportStatus.PENDING
      pendingReports.forEach (row) =>
         reportRow = row.data
         report = reportRow.report()

         subscriptionId = GeneratedReportStore.subscribeToGeneratedReportStatus report.id, {}, (err, status, progress) =>
            # Throw the error if it occurs. This should not happen.
            throw err if err
            if status == GeneratedReport.ReportStatus.PENDING
               if GeneratedReport.STAGE_ORDER.indexOf(progress.stage) > GeneratedReport.STAGE_ORDER.indexOf(reportRow.progressStage())
                  reportRow.progressStage(progress.stage)
               if progress.percentage > reportRow.progressPercentage()
                  reportRow.progressPercentage(progress.percentage)
                  reportRow.estimatedTimeRemaining(progress.estimated_time_remaining)
            else
               # Report is failed or complete. Stop listening for updated.
               report.reportStatus(status)
               @disposeOfSubscription(report.id, subscriptionId)
               delete @pendingReportSubscriptions[report.id]

         @pendingReportSubscriptions[report.id] = subscriptionId

   onReachedBottom: () =>
      return if @isLoading() or !@pageCursor.hasMoreBottomRecords()

      @progressMediator.showLoader()
      @progressMediator.startProgress()

      @currentRequest @pageCursor.increment({}, (err, data) =>
         if err
            return notificationManager.show(new Notification({ icon: Icons.WARNING, text: 'There is an issue with fetching reports. Please try again or contact support if it continues.'}))
         additionalRows = @mapToRows(data.reports)
         @subscribeToPendingStatusUpdates(additionalRows)
         @progressMediator.appendData =>
            ko.utils.arrayPushAll(@rows, additionalRows)
            @currentRequest(null)
      )

   isReportDownloadDisabled: (report) =>
      return !report or report.reportStatus() != "complete"

   formatCreatedAtDate:(createdAt) =>
      return "#{DateUtils.formatDate(createdAt, defaultStore.getDateFormat(), {
         yearFormat: DateUtils.YearFormat.FULL
         monthFormat: DateUtils.MonthFormat.ABBREV
         dayFormat: DateUtils.DayFormat.ONE_DIGIT
      })} at #{DateUtils.getTime(createdAt)}"

   deleteReport: (report) =>
      try
         await GeneratedReportStore.delete(report.id);
         @refresh(0)
      catch err
         console.error err
         return notificationManager.show(new Notification({ icon: Icons.WARNING, text: 'There is an issue with deleting a report. Please try again or contact support if it continues.'}))

   download: (report) =>
      try
         await GeneratedReportStore.download(report.id);
      catch error
         console.error error
         notificationManager.show(new Notification({
            icon: Icons.WARNING,
            text: "Failed to download report '#{report.name()}'."
         }))
            

   navigateToReport: (reportName) ->
      pageName = switch reportName
         when "look-ahead" then App.RouteName.LOOK_AHEAD_REPORT
         when "assignment-history" then App.RouteName.ASSIGNMENT_HISTORY_REPORT
         when "tag-action" then App.RouteName.TAG_ACTION_REPORT
         when "workforce" then App.RouteName.MANPOWER_REPORT

      router.navigate(pageName, "/groups/#{authManager.selectedGroupId()}/reports/#{reportName}")

   disposeOfPendingReportSubscriptions: () => 
      Object.keys(@pendingReportSubscriptions).forEach (reportId) => 
         subscriptionId = @pendingReportSubscriptions[reportId]
         @disposeOfSubscription(reportId, subscriptionId)

   dispose: (next) =>
      @groupIdSubscription.dispose()
      @disposeOfPendingReportSubscriptions()
      next()

ReportsViewModel.DefaultColumnGroups = [
   {
      key: "created_at",
      columns: [
         {
            key: "created_at"
            name: "Created At"
            sortable: false
            width: 175
            resize: true,
         }
      ]
   },
   {
      key: "status",
      columns: [
         {
            key: "status"
            name: "Status"
            sortable: false
            width: 200
            resize: true,
         }
      ]
   },
   {
      key: "name",
      columns: [
         {
            key: "name"
            name: "Name"
            sortable: false
            width: 180
            resize: true,
         }
      ]
   }]