import template from "./create-edit-status-pane.pug";
import { ValidationUtils } from "@/lib/utils/validation";
const validateInput = ValidationUtils.validateInput;
import type { Observable, ObservableArray } from "knockout";
import { observable, observableArray } from "knockout";

/* Auth, Real-Time & Stores */
import { DefaultStore } from "@/stores/default-store";

/* Models */
import { Status } from "@/models/status";

/* Modals */
import { modalManager } from "@/lib/managers/modal-manager";
import { ModalPane } from "@/lib/components/modals/modal-pane";

/* Popups */
import { Popup } from "@/lib/components/popup/popup";
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane";

export class CreateEditStatusPane extends ModalPane {
   private readonly status: Observable<Status>;
   private readonly title: Observable<string>;
   private blockAbbrSub: boolean;
   private readonly selectedColor: Observable<string>;
   private readonly colorOptions: ObservableArray<string>;
   private readonly colorSelectorPopupBuilder: () => Popup;
   private readonly colorSelectorPopupWrapper: {
      popupBuilder: () => Popup;
      options: { triggerClasses: string[] };
   };
   private readonly editingStatus?: Status;

   constructor(editingStatus?: Status) {
      super("Create Status", "Save", template());
      this.title = editingStatus != null ? observable("Edit Status") : observable("Create Status");
      this.maybeShowValidationError = this.maybeShowValidationError.bind(this);
      this.actionBtnIntercept = this.actionBtnIntercept.bind(this);
      this.overflowable(true);
      this.editingStatus = editingStatus;
      this.status = observable(editingStatus != null ? editingStatus : new Status({}, true));
      this.blockAbbrSub = false;
      this.status().abbreviation.subscribe((newVal) => {
         if (this.blockAbbrSub) {
            return (this.blockAbbrSub = false);
         }
         if (newVal.length > 5) {
            this.blockAbbrSub = true;
            return this.status().abbreviation(newVal.slice(0, 5));
         }
      });
      this.selectedColor = observable(DefaultStore.TagColors[0]);
      this.colorOptions = observableArray(DefaultStore.TagColors);
      if (editingStatus != null) {
         this.selectedColor(editingStatus.color());
      }
      this.colorSelectorPopupBuilder = () => {
         return new Popup(
            "Select Status Color",
            Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_LEFT,
            [new ColorSelectorPane(this.selectedColor)],
            ["settings-pane__color-btn", "settings-pane__color-btn__color"],
            ["settings-pane__color-popup", "popup--color-selector"],
         );
      };
      this.colorSelectorPopupWrapper = {
         popupBuilder: this.colorSelectorPopupBuilder,
         options: {
            triggerClasses: ["settings-pane__color-btn__color"],
         },
      };
      this.loadData();
   }

   maybeShowValidationError(next: () => void): void {
      if (!validateInput(this.status().name())) {
         return this.displayingNotice(StatusPaneNotice.NAME);
      }
      this.displayingNotice(null);
      return next();
   }

   actionBtnIntercept(): void {
      return this.maybeShowValidationError(() => {
         this.data.set("name", this.status().name());
         this.data.set("color", this.selectedColor());
         if (validateInput(this.status().abbreviation())) {
            this.data.set("abbreviation", this.status().abbreviation());
         }
         return modalManager.modalFinished();
      });
   }

   loadData(): void {} // No extra loading needs to be done
}

const StatusPaneNotice = {
   NAME: {
      text: "Your Status needs a name.",
      info: null,
      color: "red",
      dissmissable: true,
   },
};
