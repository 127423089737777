import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";
import type {
   CreateOauthAppsResponse,
   GetOauthAppsQueryParams,
   GetOauthAppsResponse,
   UpdateOauthAppsResponse,
   DeleteOauthAppsResponse,
} from "./oauth-app-store";
import type { OauthAppData } from "@/models/oauth-app";

export abstract class OauthAppStore extends Store {
   constructor() {
      super();
   }

   static getOauthApps(query: GetOauthAppsQueryParams): StoreJsonResponse<GetOauthAppsResponse> {
      return this.requestJson({
         method: "GET",
         query,
         url: "/api/v3/oauth-apps",
      });
   }

   static createOauthApp(
      oauthApp: Partial<OauthAppData>,
   ): StoreJsonResponse<CreateOauthAppsResponse> {
      return this.requestJson({
         method: "POST",
         body: oauthApp,
         url: `/api/v3/oauth-apps`,
      });
   }

   static updateOauthApp(
      oauthApp: Partial<OauthAppData>,
   ): StoreJsonResponse<UpdateOauthAppsResponse> {
      return this.requestJson({
         method: "PATCH",
         body: oauthApp,
         url: `/api/v3/oauth-apps/${oauthApp.id}`,
      });
   }

   static deleteOauthApp(id: string): StoreJsonResponse<DeleteOauthAppsResponse> {
      return this.requestJson({
         method: "DELETE",
         url: `/api/v3/oauth-apps/${id}`,
      });
   }
}
