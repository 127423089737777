import "./create-edit-position-pane.styl"
import ko from "knockout"
import template from "./create-edit-position-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"
import { GroupStore } from "@/stores/group-store.core"

### Models ###
import { Position } from "@/models/position"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### UI Assets ###
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class CreateEditPositionPane extends ModalPane
   constructor: (editingPosition) ->
      super("Create Job Title", "Save", template())
      @title("Edit Job Title") if editingPosition?
      @overflowable(true)
      @editingPosition = editingPosition
      @position = ko.observable(if editingPosition? then editingPosition else new Position({}, true))

      @selectedGroups = ko.observableArray()
      @restrictedGroupIds = ko.observableArray([])
      @groupOptions = ko.observableArray()

      @selectedGroupIdsCore = ko.observable(new Set())
      @globallyAccessible = ko.observable(false)

      @selectedColor = ko.observable()
      @colorOptions = ko.observableArray()

      @typeOptions = ko.observableArray([
         new SegmentedControllerItem("Salaried", "salaried")
         new SegmentedControllerItem("Hourly", "hourly"),
      ])
      @selectedType = ko.observable()
      if editingPosition?
         for option in @typeOptions()
            if option.value() == editingPosition.type()
               @selectedType(option)
               break

      if editingPosition?
         @selectedColor(editingPosition.color())
         @globallyAccessible(editingPosition.globallyAccessible())

      @colorSelectorPopupBuilder = =>
         new Popup("Job Title Color", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new ColorSelectorPane(@selectedColor)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['settings-pane__color-popup', 'popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @groupDropdownPane = new MultiSelectDropDownPane({
         items: ko.pureComputed(()=> @groupOptions()),
         searchTextProvider: (group) => group.name,
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIdsCore()
            write: (groups) => @selectedGroupIdsCore(groups)
         })
         textProvider: (group) => group.name,
         cellFactory: ColorCircleTextCell.factory((group) => ({
            text: group.name,
            color: group.color,
         })),
      });
      @groupDropdownParams = {
         actionInterceptor: @groupDropdownPane.actionInterceptor,
         cellFactory: @groupDropdownPane.cellFactory,
         panes: [@groupDropdownPane],
         selectedIds: ko.pureComputed({
            read: () => @selectedGroupIdsCore()
            write: (groups) => @selectedGroupIdsCore(groups)
         })
         placeholder: "Select Groups"
         isClearable: true
         selectedItemCellFactory: ColorCircleTextCell.factory((group) => group.id),
      };

      @loadData()

   maybeShowValidationError: (next) =>
      return @displayingNotice(CreateEditPositionPane.Notice.NAME) unless validateInput(@position().name())
      return @displayingNotice(CreateEditPositionPane.Notice.GROUP) unless @globallyAccessible() or (@selectedGroupIdsCore().length != 0)
      return @displayingNotice(CreateEditPositionPane.Notice.RATE) unless (Number(@position().hourlyRate()) >= 0 || (@position().hourlyRate() == undefined))
      @displayingNotice(null)
      next()

   clearTypeSelection: ->
      @selectedType(null)

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      @maybeShowValidationError =>
         @data.set("name", @position().name())
         @data.set("color", @selectedColor())
         @data.set("type", @selectedType()?.value() or null)
         @data.set("hourly_rate", Number(@position().hourlyRate()) or null)
         if @globallyAccessible()
            @data.set('globally_accessible', true)
            @data.set("group_ids", [])
         else
            @data.set('globally_accessible', false)
            groupIds = []
            for group from @selectedGroupIdsCore()
               groupIds.push(group)
            @data.set("group_ids", if @globallyAccessible() then [] else groupIds)
         modalManager.modalFinished()

   loadData: ->
      groups = []
      results = await GroupStore.findGroupsSettingsStream({}).stream
      for await row from results
         groups.push(row)
      if @editingPosition? and @editingPosition.groupIds().length != 0
         @selectedGroupIdsCore(new Set(@editingPosition.groupIds()))
      @groupOptions(groups)

      defaultStore.getResourceColorStrings (err, colors) =>
         @colorOptions(colors)
         @selectedColor(colors[0]) unless @selectedColor()?

CreateEditPositionPane.Notice = {
   NAME: {
      text: 'Your job title needs a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   GROUP: {
      text: 'Your job title needs to be accessible to at least 1 group.'
      info: null
      color: 'red'
      dissmissable: true
   }
   RATE: {
      text: 'Your job title can not have a negative hourly rate.'
      info: null
      color: 'red'
      dissmissable: true
   }
}