import "./page-missing.styl";
import template from "./page-missing.pug";
import { router } from "@/lib/router";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

export class PageMissingViewModel extends PageContentViewModel {
   constructor() {
      super(template());
   }

   goHome(): void {
      return router.navigate(null, "/home");
   }
}
