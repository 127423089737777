import type { StoreJsonResponse } from "./common";
import { Store } from "./common/store.core";

import type { ResendInviteResponse } from "@laborchart-modules/lc-core-api/dist/api/invite/resend-invite";

export abstract class InviteStore extends Store {
   static resendInvite(personId: string): StoreJsonResponse<ResendInviteResponse> {
      return this.requestJson({
         url: `/api/v3/people/${personId}/invites`,
         method: "POST",
      });
   }
}
