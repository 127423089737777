import "./invalid-batch-edit-dates-pane.styl"
import template from "./invalid-batch-edit-dates-pane.pug"
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"
import { DateUtils } from "@/lib/utils/date"
import { defaultStore } from "@/stores/default-store"

export class InvalidAssignment
   constructor: (fields) ->
      @resourceTitle = fields.resourceTitle
      @projectName = fields.projectName
      @startDay = fields.startDay
      @endDay = fields.endDay

export class InvalidBatchEditDatesPane extends ModalPane
   constructor: (invalidAssignments, startDay, endDay) ->
      super("Invalid Operation", "", template())
      @invalidAssignments = invalidAssignments
      @startDay = startDay
      @endDay = endDay

   getDescription: (invalidAssignment) => 
      format = defaultStore.getDateFormat()
      options = {
         yearFormat: DateUtils.YearFormat.FULL,
         monthFormat: DateUtils.MonthFormat.ONE_DIGIT,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
      }

      return "
         <b>#{invalidAssignment.resourceTitle}</b> on
         <b>#{invalidAssignment.projectName}</b> from
         <b>#{DateUtils.formatDetachedDay(invalidAssignment.startDay, format, options)}</b> to
         <b>#{DateUtils.formatDetachedDay(invalidAssignment.endDay, format, options)}</b>
      "

   formatDate: (date) =>
      return DateUtils.formatDate(date, defaultStore.getDateFormat(), {
         yearFormat: DateUtils.YearFormat.FULL,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
      })
