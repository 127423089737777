import { ValueSet } from "@/models/value-set";
import type { Observable } from "knockout";
import { observable } from "knockout";
import type {
   FilterOption,
   FilterOptionClassifier,
} from "@/lib/components/chip-filter/chip-filter";
import { ANY } from "@/lib/components/chip-filter/chip-filter";
import { PROJECT_ROLES_PROPERTY } from "./chip-filter-helper";
import { Format } from "./format";

import type { FilterOption as BaseFilterOption } from "@/lib/components/chip-filter/chip-filter";

// Extend the FilterOption type
type ClassifierFilterOption = BaseFilterOption & {
   classifierPaneName?: string;
   backEnabled?: boolean;
};

export function createProjectRolesFilterOption({
   positionOptions,
   peopleOptions,
}: {
   positionOptions: Array<ValueSet<string>>;
   peopleOptions: Array<ValueSet<string>>;
}): Observable<FilterOption> {
   const classifiers: FilterOptionClassifier[] = positionOptions.map((option) => ({
      listLabel: option.name(),
      chipLabel: option.name(),
      value: option.value(),
   }));
   classifiers.unshift({ listLabel: "Any", chipLabel: "Any", value: ANY });
   return observable<ClassifierFilterOption>({
      property: PROJECT_ROLES_PROPERTY,
      classifiers,
      type: "select",
      classifierPaneName: "Job Title",
      values: [
         new ValueSet({ name: "Any", value: ANY }),
         ...Format.keyableSort(peopleOptions, "name"),
      ],
      backEnabled: true,
   });
}
