import { Button, Form, P } from "@procore/core-react";
import React, { useEffect } from "react";

export default (props: any) => {
   const { submitResetData } = props;
   useEffect(() => {}, []);

   // This is the company ID that will almost always be used for the data resets.
   // We are hardcoding it as the initial value so users shouldn't need to type anything.
   const INITIAL_SRC_COMPANY_ID = "093127c3-0d49-4e13-a465-1f1746ef49c1";

   return (
      <React.Fragment>
         <Form
            view="create"
            initialValues={{ src_company_id: INITIAL_SRC_COMPANY_ID }}
            onSubmit={(values) => submitResetData(values)}
         >
            <Form.Form>
               <P>
                  This process may take up to 4 hours to complete. Do not use this account during
                  the data reset.
               </P>
               <P style={{ marginBottom: "24px" }}>
                  You will return to the homepage when the reset has started and an email will be
                  sent when it has been completed.
               </P>
               <Form.Text
                  name="src_company_id"
                  label="Company ID:"
                  colStart={1}
                  tooltip="This is the Company ID that will be used as the seed data for your Demo account."
                  style={{
                     width: "300px",
                  }}
               />
               <Button type="submit">Reset</Button>
            </Form.Form>
         </Form>
      </React.Fragment>
   );
};
