import { DateUtils } from "@/lib/utils/date";
import { Model } from "@/models/model"
import { Tag } from "@/models/tag"
import { defaultStore } from "@/stores/default-store";
import ko from "knockout"

export class AssignmentCard extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?

      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.employee_number, insist.nullable(String))
         assertOfType(data.id, nullable(String))
         assertOfType(data.position_sequence, optional(Number))
         assertOfType(data.project_id, nullable(String))
         assertOfType(data.resource_color, nullable(String))
         assertOfType(data.resource_rate, nullable(Number))
         assertOfType(data.resource_title, String)
         assertOfType(data.status, nullable(Object))
         # Optional
         assertOfType(data.assignable_group_ids, optional(arrayOf(String)))
         assertOfType(data.cost_code_id, optional(String))
         assertOfType(data.day, optional(Number))
         assertOfType(data.end_time, optional(Number))
         assertOfType(data.group_ids, optional(arrayOf(String)))
         assertOfType(data.is_pending, optional(Boolean))
         assertOfType(data.label_id, optional(String))
         assertOfType(data.pending_id, optional(String))
         assertOfType(data.percent_allocated, optional(Number))
         assertOfType(data.position_id, optional(String))
         assertOfType(data.position_rate, optional(Number))
         assertOfType(data.resource_initials, optional(String))
         assertOfType(data.resource_photo_url, optional(String))
         assertOfType(data.resource_tag_instances, optional(arrayOf(Object)))
         assertOfType(data.start_time, optional(Number))
         assertOfType(data.work_days, optional(Object))
         assertOfType(data.dob, optional(Number))
         assertOfType(data.phone, optional(String))

      ###------------------------------------------------
         Knockout Observables
      ------------------------------------------------###
      @assignableGroupIds = ko.observableArray(data.assignable_group_ids)
      @costCodeId = ko.observable(data.cost_code_id)
      @day = ko.observable(data.day)
      @dynamicSubtitle = ko.observable(data.dynamic_subtitle)
      @employeeNumber = ko.observable(data.employee_number)
      @endDay = ko.observable(data.end_day)
      @endTime = ko.observable(data.end_time)
      @groupIds = ko.observable(data.group_ids)
      @id = data.id
      @isPending = if data.is_pending? then ko.observable(data.is_pending) else ko.observable(false)
      @labelId = ko.observable(data.label_id)
      @overtime = ko.observable(data.overtime)
      @overtimeRates = ko.observable(data.overtime_rates)
      @paySplit = ko.observable(data.pay_split)
      @pendingId = ko.observable(data.pending_id)
      @percentAllocated = ko.observable(data.percent_allocated)
      @positionId = ko.observable(data.position_id)
      @positionName = ko.observable(data.position_name)
      @positionRate = ko.observable(data.position_rate)
      @positionSequence = ko.observable(data.position_sequence)
      @projectId = ko.observable(data.project_id)
      @resourceColor = ko.observable(data.resource_color or "resource-default")
      @resourceId = ko.observable(data.resource_id)
      @resourceInitials = ko.observable(data.resource_initials)
      @resourcePhotoUrl = ko.observable(data.resource_photo_url or null)
      @resourceRate = ko.observable(data.resource_rate)
      @resourceTagInstances = if !data.resource_tag_instances? then ko.observableArray() else ko.observableArray data.resource_tag_instances.map (tag) ->
         return new Tag(tag)
      @resourceTitle = ko.observable(data.resource_title)
      @startDay = ko.observable(data.start_day)
      @startTime = ko.observable(data.start_time)
      @status = ko.observable(data.status)
      @workDays = ko.observable(data.work_days)
      @dob = ko.observable(if data.dob then DateUtils.formatDetachedDay(DateUtils.getDetachedDay(new Date(data.dob)), defaultStore.getDateFormat()) else "-")
      @phone = ko.observable(if data.phone then data.phone else "-")
