import type { BatchEditor } from "@/lib/components/batch-edit/batch-edit";
import type { Observable, PureComputed } from "knockout";
import { toJS } from "knockout";
import { observable, pureComputed } from "knockout";
import type {
   FindRequestsPaginatedQueryParams,
   SerializedFindRequest,
} from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { ComponentArgs } from "@/lib/components/common";
import type { DetachedDayEditorParams } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import { DetachedDayEditor } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import type { PercentEditorParams } from "@/lib/components/editors/percent-editor/percent-editor";
import { PercentEditor } from "@/lib/components/editors/percent-editor/percent-editor";
import type { TimeEditorParams } from "@/lib/components/editors/time-editor/time-editor";
import { TimeEditor } from "@/lib/components/editors/time-editor/time-editor";
import { DateUtils } from "@/lib/utils/date";
import type { AssignmentWorkDays } from "@/models/assignment";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import { FilterFieldType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { CustomFieldInstance } from "@laborchart-modules/common";
import { PersonDropDownPane } from "@/lib/components/drop-downs/panes/person-drop-down-pane";
import type { DropDownEditorParams } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { DropDownEditor } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { authManager } from "@/lib/managers/auth-manager";
import { formatName } from "@/lib/utils/preferences";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { defaultStore } from "@/stores/default-store";
import type { TagInstancesEditorParams } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import { TagInstancesEditor } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import { ProjectDropDownPane } from "@/lib/components/drop-downs/panes/project-drop-down-pane";
import type { UpdateRequestsPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/update-requests";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { GridStoreRowsUpdater } from "@/lib/utils/grid-store/grid-store-rows-updater";
import { RequestStore } from "@/stores/request-store.core";
import { JobTitleDropDownPane } from "@/lib/components/drop-downs/panes/job-title-drop-down-pane";
import { ProjectStore } from "@/stores/project-store.core";
import { PermissionLevel } from "@/models/permission-level";
import type {
   KeysetGridStoreParams,
   ResponsePayload,
} from "@/lib/components/grid/keyset-grid-store";
import { KeysetGridStore } from "@/lib/components/grid/keyset-grid-store";
import type { StoreStreamResponse } from "@/stores/common/store.core";
import type {
   SerializedCostCode,
   SerializedCostCodeLabel,
   SerializedPerson,
   SerializedPosition,
   SerializedProject,
   SerializedTag,
   SerializedTagInstance,
} from "@laborchart-modules/common/dist/rethink/serializers";
import type { RequestDetailsModalStagedUpdate } from "@/lib/components/modals/request-details-modal/request-details-modal";
import type { CustomFieldMeta } from "@/models/column-header";
import { customFieldUpdateRowApplier } from "@/lib/utils/custom-field-instance";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { WorkDaySelection } from "@/lib/components/modals/editors/work-day-editor-element/work-day-editor-element";
import type { FindProjectsFilter } from "@laborchart-modules/common/dist/reql-builder";
import type {
   CreateRequestPayload,
   CreateRequestResponse,
} from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";
import type {
   CreateAssignmentPayload,
   CreateAssignmentResponse,
} from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type { AssignmentMessageModalPlaceholder, ModalData } from "@/lib/managers/alert-manager";
import { alertManager } from "@/lib/managers/alert-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import type { StatusOption } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import { StatusDropDownPane } from "@/lib/components/drop-downs/panes/status-drop-down-pane";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import type { BatchDeleteRequestsPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/delete-request";

export const NO_PERMISSON_TO_EDIT_REQUEST_MESSAGE =
   "You do not have permission to edit this request.";

type DropDownOption = {
   id: string;
   name: string;
};

type RowUpdate<TFields extends keyof SerializedFindRequest> = {
   request: Omit<SerializedFindRequest, TFields>;
   update: Pick<SerializedFindRequest, TFields>;
};

export type RequestList3GridStoreParams =
   KeysetGridStoreParams<FindRequestsPaginatedQueryParams> & {
      customFields: PureComputed<SerializedCustomField[]>;
      errorModalColumnGroups: Array<GridColumnGroup<SerializedFindRequest>>;
      tags: PureComputed<SerializedTag[]>;
   };

export class RequestList3GridStore extends KeysetGridStore<
   SerializedFindRequest,
   FindRequestsPaginatedQueryParams
> {
   private readonly rowsUpdater: GridStoreRowsUpdater<
      SerializedFindRequest,
      Array<UpdateRequestsPayload<AuthType.SESSION>>
   >;
   private readonly rowsRemover: GridStoreRowsUpdater<
      SerializedFindRequest,
      BatchDeleteRequestsPayload
   >;
   private readonly errorModalColumnGroups: Array<GridColumnGroup<SerializedFindRequest>>;
   private readonly customFields: RequestList3GridStoreParams["customFields"];
   private readonly tags: RequestList3GridStoreParams["tags"];

   constructor(params: RequestList3GridStoreParams) {
      super(params);
      this.customFields = params.customFields;
      this.tags = params.tags;
      this.errorModalColumnGroups = params.errorModalColumnGroups;
      this.rowsUpdater = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => RequestStore.updateRequestsStream(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
      this.rowsRemover = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => RequestStore.batchDelete(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
   }

   protected createLoadAllRowsStream(
      queryParams: FindRequestsPaginatedQueryParams,
   ): StoreStreamResponse<SerializedFindRequest> {
      return RequestStore.findRequestsStream({
         filters: queryParams.filters,
         sort_by: queryParams.sort_by,
         timezone: queryParams.timezone,
         group_id: queryParams.group_id,
         sort_direction: queryParams.sort_direction,
         search: queryParams.search,
         // TODO: Add starting_at when we don't have previous records.
      });
   }

   protected async loadRows(
      queryParams: FindRequestsPaginatedQueryParams,
   ): Promise<ResponsePayload<SerializedFindRequest>> {
      return RequestStore.findRequestsPaginated(queryParams).payload;
   }

   private replaceFields = <TFields extends keyof SerializedFindRequest>({
      request,
      update,
   }: RowUpdate<TFields>): SerializedFindRequest =>
      ({
         ...request,
         ...update,
      } as SerializedFindRequest);

   private startDayRowApplier = ({
      request,
      startDay,
   }: {
      request: SerializedFindRequest;
      startDay: number;
   }) =>
      this.replaceFields({
         request,
         update: {
            start_day: startDay,
         },
      });
   startDayEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      return DetachedDayEditor.factory(() => ({
         title: "Start Date",
         value: requests.length == 1 ? requests[0].start_day : null,
         onOrBeforeDay: requests.length == 1 ? requests[0].end_day || undefined : undefined,
         isRequired: true,
         validators:
            requests.length == 1
               ? [(startDay) => isWorkDayValidator("Start Date", requests[0], startDay)]
               : [],
         saveProvider: async (startDay): Promise<void> => {
            if (!startDay) return;
            await this.updateRows({
               requests,
               update: { start_day: startDay },
               rowApplier: (request) => this.startDayRowApplier({ request, startDay }),
            });
         },
      }))(requests);
   };

   private statusRowApplier = ({
      request,
      status,
   }: {
      request: SerializedFindRequest;
      status: StatusOption | null;
   }) => {
      const update =
         status == null
            ? {
                 status: null,
                 status_id: null,
              }
            : {
                 status: {
                    company_id: authManager.companyId(),

                    ...status,
                 },
                 status_id: status.id,
              };
      return this.replaceFields({
         request,
         update,
      });
   };
   statusEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DropDownEditorParams<StatusOption>> => {
      const selectedItem =
         requests.length == 1 && requests[0].status
            ? {
                 abbreviation: requests[0].status.abbreviation,
                 color: requests[0].status.color,
                 id: requests[0].status.id,
                 name: requests[0].status.name,
                 sequence: requests[0].status.sequence,
              }
            : null;
      return DropDownEditor.factory(() => ({
         pane: new StatusDropDownPane(),
         cellFactory: ColorCircleTextCell.factory<StatusOption>((status) => {
            return {
               text: status.name,
               color: status.color,
            };
         }),
         title: "Status",
         placeholder: "Select Status...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem,
         isClearable:
            (requests.length > 1 || selectedItem != null) &&
            authManager.checkAuthAction(PermissionLevel.Action.CAN_VIEW_ALL_STATUSES),
         saveProvider: async ([status = null]) => {
            const requestTransformer = !status
               ? (request: SerializedFindRequest) => {
                    request.status = null;
                    return request;
                 }
               : (request: SerializedFindRequest) => {
                    // We are required to mutate the primary object to propagate updates
                    request.status = {
                       ...(request.status as any), // TODO: Remove any
                       id: status.id,
                       color: status.color,
                       name: status.name,
                    };
                    return request;
                 };
            await this.updateRows({
               requests: requests.map(requestTransformer),
               update: { status_id: status?.id ?? null },
               rowApplier: (request) =>
                  this.statusRowApplier({
                     request,
                     status,
                  }),
            });
         },
      }))(requests);
   };

   private costCodeRowApplier = ({
      request,
      costCode,
   }: {
      request: SerializedFindRequest;
      costCode: SerializedCostCode | null;
   }) =>
      this.replaceFields<"category" | "category_id" | "subcategory" | "subcategory_id">({
         request,
         update: {
            category: costCode,
            category_id: costCode?.id ?? null,
            subcategory: null,
            subcategory_id: null,
         },
      });
   costCodeEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DropDownEditorParams<SerializedCostCode>> => {
      const [request] = requests;
      const selectedItem = (() => {
         if (!request.category_id) return null;
         return request.project.categories.find((c) => c.id === request.category_id) ?? null;
      })();

      return DropDownEditor.factory<SerializedFindRequest, SerializedCostCode>(() => ({
         pane: new ArrayDropDownPane<SerializedCostCode>({
            items: request.project.categories.sort((a, b) => a.sequence - b.sequence),
            searchTextProvider: (item) => item.name,
         }),
         cellFactory: TextCell.factory<SerializedCostCode>((item) => item.name),
         title: "Category",
         placeholder: "Select Category...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem,
         isClearable: requests.length > 1 || selectedItem != null,
         saveProvider: async ([category = null]) => {
            await this.updateRows({
               requests: requests,
               update: { category_id: category?.id ?? null, subcategory_id: null },
               rowApplier: (request) => this.costCodeRowApplier({ request, costCode: category }),
            });
         },
      }))(requests);
   };

   private costCodeLabelRowApplier = ({
      request,
      costCodeLabel,
   }: {
      request: SerializedFindRequest;
      costCodeLabel: SerializedCostCodeLabel | null;
   }) =>
      this.replaceFields<"subcategory" | "subcategory_id">({
         request,
         update: {
            subcategory: costCodeLabel,
            subcategory_id: costCodeLabel?.id ?? null,
         },
      });
   costCodeLabelEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DropDownEditorParams<SerializedCostCodeLabel>> => {
      const [request] = requests;
      const subCategories: SerializedCostCodeLabel[] = [];

      const selectedItem = (() => {
         if (!request.category_id) return null;
         const category = request.project.categories.find((c) => c.id === request.category_id);
         if (!category) return null;
         subCategories.push(...category.subcategories.sort((a, b) => a.sequence - b.sequence));

         return category.subcategories.find((s) => s.id === request.subcategory_id) ?? null;
      })();

      return DropDownEditor.factory(() => ({
         pane: new ArrayDropDownPane({
            items: subCategories,
            searchTextProvider: (item) => item.name,
         }),
         cellFactory: TextCell.factory<SerializedCostCodeLabel>((item) => item.name),
         title: "Subcategory",
         placeholder: "Select Subcategory...",
         value: new Set(selectedItem ? [selectedItem.id] : []),
         selectedItem,
         isClearable: requests.length > 1 || selectedItem != null,
         saveProvider: async ([subcategory = null]) => {
            await this.updateRows({
               requests: requests,
               update: { subcategory_id: subcategory?.id ?? null },
               rowApplier: (request) =>
                  this.costCodeLabelRowApplier({ request, costCodeLabel: subcategory }),
            });
         },
      }))(requests);
   };

   fillRequestEditorFactory = ({
      requests,
      onSelect,
   }: {
      requests: SerializedFindRequest[];
      onSelect: (resource: SerializedPerson, request: SerializedFindRequest) => void;
   }): ComponentArgs<DropDownEditorParams<SerializedPerson>> => {
      return DropDownEditor.factory<SerializedFindRequest, SerializedPerson>(() => ({
         title: "Resource",
         saveText: "Continue",
         value: new Set(),
         pane: new PersonDropDownPane({
            transformer: (person) => person,
            status: PersonStatus.ACTIVE,
            groupIds:
               authManager.selectedGroupId() != "my-groups"
                  ? new Set([authManager.selectedGroupId()])
                  : undefined,
            isAssignable: true,
         }) as any,
         cellFactory: TextCell.factory<SerializedPerson>((item) => formatName(item.name)),
         async saveProvider([resource]) {
            onSelect(resource, requests[0]);
         },
         isRequired: true,
      }))(requests);
   };

   fillRequestSave = async ({
      assignment,
      requestData,
      showAlertModal = false,
   }: {
      assignment: CreateAssignmentPayload;
      requestData: {
         id: string;
         instuctionText: string | null;
         scopeOfWork: string | null;
      };
      showAlertModal: boolean;
   }): Promise<CreateAssignmentResponse> => {
      /* istanbul ignore next */
      const formattedPayload: any = {
         ...assignment,
         overtime: assignment.overtime ?? false,
         overtime_rates: assignment.overtime_rates ?? null,
      };
      const newAssignment = await RequestStore.fillRequest(requestData.id, formattedPayload)
         .payload;
      modalManager.clearModal();

      // NOTE: Alerting must be AFTER the creation occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: newAssignment.data.id,
            notifyProjectId: assignment.project_id,
            notifyResourceId: assignment.resource_id,
            notifyContext: "assignment-new",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(
            modalData,
            {
               instructionText: requestData.instuctionText,
               workScopeText: requestData.scopeOfWork,
            },
            function () {},
         );
      }
      /* istanbul ignore next */
      return newAssignment as any;
   };

   showAssignmentMessageModal = (
      modalData: ModalData,
      requestData: AssignmentMessageModalPlaceholder,
      callback: () => void,
   ): void => {
      alertManager.showAssignmentMessageModal(modalData, requestData, callback);
   };

   private tagsRowApplier = ({
      request,
      tagsUpdate,
   }: {
      request: SerializedFindRequest;
      tagsUpdate: Record<string, boolean>;
   }) => {
      const temp = new Set(request.tag_ids);
      Object.entries(tagsUpdate).forEach(([id, isIncluded]) => {
         if (isIncluded) temp.add(id);
         if (!isIncluded) temp.delete(id);
      });
      const tags = [...temp]
         .map((tagId) => this.tags().find((tag) => tag.id == tagId))
         .filter((tag) => tag != null) as SerializedTag[];
      return this.replaceFields<"tags" | "tag_ids">({
         request,
         update: {
            tags,
            tag_ids: tags.map((tag) => tag.id),
         },
      });
   };
   tagInstancesEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<TagInstancesEditorParams<SerializedFindRequest>> => {
      const validator = (record: SerializedFindRequest): string | null => {
         const canManageOthers = canManageOthersValidator(record);
         if (!canManageOthers.status) return canManageOthers.message;
         return null;
      };
      return TagInstancesEditor.factory<SerializedFindRequest>(() => ({
         title: "Tags",
         value: requests,
         validator,
         conflictModalColumnGroups: this.errorModalColumnGroups,
         isExpirationDayEnabled: false,
         recordTransformer: (request: SerializedFindRequest) => {
            // Requests currently only store tag_ids instead of full tag_instances, so let's generate fake ones from its tag_ids.
            const fakeTagInstances = pureComputed<SerializedTagInstance[]>(() => {
               // TODO: This may result in poor performance. Tests for optimizations should be made.
               const rowIndexForRequest = this.rows().findIndex((row) => row.id == request.id);
               if (rowIndexForRequest == -1) {
                  return [];
               }
               const watchedRequest = this.rows()[rowIndexForRequest];
               return watchedRequest.tags.map<SerializedTagInstance>((tag) => ({
                  ...tag,
                  tag_id: tag.id,
                  attachment_ids: [],
                  expr_date: null,
               }));
            });
            return {
               id: request.id,
               group_ids: new Set([...request.project.group_ids]),
               tagInstances: fakeTagInstances,
            };
         },
         saveProvider: async (update) => {
            const validRequests = requests.filter((request) => validator(request) == null);
            const validPayload = update.payload.filter((payload) =>
               validRequests.find((r) => r.id == payload.id),
            );
            await this.rowsUpdater.update({
               size: validPayload.length,
               updatePayload: validPayload.map((validPayload) => {
                  const tagUpdate: UpdateRequestsPayload<AuthType.SESSION>["tag_ids"] = {};
                  if (update.removedTagId) tagUpdate[update.removedTagId] = false;
                  if (update.addedTag) tagUpdate[update.addedTag.id] = true;
                  return { id: validPayload.id, tag_ids: tagUpdate };
               }),
               rowApplier: (request) => {
                  const tagsUpdate: Record<string, boolean> = {};
                  if (update.removedTagId) tagsUpdate[update.removedTagId] = false;
                  if (update.addedTag) tagsUpdate[update.addedTag.id] = true;
                  return this.tagsRowApplier({
                     request,
                     tagsUpdate,
                  });
               },
            });
         },
      }))(requests);
   };

   private endDayRowApplier = ({
      request,
      endDay,
   }: {
      request: SerializedFindRequest;
      endDay: number;
   }) =>
      this.replaceFields<"end_day">({
         request,
         update: {
            end_day: endDay,
         },
      });
   endDayEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      return DetachedDayEditor.factory(() => ({
         title: "End Date",
         value: requests.length == 1 ? requests[0].end_day : null,
         onOrAfterDay: requests.length == 1 ? requests[0].start_day || undefined : undefined,
         isRequired: true,
         validators:
            requests.length == 1
               ? [(endDay) => isWorkDayValidator("End Date", requests[0], endDay)]
               : [],
         saveProvider: async (endDay): Promise<void> => {
            if (!endDay) return;
            await this.updateRows({
               requests,
               update: { end_day: endDay },
               rowApplier: (request) => this.endDayRowApplier({ request, endDay }),
            });
         },
      }))(requests);
   };

   private startTimeRowApplier = ({
      request,
      startTime,
   }: {
      request: SerializedFindRequest;
      startTime: number | null;
   }) =>
      this.replaceFields<"start_time">({
         request,
         update: {
            start_time: startTime,
         },
      });
   startTimeEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<TimeEditorParams> => {
      const isOne = requests.length === 1;
      return TimeEditor.factory(() => ({
         title: "Start Time",
         value: isOne ? requests[0].start_time ?? null : null,
         isRequired: true,
         saveProvider: async (startTime): Promise<void> => {
            await this.updateRows({
               requests,
               update: { start_time: startTime },
               rowApplier: (request) => this.startTimeRowApplier({ request, startTime }),
            });
         },
      }))(requests);
   };

   private endTimeRowApplier = ({
      request,
      endTime,
   }: {
      request: SerializedFindRequest;
      endTime: number | null;
   }) =>
      this.replaceFields<"end_time">({
         request,
         update: {
            end_time: endTime,
         },
      });
   endTimeEditorFactory = (requests: SerializedFindRequest[]): ComponentArgs<TimeEditorParams> => {
      const isOne = requests.length === 1;
      return TimeEditor.factory(() => ({
         title: "End Time",
         value: isOne ? requests[0].end_time ?? null : null,
         isRequired: true,
         saveProvider: async (endTime): Promise<void> => {
            await this.updateRows({
               requests,
               update: { end_time: endTime },
               rowApplier: (request) => this.endTimeRowApplier({ request, endTime }),
            });
         },
      }))(requests);
   };

   private percentAllocatedRowApplier = ({
      request,
      percentAllocated,
   }: {
      request: SerializedFindRequest;
      percentAllocated: number | null;
   }) =>
      this.replaceFields<"percent_allocated">({
         request,
         update: {
            percent_allocated: percentAllocated,
         },
      });
   percentAllocatedEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<PercentEditorParams> => {
      const isOne = requests.length === 1;
      return PercentEditor.factory(() => ({
         title: "Percent Allocation",
         value: isOne ? requests[0].percent_allocated : null,
         isRequired: true,
         validators: isOne ? [percentAllocatedValidator(requests[0])] : [isPercentAllocatedGtZero],
         saveProvider: async (percentAllocated): Promise<void> => {
            if (!percentAllocated) return;
            await this.updateRows({
               requests,
               update: { percent_allocated: percentAllocated },
               rowApplier: (request) =>
                  this.percentAllocatedRowApplier({ request, percentAllocated }),
            });
         },
      }))(requests);
   };

   private projectRowApplier = ({
      request,
      project,
   }: {
      request: SerializedFindRequest;
      project: SerializedProject;
   }) =>
      this.replaceFields<
         "category" | "category_id" | "project" | "project_id" | "subcategory" | "subcategory_id"
      >({
         request,
         update: {
            category: null,
            category_id: null,
            project,
            project_id: project.id,
            subcategory: null,
            subcategory_id: null,
         },
      });
   projectEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DropDownEditorParams<DropDownOption>> => {
      const selectedItem: Observable<DropDownOption | null> = observable(
         requests.length == 1
            ? { id: requests[0].project_id, name: requests[0].project.name }
            : null,
      );

      return DropDownEditor.factory<SerializedFindRequest, DropDownOption>(() => ({
         title: "Project",
         value: requests.length == 1 ? new Set([requests[0].project_id]) : new Set<string>(),
         selectedItem: selectedItem,
         pane: new ProjectDropDownPane({
            transformer: (project) => ({ id: project.id, name: project.name }),
            filters: [
               {
                  name: "Status",
                  // TODO: Update FindProjectsFilter to be in a full-import-friendly location.
                  property: "status" as FindProjectsFilter,
                  type: FilterFieldType.SELECT,
                  value_sets: [{ value: ProjectStatus.ACTIVE }, { value: ProjectStatus.PENDING }],
               },
            ],
            groupId:
               authManager.selectedGroupId() != "my-groups"
                  ? authManager.selectedGroupId()
                  : undefined,
         }) as any,
         cellFactory: TextCell.factory<DropDownOption>((item) => item.name),
         saveProvider: async ([project]) => {
            const payload = await ProjectStore.getProject(project.id).payload;
            await this.updateRows({
               requests,
               update: { project_id: payload.data.id },
               rowApplier: (request) => this.projectRowApplier({ request, project: payload.data }),
            });
         },
         isRequired: true,
      }))(requests);
   };

   private jobTitleRowApplier = ({
      request,
      jobTitle,
   }: {
      request: SerializedFindRequest;
      jobTitle: SerializedPosition | null;
   }) => {
      const update = jobTitle
         ? {
              job_title: jobTitle,
              job_title_id: jobTitle.id,
           }
         : {
              job_title: null,
              job_title_id: null,
           };
      return this.replaceFields<"job_title" | "job_title_id">({
         request,
         update,
      });
   };
   jobTitleEditorFactory = (
      requests: SerializedFindRequest[],
   ): ComponentArgs<DropDownEditorParams<SerializedPosition>> => {
      const selectedItem = (() => {
         if (requests.length > 1) return null;
         const jobTitle = requests[0]?.job_title || null;
         if (!jobTitle) return null;
         return jobTitle;
      })();
      return DropDownEditor.factory(() => ({
         pane: new JobTitleDropDownPane({
            transformer: (position) => position,
         }) as any,
         cellFactory: ColorCircleTextCell.factory<SerializedPosition>((jobTitle) => {
            return {
               text: jobTitle.name,
               color: jobTitle.color,
            };
         }),
         title: "Job Title",
         value: selectedItem ? new Set([selectedItem.id]) : new Set(),
         selectedItem,
         isClearable: requests.length > 1 || selectedItem != null,
         placeholder: "Select Job Title...",
         saveProvider: async ([jobTitle = null]) => {
            const requestTransformer = (request: SerializedFindRequest) => {
               request.job_title = jobTitle;
               return request;
            };
            await this.updateRows({
               requests: requests.map(requestTransformer),
               update: { job_title_id: jobTitle?.id ?? null },
               rowApplier: (request) => this.jobTitleRowApplier({ request, jobTitle }),
            });
         },
      }))(requests);
   };

   // Not currently visible in a row, but used for
   // applying and keeping state changes in the request-details-modal.
   private workDaysRowApplier = ({
      request,
      workDays,
   }: {
      request: SerializedFindRequest;
      workDays: WorkDaySelection;
   }) =>
      this.replaceFields<"work_days">({
         request,
         update: {
            work_days: workDays,
         },
      });

   private formatDayLong(day: number) {
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat(), {
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
         weekdayFormat: DateUtils.WeekDayFormat.NONE,
      });
   }

   batchEditFields(): Array<BatchEditor<SerializedFindRequest>> {
      return [
         {
            factory: this.tagInstancesEditorFactory,
            hasInternalSaveManagement: true,
         },
         {
            factory: this.projectEditorFactory,
            validator: (request) => {
               const canManageOthers = canManageOthersValidator(request);
               return !canManageOthers.status ? canManageOthers.message : null;
            },
         },
         {
            factory: this.jobTitleEditorFactory,
            validator: (request) => {
               const canManageOthers = canManageOthersValidator(request);
               return !canManageOthers.status ? canManageOthers.message : null;
            },
         },
         {
            factory: this.startDayEditorFactory,
            validator: (request, startDay) => {
               const canManageOthers = canManageOthersValidator(request);
               if (!canManageOthers.status) return canManageOthers.message;

               const result = isWorkDayValidator("Start day", request, startDay);
               if (!result.status) return result.message;
               return !startDay || request.end_day! < startDay
                  ? `Start day must be on or before ${this.formatDayLong(request.end_day!)}`
                  : null;
            },
         } as BatchEditor<SerializedFindRequest, number | null>,
         {
            factory: this.endDayEditorFactory,
            validator: (request, endDay) => {
               const canManageOthers = canManageOthersValidator(request);
               if (!canManageOthers.status) return canManageOthers.message;

               const result = isWorkDayValidator("End day", request, endDay);
               if (!result.status) return result.message;
               return !endDay || request.start_day! > endDay
                  ? `End day must be on or after ${this.formatDayLong(request.start_day!)}`
                  : null;
            },
         } as BatchEditor<SerializedFindRequest, number | null>,
         {
            factory: this.startTimeEditorFactory,
            validator: (request) => {
               const canManageOthers = canManageOthersValidator(request);
               if (!canManageOthers.status) return canManageOthers.message;

               const constraint = timeValidator("Start time", request);
               return !constraint.status ? constraint.message : null;
            },
         } as BatchEditor<SerializedFindRequest, number>,
         {
            factory: this.endTimeEditorFactory,
            validator: (request) => {
               const canManageOthers = canManageOthersValidator(request);
               if (!canManageOthers.status) return canManageOthers.message;

               const constraint = timeValidator("End time", request);
               return !constraint.status ? constraint.message : null;
            },
         } as BatchEditor<SerializedFindRequest, number>,
         {
            factory: this.percentAllocatedEditorFactory,
            validator: (request, percentAllocated) => {
               const canManageOthers = canManageOthersValidator(request);
               if (!canManageOthers.status) return canManageOthers.message;

               const validator = percentAllocatedValidator(request);
               const constraint = validator(percentAllocated);
               return constraint.status ? null : constraint.message;
            },
         } as BatchEditor<SerializedFindRequest, number | null>,
         {
            factory: this.statusEditorFactory,
            validator: (request) => {
               const canManageOthers = canManageOthersValidator(request);
               return !canManageOthers.status ? canManageOthers.message : null;
            },
         },
      ];
   }

   createRequestFromModal = async (
      request: CreateRequestPayload,
   ): Promise<CreateRequestResponse> => {
      return RequestStore.createRequest(request).payload;
   };

   updateRequestFromModal = async ({
      request,
      update,
   }: {
      request: SerializedFindRequest;
      update: Partial<RequestDetailsModalStagedUpdate>;
   }): Promise<void> => {
      const {
         category: costCode,
         status,
         job_title: jobTitle,
         project,
         subcategory: costCodeLabel,
         ...strippedUpdate
      } = update;

      await this.updateRows({
         requests: [request],
         update: strippedUpdate,
         rowApplier: (request) => {
            let newRequest: SerializedFindRequest = { ...request };

            // NOTE: Order of operations matters here, as some row appliers update the same elements. Be thoughtful.
            if (update.project_id != null && project != null) {
               newRequest = this.projectRowApplier({
                  request: newRequest,
                  project,
               });
            }
            if (update.category_id !== undefined) {
               newRequest = this.costCodeRowApplier({
                  request: newRequest,
                  costCode: costCode ?? null,
               });
            }
            if (update.subcategory_id !== undefined) {
               newRequest = this.costCodeLabelRowApplier({
                  request: newRequest,
                  costCodeLabel: costCodeLabel ?? null,
               });
            }

            // Remaining fields are unordered.
            if (update.start_day != null) {
               newRequest = this.startDayRowApplier({
                  request: newRequest,
                  startDay: update.start_day,
               });
            }
            if (update.end_day != null) {
               newRequest = this.endDayRowApplier({
                  request: newRequest,
                  endDay: update.end_day,
               });
            }
            if (update.start_time !== undefined) {
               newRequest = this.startTimeRowApplier({
                  request: newRequest,
                  startTime: update.start_time,
               });
            }
            if (update.end_time !== undefined) {
               newRequest = this.endTimeRowApplier({
                  request: newRequest,
                  endTime: update.end_time,
               });
            }
            if (update.status !== undefined) {
               newRequest = this.statusRowApplier({ request: newRequest, status: update.status });
            }
            if (jobTitle !== undefined) {
               newRequest = this.jobTitleRowApplier({
                  request: newRequest,
                  jobTitle: jobTitle,
               });
            }
            if (update.percent_allocated !== undefined) {
               newRequest = this.percentAllocatedRowApplier({
                  request: newRequest,
                  percentAllocated: update.percent_allocated,
               });
            }
            if (update.tag_ids != null) {
               newRequest = this.tagsRowApplier({
                  request: newRequest,
                  tagsUpdate: update.tag_ids,
               });
            }
            if (update.work_days != null) {
               newRequest = this.workDaysRowApplier({
                  request: newRequest,
                  workDays: update.work_days,
               });
            }
            if (update.custom_fields != null) {
               Object.entries(update.custom_fields).forEach(async ([id, value]) => {
                  const field = this.customFields().find((field) => field.id == id)!;
                  newRequest = customFieldUpdateRowApplier({
                     row: newRequest,
                     customFieldMeta: {
                        field_entity: ColumnEntityType.REQUESTS,
                        field_id: id,
                        field_property: field.integration_name,
                        field_type: field.type,
                     },
                     value,
                  });
               });
            }
            if (update.comments) {
               const authedUser = toJS(authManager.authedUser);
               update.comments.forEach((comment) => {
                  request.comments.push({
                     id: null as any, // Only used to satisfy type. Never sent to backend.
                     content: comment.content,
                     author_id: authedUser!.id,
                     created_at: Date.now(),
                     author_name: {
                        first: authedUser!.firstName,
                        last: authedUser!.lastName,
                     },
                  });
               });
            }
            if (update.work_scope_text !== undefined) {
               newRequest = {
                  ...newRequest,
                  work_scope_text: update.work_scope_text,
               };
            }
            if (update.instruction_text !== undefined) {
               newRequest = {
                  ...newRequest,
                  instruction_text: update.instruction_text,
               };
            }
            return newRequest;
         },
      });
      modalManager.clearModal();
   };

   deleteRequestFromModal = async (request: SerializedFindRequest): Promise<void> => {
      await this.deleteRow(request);
   };

   async updateCustomFields(
      requests: SerializedFindRequest[],
      customFieldMeta: CustomFieldMeta,
      value: CustomFieldInstance["value"] | null,
   ): Promise<void> {
      await this.updateRows({
         requests,
         update: {
            custom_fields: {
               [customFieldMeta.field_id]: value,
            },
         },
         rowApplier: (request) => {
            return customFieldUpdateRowApplier({
               customFieldMeta,
               row: request,
               value,
            });
         },
      });
   }

   private async updateRows({
      requests,
      update,
      rowApplier,
   }: {
      requests: SerializedFindRequest[];
      update: Partial<UpdateRequestsPayload<AuthType.SESSION>>;
      rowApplier: (request: SerializedFindRequest) => SerializedFindRequest | null;
   }) {
      await this.rowsUpdater.update({
         size: requests.length,
         updatePayload: requests.map((r) => ({ id: r.id, ...update })),
         rowApplier,
      });
   }

   async batchDeleteRows(requests: SerializedFindRequest[]): Promise<string[]> {
      const ids = requests.map((a) => a.id);
      await this.rowsRemover.delete({ ids });
      this.totalPossible(this.totalPossible() - requests.length);
      return ids;
   }

   private async deleteRow(request: SerializedFindRequest) {
      await RequestStore.deleteRequest(request.id).payload;
      this.rows.remove(request);
      this.totalPossible(this.totalPossible() - 1);
   }
}

export function canManageOthersValidator(request: Pick<SerializedFindRequest, "creator_id">): {
   status: boolean;
   message: string | null;
} {
   const isSameUser = request.creator_id === authManager.authedUserId();
   if (isSameUser) return { status: true, message: null };

   const status = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_OTHERS_REQUESTS);
   const message = !status ? "You do not have permission to manage others' requests." : null;
   return { status, message };
}

function isWorkDayValidator(
   field: string,
   request: SerializedFindRequest,
   detachedDay: number | null,
) {
   const dayOfWeek = detachedDay ? DateUtils.getAttachedDate(detachedDay).getDay() : -1;
   if (!request.work_days[dayOfWeek as keyof AssignmentWorkDays]) {
      return {
         status: false,
         message: `${field} must be on an active workday.`,
      };
   }
   return { status: true, message: null };
}

function isPercentAllocatedGtZero(val: number | null) {
   if (val === null || val <= 0) {
      return {
         status: false,
         message: "Percent Allocation must be greater than 0.",
      };
   }
   return {
      status: true,
      message: null,
   };
}

function timeValidator(field: string, request: SerializedFindRequest) {
   if (request.percent_allocated) {
      return {
         status: false,
         message: `${field} cannot be set when Percent Allocation is set.`,
      };
   }

   return {
      status: true,
      message: null,
   };
}

function percentAllocatedValidator(
   request: SerializedFindRequest,
): (val: number | null) => { status: boolean; message: null | string } {
   return (val: number | null) => {
      const constraint = isPercentAllocatedGtZero(val);
      if (!constraint.status) return constraint;

      if (request.start_time || request.end_time) {
         return {
            status: false,
            message: "Percent Allocation can not be set when a start time or end time is set.",
         };
      }

      return {
         status: true,
         message: null,
      };
   };
}
