import { Url as UrlUtils } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { Store } from "@/stores/store"
import { authManager } from "@/lib/managers/auth-manager"
import { downloadFile } from "@/lib/reports/report-options";

### Models ###
import { Placeholder } from "@/models/placeholder"
import { Assignment } from "@/models/assignment"

export class RequestStore extends Store
   constructor: ->
      super()
      @vault = {
      }

   createRequests: (quantity, data, callback) ->
      assertArgs(arguments, Number, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/requests?quantity=#{quantity}"
         method: "POST"
         data: data
      }, (err, data) =>
         return callback(err) if err
         placeholders = data.map (item) ->
            return new Placeholder(item)

         callback(null, placeholders)

   getRequest: (requestId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/requests/#{requestId}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err

         callback(null, new Placeholder(data))

   updateRequest: (requestId, data, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/requests/#{requestId}"
         method: "POST"
         data: data
      }, (err, data) =>
         return callback(err) if err

         callback(null, new Placeholder(data))

   deleteRequest: (requestId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/requests/#{requestId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err

         callback(null, success)

   # TODO: Delete. No longer being used.
   fillRequest: (requestId, assignmentData, rangeData, callback) ->
      assertArgs(arguments, String, Object, nullable(Object), Function)
      url = "/api/companies/#{authManager.companyId()}/requests/#{requestId}/fill"
      if rangeData?
         params = UrlUtils.serializeParams(rangeData)
         url = "#{url}?#{params}"
      @makeRequest {
         url: url
         method: "POST"
         data: assignmentData
      }, (err, data) =>
         return callback(err) if err
         # Null is possible if there is no existing request data, lets handle it so the store does not error out.
         removedRequestData = null 

         if data.removed_request_data?
            removedRequestData = {
               projectId: data.removed_request_data.project_id
               costCodeId: data.removed_request_data.cost_code_id
               labelId: data.removed_request_data.label_id
               id: data.removed_request_data.id
            }

         formattedData = {
            removedRequestData: removedRequestData
            newAssignment: new Assignment(data.new_assignment)
         }
         callback(null, formattedData)

   getFilterRequests: (params, callback) ->
      assertArgs(arguments, Object, Function)
      companyId = authManager.companyId()
      groupId = authManager.selectedGroupId()
      params = UrlUtils.serializeParams(params)
      @makeRequest {
         url: "/api/companies/#{companyId}/groups/#{groupId}/requests?#{params}"
         method: "GET"
      }, (err, result) =>
         return callback(err) if err
         data = {
            placeholders: result.data.map (placeholder) ->
               return new Placeholder(placeholder, true)
            totalCount: result.total_count
         }
         callback(null, data)
   # async
   getFilteredRequestsReportCsv: (params) ->
      serializedParams = UrlUtils.serializeParams(params)
      return downloadFile("/api/download/companies/#{authManager.companyId()}/groups/#{authManager.selectedGroupId()}/requests/filter/csv?#{serializedParams}")
   
   # async
   getFilteredRequestsReportPdf: (params) ->
      serializedParams = UrlUtils.serializeParams(params)
      return downloadFile("/api/download/companies/#{authManager.companyId()}/groups/#{authManager.selectedGroupId()}/requests/filter/pdf?#{serializedParams}")


RequestStore.VaultKey = {
}

export requestStore = new  RequestStore()