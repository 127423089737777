import "./integrated-fields.styl"
import template from "./integrated-fields.pug"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { CustomFieldStore } from "@/stores/custom-field-store.core"
import { SettingsStore } from "@/stores/settings-store.core"

### Models ###
import { PermissionLevel } from "@/models/permission-level"
import { CustomField } from "@/models/custom-field"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

### Framework Includes ###
import ko from "knockout"

export class IntegratedFieldsViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - Integrated Fields")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageCustomFieldsSettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_CUSTOM_FIELDS_SETTINGS)

      @saveEnabled = ko.pureComputed =>
         if Object.keys(ko.unwrap(@keyedExistingPeopleFields)).length != @selectedPeopleFields().length
            return true
         else
            for field in @selectedPeopleFields()
               if @keyedExistingPeopleFields()[field.property]?
                  if field.locked() != @keyedExistingPeopleFields()[field.property]
                     return true
               else
                  return true

         if Object.keys(ko.unwrap(@keyedExistingProjectFields)).length != @selectedProjectFields().length
            return true
         else
            for field in @selectedProjectFields()
               if @keyedExistingProjectFields()[field.property]?
                  if field.locked() != @keyedExistingProjectFields()[field.property]
                     return true
               else
                  return true

         return false

      @searchQuery = ko.observable()
      @customFields = ko.observableArray()

      @keyedExistingPeopleFields = ko.observable({})
      @selectedPeopleFields = ko.observableArray()
      @selectedPeopleFieldProperties = ko.pureComputed =>
         return @selectedPeopleFields().map (item) -> return item.property

      @peopleFields = ko.observableArray([
         new DropDownItem("First Name", "first_name", {locked: false})
         new DropDownItem("Last Name", "last_name", {locked: ko.observable(false)})
         # Not a real property, but how the UI handles it.
         new DropDownItem("Person Type", "person_type", {locked: ko.observable(false)})
         new DropDownItem("Status", "status", {locked: ko.observable(false)})
         new DropDownItem("Groups", "group_ids", {locked: ko.observable(false)})
         new DropDownItem("Email", "email", {locked: ko.observable(false)})
         new DropDownItem("Permission Level", "permission_level_id", {locked: ko.observable(false)})
         new DropDownItem("Notification Profile", "notification_profile_id", {locked: ko.observable(false)})
         new DropDownItem("Phone", "phone", {locked: ko.observable(false)})
         new DropDownItem("Recieve SMS", "can_recieve_sms", {locked: ko.observable(false)})
         new DropDownItem("Recieve Email", "can_recieve_email", {locked: ko.observable(false)})
         new DropDownItem("Recieve Mobile Notifications", "can_recieve_mobile", {locked: ko.observable(false)})
         new DropDownItem("Address 1", "address_1", {locked: ko.observable(false)})
         new DropDownItem("Address 2", "address_2", {locked: ko.observable(false)})
         new DropDownItem("State/Province", "state_province", {locked: ko.observable(false)})
         new DropDownItem("City/Town", "city_town", {locked: ko.observable(false)})
         new DropDownItem("Zipcode", "zipcode", {locked: ko.observable(false)})
         new DropDownItem("Country", "country", {locked: ko.observable(false)})
         new DropDownItem("Job Title", "position_id", {locked: ko.observable(false)})
         new DropDownItem("Hourly Wage", "hourly_wage", {locked: ko.observable(false)})
         new DropDownItem("Employee Number", "employee_number", {locked: ko.observable(false)})
         new DropDownItem("Emergency Contact Name", "emergency_contact_name", {locked: ko.observable(false)})
         new DropDownItem("Emergency Contact Number", "emergency_contact_number", {locked: ko.observable(false)})
         new DropDownItem("Emergency Contact Email", "emergency_contact_email", {locked: ko.observable(false)})
         new DropDownItem("Emergency Contact Relation", "emergency_contact_relation", {locked: ko.observable(false)})
         new DropDownItem("Date of Birth", "dob", {locked: ko.observable(false)})
         # TODO: Update this.
         new DropDownItem("Gender", "is_male", {locked: ko.observable(false)})
         new DropDownItem("Hired Date", "hired_date", {locked: ko.observable(false)})
         new DropDownItem("Language", "language", {locked: ko.observable(false)})
      ])
      # Used to clear dropdowns out.
      @lastSelectedPeopleField = ko.observable()
      @availablePeopleFields = ko.pureComputed =>
         return @peopleFields().filter (item) =>
            return @selectedPeopleFieldProperties().indexOf(item.value()) == -1

      @keyedExistingProjectFields = ko.observable({})
      @selectedProjectFields = ko.observableArray()
      @selectedProjectFieldProperties = ko.pureComputed =>
         return @selectedProjectFields().map (item) -> return item.property

      @projectFields = ko.observableArray([
         new DropDownItem("Name", "name", {locked: ko.observable(false)})
         new DropDownItem("Status", "status", {locked: ko.observable(false)})
         new DropDownItem("Groups", "group_ids", {locked: ko.observable(false)})
         new DropDownItem("Start Date", "start_date", {locked: ko.observable(false)})
         new DropDownItem("Timezone", "timezone", {locked: ko.observable(false)})
         new DropDownItem("Color", "color", {locked: ko.observable(false)})
         new DropDownItem("Daily Start Time", "daily_start_time", {locked: ko.observable(false)})
         new DropDownItem("Daily End Time", "daily_end_time", {locked: ko.observable(false)})
         new DropDownItem("Est. End Date", "est_end_date", {locked: ko.observable(false)})
         new DropDownItem("Address 1", "address_1", {locked: ko.observable(false)})
         new DropDownItem("Address 2", "address_2", {locked: ko.observable(false)})
         new DropDownItem("State/Province", "state_province", {locked: ko.observable(false)})
         new DropDownItem("City/Town", "city_town", {locked: ko.observable(false)})
         new DropDownItem("Zipcode", "zipcode", {locked: ko.observable(false)})
         new DropDownItem("Country", "country", {locked: ko.observable(false)})
         new DropDownItem("Project Number", "job_number", {locked: ko.observable(false)})
         new DropDownItem("Bid Rate", "bid_rate", {locked: ko.observable(false)})
         new DropDownItem("Percent Complete", "percent_complete", {locked: ko.observable(false)})
         new DropDownItem("Customer Name", "customer_name", {locked: ko.observable(false)})
         new DropDownItem("Project Type", "project_type", {locked: ko.observable(false)})
      ])
      # Used to clear dropdowns out.
      @lastSelectedProjectField = ko.observable()
      @availableProjectFields = ko.pureComputed =>
         return @projectFields().filter (item) =>
            return @selectedProjectFieldProperties().indexOf(item.value()) == -1


      @loadData()

   peopleFieldSelected: (item) =>
      # Doing this to be able to edit the locked state. 
      field = {name: item.name(), property: item.value(), locked: ko.observable(false)}
      @selectedPeopleFields.push(field)
      @lastSelectedPeopleField(null)

   removePeopleField: (item) =>
      @selectedPeopleFields.remove(item)

   projectFieldSelected: (item) =>
      field = {name: item.name(), property: item.value(), locked: ko.observable(false)}
      @selectedProjectFields.push(field)
      @lastSelectedProjectField(null)

   removeProjectsField: (item) =>
      @selectedProjectFields.remove(item)

   setSearchQuery: (query) =>
      assertArgs(arguments, Function)
      @searchQuery(query())

   saveClicked: =>
      data = {
         people_integrated_fields: @selectedPeopleFields().map (item) ->
            return ko.toJS(item)
         projects_integrated_fields: @selectedProjectFields().map (item) ->
            return ko.toJS(item)
      }
      @updateIntegratedFields data, (err) =>
         return console.log "Error: ", err if err
         keyedExistingPeopleFields = {}
         for field in @selectedPeopleFields()
            keyedExistingPeopleFields[field.property] = field.locked()
         # Doing it this way to trigger computeds.
         @keyedExistingPeopleFields(keyedExistingPeopleFields)

         keyedExistingProjectFields = {}
         for field in @selectedProjectFields()
            keyedExistingProjectFields[field.property] = field.locked()
         # Doing it this way to trigger computeds.
         @keyedExistingProjectFields(keyedExistingProjectFields)

   updateIntegratedFields: (payload, callback) ->
      try
         result = await SettingsStore.updateIntegratedFields(payload).payload
         formattedResult = {
            peopleIntegratedFields: result.data.people_integrated_fields,
            projectsIntegratedFields: result.data.projects_integrated_fields,
         }
         callback(null, formattedResult)
      catch err
         callback(err)

   getIntegratedFields: (callback) ->
      try
         result = await SettingsStore.getIntegratedFields().payload
         formattedResult = {
            peopleIntegratedFields: result.data.people_integrated_fields,
            projectsIntegratedFields: result.data.projects_integrated_fields,
         }
         callback(null, formattedResult)
      catch err
         callback(err)

   loadData: ->
      @getCustomFields (err, data) =>
         return console.log "Error: ", err if err
         @customFields(data)
         peopleCustomFields = []
         projectCustomFields = []
         for field in data
            # Using ID as value so that even if someone renames the field, this stays valid.
            baggage = {locked: ko.observable(field.integrationOnly()), customField: true}
            dropdowItem = new DropDownItem(field.name(), field.id, baggage)
            if field.onPeople()
               peopleCustomFields.push(dropdowItem)
            
            if field.onProjects()
               projectCustomFields.push(dropdowItem)

         @peopleFields(@peopleFields().concat(peopleCustomFields))
         @projectFields(@projectFields().concat(projectCustomFields))

      @getIntegratedFields (err, data) =>
         return console.log "Error: ", err if err
         keyedExistingPeopleFields = {}
         selectedPeopleFields = []
         for item in data.peopleIntegratedFields
            selectedPeopleFields.push({
               name: item.name,
               property: item.property
               locked: ko.observable(item.locked)
            })
            keyedExistingPeopleFields[item.property] = item.locked
         # Doing it this way to trigger computeds.
         @selectedPeopleFields(selectedPeopleFields)
         @keyedExistingPeopleFields(keyedExistingPeopleFields)

         keyedExistingProjectFields = {}
         selectedProjectFields = []
         for item in data.projectsIntegratedFields
            selectedProjectFields.push({
               name: item.name,
               property: item.property
               locked: ko.observable(item.locked)
            })
            keyedExistingProjectFields[item.property] = item.locked
         # Doing it this way to trigger computeds.
         @selectedProjectFields(selectedProjectFields)
         @keyedExistingProjectFields(keyedExistingProjectFields)

   getCustomFields: (callback) ->
      fields = []
      try
         query = {}
         stream = await CustomFieldStore.findCustomFieldsStream(query).stream
         for await row from stream
            fields.push(new CustomField(row))
         callback(null, fields)
      catch err
         return callback(err)