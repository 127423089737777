import React, { useEffect, useState } from "react";
import { Modal, Typography, Button, DateSelect, ProgressBar, Spinner } from "@procore/core-react";
import { Info } from "@procore/core-icons";
import { isBefore } from "date-fns";
import moment from "moment";
import type { ShiftProjectPropTypes } from "@/react/prop-types";
import { ProjectStore } from "@/stores/project-store.core";
import { IntegrationsStore } from "@/stores/integration-store.core";
import { useToastAlertContext } from "@procore/toast-alert";

export const ShiftProjectModal = (props: ShiftProjectPropTypes) => {
   // I18n is included as a prop for testability
   const { projectId, projectName, startDate, refreshRootPage, I18n } = props;
   const { showToast } = useToastAlertContext();
   const [projectIntStatus, setProjectIntStatus] = useState<any>();
   const [loading, setLoading] = useState(true);
   const [isOpen, setIsOpen] = useState(true);
   const [isSaving, setIsSaving] = useState(false);
   const [progressValue, setProgressValue] = useState(10);
   const initialStartDateValue = new Date(startDate);
   const timezoneOffset = initialStartDateValue.getTimezoneOffset();
   if (timezoneOffset > 0) {
      initialStartDateValue.setHours(
         initialStartDateValue.getHours() + initialStartDateValue.getTimezoneOffset() / 60,
      );
   }
   const [newStartDate, setNewStartDate] = useState(initialStartDateValue);

   const fetchProjectIntegrationStatus = async () => {
      const data = await IntegrationsStore.getProjectIntegrationStatus(projectId).payload;

      setProjectIntStatus(data);
      setLoading(false);
   };

   useEffect(() => {
      fetchProjectIntegrationStatus();
   }, []);

   const calculateDelta = () => {
      if (!newStartDate) return;
      const originalStartDateMoment = moment(startDate).startOf("day");
      const newStartDateMoment = moment(newStartDate).startOf("day");
      // If the project shifted beyond a Daylight Savings time switch (Nov and Mar), there will be a decimal value to be rounded off.
      const shiftDelta = Math.round(
         moment.duration(newStartDateMoment.diff(originalStartDateMoment)).asDays(),
      );
      return shiftDelta;
   };

   const getDaysShiftedText = () => {
      const delta = calculateDelta();
      if (delta == 1 || delta === -1) {
         return ` ${I18n.t("views.company.workforce_planning.shift_project.day_shifted", {
            days: delta,
         })}`;
      } else {
         return ` ${I18n.t("views.company.workforce_planning.shift_project.days_shifted", {
            days: delta,
         })}`;
      }
   };

   const onUpdate = async () => {
      const formattedStartDate = moment(newStartDate).format("YYYY-MM-DD");
      setIsSaving(true);
      setProgressValue(35);
      try {
         await ProjectStore.shiftProject(projectId, formattedStartDate).payload;
         setProgressValue(98);
         showToast.success(
            I18n.t("views.company.workforce_planning.shift_project.toast.success", {
               project: projectName,
            }),
         );
         setIsOpen(false);
         refreshRootPage();
      } catch (err) {
         showToast.error(
            I18n.t("views.company.workforce_planning.shift_project.toast.error", {
               project: projectName,
            }),
         );
         setIsOpen(false);
      }
   };
   let allowShiftProject;
   if (!projectIntStatus) allowShiftProject = false;
   else if (projectIntStatus.project_integrated) {
      if (projectIntStatus.update_ignore_fields?.includes("start_date")) {
         allowShiftProject = true;
      } else {
         allowShiftProject = false;
      }
   } else allowShiftProject = true;

   const disabledMessage = projectIntStatus?.shift_project
      ? I18n.t("views.company.workforce_planning.shift_project.shift_enabled_via_integration")
      : I18n.t("views.company.workforce_planning.shift_project.shift_disabled_via_integration");

   if (isSaving) {
      return (
         <Modal open={isOpen} style={{ width: 540 }}>
            <Modal.Header>
               {I18n.t("views.company.workforce_planning.shift_project.progress_modal_header")}
            </Modal.Header>
            <Modal.Section style={{ paddingBottom: 0 }}>
               <Typography>
                  {I18n.t("views.company.workforce_planning.shift_project.progress_details_start")}
               </Typography>
               <Typography weight="semibold">{` ${projectName} `}</Typography>
               <Typography>
                  {I18n.t("views.company.workforce_planning.shift_project.progress_details_end")}
               </Typography>
               <Typography weight="semibold">{getDaysShiftedText()}</Typography>
               <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                  <ProgressBar
                     data-test-id="shift-project-progress-bar"
                     value={progressValue}
                     animated={true}
                  ></ProgressBar>
               </div>
            </Modal.Section>
         </Modal>
      );
   } else {
      const showInfoText = newStartDate != null && newStartDate != initialStartDateValue;

      return (
         <Modal open={isOpen} style={{ width: 540 }}>
            <Modal.Header
               onClose={() => {
                  setIsOpen(false);
               }}
            >
               {I18n.t("views.company.workforce_planning.shift_project.modal_header")}
            </Modal.Header>
            <Spinner loading={loading}>
               <Modal.Section style={{ paddingBottom: 0 }}>
                  {allowShiftProject ? (
                     <>
                        <Typography>
                           {I18n.t("views.company.workforce_planning.shift_project.details")}
                        </Typography>
                        <Typography weight="semibold">{` ${projectName} `}</Typography>
                        <Typography>
                           {I18n.t("views.company.workforce_planning.shift_project.project_end")}
                        </Typography>
                        <div style={{ paddingTop: 24 }}>
                           <Typography weight="semibold" as="div">
                              {I18n.t("views.company.workforce_planning.start_date")}
                           </Typography>
                           <DateSelect
                              data-test-id={"shift-project-date-picker"}
                              disabledDate={(date, today) => isBefore(date, today)}
                              value={newStartDate}
                              onChange={(date) => setNewStartDate(date!)}
                           />
                        </div>
                        <div
                           className="shift-project-info-block"
                           style={{ visibility: showInfoText ? "visible" : "hidden" }}
                        >
                           <Info style={{ height: 20, width: 20 }} />
                           <span>
                              <Typography>
                                 {I18n.t(
                                    "views.company.workforce_planning.shift_project.confirmation",
                                 )}
                              </Typography>
                              <Typography weight="semibold">{getDaysShiftedText()}</Typography>
                           </span>
                        </div>
                     </>
                  ) : (
                     <Typography weight="semibold">{disabledMessage}</Typography>
                  )}
               </Modal.Section>
            </Spinner>
            <Modal.Footer>
               <Modal.FooterButtons>
                  <Button
                     variant="tertiary"
                     onClick={() => {
                        setIsOpen(false);
                     }}
                  >
                     {I18n.t("views.company.workforce_planning.cancel")}
                  </Button>
                  {allowShiftProject && (
                     <Button variant="primary" onClick={onUpdate} disabled={loading}>
                        {I18n.t("views.company.workforce_planning.update")}
                     </Button>
                  )}
               </Modal.FooterButtons>
            </Modal.Footer>
         </Modal>
      );
   }
};
