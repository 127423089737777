import "./confirm-delete-status-pane.styl";
import template from "./confirm-delete-status-pane.pug";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import { modalManager } from "@/lib/managers/modal-manager";
import { ModalPane } from "@/lib/components/modals/modal-pane";

export class ConfirmDeleteStatusPaneViewModel extends ModalPane {
   private readonly statusName: string;

   constructor(statusName: string) {
      super("Delete Status", "", template());
      this.statusName = statusName;
   }

   private readonly confirmationText: Observable<string> = observable("");
   private readonly canDelete: PureComputed<boolean | undefined> = pureComputed(() => {
      if (this.confirmationText() == null) return;
      return this.confirmationText().toLowerCase() === "yes";
   });

   confirmDelete(): void {
      if (!this.canDelete()) return;
      return modalManager.modalFinished();
   }
}
