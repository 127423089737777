import ko from "knockout"
import template from "./person-qr-profile.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Flag } from "@/flags"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"
import { PersonStore } from "@/stores/person-store.core"

export class PersonQrProfileViewModel extends PageContentViewModel
   constructor: (companyQrId, personQrId) ->
      super(template(), "Person QR Profile")
      @companyQrId = companyQrId
      @personQrId = personQrId

      @errorMessage = ko.observable(null)

      @qrInfo = ko.observable()
      @logoUrl = "#{window.config.LC_DOMAIN}/images/procore-logo.png"

      @showLogo = Flag.ENABLE_WORKFORCE_PLANNING_V1

      @loadData()

   formatMsDate: (ms) ->
      return DateUtils.getShortNumericDate(new Date(ms), defaultStore.getDateFormat())

   formatTimeVal: (time) ->
      return DateUtils.formatTimeVal(time)

   formattedDay: (day) ->
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat())

   formatPhone: (phone) ->
      return "#{phone.slice(0,2)}-#{phone.slice(2,5)}-#{phone.slice(5,8)}-#{phone.slice(8,12)}"

   getPhotoUrl: (baseUrl) ->
      fragments = baseUrl.split("upload/")
      url = "#{fragments[0]}upload/g_face,c_thumb,w_80,h_80/#{fragments[1]}"
      return url

   getArrayString: (vals) ->
      str = ''
      for val in vals
         str += "#{val}, "
      return str.slice(0, -2)

   getExprRequiredTagClass: (data) ->
      return "" unless data.expr_date?
      detachedNow = DateUtils.getDetachedDay(new Date())
      if  data.expr_date <= detachedNow
         return "icon-warning-triangle"
      else
         daysBetween = DateUtils.getDaysBetweenDetachedDays(detachedNow, data.expr_date)
         if daysBetween <= data.expr_days_warning
            return "icon-caution-triangle"
         return ""

   loadData: ->
      try
         result = await PersonStore.getPersonQrInfo(@companyQrId, @personQrId).payload
         @qrInfo(result.data)
      catch Error
         console.log("Error", Error)
         err = if typeof Error.message == Object then JSON.parse(Error.message) else Error
         if err.detailCode == 'peopleQrDisabled'
            @errorMessage("QR profiles for People have been disabled by the admins of this account.")
         else if err.detailCode == 'qrModuleDisabled'
            @errorMessage("The QR Code module has been disabled for this account.")
         else if err.detailCode == 'qrEntityArchived'
            @errorMessage("The Person for this QR code has been removed.")
         else
            @errorMessage("Invalid Scan. The image may be corrupted.")

