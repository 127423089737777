import template from "./costing.pug"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import * as ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { SettingsStore } from "@/stores/settings-store.core"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

export class CostingViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - Costing")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageCostingSettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_COSTING_SETTINGS)

      @existingConfig = ko.observable()

      @paidShiftHours = ko.observable(8)
      @allowOvertimeConfig = ko.observable(false)

      @sundayRate = ko.observable()
      @mondayRate = ko.observable()
      @tuesdayRate = ko.observable()
      @wednesdayRate = ko.observable()
      @thursdayRate = ko.observable()
      @fridayRate = ko.observable()
      @saturdayRate = ko.observable()

      @canSave = ko.pureComputed =>
         return false unless @existingConfig()?
         return true if @existingConfig().paidShiftHours != Number(@paidShiftHours())
         if @existingConfig().overtimeDayRates? and @allowOvertimeConfig()
            if (@existingConfig().overtimeDayRates.sunday != Number(@sundayRate()) or
            @existingConfig().overtimeDayRates.monday != Number(@mondayRate()) or
            @existingConfig().overtimeDayRates.tuesday != Number(@tuesdayRate()) or
            @existingConfig().overtimeDayRates.wednesday != Number(@wednesdayRate()) or
            @existingConfig().overtimeDayRates.thursday != Number(@thursdayRate()) or
            @existingConfig().overtimeDayRates.friday != Number(@fridayRate()) or
            @existingConfig().overtimeDayRates.saturday != Number(@saturdayRate())
            )
               return true 
            else
               return false
         else if !@existingConfig().overtimeDayRates? and !@allowOvertimeConfig()
            return false
         else
            return true

      @loadData()

   cancelChanges: =>
      data = @existingConfig()

      @paidShiftHours(Number(data.paidShiftHours))

      if data.overtimeDayRates?
         @allowOvertimeConfig(true)
         @sundayRate(data.overtimeDayRates.sunday)
         @mondayRate(data.overtimeDayRates.monday)
         @tuesdayRate(data.overtimeDayRates.tuesday)
         @wednesdayRate(data.overtimeDayRates.wednesday)
         @thursdayRate(data.overtimeDayRates.thursday)
         @fridayRate(data.overtimeDayRates.friday)
         @saturdayRate(data.overtimeDayRates.saturday)
      else
         # Defaults
         @allowOvertimeConfig(false)
         @sundayRate(2)
         @mondayRate(1.5)
         @tuesdayRate(1.5)
         @wednesdayRate(1.5)
         @thursdayRate(1.5)
         @fridayRate(1.5)
         @saturdayRate(1.5)

   saveChanges: ->
      dataToSave = {
         paid_shift_hours: Number(@paidShiftHours())
      }
      if @allowOvertimeConfig()
         dataToSave['overtime_day_rates'] = {
            sunday: if Number(@sundayRate()) >= 0 then Number(@sundayRate()) else 2
            monday: if Number(@mondayRate()) >= 0 then Number(@mondayRate()) else 1.5
            tuesday: if Number(@tuesdayRate()) >= 0 then Number(@tuesdayRate()) else 1.5
            wednesday: if Number(@wednesdayRate()) >= 0 then Number(@wednesdayRate()) else 1.5
            thursday: if Number(@thursdayRate()) >= 0 then Number(@thursdayRate()) else 1.5
            friday: if Number(@fridayRate()) >= 0 then Number(@fridayRate()) else 1.5
            saturday: if Number(@saturdayRate()) >= 0 then Number(@saturdayRate()) else 1.5
         }
      else
         dataToSave['overtime_day_rates'] = null

      @updateCompanyCostingInfo dataToSave, (err, data) =>
         return console.log "Error: ", err if err
         @existingConfig(data)

   updateCompanyCostingInfo: (payload, callback) ->
      try
         result = await SettingsStore.updateCostingInfo(payload).payload
         formattedData = {
            paidShiftHours: result.data.paid_shift_hours
            overtimeDayRates: result.data.overtime_day_rates
         }
         callback(null, formattedData)
      catch err
         callback(err)

   getCompanyCostingInfo: (callback) ->
      try
         result = await SettingsStore.getCostingInfo().payload
         formattedData = {
            paidShiftHours: result.data.paid_shift_hours
            overtimeDayRates: result.data.overtime_day_rates
         }
         callback(null, formattedData)
      catch err
         callback(err)

   loadData: ->
      @getCompanyCostingInfo (err, data) =>
         return console.log "Error: ", err if err

         @existingConfig(data)

         @paidShiftHours(Number(data.paidShiftHours))

         if data.overtimeDayRates?
            @allowOvertimeConfig(true)
            @sundayRate(data.overtimeDayRates.sunday)
            @mondayRate(data.overtimeDayRates.monday)
            @tuesdayRate(data.overtimeDayRates.tuesday)
            @wednesdayRate(data.overtimeDayRates.wednesday)
            @thursdayRate(data.overtimeDayRates.thursday)
            @fridayRate(data.overtimeDayRates.friday)
            @saturdayRate(data.overtimeDayRates.saturday)
         else
            # Defaults
            @allowOvertimeConfig(false)
            @sundayRate(2)
            @mondayRate(1.5)
            @tuesdayRate(1.5)
            @wednesdayRate(1.5)
            @thursdayRate(1.5)
            @fridayRate(1.5)
            @saturdayRate(1.5)


