import type { StoreJsonResponse } from "@/stores/common/store.core";
import { Store } from "@/stores/common/store.core";

import type { GetReportOptionsResponse } from "@laborchart-modules/lc-core-api/dist/api/reports/get-reports-by-type";
import type {
   CreateManPowerOptionsRequest,
   CreateManPowerOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/create-manpower-report-options";
import type {
   UpdateManPowerOptionsResponse,
   UpdateManPowerOptionsRequest,
} from "@laborchart-modules/lc-core-api/dist/api/reports/update-manpower-report-options";
import type { DeleteReportResponse } from "@laborchart-modules/lc-core-api/dist/api/reports/delete-report-options";
import type { GetReportResponse } from "@laborchart-modules/lc-core-api/dist/api/reports/get-report-options";
import type {
   CreateLookAheadOptionsRequest,
   CreateLookAheadOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/create-look-ahead-report-options";
import type {
   UpdateLookAheadOptionsRequest,
   UpdateLookAheadOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/update-look-ahead-report-options";

import type {
   CreateTagActionOptionsRequest,
   CreateTagActionOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/create-tag-action-report-options";

import type {
   UpdateTagActionOptionsRequest,
   UpdateTagActionOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/update-tag-action-report-options";

import type {
   CreateAssignmentHistoryOptionsRequest,
   CreateAssignmentHistoryOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/create-assignment-history-report-options";

import type {
   UpdateAssignmentHistoryOptionsRequest,
   UpdateAssignmentHistoryOptionsResponse,
} from "@laborchart-modules/lc-core-api/dist/api/reports/update-assignment-history-report-options";

export abstract class ReportStore extends Store {
   static getReportsByType(
      groupId: string,
      reportType: string,
   ): StoreJsonResponse<GetReportOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/groups/${groupId}/reports/`,
         method: "GET",
         query: { report_type: reportType },
      });
   }

   static getReportOptions(reportId: string): StoreJsonResponse<GetReportResponse> {
      return this.requestJson({
         url: `/api/v3/reports/${reportId}`,
         method: "GET",
      });
   }

   static deleteReportOptions(reportId: string): StoreJsonResponse<DeleteReportResponse> {
      return this.requestJson({
         url: `/api/v3/reports/${reportId}`,
         method: "DELETE",
      });
   }

   static createManpowerReportOptions(
      body: CreateManPowerOptionsRequest,
   ): StoreJsonResponse<CreateManPowerOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/manpower`,
         method: "POST",
         body,
      });
   }

   static updateManpowerReportOptions(
      payload: UpdateManPowerOptionsRequest,
   ): StoreJsonResponse<UpdateManPowerOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/manpower/${payload.params.report_id}`,
         method: "PATCH",
         body: payload.body,
      });
   }

   static createLookAheadReportOptions(
      payload: CreateLookAheadOptionsRequest,
   ): StoreJsonResponse<CreateLookAheadOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/look-ahead`,
         method: "POST",
         body: payload.body,
      });
   }

   static updateLookAheadReportOptions(
      payload: UpdateLookAheadOptionsRequest,
   ): StoreJsonResponse<UpdateLookAheadOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/look-ahead/${payload.params.report_id}`,
         method: "PATCH",
         body: payload.body,
      });
   }

   static createTagActionReportOptions(
      payload: CreateTagActionOptionsRequest,
   ): StoreJsonResponse<CreateTagActionOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/tag-action`,
         method: "POST",
         body: payload.body,
      });
   }

   static updateTagActionReportOptions(
      payload: UpdateTagActionOptionsRequest,
   ): StoreJsonResponse<UpdateTagActionOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/tag-action/${payload.params.report_id}`,
         method: "PATCH",
         body: payload.body,
      });
   }

   static createAssignmentHistoryReportOptions(
      payload: CreateAssignmentHistoryOptionsRequest,
   ): StoreJsonResponse<CreateAssignmentHistoryOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/assignment-history`,
         method: "POST",
         body: payload.body,
      });
   }

   static updateAssignmentHistoryReportOptions(
      payload: UpdateAssignmentHistoryOptionsRequest,
   ): StoreJsonResponse<UpdateAssignmentHistoryOptionsResponse> {
      return this.requestJson({
         url: `/api/v3/reports/assignment-history/${payload.params.report_id}`,
         method: "PATCH",
         body: payload.body,
      });
   }
}
