import { authManager } from "./auth-manager";
import { Url as UrlUtils } from "@/lib/utils/url";
import type { RouteRequest } from "../router";

export type RequestObject =
   | {
        fragment: string;
        params: object;
     }
   | RouteRequest;

export type ResponseError =
   | Error
   | {
        responseText: string;
        status: number;
     };

export class ProcoreAuthManager {
   oauthCallback = async (req?: RequestObject): Promise<void> => {
      const fragment = req ? req.fragment : UrlUtils.getURLFragment();

      if (fragment) {
         const params = UrlUtils.parseFragment(fragment);
         const state = decodeURIComponent(params.state);
         const procoreCompanyId = UrlUtils.getProcoreCompanyIdFromURL(state);

         const callback = (err: ResponseError) => {
            if (err && "status" in err) {
               const error = encodeURIComponent(JSON.parse(err.responseText).message);
               window.location.replace(`${state}?status=${err.status}&error=${error}`);
               return console.log("err: ", err);
            }
         };

         const response = await authManager.attemptProcoreLogin(
            procoreCompanyId!,
            params.access_token,
            callback,
         );

         if (!response) {
            return;
         }

         const {
            data: { wfp_access_token, wfp_refresh_token },
         } = response;

         if (wfp_access_token) {
            localStorage.setItem("wfpAccessToken", wfp_access_token);
         }

         if (wfp_refresh_token) {
            localStorage.setItem("wfpRefreshToken", wfp_refresh_token);
         }

         window.location.replace(state);
      } else {
         throw new Error("OAuth response does not contain an access token");
      }
   };
}

export const procoreAuthManager = new ProcoreAuthManager();
