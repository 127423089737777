import "./sign-up.styl"
import template from "./sign-up.pug"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { companyStore } from "@/stores/company-store"

### Models ###
import { Group } from "@/models/group"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

export class SignUpViewModel extends PageContentViewModel
   constructor: ->
      super(template())

      @activePaneName = ko.observable("company")
      @nextPaneTitle = ko.pureComputed =>
         switch ko.unwrap(@activePaneName)
            when "company"
               return "User Profile"
            when "admin"
               return "Groups"
            when "groups"
               return "Billing Info"
            when "billing"
               return "Manage Plans"
            when "plans"
               return "Confirm & Pay"

      @selectedItemClass = ko.pureComputed =>
         switch ko.unwrap(@activePaneName)
            when "company"
               return "side-menu__selected-item--company"
            when "admin"
               return "side-menu__selected-item--admin"
            when "groups"
               return "side-menu__selected-item--groups"
            when "billing"
               return "side-menu__selected-item--billing"
            when "plans"
               return "side-menu__selected-item--plans"

      # Company Info
      @companyName = ko.observable()
      @companyAddress1 = ko.observable()
      @companyAddress2 = ko.observable()
      @companyCityTown = ko.observable()
      @companyStateProvince = ko.observable()
      @companyZipcode = ko.observable()
      @companyCountry = ko.observable()
      @companyTimezone = ko.observable()

      @companyComplete = ko.pureComputed =>
         return ((@companyName()? and @companyName() != "") and
         (@companyAddress1()? and @companyAddress1() != "") and
         (@companyCityTown()? and @companyCityTown() != "") and
         (@companyStateProvince()? and @companyStateProvince() != "") and
         (@companyZipcode()? and @companyZipcode() != "") and
         (@companyCountry()? and @companyCountry()!= ""))

      @companyPaneClass = ko.pureComputed =>
         return "" if @activePaneName() == "company"
         return "sign-up__pane--left"

      # User Profile
      @adminName = ko.observable()
      @adminEmail = ko.observable()
      @adminConfirmEmail = ko.observable()
      @adminPassword = ko.observable()
      @adminConfirmPassword = ko.observable()

      @adminComplete = ko.pureComputed =>
         return ((@adminName()? and @adminName() != "") and
            (@adminEmail()? and @adminEmail() != "") and
            (@adminEmail() == @adminConfirmEmail()) and
            (@adminPassword()? and @adminPassword() != "") and
            (@adminPassword() == @adminConfirmPassword()))

      @adminPaneClass = ko.pureComputed =>
         return "" if @activePaneName() == "admin"
         return "sign-up__pane--right" if @activePaneName() == "company"
         return "sign-up__pane--left"

      # Group
      @group = new Group({name: "Corporate HQ"}, true)

      @groupsPaneClass = ko.pureComputed =>
         return "" if @activePaneName() == "groups"
         return "sign-up__pane--right" if @activePaneName() in ["company", "admin"]
         return "sign-up__pane--left"

      @groupsComplete = ko.pureComputed =>
         return (@group.name()? and @group.name() != "")

      @groupTitle = ko.pureComputed =>
         if @group?.name()? and @group?.name() != "" then return @group.name() else return "New Group"

      # Billing Info
      @billingPaneClass = ko.pureComputed =>
         return "" if @activePaneName() == "billing"
         return "sign-up__pane--right" if @activePaneName() in ["company", "admin", "groups"]
         return "sign-up__pane--left"

      @billingComplete = ko.pureComputed =>
         #TODO: Wire billing to Stripe
         # return false unless @group.stripeId()? and @group.creditCard().lastFourDigits()?
         return true

      # Card Properties
      @nameOnCard = ko.observable()
      @rawCardNumber = ko.observable()
      @displayCardNumber = ko.observable().extend({maskCreditCard: {rawVal: @rawCardNumber}})
      @exprMonth = ko.observable()
      @exprYear = ko.observable()
      @cvv = ko.observable()

      @exprMonthOptions = ko.observableArray([
         new DropDownItem("1","1")
         new DropDownItem("2","2")
         new DropDownItem("3","3")
         new DropDownItem("4","4")
         new DropDownItem("5","5")
         new DropDownItem("6","6")
         new DropDownItem("7","7")
         new DropDownItem("8","8")
         new DropDownItem("9","9")
         new DropDownItem("10","10")
         new DropDownItem("11","11")
         new DropDownItem("12","12")
         ])
      @selectedExprMonth = ko.observable()

      @exprYearOptions = ko.observableArray([
         new DropDownItem("16","16")
         new DropDownItem("17","17")
         new DropDownItem("18","18")
         new DropDownItem("19","19")
         new DropDownItem("20","20")
         new DropDownItem("21","21")
         new DropDownItem("22","22")
         new DropDownItem("23","23")
         new DropDownItem("24","24")
         new DropDownItem("25","25")
         new DropDownItem("26","26")
         new DropDownItem("27","27")
         ])
      @selectedExprYear = ko.observable()

      # Plan Management
      @plansPaneClass = ko.pureComputed =>
         return "" if @activePaneName() == "plans"
         return "sign-up__pane--right"

      # Footer Action Button
      @actionBtnColorClass = ko.pureComputed =>
         switch ko.unwrap(@activePaneName)
            when "company"
               if ko.unwrap(@companyComplete) then return "sign-up-form__action-btn--active-blue" else return ""
            when "admin"
               if ko.unwrap(@adminComplete) then return "sign-up-form__action-btn--active-blue" else return ""
            when "groups"
               if ko.unwrap(@groupsComplete) then return "sign-up-form__action-btn--active-blue" else return ""
            when "billing"
               if ko.unwrap(@billingComplete) then return "sign-up-form__action-btn--active-blue" else return ""
            when "plans"
               return "Confirm & Pay"

      @actionBtnSpriteClass = ko.pureComputed =>
         switch ko.unwrap(@activePaneName)
            when "company"
               if ko.unwrap(@companyComplete) then return "icon-right-arrow-white" else return "icon-right-arrow-disabled"
            when "admin"
               if ko.unwrap(@adminComplete) then return "icon-right-arrow-white" else return "icon-right-arrow-disabled"
            when "groups"
               if ko.unwrap(@billingComplete) then return "icon-right-arrow-white" else return "icon-right-arrow-disabled"
            when "billing"
               return "icon-right-arrow-white"
            when "plans"
               return "icon-right-arrow-white"

   setPane: (paneName) ->
      switch paneName
         when "company"
            @activePaneName("company")
         when "admin"
            @activePaneName("admin") if @companyComplete()
         when "groups"
            @activePaneName("groups") if @adminComplete()
         when "billing"
            @activePaneName("billing") if @groupsComplete()
         when "plans"
            @activePaneName("plans") if @billingComplete()

   gotToNextPane: ->
      switch @activePaneName()
         when "company"
            @activePaneName("admin") if @companyComplete()
         when "admin"
            @activePaneName("groups") if @adminComplete()
         when "groups"
            @activePaneName("billing") if @groupsComplete()
         when "billing"
            @activePaneName("plans") if @billingComplete()
         when "plans"
            @completeSetup()

   completeSetup: ->
      group = {name: @group.name()}
      group['address_1'] = @group.address1() if @group.address1()?
      group['address_2'] = @group.address2() if @group.address2()?
      group['city_town'] = @group.cityTown() if @group.cityTown()?
      group['state_province'] = @group.stateProvince() if @group.stateProvince()?
      group['zipcode'] = @group.zipcode() if @group.zipcode()?
      group['country'] = @group.country() if @group.country()?
      data = {
         name: @companyName()
         address_1: @companyAddress1()
         address_2: @companyAddress2() or null
         city_town: @companyCityTown()
         state_province: @companyStateProvince()
         zipcode: @companyZipcode()
         country: @companyCountry()
         timezone: @companyTimezone()
         admin: {
            name: @adminName()
            email: @adminEmail()
            password: @adminPassword()
            is_admin: true
         }
         group: group
      }
      authManager.handleAccountSetup data, (err) ->
         return console.log "error setting up account: ", err if err?
         router.navigate(null, "/groups/#{authManager.selectedGroupId()}/assignments")
