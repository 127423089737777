import "./project-detail.styl"
import template from "./project-detail.pug"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { v4 } from "uuid";

### Utils ###
import { Format } from "@/lib/utils/format"
import { DateUtils } from "@/lib/utils/date"
import { ValidationUtils } from "@/lib/utils/validation"
import { formatName } from "@/lib/utils/preferences"
import { Url as UrlUtils } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { projectStore as legacyProjectStore } from "@/stores/project-store"
import { ProjectStore } from "@/stores/project-store.core"
import { CompanyStore } from "@/stores/company-store.core"
import { TagStore } from "@/stores/tag-store.core"
import { defaultStore } from "@/stores/default-store"
import { dragManager } from "@/lib/managers/drag-manager"
# Importing this, as we are unable to import the typescript type from lc-node-modules find-activity
import { ActivityStore as LegacyActivityStore} from "@/stores/activity-store"
import { ActivityStore } from "@/stores/activity-store.core"
import { NoteStore } from "@/stores/note-store.core"
import { MessageStore } from "@/stores/message-store.core"
import { CustomFieldStore } from "@/stores/custom-field-store.core"
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane"
import { ConfirmDeleteProjectPaneViewModel } from "@/lib/components/modals/confirm-delete-project-pane"
import { EditNotePaneViewModel } from "@/lib/components/modals/edit-note-pane"
import { ConfirmDeleteCostCodePaneViewModel } from "@/views/project-detail/modals/confirm-delete-cost-code-pane"
import { CreateCannedMessagePane } from "@/lib/components/modals/create-canned-message-pane"

### Models ###
import { Project } from "@/models/project"
import { Project as ProjectLabelParent } from "@/models/project"
ProjectLabel = ProjectLabelParent.ProjectLabel
import { Project as CostCodeParent } from "@/models/project"
CostCode = CostCodeParent.CostCode
import { Project as WageOverrideParent } from "@/models/project"
WageOverride = WageOverrideParent.WageOverride
import { ValueSet } from "@/models/value-set"
import { Activity } from "@/models/activity"
import { Project as RoleParent } from "@/models/project"
Role = RoleParent.Role
import { CannedMessage } from "@/models/canned-message"
import { CustomField } from "@/models/custom-field"
import { PermissionLevel } from "@/models/permission-level"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"
import { AddAttachmentPane } from "@/views/person-detail/popup/add-attachments-pane"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down"
import { ToolbarButton } from "@/lib/components/toolbar/toolbar-button"
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell"
import { TextCell } from "@/lib/components/grid/cells/text-cell"
import { JobTitleDropDownPane } from "@/lib/components/drop-downs/panes/job-title-drop-down-pane"
import { PersonDropDownPane } from "@/lib/components/drop-downs/panes/person-drop-down-pane"
import { ActionResult } from "@/lib/components/drop-downs/drop-down-2"
import renderReactComponent from "@/react/render-react-component";

import Bugsnag from "@bugsnag/js"
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper"

import ko from "knockout"

Notice = {
   NAME: {
      text: "Project must have a name."
      color: "red"
      info: null
      dissmissable: true
   },
   GROUPS: {
      text: 'At least one group must be selected.'
      info: null
      color: 'red'
      dissmissable: true
   },
   ACTIVE_START_DATE: {
      text: 'Active projects must have a Start Date selected.'
      info: null
      color: 'red'
      dissmissable: true
   }
}

export class ProjectDetailViewModel extends PageContentViewModel
   constructor: (projectId) ->
      assertArgs(arguments, String)
      super(template(), "Project Detail")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canEditProjectDetails = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_DETAILS)
      @canViewProjectSensitive = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE)
      @canEditProjectSensitive = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_SENSITIVE)
      @canViewProjectTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)
      @canEditProjectTags = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_TAGS)
      @canViewProjectAttachments = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ATTACHMENTS)
      @canEditProjectAttachments = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_ATTACHMENTS)
      @canEditProjectCategories = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_CATEGORIES)
      @canViewProjectRoles = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ROLES)
      @canEditProjectRoles = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_ROLES)
      @canViewProjectWageOverrides = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_WAGE_OVERRIDES)
      @canEditProjectWageOverrides = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_WAGE_OVERRIDES)
      @canEditProjectAlerts = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_CUSTOM_ALERTS)
      @canViewProjectDefaultRecipients = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_DEFAULT_RECIPIENTS)
      @canEditProjectDefaultRecipients = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_DEFAULT_RECIPIENTS)
      @canViewProjectNotes = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_NOTES)
      @canEditProjectNotes = authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_NOTES)
      @canViewProjectActivity = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ACTIVITY)
      @canDeleteProject = authManager.checkAuthAction(PermissionLevel.Action.DELETE_PROJECT)
      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
      @canViewRequests = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
      @canViewAssignments = authManager.checkAuthAction(PermissionLevel.Action.VIEW_ASSIGNMENTS)
      @canAccessGanttPage = authManager.checkAuthAction(PermissionLevel.Action.ACCESS_GANTT_PAGE)
      @canCreateLaborPlan = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS)

      @customFieldModuleEnabled = authManager.companyModules()?.customFields
      @qrCodesEnabled = authManager.companyModules()?.qrCodes
      @projectsQrCodes = authManager.companyModules()?.projectsQrCodes
      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories

      @isLoadingActivities = ko.observable(true)

      fanoutManager.getSubscription(
         { projectId: @projectId },
          "vm.ProjectDetailViewModel"
         {
            path: "/projects",
            structure: { inGroup: true },
         },
         null,
         @loadProject.bind(@),
      )

      ###------------------------------------
         Data Properties
      ------------------------------------###
      @projectId = projectId
      @project = ko.observable(null)
      @editingProject = ko.observable()
      @photo = ko.observable()

      @integratedFields = ko.observableArray([])
      @lockedIntegratedFieldKeys = ko.pureComputed =>
         lockedFields = new Set()
         # Integration-locked fields at company level.
         for field from @integratedFields()
            if field.locked == true
               lockedFields.add(field.property)
         # Integration-locked fields at custom field level.
         for field from @availableCustomFields()
            if field.integrationOnly() == true
               lockedFields.add(field.id)
         return [...lockedFields]

      @hasIntegratedFields = ko.observable(false)

      # This is a total page failure, not just a warning.
      @pageError = ko.observable(null)
      @displayingNotice = ko.observable(null)

      groupIdsSet = new Set(authManager.authedUser()?.groupIds() ? [])
      groupIdsAndNames = Object.entries(authManager.companyGroupNames()).filter(([id]) => groupIdsSet.has(id))
      @groupOptions = ko.observableArray(
         groupIdsAndNames.map(([gId, gName]) =>
            new MultiDropDownItem(gName, gId, false)
         )
      )
      @selectedGroupOptions = ko.observableArray()
      @orderSelectedGroupNames = ko.pureComputed =>
         return @sortNames(@selectedGroupOptions())
      @restrictedGroupIds = ko.observableArray([])
      @costCodeOptions = ko.observableArray()
      @labelOptions = ko.observableArray()
      @selectedStartTime = ko.observable()
      @formattedStartTime = ko.pureComputed =>
         return '' unless @selectedStartTime()
         return DateUtils.formatTimeVal(@selectedStartTime())
      @selectedEndTime = ko.observable()
      @formattedEndTime = ko.pureComputed =>
         return '' unless @selectedEndTime()
         return DateUtils.formatTimeVal(@selectedEndTime())
      @colorStrings = ko.observableArray()
      @selectedTimezone = ko.observable()
      @bidRate = ko.observable()

      @addControl(ToolbarButton.LOCATION.LEFT, new ToolbarButton({
            icon: ToolbarButton.ICONS.BACK
         }, 1, -> router.back())
      )

      # Detail Group
      @detailsGroupExpanded = ko.observable(false)

      # Tag Group
      @tagGroupEditing = ko.observable(false)
      @editTagTrayOpen = ko.observable(false)
      @isAddingNewTag = ko.observable(false)
      @editingTag = ko.observable(null)
      @editingTagsAttachments = ko.observableArray()
      @allTags = ko.observableArray()
      @selectedTagOption = ko.observable()
      @appliedTagIds = ko.observableArray()
      @tagOptions = ko.pureComputed =>
         tagOptions = []
         for tag in @allTags()
            if @appliedTagIds().indexOf(tag.id) == -1
               tagOptions.push(new ValueSet({name: tag.name(), value: tag.id}))
         return tagOptions

      @categorizedTags = ko.observable()
      @availableCategorizedTags = ko.pureComputed =>
         data = {}
         for key, value of @categorizedTags()
            tags = value.filter (tag) =>
               return @appliedTagIds().indexOf(tag.value()) == -1
            if tags.length > 0
               data[key] = tags
         return data

      @projectStartDate = ko.observable()
      formattingOptions = {
         yearFormat: DateUtils.YearFormat.FULL
         monthFormat: DateUtils.MonthFormat.FULL
         dayFormat: DateUtils.DayFormat.ONE_DIGIT
      }
      @projectStartDateString = ko.pureComputed =>
         date = @projectStartDate()
         return "" unless date?
         return DateUtils.formatDate(date, defaultStore.getDateFormat(), formattingOptions)
      @projectEstEndDate = ko.observable()
      @projectEstEndDateString = ko.pureComputed =>
         date = @projectEstEndDate()
         return "" unless date?
         return DateUtils.formatDate(date, defaultStore.getDateFormat(), formattingOptions)

      @statusOptionActive = new SegmentedControllerItem("Active", "active")
      @statusOptionPending = new SegmentedControllerItem("Pending", "pending")
      @statusOptionInactive = new SegmentedControllerItem("Inactive", "inactive")
      @statusOptions = [
         @statusOptionActive
         @statusOptionPending
         @statusOptionInactive
      ]
      @selectedStatus = ko.observable()

      # Custom Fields
      @availableCustomFields = ko.observableArray()
      @customFieldValues = null

      # Attachment Group
      @projectAttachments = ko.observableArray()

      # Cost Code Group
      @projectCostCodes = ko.observableArray([])
      @costCodeGroupEditing = ko.observable(false)
      @editCostCodeTrayOpen = ko.observable(false)
      @isAddingNewCostCode = ko.observable(false)
      @originalCostCode = ko.observable(null)
      @editingCostCode = ko.observable(null)
      @editingCostCodeHasChanges = ko.pureComputed =>
         return false unless @editingCostCode()? and (@originalCostCode()? or !@editingCostCode().id?)
         return true unless @editingCostCode().id?
         return true if @editingCostCode().name() != @originalCostCode().name()
         return true if @editingCostCode().labels().length != @originalCostCode().labels().length
         for label in @editingCostCode().labels()
            for oLabel in @originalCostCode().labels()
               if label.id == oLabel.id
                  if label.name() != oLabel.name() || label.sequence() != oLabel.sequence()
                     return true

      @showCostCodeSaveBtns = ko.observable(false)
      @showCostCodeOrderProcessing = ko.observable(false)
      @showLabelOrderProcessing = ko.observable(false)
      @archiveLabelMessage = 'Please confirm or deny this action.'

      # Wage Override Group
      @wageGroupEditing = ko.observable(false)
      @isAddingNewWageOverride = ko.observable(false)
      @editWageOverrideTrayOpen = ko.observable(false)
      @wageOverrides = ko.observableArray([])
      @wageOverridePositionIds = ko.pureComputed =>
         return @wageOverrides().map (override) ->
            return override.positionId()
      @editingWage = ko.observable()
      @editingWageRate = ko.observable()
      @originalWage = ko.observable()
      @positionOptions = ko.observableArray([])
      @wageOverridePositionOptions = ko.pureComputed =>
         return @positionOptions().filter (option) =>
            return @wageOverridePositionIds().indexOf(option.value()) == -1

      @selectedPositionId = ko.observable()
      @editingWageHasChanges = ko.pureComputed =>
         return false unless @editingWage()? and (@originalWage()? or !@editingWage().id?)
         return true if !@editingWage().id? and @selectedPositionId()? and @editingWageRate() != 0 and
         @editingWageRate()?
         return false unless @originalWage()?
         return true if @editingWageRate() != @originalWage().rate()

      @availableTags = ko.pureComputed(() =>
         @allTags().filter((tag) => !@tagIdsInUse().has(tag.id))
      )
      @tagIdsInUse = ko.pureComputed(() => new Set(@project()?.tagInstances()?.map((t) => t.tagId())))
      @onTagSelected = (tag) =>
         @selectedTagOption({ name: ko.observable(tag.name), value: ko.observable(tag.id), color: ko.observable(tag.color) })
         @selectNewTag()

      @projectRoleJobTitlePane = new JobTitleDropDownPane({ transformer: (jobTitle) => jobTitle })
      @projectRoleJobTitleDropDownParams = {
         placeholder: "Select Role",
         panes: [@projectRoleJobTitlePane],
         selectedIds: ko.pureComputed({
            read: () => new Set([@editingRole()?.positionId()]),
            write: (ids) => @editingRole().positionId([...ids][0]),
         }),
         cellFactory: ColorCircleTextCell.factory((position) => {
            text: position.name,
            color: position.color,
         }),
      }

      @defaultAlertPersonPane = new PersonDropDownPane({
         transformer: (person) => person,
         status: "active",
      })
      @defaultAlertDropDownParams = {
         actionInterceptor: (val) => 
            recip = { name: ko.observable(formatName(val.name)), value: ko.observable(val.id) }
            @handleDefaultRecipientSelection(recip)
            return ActionResult.SELECT_ONLY_AND_CLOSE
         placeholder: "Select Recipient",
         panes: [@defaultAlertPersonPane],
         cellFactory: TextCell.factory((item) => formatName(item.name)),
      }

      @projectRolePersonPane = new PersonDropDownPane({
         transformer: (person) => person,
         status: "active",
      })
      @projectRolePersonDropDownParams = {
         placeholder: "Select Assignee",
         panes: [@projectRolePersonPane],
         selectedIds: ko.pureComputed({
            read: () => new Set([@editingRole()?.personId()]),
            write: (ids) => @editingRole().personId([...ids][0]),
         }),
         cellFactory: TextCell.factory((item) => formatName(item.name)),
      }

      @wageOverrideRolePane = new JobTitleDropDownPane({ transformer: (jobTitle) => jobTitle })
      @wageOverrideRoleDropDownParams = {
         placeholder: "Select an Option",
         panes: [@wageOverrideRolePane],
         selectedIds: ko.pureComputed({
            read: () => new Set([@selectedPositionId()]),
            write: (ids) => @selectedPositionId([...ids][0]),
         }),
         cellFactory: ColorCircleTextCell.factory((position) => {
            text: position.name,
            color: position.color,
         }),
      }

      # @loadProject()
      @loadSupportData()


      #Roles
      @roleGroupEditing = ko.observable(false)
      @isAddingNewRole = ko.observable(false)
      @editingRoleTrayOpen = ko.observable(false)
      @roles = ko.observableArray([])
      @editingRole = ko.observable()
      @originalRole = ko.observable()
      @roleOptions = ko.observableArray()
      @selectedRole = ko.observable()
      @selectedAssignee = ko.observable()
      @editingRoleHasChanges = ko.pureComputed =>
         return false unless @editingRole()?
         if !@editingRole().id?
            return true if @editingRole().positionId()? and @editingRole().personId()?
         else
            return false unless @originalRole()?
            return true if @editingRole().personId()? and @editingRole().personId() != @originalRole().personId()
            return true if @editingRole().positionId()? and @editingRole().positionId() != @originalRole().positionId()
         return false

      # Default Recipients Section
      @isAddingNewDefaultRecipient = ko.observable(false)
      @recipientGroupEditing = ko.observable(false)
      @editingDefaultRecipientTrayOpen = ko.observable(false)
      @selectedDefaultRecipient = ko.observable()

      # Manpower Graph Section
      @manpowerGraphData = ko.observable(null)

      # Note Section
      @notesDataExists = ko.observable(false)
      @newNoteContent = ko.observable()
      @newNoteAttachments = ko.observableArray()
      @newNoteIsPrivate = ko.observable(true)
      @canSaveNewNote = ko.pureComputed =>
         return ValidationUtils.validateInput(@newNoteContent())

      # Activity Section
      @activity = ko.observableArray()
      @selectedActivityCategory = ko.observable("all")
      @activityDepth = ko.observable(0)
      @disableLoadMoreActivity = ko.observable(false)
      @subscribeToProjectActivity(true)
      # For Core Activity
      @nextStartingAfter = ko.observable(null)

      @qrUrl = ko.observable(null)

      @companyQrId = ko.observable(null)
      @entityQrId = ko.observable(null)
      @entityTitle = ko.observable(null)
      @entitySubtitle = ko.observable(null)

      ###------------------------------------
         Popups
      ------------------------------------###
      @noteAttachmentPopupBuilder = =>
         return new Popup("Attachments", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new AddAttachmentPane(@newNoteAttachments)], ['notes-cell__attachments', 'icon-attachment',
            'files-uploader__file-input'], ['notes-cell__popup--note-attachment'], null)

      @noteAttachmentPopupWrapper = {
         popupBuilder: @noteAttachmentPopupBuilder,
         options: {
            triggerClasses: ['icon-attachment']
            allowClickDefaultClasses: ['files-uploader__file-input']
         }
      }

      @colorSelectorPopupBuilder = =>
         return new Popup("Select Project Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@editingProject().color)],
            ['entity-details__color-btn', 'entity-details__color-btn__color'], ['popup--color-selector'])

      @colorSelectorPopupWrapper = {
         popupBuilder: @colorSelectorPopupBuilder
         options: {triggerClasses: ['entity-details__color-btn__color']}
      }

   boundToElement: ->
      dragManager.maybeAddNoDragClass(["project-detail__cost-code-tray__editing-label-name", ".project-detail__cost-code-tray__label-row"])

   clearObservable: (observableToClear) ->
      assertArgs(arguments, Function)
      # Set observable to null
      observableToClear(null)

   checkFieldVisibility: (field) =>
      field = ko.unwrap(field)
      return true if @canViewProjectSensitive
      return authManager.projectsSensitiveFields().indexOf(field) == -1

   customFieldValueIsValid: (data) =>
      ### eslint-disable no-prototype-builtins ###
      return data and @customFieldValues?.hasOwnProperty(data.id) and \
         data.hasOwnProperty('type') and data.hasOwnProperty('values')
      ### eslint-enable no-prototype-builtins ###

   checkFieldEditing: (field) =>
      try
         field = ko.unwrap(field)
         unless @canEditProjectSensitive
            return false if authManager.projectsSensitiveFields().indexOf(field) != -1
         if @hasIntegratedFields()
            return false if @lockedIntegratedFieldKeys().indexOf(field) != -1
         return true
      catch err
         Bugsnag.notify(err, (event) =>
            event['context'] = "project-detail_checkFieldEditing"
            event.addMetadata(BUGSNAG_META_TAB.USER_DATA, buildUserData(authManager.authedUser(), authManager.activePermission))
            event.addMetadata('checked field', field)
         )
         throw err

   formatCustomField: (field) ->
      if field.type == "date"
         return DateUtils.formatDetachedDay(field.value, defaultStore.getDateFormat())
      else if field.type == "bool"
         return String(field.value).toUpperCase()
      else if field.type == "currency"
         return Format.formatCurrency(field.value)
      else if field.type == "multi-select"
         return field.value.join(", ")
      else if field.type == "number"
         return Format.formatNumber(field.value)
      else
         return field.value

   ###------------------------------------
      Labor Plan Methods
   ------------------------------------###
   openLaborPlansPage: (project) =>
      return unless @canCreateLaborPlan
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{project.projectId}/create-labor-plan")

   ###------------------------------------
      Tag Group Methods
   ------------------------------------###
   toggleTagGroupEditing: ->
      return unless @canEditProjectTags
      if @isAddingNewTag()
         @tagGroupEditing(false)
      else
         @tagGroupEditing(!@tagGroupEditing())
      @isAddingNewTag(false)
      @editTagTrayOpen(false)
      @editingTag(null)

   editTag: (tag) =>
      return unless @canEditProjectTags
      # Required to break dependency to later check if update is needed.
      exitingAttachments = tag.attachments().map (attachment) -> return attachment
      @editingTagsAttachments(exitingAttachments)
      @editingTag(tag)
      @editTagTrayOpen(true)

   deleteTag: (tag) =>
      return unless @canEditProjectTags
      pane1 = new ConfirmActionPaneViewModel("Remove Tag", null, "Confirm Deletion")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-action-modal'}, (modal, exitStatus) =>
         return unless exitStatus == "finished"
         try
            await ProjectStore
               .updateProject(@project().id, { tag_instances: { [tag.tagId()]: null } })
               .payload
            @editTagTrayOpen(false)
            @project().tagInstances.remove(tag)
            @editingTag(null)
         catch err
            console.log("error: ", err)

   closeEditingTag: ->
      return unless @canEditProjectTags
      @isAddingNewTag(false)
      @editTagTrayOpen(false)
      @editingTag(null)
      @editingTagsAttachments([])

   addAttachmentIdToTag: (err) =>
      return unless @canEditProjectTags
      return console.log "error: ", err if err

      # new attachment id should already be in @editingTagsAttachments
      attachmentIds = @editingTagsAttachments().map((attachment) => attachment.id)
      try
         await ProjectStore.updateProject(
            @project().id,
            {
               tag_instances: {
                  [@editingTag().tagId()]: {
                     attachment_ids: attachmentIds
                  }
               }
            }
         ).payload
      catch err
         console.log("error: ", err)

   removeAttachmentIdFromTag: (err, attachmentId) =>
      return unless @canEditProjectTags
      return console.log "error: ", err if err
      attachmentIds = @editingTagsAttachments()
         .map((attachment) => attachment.id)
         .filter((id) => id != attachmentId)
      try
         await ProjectStore.updateProject(
            @project().id,
            {
               tag_instances: {
                  [@editingTag().tagId()]: {
                     attachment_ids: attachmentIds
                  }
               }
            }
         ).payload
      catch err
         console.log("error: ", err)

   selectNewTag: () =>
      return unless @canEditProjectTags
      for tag in @allTags()
         if tag.id == @selectedTagOption().value()
            @editingTag(tag)
            break

   saveNewTag: ->
      return unless @canEditProjectTags
      newTag = {
         attachment_ids: @editingTagsAttachments().map((attachment) => attachment.id)
      }
      try
         await ProjectStore.updateProject(
            @project().id,
            {
               tag_instances: {
                  [@editingTag().id]: newTag,
               }
            }
         ).payload
         @isAddingNewTag(false)
         @editTagTrayOpen(false)
         # @project().tagInstances.push(@editingTag())
         @editingTag(null)
         @editingTagsAttachments([])
         @selectedTagOption(null)
      catch err
         console.log("error: ", err)

   newTag: ->
      return unless @canEditProjectTags
      if @allTags().length == 0
         try
            stream = await TagStore.findTagsStream({}).stream
            for await row from stream
               if row.globally_accessible || row.group_ids.some((id) => @project().groupIds().includes(id))
                  @allTags.push(row)
         catch err
            console.log("Error loading tags: ", err)

      @editingTag(null)
      @editingTagsAttachments([])
      @isAddingNewTag(true)
      @editTagTrayOpen(true)

   ###------------------------------------
      Attachment Group Methods
   ------------------------------------###
   saveNewAttachments: (err) =>
      return unless @canEditProjectAttachments
      return console.log "error: ", err if err

      # new attachmentId should already be in @projectAttachments
      attachmentIds = @projectAttachments().map((a) => a.id)
      try
         await ProjectStore.updateProject(@project().id, {
            attachment_ids: attachmentIds
         }).payload
      catch err
         console.log("error: ", err)

   removeAttachmentIdFromProject: (err, attachmentId) =>
      return unless @canEditProjectAttachments
      return console.log "error: ", err if err

      attachmentIds = @projectAttachments()
         .map((a) => a.id)
         .filter((id) => id != attachmentId)
      try
         await ProjectStore.updateProject(@project().id, {
            attachment_ids: attachmentIds
         }).payload
      catch err
         console.log("error: ", err)

   ###------------------------------------
      Cost Code Group Methods
   ------------------------------------###
   newCostCode: ->
      return unless @canEditProjectCategories
      sequence = if @project().costCodes().length != 0 then @projectCostCodes().length else 0
      @editingCostCode(new CostCode({name: "New Category", sequence: sequence}, true))
      @isAddingNewCostCode(true)
      @editCostCodeTrayOpen(true)

   toggleCostCodeGroupEditing: ->
      return unless @canEditProjectCategories
      @costCodeGroupEditing(!@costCodeGroupEditing())
      @editCostCodeTrayOpen(false)

   closeEditingCostCode: ->
      return unless @canEditProjectCategories
      @editCostCodeTrayOpen(false)
      @isAddingNewCostCode(false)
      @editingCostCode().mapProperties(@originalCostCode()) if @originalCostCode()?
      @editingCostCode(null)
      @originalCostCode(null)

   editCostCode: (costCode) =>
      return unless @canEditProjectCategories
      @editingCostCode(costCode)
      @originalCostCode(costCode.clone(CostCode))
      @editCostCodeTrayOpen(true)
      @isAddingNewCostCode(false)

   costCodeDrop: (element) =>
      return unless @canEditProjectCategories
      elementData = ko.dataFor(element)
      loopingEl = element
      i = 0
      while (loopingEl = loopingEl.previousSibling) != null
         # Filter out virtual elements
         continue unless loopingEl.innerHTML?
         i++
      previousSequence = elementData.sequence()
      elementData.sequence(i + 1)
      @maybeUpdateCostCodeSequences elementData, previousSequence, =>
         @showCostCodeSaveBtns(true)

   addLabelToEditingCostCode: ->
      return unless @canEditProjectCategories
      sequence = @editingCostCode().labels().length
      @editingCostCode().labels.push(new ProjectLabel({ id: v4(), name: "New Subcategory", sequence: sequence }, true))

   showConfirmLabelDeleteModal: (label) =>
      return unless @canEditProjectCategories
      pane1 = new ConfirmActionPaneViewModel("Delete Subcategory", @archiveLabelMessage, "Confirm Deletion")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-delete-label-modal'}, (modal, exitStatus) =>
         return @removeLabelFromCostCode(label) if exitStatus == "finished"

   removeLabelFromCostCode: (labelToRemove) =>
      return unless @canEditProjectCategories
      if not labelToRemove.id?
         @editingCostCode().labels.remove(labelToRemove)
      else
         try
            await ProjectStore.deleteSubcategory(@projectId, @editingCostCode().id, labelToRemove.id).payload
            for label in @editingCostCode().labels()
               label.sequence(label.sequence() - 1) if label.sequence() > labelToRemove.sequence()
            # Required becuase we cloned the cost code to save the original thus the lable instances don't match.
            for label in @originalCostCode().labels()
               if label.id == labelToRemove.id
                  @originalCostCode().labels.remove(label)
                  break
            @editingCostCode().labels.remove(labelToRemove)
         catch err
            console.log("Error: ", err)

   labelDrop: (element) =>
      return unless @canEditProjectCategories
      # @showLabelOrderProcessing(true) # commented out because was causing a bug if you performed one labelDrop and then a second label drop before clicking save
      elementData = ko.dataFor(element)
      loopingEl = element
      i = 0
      while (loopingEl = loopingEl.previousSibling) != null
         # Filter out virtual elements
         continue unless loopingEl.innerHTML?
         i++
      previousSequence = elementData.sequence()
      elementData.sequence(i + 1)
      @maybeUpdateLabelSequences @editingCostCode(), elementData, previousSequence, =>
         @showLabelOrderProcessing(false)

   maybeDeleteCostCode: (costCode) =>
      return unless @canEditProjectCategories
      pane1 = new ConfirmDeleteCostCodePaneViewModel(@projectId, costCode)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-delete-cost-code-modal'}, (modal, exitStatus) =>
         return unless exitStatus == "finished"
         try
            await ProjectStore.deleteCategory(@project().id, costCode.id).payload
            @projectCostCodes.remove(costCode)
            # Saving back to the API allows our sequences to be persisted.
            # Not, saving means that users will have issues with having the correct order displayed in the UI.
            @saveCostCodeOrder()
         catch err
            console.log("Error: ", err)

   cancelCostCodeOrderChanges: ->
      return unless @canEditProjectCategories
      @showCostCodeSaveBtns(false)
      @costCodeGroupEditing(false)

   saveCostCodeOrder: ->
      return unless @canEditProjectCategories
      # Data passing through this function has potentially gotten out of the correct state for clients
      # To fix the poor data inconsistency we should sort these and then force their indices to match up with their order.
      @projectCostCodes().sort (a, b) -> a.sequence() - b.sequence()
      @projectCostCodes().forEach (record, index) -> record.sequence(index + 1);

      @showCostCodeOrderProcessing(true)
      orderData = {}
      for costCode in @projectCostCodes()
         orderData[costCode.sequence()] = costCode.id
      performReorder = =>
         await ProjectStore.reorderCategories(@project().id, orderData)
            .payload
            .catch((err) => console.log("Error: ", err))

      for key, val of orderData
         for costCode in @project().costCodes()
            if costCode.id == val and costCode.sequence() != key
               await performReorder()

      @showCostCodeOrderProcessing(false)
      @showCostCodeSaveBtns(false)
      @costCodeGroupEditing(false)

   updateCostCode: ->
      return unless @canEditProjectCategories
      return @saveNewCostCode() unless @editingCostCode().id?
      data = {}
      updateLabels = false
      if @editingCostCode().labels().length != @originalCostCode().labels().length
         updateLabels = true
      else
         for label in @editingCostCode().labels()
            foundOldLabel = false
            for oLabel in @originalCostCode().labels()
               if label.id == oLabel.id
                  foundOldLabel = true
                  if label.name() != oLabel.name() || label.sequence() != oLabel.sequence()
                     updateLabels = true
                     break
            unless foundOldLabel
               updateLabels = true
               break
      if updateLabels
         # data['subcategories'] = @editingCostCode().labels().map (label) -> {id: label.id, name: label.name(), sequence: label.sequence()}
         data['subcategories'] = @editingCostCode().labels().sort((label) => label.sequence()).map((label) => ({ id: label.id, name: label.name(), sequence: label.sequence() }))
      data['name'] = @editingCostCode().name() if @editingCostCode().name() != @originalCostCode().name() or updateLabels
      if Object.keys(data).length == 0
         @editCostCodeTrayOpen(false)
         @editingCostCode(null)
         @originalCostCode(null)
         @showLabelOrderProcessing(false)
         return
      try
         await ProjectStore.updateCategory(
            @project().id,
            @editingCostCode().id,
            data,
         ).payload
         @editCostCodeTrayOpen(false)
         @editingCostCode(null)
         @originalCostCode(null)
         @showLabelOrderProcessing(false)
      catch err
         console.log("Error updating cost code: ", err)

   saveNewCostCode: ->
      return unless @canEditProjectCategories
      return unless @editingCostCode()? and @editingCostCode().name().length > 0
      data = {
         name: @editingCostCode().name()
         subcategories: @editingCostCode().labels().sort((label) => label.sequence()).map((label) => { name: label.name() })
      }
      @projectCostCodes.push(@editingCostCode())
      try
         await ProjectStore.addCategory(@project().id, data).payload
         @editCostCodeTrayOpen(false)
         @editingCostCode(null)
         @originalCostCode(null)
         @showLabelOrderProcessing(false)
         @isAddingNewCostCode(false)
      catch err
         console.log("Error adding category: ", err)

   maybeUpdateCostCodeSequences: (updatedCostCode, previousSequence, next) ->
      return unless @canEditProjectCategories
      assertArgs(arguments, CostCode, Number, optional(Function))
      return if previousSequence == updatedCostCode.sequence()
      for costCode in @projectCostCodes()
         continue if costCode == updatedCostCode
         if previousSequence > updatedCostCode.sequence()
            if previousSequence > costCode.sequence() >= updatedCostCode.sequence()
               costCode.sequence(costCode.sequence() + 1)
         else
            if previousSequence < costCode.sequence() <= updatedCostCode.sequence()
               costCode.sequence(costCode.sequence() - 1)

         # No API changes occur here because this visual change is optional.
      next() if next?

   maybeUpdateLabelSequences: (costCode, updatedLabel, previousSequence, next) ->
      return unless @canEditProjectCategories
      assertArgs(arguments, CostCode, ProjectLabel, Number, optional(Function))
      return if previousSequence == updatedLabel.sequence()
      for label in costCode.labels()
         continue if label == updatedLabel
         if previousSequence > updatedLabel.sequence()
            if previousSequence > label.sequence() >= updatedLabel.sequence()
               label.sequence(label.sequence() + 1)
         else
            if previousSequence < label.sequence() <= updatedLabel.sequence()
               label.sequence(label.sequence() - 1)
      next() if next?

   getLabelCountString: (costCode) ->
      if costCode.labels().length >= 2
         return "- #{costCode.labels().length} Subcategories"
      else if costCode.labels().length == 1
         return "- 1 Subcategory"
      else
         return ""

   ###------------------------------------
      Wage Override Group Methods
   ------------------------------------###
   newWageOverride: ->
      return unless @canEditProjectWageOverrides
      @editingWage(new WageOverride({}, true))
      @editingWageRate(0)
      @editWageOverrideTrayOpen(true)
      @isAddingNewWageOverride(true)

   toggleWageGroupEditing: ->
      return unless @canEditProjectWageOverrides
      @wageGroupEditing(!@wageGroupEditing())
      @editWageOverrideTrayOpen(false)
      @isAddingNewWageOverride(false)

   editWageOverride: (wageOverride) =>
      return unless @canEditProjectWageOverrides
      @originalWage(wageOverride.clone(WageOverride))
      @editingWage(wageOverride)
      @editingWageRate(wageOverride.rate())
      @editWageOverrideTrayOpen(true)

   deleteWageOverride: (wageOverride) =>
      return unless @canEditProjectWageOverrides
      pane1 = new ConfirmActionPaneViewModel("Remove", null, "Confirm Deletion")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-action-modal'}, (modal, exitStatus) =>
         return unless exitStatus == "finished"
         try
            await ProjectStore.updateProject(
               @projectId,
               {
                  wage_overrides: { [wageOverride.positionId()]: null }
               }
            ).payload
         catch err
            console.log("Error deleting wage override: ", err)

   updateWageOverride: ->
      return unless @canEditProjectWageOverrides
      return @saveNewWageOverride() unless @editingWage().id?
      data = { [@editingWage().positionId()]: Number(@editingWageRate()) }
      try
         await ProjectStore.updateProject(
            @projectId,
            { wage_overrides: data },
         ).payload
         @editWageOverrideTrayOpen(false)
         @editingWage(null)
         @originalWage(null)
         @isAddingNewWageOverride(false)
         @wageGroupEditing(false)
      catch err
         console.log("Error updating wage override: ", err)

   closeEditingWageOverride: ->
      return unless @canEditProjectWageOverrides
      @editWageOverrideTrayOpen(false)
      @editingWage().mapProperties(@originalWage()) if @editingWage().id?
      @editingWage(null)
      @originalWage(null)
      @isAddingNewWageOverride(false)
      @selectedPositionId(null)
      @wageGroupEditing(false)

   saveNewWageOverride: ->
      return unless @canEditProjectWageOverrides
      data = {
         [@selectedPositionId()]: Number(@editingWageRate()),
      }
      try
         await ProjectStore.updateProject(
            @projectId,
            { wage_overrides: data },
         ).payload
         @editWageOverrideTrayOpen(false)
         @editingWage(null)
         @originalWage(null)
         @isAddingNewWageOverride(false)
         @selectedPositionId(null)
         @wageGroupEditing(false)
      catch err
         console.log("Error adding new wage override: ", err)

   ###------------------------------------
      Role Group Methods
   ------------------------------------###
   getAssigneeName: (role) ->
      return "#{role.assignee().firstName()} #{role.assignee().lastName()}"

   newRole: ->
      @editingRole(new Role({}, true))
      @isAddingNewRole(true)
      @editingRoleTrayOpen(true)

   deleteRole: (role, callback) =>
      try
         await ProjectStore.updateProject(@projectId, {
            roles: [
               {
                  id: role.id,
                  # We're keeping "position_id" instead of "job_title_id" for now for compatibility reasons.
                  # The refactor is simply too deep to comfortably execute while still also supporting the old API and Rethink
                  position_id: role.positionId(),
                  person_id: role.personId(),
                  archived: true,
               }
            ]
         }).payload
      catch err
         console.error("Error deleting role with payload 1:", err)

         # If new payload fails, fallback to the old payload
         try
            await ProjectStore.updateProject(@projectId, {
               roles: {
                  [role.positionId()]: {
                     [role.personId()]: false,
                  }
               }
            }).payload

            callback(err, false)
         catch err
            console.error("Error deleting role with payload 2:", err)
            callback(err, false)
      
      callback(null, true)

   showDeleteRoleModal: (role) =>
      pane1 = new ConfirmActionPaneViewModel("Remove Role", null, "Confirm Deletion")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-action-modal'}, (modal, exitStatus) =>
         return unless exitStatus == "finished"
         @deleteRole(role, (err, success) =>
            return console.log "error: ", err if err
            @roles.remove(role) if success
         )

   addRole: (role, callback) ->
      try
         result = await ProjectStore.updateProject(@projectId, {
            roles: [
               {
                  id: v4(),
                  # We're keeping "position_id" instead of "job_title_id" for now for compatibility reasons.
                  # The refactor is simply too deep to comfortably execute while still also supporting the old API and Rethink
                  position_id: role.position_id,
                  person_id: role.person_id,
                  archived: false,
               }
            ]
         }).payload

         resultRole = result.data.roles.find((r) => r.person_id == role.person_id && r.job_title_id == role.position_id)
         renamedFieldsRole = {
            ...resultRole,
            position_id: resultRole.job_title_id,
         }
         callback(null, new Role(renamedFieldsRole))
      catch err
         console.error("Error adding role with payload 1:", err)

         # If new payload fails, fallback to the old payload
         try
            result = await ProjectStore.updateProject(@projectId, {
               roles: {
                  [role.position_id]: {
                     [role.person_id]: true,
                  }
               }
            }).payload

            resultRole = result.data.roles.find((r) => r.person_id == role.person_id && r.job_title_id == role.position_id)
            renamedFieldsRole = {
               ...resultRole,
               position_id: resultRole.job_title_id,
            }
            callback(null, new Role(renamedFieldsRole))
         catch err
            console.error("Error adding role with payload 2:", err)
            callback(err)

   saveRole: ->
      role = {
         position_id: @editingRole().positionId()
         person_id: @editingRole().personId()
      }
      @addRole(role, (err) =>
         return console.log "error: ", err if err
         @roles(@roles().sort (a, b) ->
            return a.position().sequence() - b.position().sequence()
         )
         @editingRoleTrayOpen(false)
         @isAddingNewRole(false)
         @editingRole(null)
         @originalRole(null)
         @selectedRole(null)
         @selectedAssignee(null)
      )

   cancelEditingRole: ->
      @editingRoleTrayOpen(false)
      @isAddingNewRole(false)
      @editingRole(null)
      @originalRole(null)
      @selectedRole(null)
      @selectedAssignee(null)

   toggleRolesGroupEditing: ->
      @roleGroupEditing(!@roleGroupEditing())
      @editingRoleTrayOpen(false)
      @isAddingNewRole(false)
      @editingRole(null)
      @originalRole(null)
      @selectedRole(null)
      @selectedAssignee(null)

   ###------------------------------------
      Canned Message Methods
   ------------------------------------###
   getRoleTokensForMessages_: ->
      roleTokens = []
      for role in @roles()
         name = "#{role.assignee().firstName()} #{role.assignee().lastName()}"
         roleTokens.push({
            name: "#{role.position().name()}: #{name}"
            subject1Key: "name"
            subject1Type: "people"
            subject1Id: role.assignee().id
         })
         if role.assignee().phone()?
            roleTokens.push({
               name: "#{name}: Phone"
               subject1Key: "phone"
               subject1Type: "people"
               subject1Id: role.assignee().id
            })
         if role.assignee().email()?
            roleTokens.push({
               name: "#{name}: Email"
               subject1Key: "email"
               subject1Type: "people"
               subject1Id: role.assignee().id
            })
      return roleTokens

   newCannedMessage: (assignmentContext) ->
      assertArgs(arguments, String)
      tokens = ProjectDetailViewModel.BaseAssignmentTokens.concat(@getRoleTokensForMessages_())
      title = switch assignmentContext
         when CannedMessage.Type.ASSIGNMENT_NEW then "Assignment Creations"
         when CannedMessage.Type.ASSIGNMENT_EDIT then "Assignment Updates"
         when CannedMessage.Type.ASSIGNMENT_TRANSFER then "Assignment Transfers"
         when CannedMessage.Type.ASSIGNMENT_DELETE then "Assignment Deletions"
         when CannedMessage.Type.ASSIGNMENT_REMINDER then "Assignment Reminders"
      pane1 = new CreateCannedMessagePane(null, @projectId, assignmentContext, tokens, title)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: "create-canned-message-modal"}, (modal, exitStatus, observableData) =>
         return unless observableData.data.message?
         newMessageId = observableData.data.message.id
         switch assignmentContext
            when CannedMessage.Type.ASSIGNMENT_NEW then @project().newAssignmentMessageId(newMessageId)
            when CannedMessage.Type.ASSIGNMENT_EDIT then @project().editAssignmentMessageId(newMessageId)
            when CannedMessage.Type.ASSIGNMENT_TRANSFER then @project().transferAssignmentMessageId(newMessageId)
            when CannedMessage.Type.ASSIGNMENT_DELETE then @project().deleteAssignmentMessageId(newMessageId)
            when CannedMessage.Type.ASSIGNMENT_REMINDER then @project().assignmentReminderMessageId(newMessageId)

   editCannedMessage: (assignmentContext) ->
      assertArgs(arguments, String)
      tokens = ProjectDetailViewModel.BaseAssignmentTokens.concat(@getRoleTokensForMessages_())
      title = switch assignmentContext
         when CannedMessage.Type.ASSIGNMENT_NEW then "Assignment Creations"
         when CannedMessage.Type.ASSIGNMENT_EDIT then "Assignment Updates"
         when CannedMessage.Type.ASSIGNMENT_TRANSFER then "Assignment Transfers"
         when CannedMessage.Type.ASSIGNMENT_DELETE then "Assignment Deletions"
         when CannedMessage.Type.ASSIGNMENT_REMINDER then "Assignment Reminders"
      existingMessageId = switch assignmentContext
         when CannedMessage.Type.ASSIGNMENT_NEW then @project().newAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_EDIT then @project().editAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_TRANSFER then @project().transferAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_DELETE then @project().deleteAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_REMINDER then @project().assignmentReminderMessageId()
         else null
      pane1 = new CreateCannedMessagePane(existingMessageId, @projectId, assignmentContext, tokens, title)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: "create-canned-message-modal"}, () =>
         return

   deleteCannedMessage: (assignmentContext) ->
      assertArgs(arguments, String)
      existingMessageId = switch assignmentContext
         when CannedMessage.Type.ASSIGNMENT_NEW then @project().newAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_EDIT then @project().editAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_TRANSFER then @project().transferAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_DELETE then @project().deleteAssignmentMessageId()
         when CannedMessage.Type.ASSIGNMENT_REMINDER then @project().assignmentReminderMessageId()
         else null

      pane1 = new ConfirmActionPaneViewModel("Remove", null, "Confirm Deletion")
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-action-modal'}, (modal, exitStatus) =>
         return unless exitStatus == "finished"
         await MessageStore.deleteCannedMessage(existingMessageId)
         switch assignmentContext
            when CannedMessage.Type.ASSIGNMENT_NEW then @project().newAssignmentMessageId(null)
            when CannedMessage.Type.ASSIGNMENT_EDIT then @project().editAssignmentMessageId(null)
            when CannedMessage.Type.ASSIGNMENT_TRANSFER then @project().transferAssignmentMessageId(null)
            when CannedMessage.Type.ASSIGNMENT_DELETE then @project().deleteAssignmentMessageId(null)
            when CannedMessage.Type.ASSIGNMENT_REMINDER then @project().assignmentReminderMessageId(null)

   ###------------------------------------
      Default Message Recipients
   ------------------------------------###
   toggleAddingDefaultRecipient: ->
      @isAddingNewDefaultRecipient(!@isAddingNewDefaultRecipient())

   removeDefaultRecipientId: (data) =>
      @project().defaultRecipients.remove(data)
      await ProjectStore.updateProject(
         @project().id,
         { default_recipient_ids: @project().defaultRecipients().map((r) => r.value()) },
      ).payload

   handleDefaultRecipientSelection: (data) =>
      @project().defaultRecipients.push(data)
      await ProjectStore.updateProject(
         @project().id,
         { default_recipient_ids: @project().defaultRecipients().map((r) => r.value()) },
      ).payload
      @isAddingNewDefaultRecipient(false)
      @selectedDefaultRecipient(null)

   ###------------------------------------
      Note Methods
   ------------------------------------###
   canShowEditButton: (note) =>
      return true if @canEditProjectNotes
      return note.authorId() == authManager.authedUser()?.id

   createNewNote: ->
      return unless @canEditProjectNotes
      if @newNoteContent()? and (@newNoteContent() != "" and @newNoteContent() != " ")
         noteData = {
            content: @newNoteContent()
            is_private: @newNoteIsPrivate()
            attachment_ids: @newNoteAttachments().map (attachment) ->
               return attachment.id
         }
         try
            await NoteStore.createNote(noteData, { project_id: @project().id }).payload
            @newNoteContent("")
            @newNoteIsPrivate(true)
            @newNoteAttachments([])
         catch err
            return console.error("ProjectDetailViewModel createNewNote - Error: ", err)
      else
         @newNoteContent("")
         @newNoteIsPrivate(true)
         @newNoteAttachments([])

   editNote: (note) =>
      return unless @canEditProjectNotes
      return unless note.lastEdited()?
      pane1 = new EditNotePaneViewModel(@projectId, "project", note)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'edit-note-modal'}, (modal, modalStatus, observableData) =>
         if Object.keys(observableData.data).length != 0
            @updateNote(observableData.data, note)

   updateNote: (newData, oldData) ->
      return unless @canEditProjectNotes
      updateData = {}
      if newData.attachments != oldData.attachments()
         updateData['attachment_ids'] = newData.attachments.map (attachment) ->
            return attachment.id
      updateData['content'] = newData.content if newData.content != oldData.content()
      updateData['is_private'] = newData.isPrivate if newData.isPrivate != oldData.isPrivate()
      if Object.keys(updateData).length != 0
         try
            await NoteStore.updateNote(
               oldData.id,
               { project_id: @project().id }
               updateData,
            ).payload
            @loadProject()
         catch err
            return console.error("ProjectDetailViewModel updateNote - Error: ", err)

   getNoteDetails: (note) ->
      lastEdited = new Date(note.lastEdited())
      formattedDate = DateUtils.formatDate(lastEdited, defaultStore.getDateFormat(), {
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
      })
      return "#{formattedDate} at #{DateUtils.getTime(lastEdited)}"

   getNoteFileCount: (note) ->
      return "#{note.attachments().length} Attachment" if note.attachments().length == 1
      return "#{note.attachments().length} Attachments"

   ###------------------------------------
      Activity Methods
   ------------------------------------###
   getActivityIcon: (activity) ->
      return Activity.CategoryIcon[activity.category()]

   updateActivityCategorySelection: (category) ->
      return unless @selectedActivityCategory() != category
      @isLoadingActivities(true)
      @selectedActivityCategory(category)
      fanoutManager.updateListenerData {projectId: @projectId, category: category}, "vm.ProjectDetailViewModel",
      FanoutManager.Channel.PROJECT_ACTIVITY, {projectId: @projectId}, (err) =>
         return console.log "error: ", err if err
         @subscribeToProjectActivity(true)

   subscribeToProjectActivity: (forceRequest) ->
      assertArgs(arguments, optional(Boolean))
      force = if forceRequest? then forceRequest else false
      legacyProjectStore.subscribeToProjectActivity(
         {
            projectId: @projectId,
            category: @selectedActivityCategory(),
         },
         "vm.ProjectDetailViewModel",
         force,
         (err, activity) =>
            return console.log "error: ", err if err
            if activity.length != 0
               @activity(activity)
               @disableLoadMoreActivity(false)
               @nextStartingAfter(activity.at(-1).id)
            else
               @activity(activity)
               @disableLoadMoreActivity(true) if activity.length == 0
            @isLoadingActivities(false)
      )

   getProjectActivity: (depth, category, loadMore) =>
      assertArgs(arguments, Number, String, Boolean)
      @isLoadingActivities(true)
      @selectedActivityCategory(category)

      query = {
         entity_id: @projectId,
         entity_type: LegacyActivityStore.ActivityEntityType.PROJECTS,
         limit: 25
      }
      if loadMore
         query['starting_after'] = @nextStartingAfter()
      if category != "all"
         if category == "project_cost_code"
            query['included_categories'] = ["project_cost_codes"]
         else
            query['included_categories'] = [category]

      try
         results = await ActivityStore.findActivityPaginated(query).payload
         activityResults = []
         for await activity from results.data
            activityResults.push(new Activity(activity))
         @nextStartingAfter(results.pagination.next_starting_after)

         if activityResults.length == 0
            @disableLoadMoreActivity(true)
         else
            @activity(@activity().concat(activityResults))
            if results.pagination.total_possible == activityResults.length
               @disableLoadMoreActivity(true)
         @isLoadingActivities(false)
      catch err
         console.log err


   loadMoreActivity: ->
      @isLoadingActivities(true)
      @activityDepth(@activityDepth() + 40)
      @getProjectActivity(@activityDepth(), @selectedActivityCategory(), true)

   getNoActivityString: ->
      switch @selectedActivityCategory()
         when "project_placeholder"
            return "no activity for requests"
         when "project_notes"
            return "no activity for notes"
         when "project_attachments"
            return "no activity for attachments"
         when "project_tags"
            return "no activity for tags"
         when "project_cost_code"
            return "no activity for categories"
         when "project_wage_override"
            return "no activity for wage overrides"
         when "project_info"
            return "no activity for info"
         when "project_roles"
            return "no activity for roles"
         else
            return "no activity"

   ###------------------------------------
      Project Detail Group Methods
   ------------------------------------###
   getFormattedDay: (day) ->
      return DateUtils.formatDetachedDay(ko.unwrap(day), defaultStore.getDateFormat())

   sortNames: (entities) ->
      return entities.sort (a, b) ->
         return if ko.unwrap(a.name) > ko.unwrap(b.name) then 1 else -1

   handleProfilePic: (url) =>
      return if url == @editingProject().profilePicUrl()
      try
         await ProjectStore.updateProject(
            @projectId,
            { profile_pic_url: url },
         ).payload
      catch err
         console.log("Error updating project profile picture: ", err)

   toggleDetailGroup: ->
      return unless @canEditProjectDetails
      @detailsGroupExpanded(!@detailsGroupExpanded())

   cancelDetailGroupChanges: ->
      return unless @canEditProjectDetails
      @computeUIValues @project(), =>
         @editingProject(@project().clone(Project))
         @detailsGroupExpanded(false)

   updateProject: (updateData, callback) ->
      customFieldsUpdate = updateData.custom_fields ? []
      newCustomFieldValues = customFieldsUpdate
         .filter((cf) => !@lockedIntegratedFieldKeys().includes(cf.field_id))
         .filter((cf) =>
            oldFieldInstance = @project().customFields().find((oldCf) => cf.field_id == oldCf.fieldId)
            if oldFieldInstance?
               return oldFieldInstance.value != cf.value
            else
               return true
         )
         .map((cf) => ({ [cf.field_id]: cf.value }))
         .reduce(((acc, cur) => ({ ...acc, ...cur })), {})
      nullifiedCustomFieldValues = @project().customFields()
         .filter((cf) => customFieldsUpdate.findIndex((updateCf) => updateCf.field_id == cf.fieldId) == -1)
         .map((cf) => ({ [cf.fieldId]: null }))
         .reduce(((acc, cur) => ({ ...acc, ...cur })), {})
      transformedUpdateData = {
         ...updateData,
         custom_fields: {
            ...newCustomFieldValues,
            ...nullifiedCustomFieldValues,
         }
      }
      try
         result = await ProjectStore.updateProject(@project().id, transformedUpdateData).payload
         callback(null, result.data)
      catch err
         callback(err)

   saveUpdates: ->
      return unless @canEditProjectDetails
      return unless @checkValidation()
      updateData = {}
      if @editingProject().name() != @project().name()
         updateData['name'] = @editingProject().name()
      # Groups
      editingGroupIds = @selectedGroupOptions().map (option) -> return option.value()

      # Find the set of group IDs that can be edited.
      nonRestrictedGroupIds = @project().groupIds().filter (groupId) =>
         return @restrictedGroupIds().indexOf(groupId) == -1

      # Check if the non restricted group IDs have changed.
      if (
         nonRestrictedGroupIds.length != editingGroupIds.length or
         editingGroupIds.some((groupId) => nonRestrictedGroupIds.indexOf(groupId) == -1)
      )
         allGroupIds = @groupOptions().map((option) => option.value())
         newGroupIds = @selectedGroupOptions().map((option) => option.value())
         updateData['group_ids'] = allGroupIds.map((id) => ({ [id]: newGroupIds.includes(id) })).reduce((acc, cur) => ({ ...acc, ...cur }))


      if @selectedStatus().value() != @project().status()
         updateData['status'] = @selectedStatus().value()

      if @selectedTimezone()? and @project().timezone() != @selectedTimezone()
         updateData['timezone'] = @selectedTimezone()

      if @editingProject().jobNumber() != @project().jobNumber()
         updateData['job_number'] = @editingProject().jobNumber()

      if @editingProject().address1() != @project().address1()
         updateData['address_1'] = @editingProject().address1()

      if @editingProject().address2() != @project().address2()
         updateData['address_2'] = @editingProject().address2()

      if @editingProject().cityTown() != @project().cityTown()
         updateData['city_town'] = @editingProject().cityTown()

      if @editingProject().stateProvince() != @project().stateProvince()
         updateData['state_province'] = @editingProject().stateProvince()

      if @editingProject().zipcode() != @project().zipcode()
         updateData['zipcode'] = @editingProject().zipcode()

      if @editingProject().country() != @project().country()
         updateData['country'] = @editingProject().country()

      if !@lockedIntegratedFieldKeys().includes("start_date")
         if @projectStartDate()? and @projectStartDate().getTime() != @project().startDate()
            updateData['start_date'] = @projectStartDate().setHours(0,0,0,0)
         else if !@projectStartDate()? and @project().startDate()?
            updateData['start_date'] = null

      if !@lockedIntegratedFieldKeys().includes("est_end_date")
         if @projectEstEndDate()? and @projectEstEndDate().getTime() != @project().estEndDate()
            updateData['est_end_date'] = @projectEstEndDate().setHours(0,0,0,0)
         else if !@projectEstEndDate()? and @project().estEndDate()?
            updateData['est_end_date'] = null

      if @selectedStartTime() != @project().dailyStartTime()
         updateData['daily_start_time'] = @selectedStartTime()

      if @selectedEndTime() != @project().dailyEndTime()
         updateData['daily_end_time'] = @selectedEndTime()

      if @bidRate() != @project().bidRate()
         updateData['bid_rate'] = Number(@bidRate())

      if @editingProject().percentComplete() != @project().percentComplete()
         percentComplete = @editingProject().percentComplete()
         updateData['percent_complete'] = Number(percentComplete)

      if @editingProject().customerName() != @project().customerName()
         updateData['customer_name'] = @editingProject().customerName()

      if @editingProject().projectType() != @project().projectType()
         updateData['project_type'] = @editingProject().projectType()

      if @editingProject().color() != @project().color()
         updateData['color'] = @editingProject().color()

      if @customFieldModuleEnabled
         updateData['custom_fields'] = []
         for key, val of @customFieldValues
            if ((val() instanceof Array) or
            (!(val() instanceof Array) and ValidationUtils.validateInput(val())))
               field = null
               for customField in @availableCustomFields()
                  if customField.id == key
                     field = customField
                     break
               continue unless field?
               if val() instanceof Array
                  formattedValue = val().map (i) -> return i.value()
               else if val() instanceof Date
                  formattedValue = DateUtils.getDetachedDay(val())
               else if val().value?
                  formattedValue = val().value()
               else
                  formattedValue = val()

               if field.type() == "number" || field.type() == "currency"
                  formattedValue = Number(val())

               updateData.custom_fields.push({
                  field_id: key
                  integration_name: field.integrationName(),
                  name: field.name()
                  value: formattedValue
                  type: field.type()
               })

      if Object.keys(updateData).length != 0
         @updateProject(updateData, (err, project) =>
            return console.log "error: ", err if err
            @detailsGroupExpanded(false)

            if !project
               return

            if !@lockedIntegratedFieldKeys().includes("start_date")
               @project().startDate(project.start_date)
            if !@lockedIntegratedFieldKeys().includes("est_end_date")
               @project().estEndDate(project.est_end_date)

            @setTitle(project.name)
            @project().name(project.name)
            @project().jobNumber(project.job_number)
            @project().color(project.color)
            @project().timezone(project.timezone)
            @project().status(project.status)
            @project().dailyStartTime(project.daily_start_time)
            @project().dailyEndTime(project.daily_end_time)

            # Permissioned and Sensitive Fields
            if project.address_1
               @project().address1(project.address_1)
            if project.address_2
               @project().address2(project.address_2)
            if project.city_town
               @project().cityTown(project.city_town)
            if project.state_province
               @project().stateProvince(project.state_province)
            if project.zipcode
               @project().zipcode(project.zipcode)
            if project.country
               @project().country(project.country)
            if project.bid_rate
               @project().bidRate(project.bid_rate)
            if project.percent_complete
               @project().percentComplete(project.percent_complete)
            if project.customer_name
               @project().customerName(project.customer_name)
            if project.project_type
               @project().projectType(project.project_type)

            @loadSelectedGroups(new Project(project, true))

            if project.status == "active"
               @selectedStatus(@statusOptionActive)
            else if project.status == "pending"
               @selectedStatus(@statusOptionPending)
            else if project.status == "inactive"
               @selectedStatus(@statusOptionInactive)

            @selectedStartTime(project.daily_start_time)
            @selectedEndTime(project.daily_end_time)
         )

      else
         @detailsGroupExpanded(false)

   checkValidation: () =>
      unless ValidationUtils.validateInput(@editingProject().name())
         @displayingNotice(Notice.NAME)
         return false
      unless @selectedGroupOptions().some((option) -> option.value())
         @displayingNotice(Notice.GROUPS)
         return false
      if @selectedStatus().value() == "active" and (@projectStartDate() == undefined || @projectStartDate() == null)
         @displayingNotice(Notice.ACTIVE_START_DATE)
         return false
      @displayingNotice(null)
      return true

   loadSelectedGroups: (project) =>
      selectedGroupOptions = []
      for option in @groupOptions()
         if project.groupIds().indexOf(option.value()) != -1
            option.selected(true)
            selectedGroupOptions.push(option)
      # Catch group ids that user doesn't have access to.
      if project.groupIds().length != selectedGroupOptions.length
         for id in project.groupIds()
            foundId = false
            for option in selectedGroupOptions
               if option.value() == id
                  foundId = true
                  break
            continue if foundId
            @restrictedGroupIds.push(id)
      @selectedGroupOptions(selectedGroupOptions)

   computeUIValues: (project, next) ->
      assertArgs(arguments, Project, optional(Function))
      @loadSelectedGroups(project)

      @companyQrId = ko.observable(project.baggage().company_qr_id)
      @entityQrId = ko.observable(project.baggage().qr_id)
      @entityTitle = ko.observable(project.name())
      @entitySubtitle = ko.observable(project.jobNumber())

      @qrUrl("#{location.origin}/qrc/#{project.baggage().company_qr_id}/pr/#{project.baggage().qr_id}")

      @photo(project.profilePicUrl())

      @selectedTimezone(project.timezone())

      @projectAttachments(project.attachments())

      sortedRoles = project.roles().sort((a, b) -> a.position().sequence() - b.position().sequence())
      @roles(sortedRoles)

      @appliedTagIds project.tagInstances().map (tag) ->
         return tag.tagId()

      for option in @statusOptions
         if option.value() == project.status()
            @selectedStatus(option)
            break

      @selectedStartTime(project.dailyStartTime()) if project.dailyStartTime()?

      @selectedEndTime(project.dailyEndTime()) if project.dailyEndTime()?

      if project.startDate()?
         startDateObj = new Date(project.startDate())
         timezoneOffset = startDateObj.getTimezoneOffset()
         # If the user's timezone offset is negative, don't shift the date back. This solves some issues for UK customers. 
         if timezoneOffset > 0
            startDateObj.setHours(startDateObj.getHours() + startDateObj.getTimezoneOffset() / 60);
         @projectStartDate(startDateObj)
         project.startDate(startDateObj.getTime())

      if project.estEndDate()?
         estEndDateObj = new Date(project.estEndDate())
         timezoneOffset = estEndDateObj.getTimezoneOffset()
         # If the user's timezone offset is negative, don't shift the date back. This solves some issues for UK customers. 
         if (timezoneOffset > 0)
            estEndDateObj.setHours(estEndDateObj.getHours() + estEndDateObj.getTimezoneOffset() / 60);
         @projectEstEndDate(estEndDateObj)
         project.estEndDate(estEndDateObj.getTime())

      @notesDataExists(project.notes().length != 0)

      @bidRate(project.bidRate())

      # To clear UI.
      @projectCostCodes([])
      @projectCostCodes(
         project
            .costCodes()
            .map((costCode) => costCode.clone(CostCode))
            .sort((a, b) => a.sequence() - b.sequence())
      )

      # TODO: Do we need to clone here to have content to revert back to or not?
      @wageOverrides project.wageOverrides().map (override) ->
         return override.clone(WageOverride)

      if @customFieldModuleEnabled and @customFieldValues? and project.customFields?
         for appliedField in project.customFields()
            if @customFieldValues[appliedField.fieldId]?
               if appliedField.type == CustomField.Type.SELECT
                  for avilableField in @availableCustomFields()
                     if avilableField.id == appliedField.fieldId
                        for option in avilableField.options()
                           if option.value() == appliedField.value
                              @customFieldValues[appliedField.fieldId](option)
                              break
                        break
               else if appliedField.type == CustomField.Type.MULTI_SELECT
                  for avilableField in @availableCustomFields()
                     if avilableField.id == appliedField.fieldId
                        # Need to clear so doesn't dupe and keep adding on other
                        @customFieldValues[appliedField.fieldId]([])
                        for option in avilableField.options()
                           if appliedField.value.indexOf(option.value()) != -1
                              option.selected(true)
                              @customFieldValues[appliedField.fieldId].push(option)
                        break
               else if appliedField.type == CustomField.Type.DATE
                  @customFieldValues[appliedField.fieldId](DateUtils.getAttachedDate(appliedField.value))
               else
                  @customFieldValues[appliedField.fieldId](appliedField.value)

      next() if next?

   loadProject: () ->
      try
         result = await ProjectStore.getProjectDetails(@projectId).payload
         result = result.data
         companyResult = await CompanyStore.getCompany().payload
         companyResult = companyResult.data

         # Transformations to match Frontend model
         creatorName = result.creator_name?.split(" ")
         result.creator_name = if creatorName then {
            first: creatorName[0],
            last: creatorName[1],
         } else null
         for recip in result.default_recipients
            recip.value = recip.id
         for r in result.roles
            r.assignee.first_name = r.assignee.name.first
            r.assignee.last_name = r.assignee.name.last
            r.assignee.can_recieve_email = r.assignee.can_receive_email
            r.assignee.can_recieve_mobile = r.assignee.can_receive_mobile
            r.assignee.can_recieve_sms = r.assignee.can_receive_sms
            r.assignee.hourly_wage = r.assignee.hourly_wage ? null
            delete r.assignee.tag_instances
         for t in result.tag_instances
            t.name = t.tag.name
            t.abbreviation = t.tag.abbreviation
            t.color = t.tag.color
            t.effective_date = t.tag.effective_date
            t.require_expr_date = t.tag.require_expr_date
            t.globally_accessible = t.tag.globally_accessible
            t.group_ids = t.tag.group_ids
            t.categories = t.tag.categories
            delete t.tag
         project = new Project(result)
         project.baggage = ko.observable({
            qr_id: result.qr_id
            company_qr_id: companyResult.qr_id
         })
         
         @setTitle(project.name())
         @computeUIValues(project, () =>
            @project(project)
            @editingProject(project.clone(Project))
         )
         procoreCompanyId = UrlUtils.getProcoreCompanyIdFromURL();
         if project.procoreId() && procoreCompanyId
            renderReactComponent("link-to-procore-entity", "LinkToProcoreEntity", {
               entityType: "project",
               procoreEntityId: project.procoreId(),
               procoreCompanyId: procoreCompanyId,
            });
      catch err
         console.log("Error: ", err)

   deleteProject: (callback) ->
      try
         await ProjectStore.deleteProject(@projectId).payload
      catch err
         return callback(err, false)
      callback(null, true)

   showDeleteProjectModal: ->
      return unless @canDeleteProject
      pane1 = new ConfirmDeleteProjectPaneViewModel(@project().name())
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-delete-project-modal'}, (modal, exitStatus) =>
         if exitStatus == "finished"
            @deleteProject((err, success) =>
               return console.log "error: ", err if err?
               @dispose ->
                  router.back() if success
            )

   loadSupportData: ->
      try
         result = await CompanyStore.getIntegratedFields().payload
         @integratedFields(result.data.projects_integrated_fields)
         @hasIntegratedFields(@integratedFields().length > 0)
      catch err
         console.log("Error getting integrated fields: ", err)

      defaultStore.getResourceColorStrings (err, colors) =>
         @colorStrings(colors)
         @loadProject()


      if @customFieldModuleEnabled
         @getCustomFields (err, data) =>
            return console.log "Error: ", err if err
            @availableCustomFields(data)
            values = {}
            for field in data
               if field.type() == "multi-select"
                  values[field.id] = ko.observableArray()
               else
                  values[field.id] = ko.observable()

            @customFieldValues = values
            if @project()? and @project().customFields?
               for appliedField in @project().customFields()
                  if @customFieldValues[appliedField.fieldId]?
                     if appliedField.type == CustomField.Type.SELECT
                        for avilableField in @availableCustomFields()
                           if avilableField.id == appliedField.fieldId
                              for option in avilableField.options()
                                 if option.value() == appliedField.value
                                    @customFieldValues[appliedField.fieldId](option)
                                    break
                              break
                     else if appliedField.type == CustomField.Type.MULTI_SELECT
                        for avilableField in @availableCustomFields()
                           if avilableField.id == appliedField.fieldId
                              # Need to clear so doesn't dupe and keep adding on other calls.
                              @customFieldValues[appliedField.fieldId]([])
                              for option in avilableField.options()
                                 if appliedField.value.indexOf(option.value()) != -1
                                    option.selected(true)
                                    @customFieldValues[appliedField.fieldId].push(option)
                              break
                     else if appliedField.type == CustomField.Type.DATE
                        @customFieldValues[appliedField.fieldId](DateUtils.getAttachedDate(appliedField.value))
                     else
                        @customFieldValues[appliedField.fieldId](appliedField.value)

   getCustomFields: (callback) ->
      fields = []
      try
         query = { is_on_entities: [CustomFieldEntity.PROJECT] }
         stream = await CustomFieldStore.findCustomFieldsStream(query).stream
         for await row from stream
            fields.push(new CustomField(row))
         callback(null, fields)
      catch err
         return callback(err)

   formatCreatorName: () ->
      if @project().creatorName()?
         return formatName(@project().creatorName())
      else
         return null

   dispose: (next) ->
      assertArgs(arguments, Function)

      fanoutManager.unsubscribe("vm.ProjectDetailViewModel", FanoutManager.Channel.PROJECTS,
      {projectId: @projectId})

      fanoutManager.unsubscribe("vm.ProjectDetailViewModel", FanoutManager.Channel.PROJECT_ACTIVITY,
      {
         projectId: @projectId,
         category: @selectedActivityCategory(),
      })

      next()

   navigateToGantt: (name) =>
      # eslint-disable-next-line no-useless-escape
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/gantt?projectSearchFilter=\"#{name}\"")

   navigateToAssignments: (name) =>
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/assignments?projectSearchFilter=#{name}")

ProjectDetailViewModel.RoleAssigneeGroup = {
   USERS: "Users"
   PEOPLE: "People"
}

ProjectDetailViewModel.BaseAssignmentTokens = [
   {
      name: "Assignee's Name"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "name"
      subject2Type: "people"
   }
   {
      name: "Assignee's Email"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "email"
      subject2Type: "people"
   }
   {
      name: "Assignee's Phone"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "phone"
      subject2Type: "people"
   }
   {
      name: "Assignee's Job Title"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "position_id"
      subject2Type: "people"
      subject3Key: "name"
      subject3Type: "positions"
   }
   {
      name: "Assignment Start Date"
      subject1Key: "start_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Date"
      subject1Key: "end_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment Start Time"
      subject1Key: "start_time"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Time"
      subject1Key: "end_time"
      subject1Type: "assignments"
   }
   {
      name: "Assignment Work Days"
      subject1Key: "work_days"
      subject1Type: "assignments"
   }
   {
      name: "Project Name"
      subject1Key: "name"
      subject1Type: "projects"
   }
   {
      name: "Project Address"
      subject1Key: "address_1"
      subject1Type: "projects"
   }
   {
      name: "Project Address 2"
      subject1Key: "address_2"
      subject1Type: "projects"
   }
   {
      name: "Project City"
      subject1Key: "city_town"
      subject1Type: "projects"
   }
   {
      name: "Project State"
      subject1Key: "state_province"
      subject1Type: "projects"
   }
   {
      name: "Project Postal Code"
      subject1Key: "zipcode"
      subject1Type: "projects"
   }
   {
      name: "Project Country"
      subject1Key: "country"
      subject1Type: "projects"
   }
   {
      name: "Project Number"
      subject1Key: "job_number"
      subject1Type: "projects"
   }
]

ProjectDetailViewModel.BaseAssignmentTokens.push({
   name: "Assignment Status"
   subject1Key: "status_id"
   subject1Type: "assignments"
   subject2Key: "name"
   subject2Type: "statuses"
})

ProjectDetailViewModel.RecievableError = {
   OUTSIDE_USERS_GROUP: "outsideUsersGroups"
   RECORD_ARCHIVED: "projectArchived"
   UNASSOCIATED_WITH_PROJECT: "Person is not associated with this project."
}

ProjectDetailViewModel.PageError = {
   OUTSIDE_USERS_GROUP: {
      title: "Access Blocked"
      message: "You do not have access to the groups this project belongs to."
   }
   RECORD_ARCHIVED: {
      title: "Record Deleted"
      message: "This record has been removed. Contact LaborChart support if you think this was an mistake."
   }
}
