import { Model } from "@/models/model"
import { Tag } from "@/models/tag"
import { Note } from "@/models/note"
import { Person } from "@/models/person"
import { Position } from "@/models/position"
import { ProjectValue } from "@/models/project-value"
import { AssignmentCard } from "@/models/assignment-card"
import { Attachment } from "@/models/attachment"
import { Placeholder } from "@/models/placeholder"
import * as ko from "knockout"

export class Project extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?

      unless disableTypes
         ###
         # Core Project Fields
         ###
         # Required
         assertOfType(data.id, String)
         assertOfType(data.company_id, String)
         assertOfType(data.group_ids, arrayOf(String))
         assertOfType(data.attachment_ids, optional(arrayOf(String)))
         assertOfType(data.default_recipient_ids, optional(arrayOf(String)))
         assertOfType(data.note_ids, optional(arrayOf(String)))
 
         assertOfType(data.color, String)
         assertOfType(data.daily_end_time, Number)
         assertOfType(data.daily_start_time, Number)
         assertOfType(data.name, String)
         assertOfType(data.status, String)

         assertOfType(data.cost_codes, optional(arrayOf(Object)))
         assertOfType(data.custom_fields, arrayOf(Object))
         assertOfType(data.roles, arrayOf(Object))
         assertOfType(data.tag_instances, arrayOf(Object))
         assertOfType(data.wage_overrides, arrayOf(Object))
         # Nullable
         assertOfType(data.address_1, nullable(String))
         assertOfType(data.address_2, nullable(String))
         assertOfType(data.city_town, nullable(String))
         assertOfType(data.country, nullable(String))
         assertOfType(data.est_end_date, nullable(Number))
         assertOfType(data.start_date, nullable(Number))
         assertOfType(data.state_province, nullable(String))
         assertOfType(data.timezone, nullable(String))
         assertOfType(data.zipcode, nullable(String))
         # Optional
         assertOfType(data.bid_rate, optional(Number))
         assertOfType(data.customer_name, optional(String))
         assertOfType(data.job_number, optional(String))
         assertOfType(data.percent_complete, optional(Number))
         assertOfType(data.profile_pic_url, optional(String))
         assertOfType(data.project_type, optional(String))
         # TODO: Remove optional when we're 100% sure created_at/created_id fields will be defined (Keep nullable).
         assertOfType(data.created_at, optional(nullable(Number)))
         assertOfType(data.creator_id, optional(nullable(String)))
         assertOfType(data.procore_id, optional(nullable(String)))

         ###
         # Boards Specific Project Fields
         ###
         assertOfType(data.actual_rate, optional(nullable(Number)))
         # assertOfType(data.assignment_reminder_message_id, optional(String))
         assertOfType(data.attachments, optional(arrayOf(Object)))
         assertOfType(data.creator_name, optional(nullable(Object)))
         assertOfType(data.default_recipients, optional(arrayOf(Object)))
         assertOfType(data.delete_assignment_message_id, optional(String))
         assertOfType(data.edit_assignment_message_id, optional(String))
         assertOfType(data.new_assignment_message_id, optional(String))
         assertOfType(data.notes, optional(arrayOf(Object)))
         assertOfType(data.total_burn, optional(nullable(Number)))
         assertOfType(data.transfer_assignment_message_id, optional(String))

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      # TODO: Can remove coalescing to null when we're 100% sure these fields will be defined.
      @createdAt = ko.observable(data.created_at ? null)
      @creatorId = ko.observable(data.creator_id ? null)
      @creatorName = ko.observable(data.creator_name ? null)
      @procoreId = ko.observable(data.procore_id ? null)

      @status = ko.observable(data.status)
      @companyId = ko.observable(data.company_id)
      @name = ko.observable(data.name)
      @timezone = ko.observable(data.timezone)
      @color = ko.observable(data.color)
      @dailyStartTime = ko.observable(data.daily_start_time)
      @dailyEndTime = ko.observable(data.daily_end_time)
      @sendDailyRecap = ko.observable(data.send_daily_recap)

      @address1 = ko.observable(data.address_1)
      @address2 = ko.observable(data.address_2)
      @cityTown = ko.observable(data.city_town)
      @stateProvince = ko.observable(data.state_province)
      @zipcode = ko.observable(data.zipcode)
      @country = ko.observable(data.country)
      @startDate = ko.observable(data.start_date)
      @estEndDate = ko.observable(data.est_end_date)

      @closedDate = ko.observable(data.closed_date)
      @jobNumber = ko.observable(data.job_number)
      @bidRate = ko.observable(data.bid_rate)
      @actualRate = ko.observable(data.actual_rate)
      @totalBurn = ko.observable(data.total_burn)
      @percentComplete = ko.observable(data.percent_complete)
      @customerName = ko.observable(data.customer_name)
      @projectType = ko.observable(data.project_type)
      @placeholdersExpanded = ko.observable(data.placeholders_expanded or false)
      @profilePicUrl = ko.observable(data.profile_pic_url)
      @uncategorizedAssignmentsOpen = ko.observable(data.uncategorized_assignments_open or false)

      cannedMessages = data.canned_messages ? [];

      @newAssignmentMessageId = ko.observable(data.new_assignment_message_id ? cannedMessages.find((m) => m.type == "assignment-new")?.id)
      @editAssignmentMessageId = ko.observable(data.edit_assignment_message_id ? cannedMessages.find((m) => m.type == "assignment-edit")?.id)
      @transferAssignmentMessageId = ko.observable(data.transfer_assignment_message_id ? cannedMessages.find((m) => m.type == "assignment-transfer")?.id)
      @deleteAssignmentMessageId = ko.observable(data.delete_assignment_message_id ? cannedMessages.find((m) => m.type == "assignment-delete")?.id)
      # @assignmentReminderMessageId = ko.observable(data.assignment_reminder_message_id)

      ###------------------------------------
         Knockout Observable Arrays
      ------------------------------------###
      @groupIds = ko.observableArray(data.group_ids)
      @attachment_ids = ko.observableArray(data.attachment_ids)
      @default_recipient_ids = ko.observableArray(data.default_recipient_ids)
      @note_ids = ko.observableArray(data.note_ids)

      costCodes = data.cost_codes ? data.categories ? null

      @costCodes = if costCodes == null then ko.observableArray() else ko.observableArray costCodes.map (costCode) ->
         return new CostCode(costCode, disableTypes)
      @wageOverrides = if !data.wage_overrides? then ko.observableArray() else ko.observable data.wage_overrides.map (override) ->
         return new WageOverride(override, disableTypes)
      @tagInstances = if !data.tag_instances?.length > 0 then ko.observableArray() else ko.observableArray data.tag_instances.map (instance) ->
         return new Tag(instance.tag ? instance, disableTypes)
      @roles = if !data.roles? then ko.observableArray() else ko.observableArray data.roles.map (role) ->
         return new Role(role, disableTypes)
      @notes = if !data.notes? then ko.observableArray() else ko.observableArray data.notes.map (note) ->
         return new Note(note, disableTypes)
      @attachments = if !data.attachments? then ko.observableArray() else ko.observableArray data.attachments.map (attachment) ->
         return new Attachment(attachment, disableTypes)
      # TODO: Can we remove this?
      @placeholders = if !data.placeholders? then ko.observableArray() else ko.observableArray data.placeholders.map (placeholder) ->
         return new Placeholder(placeholder, disableTypes)

      @uncategorizedAssignments = if !data.uncategorized_assignments? then ko.observableArray() else ko.observableArray data.uncategorized_assignments.map (assignment) ->
         return new AssignmentCard(assignment, disableTypes)

      @uncategorizedRequests = if !data.uncategorized_requests? then ko.observableArray() else ko.observableArray data.uncategorized_requests.map (request) ->
         return new Placeholder(request, disableTypes)

      @defaultRecipients = ko.observableArray((data.default_recipients ||[]).map (item) -> return new ProjectValue(item))

      if data.custom_fields?
         @customFields = ko.observableArray data.custom_fields.map (item) ->
            return {
               fieldId: item.field_id
               integrationName: item.integration_name
               value: item.value
               name: item.name
               type: item.type
            }
      else
         @customFields = null

export class WageOverride extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         assertOfType(data.id, String)
         assertOfType((if data.position_id then data.position_id else data.job_title_id), String)
         assertOfType(Number(data.rate), Number)
         assertOfType(data.position, optional(Object))

      @id = data.id
      @positionId = ko.observable((if data.position_id then data.position_id else data.job_title_id))
      @rate = ko.observable(data.rate)
      if data.position?
         @position = ko.observable(new Position(data.position, disableTypes))
      else
         @position = ko.observable()


export class CostCode extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.sequence, nullable(Number))
         assertOfType(data.assignment_count, optional(Number))
         assertOfType(data.assignments_cards, optional(arrayOf(Object)))
      @id = data.id
      @name = ko.observable(data.name)
      @sequence = ko.observable(data.sequence)
      @assignmentCount = ko.observable(data.assignment_count)
      @requestCount = ko.observable(data.request_count)
      @isOpen = if data.is_open? then ko.observable(data.is_open) else ko.observable(false)

      labels = data.labels ? data.subcategories ? null

      @labels = if labels == null then ko.observableArray() else ko.observableArray labels.map (label) ->
         return new ProjectLabel(label, disableTypes)
      @labeledAssignmentCards = if !data.labeled_assignment_cards? then ko.observableArray() else ko.observableArray data.labeled_assignment_cards.map (group) ->
         # TODO: Clean this up. The computed is to get the UI to always update. Obviously another problem somewhere
         assignmentCards = ko.observableArray group.reduction.map (card) ->
            card.status = card.status ? null
            new AssignmentCard(card, disableTypes)
         displayCards = ko.pureComputed =>
            # TODO: Clean this up to no return a double wrapped array.
            return ko.observableArray(assignmentCards())
         return {
            labelId: group.group,
            labelCount: group.label_count
            assignmentCards: assignmentCards,
            displayCards: displayCards
         }
      @labeledRequestCards = if !data.labeled_request_cards? then ko.observableArray() else ko.observableArray data.labeled_request_cards.map (group) ->
         # TODO: Clean this up. The computed is to get the UI to always update. Obviously another problem somewhere
         requestCards = ko.observableArray group.reduction.map (card) ->
            card.status = card.status ? null
            new Placeholder(card, disableTypes)
         displayCards = ko.pureComputed =>
            # TODO: Clean this up to no return a double wrapped array.
            return ko.observableArray(requestCards())
         return {
            labelId: group.group,
            labelCount: group.label_count
            requestCards: requestCards,
            displayCards: displayCards
         }


export class ProjectLabel extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         assertOfType(data.id, String)
         assertOfType(data.name, String)
         assertOfType(data.sequence, nullable(Number))
      @id = data.id
      @name = ko.observable(data.name)
      @sequence = ko.observable(data.sequence)


export class Role extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType((if data.position_id then data.position_id else data.job_title_id), String)
         assertOfType(data.person_id, String)

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @positionId = ko.observable((if data.position_id then data.position_id else data.job_title_id))
      @personId = ko.observable(data.person_id)

      if data.position?
         @position = ko.observable(new Position(data.position, disableTypes))
      else
         @position = ko.observable(new Position({}, true))
      if data.assignee?
         @assignee = ko.observable(new Person(data.assignee, disableTypes))
      else
         @assignee = ko.observable(new Person({}, true))



Project.Role = Role
Project.ProjectLabel = ProjectLabel
Project.CostCode = CostCode
Project.WageOverride = WageOverride
