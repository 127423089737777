import "./access-denied.styl";
import template from "./access-denied.pug";
import { router } from "@/lib/router";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

export class AccessDeniedViewModel extends PageContentViewModel {
   constructor() {
      super(template());
   }

   goHome(): void {
      return router.navigate(null, "/home");
   }
}
