import reactTemplate from "./react-time-off-list.pug";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import renderReactComponent from "@/react/render-react-component";
import type { Root } from "react-dom/client";

export class TimeOffList2 extends PageContentViewModel {
   private reactRoot: Root | null;

   constructor() {
      super(reactTemplate(), "List");
      this.reactRoot = null;
   }

   addedToParent(): void {
      // If react is enabled, attach the TimeOffListContainer component to the DOM
      this.reactRoot = renderReactComponent("react-time-off-mount", "TimeOffListContainer", {
         setTitle: (title: string) => this.setTitle(title),
      });
   }

   removedFromParent(): void {
      if (this.reactRoot) {
         this.reactRoot.unmount();
      }
   }
}
