import type { Observable, ObservableArray, PureComputed } from "knockout";
import { pureComputed } from "knockout";
import { observable } from "knockout";
import { observableArray } from "knockout";
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import { authManager } from "@/lib/managers/auth-manager";
import { PositionStore } from "@/stores/position-store.core";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { SerializedCompany } from "@laborchart-modules/common/dist/rethink/serializers/company-serializer";
import { SettingsStore } from "@/stores/settings-store.core";
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller";
import type {
   QrCodeConfigPeople,
   QrCodeConfigProjects,
} from "@laborchart-modules/common/dist/rethink/schemas/companies";
import type { Person } from "@laborchart-modules/common/dist/rethink/schemas/people";
import type { Project } from "@laborchart-modules/common/dist/rethink/schemas/projects";

export type qrCodeConfig = {
   people: QrCodeConfigPeople | null;
   projects: QrCodeConfigProjects | null;
} | null;
class QRCodesViewModelCoreState {
   readonly projectTagOptions: ObservableArray<MultiDropDownItem<string>>;
   readonly peopleTagOptions: ObservableArray<MultiDropDownItem<string>>;
   readonly roleOptions: ObservableArray<MultiDropDownItem<string>>;
   readonly peopleFieldOptions: ObservableArray<MultiDropDownItem<string>>;
   readonly projectFieldOptions: ObservableArray<MultiDropDownItem<string>>;
   readonly codesForProjectsEnabled: Observable<boolean>;
   readonly showProjectTags: Observable<boolean>;
   readonly selectedProjectTags: ObservableArray<MultiDropDownItem<string>>;
   readonly projectTagsShowAttachments: Observable<boolean>;
   readonly projectTagChips: ObservableArray<MultiDropDownItem<string>>;
   readonly showProjectRoles: Observable<boolean>;
   readonly selectedRoles: ObservableArray<MultiDropDownItem<string>>;
   readonly projectRoleChips: ObservableArray<MultiDropDownItem<string>>;
   readonly selectedProjectFields: ObservableArray<MultiDropDownItem<string>>;
   readonly projectFieldChips: ObservableArray<MultiDropDownItem<string>>;
   readonly codesForPeopleEnabled: Observable<boolean>;
   readonly showPeopleTags: Observable<boolean>;
   readonly selectedPeopleTags: ObservableArray<MultiDropDownItem<string>>;
   readonly peopleTagFilterChips: ObservableArray<MultiDropDownItem<string>>;
   readonly peopleTagsShowAttachments: Observable<boolean>;
   readonly peopleTagsShowExpr: Observable<boolean>;
   readonly selectedPeopleFields: ObservableArray<MultiDropDownItem<string>>;
   readonly peopleFieldChips: ObservableArray<MultiDropDownItem<string>>;
   readonly existingConfig: Observable<qrCodeConfig>;
   readonly selectedProjectTagFilterOption: Observable<SegmentedControllerItem<boolean>>;
   readonly showRolesEmail: Observable<boolean>;
   readonly showRolesPhone: Observable<boolean>;
   readonly showRolesPhoto: Observable<boolean>;
   readonly selectedRoleFilterOptions: Observable<SegmentedControllerItem<boolean>>;
   readonly selectedPeopleTagFilterOption: Observable<SegmentedControllerItem<boolean>>;
   readonly showProjectTagFilters: PureComputed<boolean> = pureComputed<boolean>(() =>
      this.selectedProjectTagFilterOption().value(),
   );
   readonly showRoleFilters: PureComputed<boolean> = pureComputed<boolean>(() =>
      this.selectedRoleFilterOptions().value(),
   );
   readonly showPeopleTagFilters: PureComputed<boolean> = pureComputed<boolean>(() =>
      this.selectedPeopleTagFilterOption().value(),
   );

   readonly tagFilterOptions = [
      new SegmentedControllerItem<boolean>("All Tags", false),
      new SegmentedControllerItem<boolean>("Filtered Tags", true),
   ];
   readonly roleFilterOptions = [
      new SegmentedControllerItem<boolean>("All Roles", false),
      new SegmentedControllerItem<boolean>("Filtered Roles", true),
   ];

   readonly defaultPeopleFields: Array<MultiDropDownItem<keyof Person>> = [
      new MultiDropDownItem<keyof Person>("Profile Picture", "profile_pic_url", false),
      new MultiDropDownItem<keyof Person>("Job Title", "position_id", false),
      new MultiDropDownItem<keyof Person>("Email", "email", false),
      new MultiDropDownItem<keyof Person>("Phone", "phone", false),
      new MultiDropDownItem<keyof Person>("Employee Number", "employee_number", false),
      new MultiDropDownItem<keyof Person>("Group Avilablility", "group_ids", false),
      new MultiDropDownItem<keyof Person>("Status", "status", false),
      new MultiDropDownItem<keyof Person>("Address", "address_1", false),
      new MultiDropDownItem<keyof Person>("Address 2", "address_2", false),
      new MultiDropDownItem<keyof Person>("City/Town", "city_town", false),
      new MultiDropDownItem<keyof Person>("State/Province", "state_province", false),
      new MultiDropDownItem<keyof Person>("Postal Code", "zipcode", false),
      new MultiDropDownItem<keyof Person>("Country", "country", false),
      new MultiDropDownItem<keyof Person>("Hourly Wage", "hourly_wage", false),
      new MultiDropDownItem<keyof Person>("Date of Hire", "hired_date", false),
      new MultiDropDownItem<keyof Person>("Date of birth", "dob", false),
      new MultiDropDownItem<keyof Person>(
         "Emergency Contact Name",
         "emergency_contact_name",
         false,
      ),
      new MultiDropDownItem<keyof Person>(
         "Emergency Contact Relation",
         "emergency_contact_relation",
         false,
      ),
      new MultiDropDownItem<keyof Person>(
         "Emergency Contact Phone",
         "emergency_contact_number",
         false,
      ),
      new MultiDropDownItem<keyof Person>(
         "Emergency Contact Email",
         "emergency_contact_email",
         false,
      ),
   ];
   readonly defaultProjectFields: Array<MultiDropDownItem<keyof Project>> = [
      new MultiDropDownItem<keyof Project>("Job Number", "job_number", false),
      new MultiDropDownItem<keyof Project>("Group Avilablility", "group_ids", false),
      new MultiDropDownItem<keyof Project>("Status", "status", false),
      new MultiDropDownItem<keyof Project>("Address", "address_1", false),
      new MultiDropDownItem<keyof Project>("Address 2", "address_2", false),
      new MultiDropDownItem<keyof Project>("City/Town", "city_town", false),
      new MultiDropDownItem<keyof Project>("State/Province", "state_province", false),
      new MultiDropDownItem<keyof Project>("Postal Code", "zipcode", false),
      new MultiDropDownItem<keyof Project>("Country", "country", false),
      new MultiDropDownItem<keyof Project>("Start Date", "start_date", false),
      new MultiDropDownItem<keyof Project>("End Date", "est_end_date", false),
      new MultiDropDownItem<keyof Project>("Daily Start Time", "daily_start_time", false),
      new MultiDropDownItem<keyof Project>("Daily End Time", "daily_end_time", false),
      new MultiDropDownItem<keyof Project>("Estimated Avg. Rate", "bid_rate", false),
      new MultiDropDownItem<keyof Project>("Percent Complete", "percent_complete", false),
      new MultiDropDownItem<keyof Project>("Customer", "customer_name", false),
      new MultiDropDownItem<keyof Project>("Project Type", "project_type", false),
      new MultiDropDownItem<keyof Project>("Project Color", "color", false),
   ];

   constructor() {
      this.projectTagOptions = observableArray();
      this.peopleTagOptions = observableArray();
      this.roleOptions = observableArray();
      this.peopleFieldOptions = observableArray();
      this.projectFieldOptions = observableArray();
      this.codesForProjectsEnabled = observable<boolean>(false);
      this.showProjectTags = observable<boolean>(false);
      this.selectedProjectTags = observableArray();
      this.projectTagsShowAttachments = observable<boolean>(false);
      this.projectTagChips = observableArray();
      this.showProjectRoles = observable<boolean>(false);
      this.selectedRoles = observableArray();
      this.projectRoleChips = observableArray();
      this.selectedProjectFields = observableArray();
      this.projectFieldChips = observableArray();
      this.codesForPeopleEnabled = observable<boolean>(false);
      this.showPeopleTags = observable<boolean>(false);
      this.selectedPeopleTags = observableArray();
      this.peopleTagFilterChips = observableArray();
      this.peopleTagsShowAttachments = observable<boolean>(false);
      this.peopleTagsShowExpr = observable<boolean>(false);
      this.selectedPeopleFields = observableArray();
      this.peopleFieldChips = observableArray();
      this.existingConfig = observable(null);
      this.showRolesEmail = observable<boolean>(false);
      this.showRolesPhone = observable<boolean>(false);
      this.showRolesPhoto = observable<boolean>(false);

      this.selectedProjectTagFilterOption = observable(this.tagFilterOptions[0]);
      this.selectedProjectTagFilterOption.subscribe((newVal) => {
         if (newVal.value() == false) {
            this.selectedProjectTags([]);
            this.projectTagChips([]);
            for (const option of this.projectTagOptions()) {
               option.selected(false);
            }
         }
      });

      this.selectedRoleFilterOptions = observable(this.roleFilterOptions[0]);
      this.selectedRoleFilterOptions.subscribe((newVal) => {
         if (newVal.value() == false) {
            this.selectedRoles([]);
            this.projectRoleChips([]);
            for (const option of this.roleOptions()) {
               option.selected(false);
            }
         }
      });

      this.selectedPeopleTagFilterOption = observable(this.tagFilterOptions[0]);
      this.selectedPeopleTagFilterOption.subscribe((newVal) => {
         if (newVal.value() == false) {
            this.selectedPeopleTags([]);
            this.peopleTagFilterChips([]);
            for (const option of this.peopleTagOptions()) {
               option.selected(false);
            }
         }
      });
   }

   processExistingConfig() {
      this.clearProjectValues();
      this.clearPeopleValues();

      const config = this.existingConfig();
      if (config?.projects) {
         this.codesForProjectsEnabled(true);
         // Project Tags
         if (config.projects.tags) {
            this.showProjectTags(true);
            this.projectTagsShowAttachments(config.projects.tags.show_attachments);
            if (config.projects.tags.tag_ids.length > 0) {
               this.selectedProjectTagFilterOption(this.tagFilterOptions[1]);
               const projectTagIds = config.projects.tags.tag_ids;
               for (const option of this.projectTagOptions()) {
                  if (projectTagIds.indexOf(option.value()) != -1) {
                     option.selected(true);
                     this.selectedProjectTags.push(option);
                     this.projectTagChips.push(option);
                  }
               }
            } else {
               this.selectedProjectTagFilterOption(this.tagFilterOptions[0]);
            }
         } else {
            this.showProjectTags(false);
            this.selectedProjectTags([]);
            this.projectTagsShowAttachments(false);
            this.projectTagChips([]);
         }

         // Project Roles
         if (config.projects.roles) {
            this.showProjectRoles(true);
            this.showRolesEmail(config.projects.roles.show_email);
            this.showRolesPhone(config.projects.roles.show_phone);
            this.showRolesPhoto(config.projects.roles.show_photo);
            if (config.projects.roles.position_ids.length > 0) {
               this.selectedRoleFilterOptions(this.roleFilterOptions[1]);
               const projectPositionIds = config.projects.roles.position_ids;
               for (const option of this.roleOptions()) {
                  if (projectPositionIds.indexOf(option.value()) != -1) {
                     option.selected(true);
                     this.selectedRoles.push(option);
                     this.projectRoleChips.push(option);
                  }
               }
            } else {
               this.selectedRoleFilterOptions(this.roleFilterOptions[0]);
               this.selectedRoles([]);
               this.projectRoleChips([]);
            }
         } else {
            this.showProjectRoles(false);
            this.showRolesEmail(false);
            this.showRolesPhone(false);
            this.showRolesPhoto(false);
            this.selectedRoles([]);
            this.projectRoleChips([]);
         }

         // Project Fields
         if (config.projects.fields && config.projects.fields.length > 0) {
            const projectFieldVals = config.projects.fields;
            for (const option of this.projectFieldOptions()) {
               if (projectFieldVals.indexOf(option.value()) != -1) {
                  option.selected(true);
                  this.selectedProjectFields.push(option);
                  this.projectFieldChips.push(option);
               }
            }
         }
      }

      if (config?.people) {
         this.codesForPeopleEnabled(true);

         // People Tags
         if (config.people.tags) {
            this.showPeopleTags(true);
            this.peopleTagsShowAttachments(config.people.tags.show_attachments);
            this.peopleTagsShowExpr(config.people.tags.show_expr_date);
            if (config.people.tags.tag_ids.length > 0) {
               this.selectedPeopleTagFilterOption(this.tagFilterOptions[1]);
               const peopleTagIds = config.people.tags.tag_ids;
               for (const option of this.peopleTagOptions()) {
                  if (peopleTagIds.indexOf(option.value()) != -1) {
                     option.selected(true);
                     this.selectedPeopleTags.push(option);
                     this.peopleTagFilterChips.push(option);
                  }
               }
            } else {
               this.selectedPeopleTagFilterOption(this.tagFilterOptions[0]);
            }
         } else {
            this.showPeopleTags(false);
            this.selectedPeopleTags([]);
            this.peopleTagsShowAttachments(false);
            this.peopleTagsShowExpr(false);
         }

         // People Fields
         if (config.people.fields && config.people.fields.length > 0) {
            const peopleFieldVals = config.people.fields;
            for (const option of this.peopleFieldOptions()) {
               if (peopleFieldVals.indexOf(option.value()) != -1) {
                  option.selected(true);
                  this.selectedPeopleFields.push(option);
                  this.peopleFieldChips.push(option);
               }
            }
         }
      }
   }

   clearProjectValues() {
      this.codesForProjectsEnabled(false);

      this.showProjectTags(false);
      this.selectedProjectTags([]);
      this.projectTagsShowAttachments(false);
      this.projectTagChips([]);
      for (const option of this.projectTagOptions()) {
         option.selected(false);
      }

      this.showProjectRoles(false);
      this.selectedRoles([]);
      this.projectRoleChips([]);
      for (const option of this.roleOptions()) option.selected(false);

      this.selectedProjectFields([]);
      this.projectFieldChips([]);
      for (const option of this.projectFieldOptions()) option.selected(false);
   }

   clearPeopleValues() {
      this.codesForPeopleEnabled(false);

      this.showPeopleTags(false);
      this.selectedPeopleTags([]);
      this.peopleTagFilterChips([]);
      this.peopleTagsShowAttachments(false);
      this.peopleTagsShowExpr(false);
      for (const option of this.peopleTagOptions()) {
         option.selected(false);
      }

      this.selectedPeopleFields([]);
      this.peopleFieldChips([]);
      for (const option of this.peopleFieldOptions()) {
         option.selected(false);
      }
   }

   async loadData(): Promise<void> {
      const loadTagOptions = async () => {
         const tagOptionsStream = await SettingsStore.findTagsStream({
            sort_direction: Order.ASCENDING,
         }).stream;
         const projectTagOptions = [];
         const peopleTagOptions = [];
         for await (const tag of tagOptionsStream) {
            projectTagOptions.push(new MultiDropDownItem(tag.name, tag.id, false));
            peopleTagOptions.push(new MultiDropDownItem(tag.name, tag.id, false));
         }
         this.projectTagOptions(projectTagOptions);
         this.peopleTagOptions(peopleTagOptions);
      };

      const loadRoleOptions = async () => {
         const positionOptionsStream = await PositionStore.findPositionsStream({
            order: Order.ASCENDING,
         }).stream;
         const roleOptions = [];
         for await (const position of positionOptionsStream) {
            roleOptions.push(new MultiDropDownItem(position.name, position.id, false));
         }
         this.roleOptions(roleOptions);
      };

      const loadCustomFieldOptions = async () => {
         const customFieldStream = authManager.companyModules()?.customFields
            ? await CustomFieldStore.findCustomFieldsStream({
                 is_on_entities: [CustomFieldEntity.PERSON, CustomFieldEntity.PROJECT],
              }).stream
            : [];
         const peopleFieldOptions = this.defaultPeopleFields as Array<MultiDropDownItem<string>>;
         const projectFieldOptions = this.defaultProjectFields as Array<MultiDropDownItem<string>>;
         for await (const field of customFieldStream) {
            if (field.on_people == true) {
               peopleFieldOptions.push(
                  new MultiDropDownItem(field.name, field.id, false, undefined, {
                     custom_field: true,
                  }),
               );
            }
            if (field.on_projects) {
               projectFieldOptions.push(
                  new MultiDropDownItem(field.name, field.id, false, undefined, {
                     custom_field: true,
                  }),
               );
            }
         }
         this.peopleFieldOptions(peopleFieldOptions);
         this.projectFieldOptions(projectFieldOptions);
      };

      await Promise.all([loadTagOptions(), loadRoleOptions(), loadCustomFieldOptions()]);

      if (this.existingConfig()) this.processExistingConfig();
      QRCodesViewModelCore.getCompanyQrCodeConfig((err, config) => {
         if (err) return console.log("error", err);
         this.existingConfig(config ?? null);
         this.processExistingConfig();
      });
   }
}

export class QRCodesViewModelCore {
   readonly state: QRCodesViewModelCoreState;

   constructor() {
      this.state = new QRCodesViewModelCoreState();
   }

   static async getCompanyQrCodeConfig(
      callback: (err: Error | null, callback?: SerializedCompany["qr_code_config"]) => void,
   ): Promise<void> {
      try {
         const result = await SettingsStore.getQrCodeConfig().payload;
         callback(null, result.data);
      } catch (err) {
         callback(err as Error);
      }
   }
}
