import template from "./confirm-delete-tag-pane.pug";
import * as ko from "knockout";
import { ModalPane } from "@/lib/components/modals/modal-pane";
import { modalManager } from "@/lib/managers/modal-manager";

export class ConfirmDeleteTagPaneViewModel extends ModalPane {
   private readonly confirmationText: ko.Observable<string | undefined>;
   private readonly canDelete: ko.PureComputed<boolean>;

   constructor(private readonly tagName: string) {
      super("Delete Tag", "", template());
      this.confirmationText = ko.observable();
      this.canDelete = ko.pureComputed(() => this.confirmationText()?.toLowerCase() === "yes");
   }

   confirmDelete(): void {
      if (!this.canDelete()) {
         return;
      }
      modalManager.modalFinished();
   }
}
