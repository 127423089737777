import "./reset-password.styl"
import template from "./reset-password.pug"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { peopleStore } from "@/stores/people-store"

export class ResetPasswordViewModel extends PageContentViewModel
   constructor: (resetId) ->
      assertArgs(arguments, String)
      super(template())
      @resetId = resetId
      @password = ko.observable()
      @confirmPassword = ko.observable()

      @invalidMessage = ko.observable(null)
      @confirmMessage = ko.observable(null)

      @actionButtonEnabled = ko.pureComputed =>
         return @password()? and @confirmPassword()? and 
         (@password().toLowerCase() == @confirmPassword().toLowerCase()) and @validPass()

      @upperCaseValid = ko.pureComputed =>
         return false unless @password()?
         return @password().match(/^(?=.*[A-Z])/)?

      @lowerCaseValid = ko.pureComputed =>
         return false unless @password()?
         return @password().match(/^(?=.*[a-z])/)?

      @numberValid = ko.pureComputed =>
         return false unless @password()?
         return @password().match(/^(?=.*[0-9])/)?

      @lengthValid = ko.pureComputed =>
         return false unless @password()?
         return @password().length >= 8

      @validPass = ko.pureComputed =>
         return false unless @password()?
         return @password().match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)?

      @confirmInputInvalid = ko.pureComputed =>
         return false unless @confirmPassword()?
         return @password() != @confirmPassword()

      @confirmInputValid = ko.pureComputed =>
         return false unless @confirmPassword()?
         return @password() == @confirmPassword()

      @blockRequests = false

   actionBtnClicked: ->
      return unless @actionButtonEnabled()
      return if @blockRequests
      @blockRequests = true
      peopleStore.resetPassword @resetId, @password(), (err, success) =>
         @blockRequests = false
         if err?.detailCode == ResetPasswordViewModel.RecievableError.MISSING_PASSWORD_RESET
            return @invalidMessage("This password reset is no longer valid. Please request another by clicking 'Forgot Password' <a href='/sign-in'>HERE</a>.")

         if err?.detailCode == ResetPasswordViewModel.RecievableError.USER_LOCKED_OUT
            return @invalidMessage("Your account has been locked out. Please contact your admin user.")

         if err?.detailCode == ResetPasswordViewModel.RecievableError.INVALID_COMPLEX_PASSWORD
            return @invalidMessage("Your password did not meet the complexity requirements.")

         if err?.detailCode == ResetPasswordViewModel.RecievableError.REUSED_PASSWORD
            return @invalidMessage("You can not reuse a previous password.")
         
         if success
            @invalidMessage(null)
            @confirmMessage("You reset was successfull! You can sign in <a href='/sign-in'>HERE</a> with your new password.")
            @password(null)
            @confirmPassword(null)
            setTimeout ->
               router.navigate(null, "/sign-in")
            , 4000
         else
            @invalidMessage("This password reset is no longer valid. If you think this is a mistake, please contact your admin.")



ResetPasswordViewModel.RecievableError = {
   MISSING_PASSWORD_RESET: 'missingPasswordReset'
   USER_LOCKED_OUT: 'userLockedOut'
   INVALID_COMPLEX_PASSWORD: 'invalidPassword'
   REUSED_PASSWORD: 'reusedPassword'
}