import { DateUtils } from "@/lib/utils/date"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"

FormatMessage = {}
FormatMessage.populatedMessage = (cannedMessage) ->
   transformValue = (category, value) ->
      return '' unless value?
      return switch category
         when "assignments-start_time" then defaultStore.formatTime(value)
         when "assignments-end_time" then defaultStore.formatTime(value)
         when "assignments-start_day" then DateUtils.getShortNumericDate(DateUtils.getAttachedDate(value), defaultStore.getDateFormat())
         when "assignments-end_day" then DateUtils.getShortNumericDate(DateUtils.getAttachedDate(value), defaultStore.getDateFormat())
         when 'assignments-resource_id-people-name' then "#{value.first} #{value.last}"
         when 'assignments-work_days' then DateUtils.getFormattedWorkDays(value)
         when 'people-name' then "#{value.first} #{value.last}"
         else ''

   if cannedMessage.subject()?
      cannedMessage.subject cannedMessage.subject().replace /LC-DT<{(.*?)}>/g, (hit) =>
         tokenId = hit.match(/\{(.*?)}/)[1]
         for token in cannedMessage.dynamicTokens()
            if token.id == tokenId
               category = "#{token.subject1Type}-#{token.subject1Key}"
               if token.subject2Type?
                  category += "-#{token.subject2Type}-#{token.subject2Key}"
               if FormatMessage.TransformationFields.indexOf(category) != -1
                  return transformValue(category, token.value)
               else
                  return token.value or ''
         return ''

   cannedMessage.content cannedMessage.content().replace /LC-DT<{(.*?)}>/g, (hit) =>
      tokenId = hit.match(/\{(.*?)}/)[1]
      for token in cannedMessage.dynamicTokens()
         if token.id == tokenId
            category = "#{token.subject1Type}-#{token.subject1Key}"
            if token.subject2Type?
               category += "-#{token.subject2Type}-#{token.subject2Key}"
            if FormatMessage.TransformationFields.indexOf(category) != -1
               return transformValue(category, token.value)
            else
               return token.value or ''
      return ''

###
# The assignmentBatchInfo and projectInfo originate in project-store and have the following shapes:
# interface assignmentBatchInfo {
#    startDay: number,
#    endDay: number,
#    startTime: number | null, 
#    endTime: number | null,
#    percentAllocated: number | null,
#    workDays: boolean[],
#    resourceName: { first: string, last: string }
# }
# interface projectInfo {
#    name: string,
#    address1: string | null,
#    address2: string | null,
#    cityTown: string | null,
#    stateProvince: string | null,
#    zipcode: string | null,
#    jobNumber: string | null,
# }
###
FormatMessage.populateAssignmentMessage = (assignmentBatchInfo, projectInfo, notifyContext) ->
      message = "Assignment Info:\n"
      formattedStartDay = DateUtils.formatDetachedDay(assignmentBatchInfo.startDay, defaultStore.getDateFormat())
      formattedEndDay = DateUtils.formatDetachedDay(assignmentBatchInfo.endDay, defaultStore.getDateFormat())

      startDate = DateUtils.getAttachedDate(assignmentBatchInfo.startDay)
      endDate = DateUtils.getAttachedDate(assignmentBatchInfo.endDay)
      startWorkDay = startDate.getDay()
      endWorkDay = endDate.getDay()
      # TODO: Consider refactoring DateUtils.getFormattedWorkDays to handle inRangeDays instead of always assuming all days of the week are included.
      # Need to be strings.
      inRangeDays = []
      duration = DateUtils.daysBetweenDates(startDate, endDate) + 1

      if duration >= 7
         inRangeDays = ['0','1','2','3','4','5','6']
      else
         if startWorkDay < endWorkDay
            ### eslint-disable ###
            # NOTE: do is required to capture the 'ref' variable created by
            # CoffeeScript's loop.
            do ->
               for i in [startWorkDay...(endWorkDay + 1)] by 1
                  inRangeDays.push("#{i}")
            ### eslint-enable ###

         else if startWorkDay > endWorkDay
            ### eslint-disable ###
            # NOTE: do is required to capture the 'ref' variable created by
            # CoffeeScript's loop.
            do ->
               for i in [startWorkDay...7] by 1
                  inRangeDays.push("#{i}")
            ### eslint-enable ###

            for i in [0...(endWorkDay + 1)] by 1
               inRangeDays.push("#{i}")

         else if startWorkDay == endWorkDay
            inRangeDays = ["#{startWorkDay}"]

      # TODO: Should use DateUtils.getFormattedWorkDays
      workDayString = "["
      seekingActiveDay = true
      lastActiveKey = null
      for key, val of assignmentBatchInfo.workDays
         # Restrict to only showing days that are within range.
         if val
            val = inRangeDays.indexOf(key) !=-1

         if seekingActiveDay
            if val
               lastActiveKey = key
               workDayString += "#{DateUtils.WorkDayAbbreviations[key]}-"
               seekingActiveDay = false
         else
            if !val
               # Ok to always subtract becuase Sunday can't be hit here.
               previousKey = String(key - 1)
               if previousKey == lastActiveKey
                  workDayString = "#{workDayString.slice(0, -1)},"
               else
                  workDayString += "#{DateUtils.WorkDayAbbreviations[key - 1]},"
               seekingActiveDay = true
            else if key == "6" and val
               # Might be at the end and enabled all the way through. 
               workDayString += "Sa,"
      workDayString = "#{workDayString.slice(0, -1)}]"

      message += "#{formattedStartDay} - #{formattedEndDay}\n"

      if assignmentBatchInfo.percentAllocated?
         message += "#{assignmentBatchInfo.percentAllocated}% Time Allocation\n"
      else
         formattedStartTime = DateUtils.formatTimeVal(assignmentBatchInfo.startTime)
         formattedEndTime = DateUtils.formatTimeVal(assignmentBatchInfo.endTime)
         message += "#{formattedStartTime} - #{formattedEndTime}\n"

      message += "Work Days: #{workDayString}\n"
      message += formatProjectInfoForMessage_(projectInfo)
      subject = switch notifyContext
         when 'assignment-new' then "New Assignment for \n"
         when 'assignment-edit' then "Assignment Update for \n"
         when 'assignment-delete' then "Canceled Assignment for \n"
         when 'assignment-transfer' then "Assignment Transfer for \n"

      subject += "#{assignmentBatchInfo.resourceName.first} #{assignmentBatchInfo.resourceName.last}"
      return [subject, message]

formatProjectInfoForMessage_ = (projectInfo) ->
   content = "#{projectInfo.name}\n"
   if projectInfo.address1?
      content += "#{projectInfo.address1}\n"
   if projectInfo.address2?
      content += "#{projectInfo.address2}\n"
   if projectInfo.cityTown? or projectInfo.stateProvince? or projectInfo.zipcode?
      locale = ""
      if projectInfo.cityTown?
         locale += "#{projectInfo.cityTown}, "
      if projectInfo.stateProvince?
         locale += "#{projectInfo.stateProvince}, "
      if projectInfo.zipcode?
         locale += "#{projectInfo.zipcode}, "
      content += "#{locale.slice(0, -2)}\n"
   if projectInfo.jobNumber?
      content += "Project #: #{projectInfo.jobNumber}\n"
   return content

FormatMessage.TransformationFields = [ 'assignments-start_day', 'assignments-end_day'
   'assignments-start_time', 'assignments-end_time', 'assignments-resource_id-people-name',
   'assignments-work_days', 'people-name'
]


export FormatMessage = FormatMessage
