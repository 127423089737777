import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import template from "./reset-data.pug";
import renderResetData from "@/react/render-reset-data";
import { router } from "@/lib/router";
import { CompanyStore } from "@/stores/company-store.core";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";
import { authManager } from "@/lib/managers/auth-manager";

export class ResetDataViewModel extends PageContentViewModel {
   constructor() {
      super(template(), "Settings - Reset Data");
   }

   addedToParent(): void {
      renderResetData({
         submitResetData: this.submitResetData,
      });
   }

   async submitResetData(src_company_id: any): Promise<any> {
      try {
         const srcCompanyId = String(src_company_id.src_company_id);
         await CompanyStore.resetDemoData(srcCompanyId).payload;
         router.navigate(null, "/home");
      } catch (error) {
         console.log("Error:", error);
         Bugsnag.notify(error as NotifiableError, (event) => {
            event.context = "reset-demo-data";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("srcCompanyId", src_company_id);
         });
      }
   }
}
