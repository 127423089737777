import template from "./company.pug"
import { authManager } from "@/lib/managers/auth-manager"
import { CompanyStore } from "@/stores/company-store.core"
import { SettingsStore } from "@/stores/settings-store.core"
import { defaultStore } from "@/stores/default-store"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### Models ###
import { Company } from "@/models/company"
import { PermissionLevel } from "@/models/permission-level"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

import { Flag } from '../../../../flags'
import * as ko from "knockout"

DEFAULT_BRANDING_LOGO_URL = ''
DEFAULT_BRANDING_PRIMARY_COLOR = '#ff7508'
DEFAULT_BRANDING_SECONDARY_COLOR = '#FF5100'

export class CompanyViewModel extends PageContentViewModel
   constructor: (companyId) ->
      super(template(), "Settings - Company")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageCompanySettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_COMPANY_SETTINGS)

      ### General Panel ###
      @companyId = companyId
      @company = ko.observable()
      @editingCompany = ko.observable()
      @selectedTimezone = ko.observable()
      @timezoneFrameLocation = Popup.FrameType.ABOVE
      @timezoneArrowLocation = Popup.ArrowLocation.BOTTOM_LEFT
      @isLoading = ko.observable(false)
      @brandingSettingsEnabled = Flag.ENABLE_BRANDING_SETTINGS
      @isSupportUser = authManager.isSupportUser()

      # Date Format
      @dateFormatOptions = ko.observableArray([
         new DropDownItem("MM/DD/YYYY", "MM/DD/YYYY")
         new DropDownItem("DD/MM/YYYY", "DD/MM/YYYY")
         new DropDownItem("YYYY/MM/DD", "YYYY/MM/DD")
         new DropDownItem("YYYY/DD/MM", "YYYY/DD/MM")
         new DropDownItem("DD-MM-YYYY", "DD-MM-YYYY")
         new DropDownItem("MM-DD-YYYY", "MM-DD-YYYY")
         new DropDownItem("YYYY-MM-DD", "YYYY-MM-DD")
      ])

      @selectedDateFormat = ko.observable()

      # Currency
      @currencyOptions = ko.observableArray([
         new DropDownItem("USD", 'usd')
         new DropDownItem("AUD", 'aud')
         new DropDownItem("CAD", 'cad')
         new DropDownItem("Danish Krone", 'danish-krone')
         new DropDownItem("Euro", 'euro')
         new DropDownItem("Norwegian Krone", 'norwegian-krone')
         new DropDownItem("Pound", 'pound')
         new DropDownItem("Swedish Krona", 'swedish-krona')
         ])
      @selectedCurrency = ko.observable()

      @loadCompany()

      @hasBranding = ko.pureComputed =>
         if !@editingCompany() or !@company()
            return false
         if !@editingCompany().branding()
            return false
         if !@company().branding()
            return false
         return true
         
      @haveBrandingColorsChanged = ko.pureComputed =>
         if !@hasBranding()
            return false
         if @editingCompany().branding().primaryColor() != @company().branding().primaryColor()
            return true
         if @editingCompany().branding().secondaryColor() != @company().branding().secondaryColor()
            return true
         return false

      @isDefaultBrandingLogo = ko.pureComputed =>
         if !@hasBranding()
            return false
         if @editingCompany().branding() &&
         @editingCompany().branding().logoUrl() == DEFAULT_BRANDING_LOGO_URL
            return true
         return false
         
      @hasChangedBrandingLogo = ko.pureComputed =>
         if !@hasBranding()
            return false
         if @isDefaultBrandingLogo()
            return false
         if @editingCompany().branding().logoUrl() != @company().branding().logoUrl()
            return true
         return false

      @hasBrandingChanged = ko.pureComputed =>
         if !@hasBranding()
            return false
         if @hasChangedBrandingLogo()
            return true
         if @haveBrandingColorsChanged() && !@isDefaultBrandingLogo()
            return true
         return false

      @hasDefaultLogoAndChangedColors = ko.pureComputed =>
         if !@hasBranding()
            return false
         if @isDefaultBrandingLogo() && @haveBrandingColorsChanged()
            return true
         return false

      @logoValidationMessage = ko.pureComputed =>
         if @hasDefaultLogoAndChangedColors()
            return "Must change logo to save color changes."
         return ""

      @phoneIsValid = ko.observable(false)
      @canSavePhone = ko.pureComputed =>
         if @company()?.contactPhone() == null || @company()?.contactPhone() == ""
            return @editingCompany().contactPhone() != null && @editingCompany().contactPhone() != "" && @phoneIsValid()
         else
            return @editingCompany()?.contactPhone() != @company()?.contactPhone() && @phoneIsValid()

      @phoneUpdateListener = null;

      @canSaveGeneral = ko.pureComputed =>
         return ((@editingCompany().name() != @company().name()) or
            (@editingCompany().address1() != @company().address1()) or
            (@editingCompany().address2() != @company().address2()) or
            (@editingCompany().cityTown() != @company().cityTown()) or
            (@editingCompany().stateProvince() != @company().stateProvince()) or
            (@editingCompany().zipcode() != @company().zipcode()) or
            (@editingCompany().country() != @company().country()) or
            (@selectedDateFormat()? and (@selectedDateFormat().value() != @company().dateFormatPreference())) or
            (@selectedCurrency()? and (@selectedCurrency().value() != @company().currency())) or
            (@editingCompany().contactName() != @company().contactName()) or
            (@canSavePhone()) or
            (@editingCompany().contactEmail() != @company().contactEmail()) or
            (@editingCompany().tbdWeeks() != @company().tbdWeeks()) or
            (@selectedTimezone() != @editingCompany().timezone()) or
            @hasBrandingChanged()
         )

      @primaryColorSelectorPopupBuilder = =>
         new Popup("Primary Color", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new ColorSelectorPane(@editingCompany().branding().primaryColor)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['settings-pane__color-popup', 'popup--color-selector'])

      @primaryColorSelectorPopupWrapper = {
         popupBuilder: @primaryColorSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @secondaryColorSelectorPopupBuilder = =>
         new Popup("Accent Color", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new ColorSelectorPane(@editingCompany().branding().secondaryColor)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['settings-pane__color-popup', 'popup--color-selector'])

      @secondaryColorSelectorPopupWrapper = {
         popupBuilder: @secondaryColorSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

   clearObservable: (observableToClear) ->
      assertArgs(arguments, Function)
      observableToClear(null)

   formatDisplayPhoneNumber: (number) ->
      n = String(number)
      return "#{n.slice(0,2)}-#{n.slice(2,5)}-#{n.slice(5,8)}-#{n.slice(8,12)}"

   cancelGeneralPanelChanges: ->
      tempBranding = @editingCompany().branding()
      @editingCompany().mapProperties(@company())
      @editingCompany().branding(tempBranding)
      @editingCompany().branding().logoUrl(@company().branding?()?.logoUrl() || DEFAULT_BRANDING_LOGO_URL)
      @editingCompany().branding().primaryColor(@company().branding?()?.primaryColor() || DEFAULT_BRANDING_PRIMARY_COLOR)
      @editingCompany().branding().secondaryColor(@company().branding?()?.secondaryColor() || DEFAULT_BRANDING_SECONDARY_COLOR)

      for option in @dateFormatOptions()
            if option.value() == @company().dateFormatPreference()
               @selectedDateFormat(option)

      for option in @currencyOptions()
         if option.value() == @company().currency()
            @selectedCurrency(option)

   saveGeneralPanelChanges: ->
      data = {}
      data['name'] = @editingCompany().name() if @editingCompany().name() != @company().name()
      data['address_1'] = @editingCompany().address1() if @editingCompany().address1() != @company().address1()
      data['address_2'] = @editingCompany().address2() if @editingCompany().address2() != @company().address2()
      data['city_town'] = @editingCompany().cityTown() if @editingCompany().cityTown() != @company().cityTown()
      data['state_province'] = @editingCompany().stateProvince() if @editingCompany().stateProvince() != @company().stateProvince()
      data['zipcode'] = @editingCompany().zipcode() if @editingCompany().zipcode() != @company().zipcode()
      data['country'] = @editingCompany().country() if @editingCompany().country() != @company().country()
      data['date_format_preference'] = @selectedDateFormat().value() if @selectedDateFormat().value() != @company().dateFormatPreference()
      data['currency'] = @selectedCurrency().value() if @selectedCurrency().value() != @company().currency()
      data['contact_name'] = @editingCompany().contactName() if @editingCompany().contactName() != @company().contactName()
      data['contact_phone'] = @editingCompany().contactPhone() if @editingCompany().contactPhone() != @company().contactPhone()
      data['contact_phone'] = null if data['contact_phone'] == ""
      data['contact_email'] = @editingCompany().contactEmail() if @editingCompany().contactEmail() != @company().contactEmail()
      data['timezone'] = @selectedTimezone() if @selectedTimezone() != @editingCompany()
      if @editingCompany().tbdWeeks() != @company().tbdWeeks()
         data['tbd_weeks'] = if Number(@editingCompany().tbdWeeks()) > 0 then Number(@editingCompany().tbdWeeks()) else 1
      if @hasBrandingChanged()
         data['branding'] = {
            'logo_url': @editingCompany().branding().logoUrl(),
            'primary_color': @editingCompany().branding().primaryColor(),
            'secondary_color': @editingCompany().branding().secondaryColor()
         }
      return unless Object.keys(data).length != 0

      try
         updateResponse = await SettingsStore.updateCompany(data).payload
         company = new Company(updateResponse.data)
         @ensureBrandingDefined_(company)
         @phoneUpdateListener.dispose()
         @editingCompany(company.clone(Company))
         @phoneUpdateListener = @editingCompany().contactPhone.subscribe((newVal) =>
            @phoneIsValid(newVal == null || newVal == "" ? true : false)
         )
         @company(company)
         # # Update in-app format preferences
         defaultStore.setDateFormat(company.dateFormatPreference())
      catch err
         console.log "Error", err

   loadCompany: ->
      try
         result = await CompanyStore.getCompany().payload
         company = new Company(result.data)
         @ensureBrandingDefined_(company)
         @editingCompany(company.clone(Company))
         @phoneUpdateListener = @editingCompany().contactPhone.subscribe((newVal) =>
            @phoneIsValid(newVal == null || newVal == "" ? true : false)
         )
         @selectedTimezone(company.timezone())
         @company(company)
         # Compute any opther vals.
         for option in @dateFormatOptions()
            if option.value() == company.dateFormatPreference()
               @selectedDateFormat(option)

         for option in @currencyOptions()
            if option.value() == company.currency()
               @selectedCurrency(option)
      catch err
         console.log "Error: ", err

   ensureBrandingDefined_: (company) =>
      if !(company && company.branding && company.branding())
         company.branding = ko.observable({})
         company.branding().logoUrl = ko.observable(DEFAULT_BRANDING_LOGO_URL)
         company.branding().primaryColor = ko.observable(DEFAULT_BRANDING_PRIMARY_COLOR)
         company.branding().secondaryColor = ko.observable(DEFAULT_BRANDING_SECONDARY_COLOR)
