import template from "./assignment-alerts.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import { Guid as GUID } from "@/lib/utils/guid"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { SettingsStore } from "@/stores/settings-store.core"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { defaultStore } from "@/stores/default-store"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane"

### Models ###
import { Company } from "@/models/company"
import { PermissionLevel } from "@/models/permission-level"
import { CannedMessage } from "@/models/canned-message"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import ko from "knockout"

export class AssignmentAlertsViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - Assignment Alerts")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageAlertsSettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS_SETTINGS)

      @selectedTab = ko.observable("default-content")

      @allowAlertReplies = ko.observable(false)
      @companyConfig = ko.observable(null)

      @responseCountOptions = ko.observableArray([
         new SegmentedControllerItem("1", 1)
         new SegmentedControllerItem("2", 2)
         new SegmentedControllerItem("3", 3)
      ])
      @selectedResponseCount = ko.observable(@responseCountOptions()[1])
      @selectedResponseCount.subscribe (newVal) =>
         if newVal.value() < 3
            @responseThree(null)
            @colorThree(null)
         if newVal.value() < 2
            @responseTwo(null)
            @colorTwo(null)

      @responseOne = ko.observable()
      @colorOne = ko.observable()
      @descriptorOne = ko.observable()

      @responseTwo = ko.observable()
      @colorTwo = ko.observable()
      @descriptorTwo = ko.observable()

      @responseThree = ko.observable()
      @colorThree = ko.observable()
      @descriptorThree = ko.observable()

      @colorOptions = ko.observableArray()

      # Default Alert Options.
      @alertContentSet = ko.observable(false)
      @workingTemplate = ko.observable()
      @originalTemplate = ko.observable()
      # TODO: This is lame. Improve.
      @hasTemplateChanges = ko.observable(false)

      @subjectHtmlContentDisposableBlock = false
      @subjectHtmlContent = ko.observable('')
      @subjectHtmlContent.subscribe =>
         return @subjectHtmlContentDisposableBlock = false if @subjectHtmlContentDisposableBlock
         @hasTemplateChanges(true)

      @subjectContainerElement = ko.observable(null)
      @baseSubjectHtmlContent = AssignmentAlertsViewModel.BASE_SUBJECT_CONTENT

      @messageHtmlContentDisposableBlock = false
      @messageHtmlContent = ko.observable('')
      @messageHtmlContent.subscribe =>
         return @messageHtmlContentDisposableBlock = false if @messageHtmlContentDisposableBlock
         @hasTemplateChanges(true)
      @messageContainerElement = ko.observable(null)
      @baseMessageHtmlContent = AssignmentAlertsViewModel.BASE_BODY_CONTENT

      @tokenQuery = ko.observable(null)
      @tokenOptions = ko.observableArray(AssignmentAlertsViewModel.BaseAssignmentTokens)

      # Selection Tracking
      @parentNode = ko.observable(null)
      @range = ko.observable(null)

      @canSaveResponse = ko.pureComputed =>
         return true if !@allowAlertReplies() and @companyConfig()?.responseOne()?
         return false unless @allowAlertReplies()
         return (
            (@responseOne() != @companyConfig()?.responseOne()) or
            (@colorOne() != @companyConfig()?.colorOne()) or
            (@descriptorOne() != @companyConfig()?.descriptorOne()) or
            (@responseTwo() != @companyConfig()?.responseTwo()) or
            (@colorTwo() != @companyConfig()?.colorTwo()) or
            (@descriptorTwo() != @companyConfig()?.descriptorTwo()) or
            (@responseThree() != @companyConfig()?.responseThree()) or
            (@colorThree() != @companyConfig()?.colorThree()) or
            (@descriptorThree() != @companyConfig()?.descriptorThree())
         )

      # TODO: Clean this up.
      @colorOneSelectorPopupBuilder = =>
         return unless @canManageAlertsSettings
         new Popup("In-App Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@colorOne)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['assignment-alert-settings__color-popup', 'popup--color-selector'])

      @colorOneSelectorPopupWrapper = {
         popupBuilder: @colorOneSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @colorTwoSelectorPopupBuilder = =>
         return unless @canManageAlertsSettings
         new Popup("In-App Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@colorTwo)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['assignment-alert-settings__color-popup', 'popup--color-selector'])

      @colorTwoSelectorPopupWrapper = {
         popupBuilder: @colorTwoSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @colorThreeSelectorPopupBuilder = =>
         return unless @canManageAlertsSettings
         new Popup("In-App Color", Popup.FrameType.RIGHT, Popup.ArrowLocation.LEFT_CENTER,
            [new ColorSelectorPane(@colorThree)],
            ['settings-pane__color-btn', 'settings-pane__color-btn__color'], ['assignment-alert-settings__color-popup', 'popup--color-selector'])

      @colorThreeSelectorPopupWrapper = {
         popupBuilder: @colorThreeSelectorPopupBuilder
         options: {triggerClasses: ['settings-pane__color-btn__color']}
      }

      @loadData()

   clearDescriptorOne: =>
      @descriptorOne(null)

   clearDescriptorTwo: =>
      @descriptorTwo(null)

   clearDescriptorThree: =>
      @descriptorThree(null)

   selectTab: (tabName) =>
      @selectedTab(tabName)

   addToken: (token) =>
      return unless @range()?
      newNode = document.createElement("span")
      newNode.contentEditable = false
      if token.subject1Type == "projects"
         token['subject1Id'] = @ownerId
      newNode.dataset.token = JSON.stringify(token)
      newNode.className = 'lc__canned-token'
      newNode.innerHTML = "{ #{token.name} }"
      @range().insertNode(newNode)
      @range().insertNode(document.createElement("span"))

      newRange = document.createRange()
      newRange.setStart(@parentNode().lastChild, @parentNode().lastChild.childNodes.length)
      newRange.setEnd(@parentNode(), @parentNode().childNodes.length)
      @range(newRange)

      # Ensure all content is up to date even if no additional character was hit.
      @subjectHtmlContent(@subjectContainerElement().innerHTML)
      @messageHtmlContent(@messageContainerElement().innerHTML)

   # TODO: Pull these into shared utility.
   decodeTokenToHtml_: (hit, tokens) ->
      tokenId = hit.match(/\{(.*?)}/)[1]
      matchedToken = null
      for token in tokens
         if token.id == tokenId
            matchedToken = token
            break

      tmpNode = document.createElement("span")
      newNode = document.createElement("span")
      newNode.contentEditable = false
      newNode.dataset.token = JSON.stringify(matchedToken)
      newNode.className = 'lc__canned-token'
      newNode.innerHTML = "{ #{matchedToken.name} }"
      tmpNode.appendChild(newNode)
      return tmpNode.innerHTML

   processLoadedMessage: =>
      if @workingTemplate().subject()?
         tokenedSubject = @workingTemplate().subject().replace /LC-DT<{(.*?)}>/g, (hit) =>
            return @decodeTokenToHtml_(hit, @workingTemplate().dynamicTokens())
         @subjectHtmlContentDisposableBlock = true
         @subjectHtmlContent(tokenedSubject)

      tokenedMessage = @workingTemplate().content().replace /LC-DT<{(.*?)}>/g, (hit) =>
         return @decodeTokenToHtml_(hit, @workingTemplate().dynamicTokens())
      chunks = tokenedMessage.split("\n")

      formattedMessage = ""
      for chunk in chunks
         chunk = "<br>" if chunk == ""
         formattedMessage += "<p class='canned-message-pane__subject-content'>#{chunk}</p>"

      @messageHtmlContentDisposableBlock = true
      @messageHtmlContent(formattedMessage)
      @selectedMessageType(@messageTypes()[1]) if @workingTemplate().isGroup()
      @alertContentSet(true)

   cancelResponseChanges: ->
      @allowAlertReplies(@companyConfig()?)
      @responseOne(@companyConfig().responseOne())
      @colorOne(@companyConfig().colorOne() or null)
      @descriptorOne(@companyConfig().descriptorOne() or null)
      @responseTwo(@companyConfig().responseTwo() or null)
      @colorTwo(@companyConfig().colorTwo() or null)
      @descriptorTwo(@companyConfig().descriptorTwo() or null)
      @responseThree(@companyConfig().responseThree() or null)
      @colorThree(@companyConfig().colorThree() or null)
      @descriptorThree(@companyConfig().descriptorThree() or null)

      if @companyConfig().responseOne()? and @companyConfig().responseTwo()? and @companyConfig().responseThree()?
         @selectedResponseCount(@responseCountOptions()[2])
      else if @companyConfig().responseOne()? and @companyConfig().responseTwo()?
         @selectedResponseCount(@responseCountOptions()[1])
      else if @companyConfig().responseOne()?
         @selectedResponseCount(@responseCountOptions()[0])

   saveResponseChanges: ->
      if @allowAlertReplies()
         # TODO: Need error message.
         return unless @responseOne()?
         if @selectedResponseCount().value() > 1
            return unless @responseTwo()?
         if @selectedResponseCount().value() > 2
            return unless @responseThree()?

         config = {
            response_one: @responseOne() or null
            color_one: @colorOne() or null
            descriptor_one: if ValidationUtils.validateInput(@descriptorOne()) then @descriptorOne() else null
            response_two: @responseTwo() or null
            color_two: @colorTwo() or null
            descriptor_two: if ValidationUtils.validateInput(@descriptorTwo()) then @descriptorTwo() else null
            response_three: @responseThree() or null
            color_three: @colorThree() or null
            descriptor_three: if ValidationUtils.validateInput(@descriptorThree()) then @descriptorThree() else null
         }
         @updateAlertConfig config, (err, success) =>
            return console.log "error: ", err if err
            if success
               @responseOne(config.response_one)
               @colorOne(config.color_one or null)
               @descriptorOne(config.descriptor_one or null)
               @responseTwo(config.response_two or null)
               @colorTwo(config.color_two or null)
               @descriptorTwo(config.descriptor_two or null)
               @responseThree(config.response_three or null)
               @colorThree(config.color_three or null)
               @descriptorThree(config.descriptor_three or null)

               @companyConfig(new Company.AlertConfig({
                  response_one: config.response_one
                  color_one: config.color_one or null
                  descriptor_one: config.descriptor_one or null
                  response_two: config.response_two or null
                  color_two: config.color_two or null
                  descriptor_two: config.descriptor_two or null
                  response_three: config.response_three or null
                  color_three: config.color_three or null
                  descriptor_three: config.descriptor_three or null
               }))
      else
         # Disabling
         config = {
            response_one: null
            color_one: null
            descriptor_one: null
            response_two: null
            color_two: null
            descriptor_two: null
            response_three: null
            color_three: null
            descriptor_three: null
         }
         @updateAlertConfig config, (err, success) =>
            return console.log "error: ", err if err
            @companyConfig(null) if success

   cancelTemplateChanges: ->
      @messageHtmlContent('')
      @subjectHtmlContent('')
      @subjectContainerElement().innerHTML = ""
      @messageContainerElement().innerHTML = ""
      @workingTemplate().mapProperties(@originalTemplate())
      @processLoadedMessage()
      @hasTemplateChanges(false)

   saveTemplate: ->
      dynamicTokens = []
      processTokenHit = (hit) ->
         encodedData = hit.match(/data-token=["']({.*?})["']/)
         newNode = document.createElement("span")
         # Just the JSON Object.
         newNode.innerHTML = encodedData[1]
         text = newNode.textContent
         processingToken = JSON.parse(text)
         tokenId = GUID()
         processingToken['id'] = tokenId
         dynamicTokens.push(processingToken)
         return " LC-DT<{#{tokenId}}> "

      subjectText = null
      if AssignmentAlertsViewModel.EMPTY_SUBJECT_CONTENT.indexOf(@subjectHtmlContent()) == -1
         rawSubject = @subjectHtmlContent()
         tokenlessSubject = rawSubject.replace /<span(.*?)lc__canned(.*?)<\/span>/g, (hit) ->
            return processTokenHit(hit)

         subjectNode = document.createElement("span")
         subjectNode.innerHTML = tokenlessSubject
         subjectText = subjectNode.textContent
         subjectText = subjectText.replace(/\n/g, "")
         subjectText = subjectText.replace(/\s+/g, ' ')

      # Get the tokens data out
      rawContent = @messageHtmlContent()
      tokenlessContent = rawContent.replace /<span(.*?)lc__canned(.*?)<\/span>/g, (hit) ->
         return processTokenHit(hit)

      formattedContent = tokenlessContent.replace(/<p><br><\/p>/g, "\n")
      formattedContent = formattedContent.replace(/<\/p><p/g, "</p>\n<p")
      totalNode = document.createElement("span")
      totalNode.innerHTML = formattedContent
      ### eslint-disable no-control-regex ###
      totalText = totalNode.textContent.replace(/[^\x00-\x7F]/g, "")
      ### eslint-enable no-control-regex ###
      # Doing this because coffeescript was erroring on literal syntax.
      spaceRegex = new RegExp(' +', 'g')
      totalText = totalText.replace(spaceRegex, ' ')

      messageData = {
         subject: subjectText
         content: totalText
         include_signature: false
         is_group: false
         dynamic_tokens: dynamicTokens.map (item) ->
            item['subject_1_type'] = item.subject1Type
            item['subject_1_key'] = item.subject1Key
            if item.subject1Id?
               item['subject_1_id'] = item.subject1Id
            else
               item['subject_1_id'] = null

            if item.subject2Type?
               item['subject_2_type'] = item.subject2Type
               item['subject_2_key'] = item.subject2Key
            if item.subject3Type?
               item['subject_3_type'] = item.subject3Type
               item['subject_3_key'] = item.subject3Key

            delete item.subject1Type
            delete item.subject1Key
            delete item.subject1Id
            delete item.subject2Type
            delete item.subject2Key
            delete item.subject3Type
            delete item.subject3Key
            return item
         type: "default"
      }

      @updateAlertConfig {default_template: messageData}, (err, success) =>
         return console.log "error: ", err if err
         console.log "success: ", success
         if success
            @workingTemplate().subject(@subjectHtmlContent())
            @workingTemplate().content(@messageHtmlContent())
            @originalTemplate().mapProperties(@workingTemplate())
            @hasTemplateChanges(false)

   updateAlertConfig: (payload, callback) ->
      try
         await SettingsStore.updateAlertConfig(payload).payload
         callback(null, true)
      catch err
         callback(err)

   getCompanyAlertConfig: (callback) ->
      try
         result = await SettingsStore.getAlertConfig().payload
         if result.data?
            callback(null, new Company.AlertConfig(result.data))
         else
            callback(null,  null)

      catch err
         callback(err)

   loadData: () ->
      @getCompanyAlertConfig (err, config) =>
         return console.log "error: ", err if err
         @companyConfig(config) if config?
         @allowAlertReplies(config?.responseOne()?)
         if config?
            if config.defaultTemplate()?
               @workingTemplate(config.defaultTemplate())
               @originalTemplate(new CannedMessage({}, true).mapProperties(config.defaultTemplate()))
            else
               @workingTemplate(new CannedMessage(AssignmentAlertsViewModel.DefaultAlertTemplate))
               @originalTemplate(new CannedMessage(AssignmentAlertsViewModel.DefaultAlertTemplate))

            # Give the DOM a chance to update before pushing.
            setTimeout => 
               @processLoadedMessage()
            , 0

            @responseOne(config.responseOne() or null)
            @colorOne(config.colorOne() or null)
            @descriptorOne(config.descriptorOne() or null)
            @responseTwo(config.responseTwo() or null)
            @colorTwo(config.colorTwo() or null)
            @descriptorTwo(config.descriptorTwo() or null)
            @responseThree(config.responseThree() or null)
            @colorThree(config.colorThree() or null)
            @descriptorThree(config.descriptorThree() or null)

            if config.responseOne()? and config.responseTwo()? and config.responseThree()?
               @selectedResponseCount(@responseCountOptions()[2])
            else if config.responseOne()? and config.responseTwo()?
               @selectedResponseCount(@responseCountOptions()[1])
            else if config.responseOne()?
               @selectedResponseCount(@responseCountOptions()[0])
         else
            @workingTemplate(new CannedMessage(AssignmentAlertsViewModel.DefaultAlertTemplate))
            @originalTemplate(new CannedMessage(AssignmentAlertsViewModel.DefaultAlertTemplate))
            # Give the DOM a chance to update before pushing.
            setTimeout => 
               @processLoadedMessage()
            , 0

      defaultStore.getResourceColorStrings (err, colors) =>
         @colorOptions(colors)

AssignmentAlertsViewModel.BASE_SUBJECT_CONTENT = "<p class='canned-message-pane__subject-content'>&#8203;</p>"
AssignmentAlertsViewModel.EMPTY_SUBJECT_CONTENT = [
   '<p class="canned-message-pane__subject-content">​</p>'
   '<p class="canned-message-pane__subject-content"><br></p>'
   AssignmentAlertsViewModel.BASE_SUBJECT_CONTENT
]
AssignmentAlertsViewModel.BASE_BODY_CONTENT = '<p class="canned-message-pane__textarea-content">&#8203;</p>'
AssignmentAlertsViewModel.EMPTY_BODY_CONTENT = [
   '<p class="canned-message-pane__textarea-content">​</p>'
   '<p class="canned-message-pane__textarea-content"><br></p>'
   AssignmentAlertsViewModel.BASE_BODY_CONTENT
]

# These tokens aren't the same as regular since they are detached from owner.
AssignmentAlertsViewModel.BaseAssignmentTokens = [
   {
      name: "Assignee's Name"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "name"
      subject2Type: "people"
   }
   {
      name: "Assignee's Email"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "email"
      subject2Type: "people"
   }
   {
      name: "Assignee's Phone"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "phone"
      subject2Type: "people"
   }
   {
      name: "Assignee's Job Title"
      subject1Key: "resource_id"
      subject1Type: "assignments"
      subject2Key: "position_id"
      subject2Type: "people"
      subject3Key: "name"
      subject3Type: "positions"
   }
   {
      name: "Assignment Start Date"
      subject1Key: "start_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Date"
      subject1Key: "end_day"
      subject1Type: "assignments"
   }
   {
      name: "Assignment Start Time"
      subject1Key: "start_time"
      subject1Type: "assignments"
   }
   {
      name: "Assignment End Time"
      subject1Key: "end_time"
      subject1Type: "assignments"
   }
   {
      name: "Assignment Work Days"
      subject1Key: "work_days"
      subject1Type: "assignments"
   }
   {
      name: "Project Name"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "name"
      subject2Type: "projects"
   }
   {
      name: "Project Address"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "address_1"
      subject2Type: "projects"
   }
   {
      name: "Project Address 2"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "address_2"
      subject2Type: "projects"
   }
   {
      name: "Project City"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "city_town"
      subject2Type: "projects"
   }
   {
      name: "Project State"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "state_province"
      subject2Type: "projects"
   }
   {
      name: "Project Postal Code"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "zipcode"
      subject2Type: "projects"
   }
   {
      name: "Project Country"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "country"
      subject2Type: "projects"
   }
   {
      name: "Project Number"
      subject1Key: "project_id"
      subject1Type: "assignments"
      subject2Key: "job_number"
      subject2Type: "projects"
   }
]

AssignmentAlertsViewModel.BaseAssignmentTokens.push({
   name: "Assignment Status"
   subject1Key: "status_id"
   subject1Type: "assignments"
   subject2Key: "name"
   subject2Type: "statuses"
})

AssignmentAlertsViewModel.BaseAssignmentTokens.push({
      name: "Project Role Name and Job Title"
      subject1Type: "projects",
      subject1Key: "role_name",
      subject2Type: null
})

AssignmentAlertsViewModel.BaseAssignmentTokens.push({
      name: "Project Role Name, Job Title, and Email"
      subject1Type: "projects",
      subject1Key: "role_name_email",
      subject2Type: null
})

AssignmentAlertsViewModel.BaseAssignmentTokens.push({
      name: "Project Role Name, Job Title, and Phone"
      subject1Type: "projects",
      subject1Key: "role_name_phone",
      subject2Type: null
})
AssignmentAlertsViewModel.BaseAssignmentTokens.push({
      name: "Project Role Name, Job Title, Email, and Phone"
      subject1Type: "projects",
      subject1Key: "role_name_email_phone",
      subject2Type: null
})

AssignmentAlertsViewModel.DefaultAlertTemplate = {
   id: "one-off"
   owner_id: "not-set"
   subject: "Assignment Alert for LC-DT<{1}>",
   content: "Assignment Info:\nLC-DT<{2}> - LC-DT<{3}>\nLC-DT<{4}> - LC-DT<{5}>\nLC-DT<{6}>\nProject #: LC-DT<{7}>",
   dynamic_tokens: [
      {
         id: "1",
         name: "Assignee's Name",
         subject_1_id: null,
         subject_1_key: "resource_id",
         subject_1_type: "assignments",
         subject_2_key: "name",
         subject_2_type: "people"
      },
      {
         id: "2",
         name: "Start Date",
         subject_1_id: null,
         subject_1_key: "start_day",
         subject_1_type: "assignments",
      },
      {
         id: "3",
         name: "End Date",
         subject_1_id: null,
         subject_1_key: "end_day",
         subject_1_type: "assignments",
      },
      {
         id: "4",
         name: "Daily Start Time",
         subject_1_id: null,
         subject_1_key: "start_time",
         subject_1_type: "assignments",
      },
      {
         id: "5",
         name: "Daily End Time",
         subject_1_id: null,
         subject_1_key: "end_time",
         subject_1_type: "assignments",
      },
      {
         id: "6",
         name: "Project Name",
         subject_1_id: null,
         subject_1_key: "project_id",
         subject_1_type: "assignments",
         subject_2_id: null,
         subject_2_key: "name",
         subject_2_type: "projects",
      },
      {
         id: "7",
         name: "Project Number",
         subject_1_id: null,
         subject_1_key: "project_id",
         subject_1_type: "assignments",
         subject_2_id: null,
         subject_2_key: "job_number",
         subject_2_type: "projects",
      }
   ],
   include_signature: true,
   is_group: false,
   is_private: true,
   type: "one-off-alert"

}
