import React from "react";
import { createRoot } from "react-dom/client";
import type { OauthAppsPropTypes } from "@/react/prop-types";
import { I18nProvider } from "@procore/core-react";
import OauthAppsTable from "./components/oauth-apps-table";

export default (props: OauthAppsPropTypes) => {
   const container = document.getElementById("oauth-apps-react-mount");
   const root = createRoot(container!);
   root.render(
      <I18nProvider locale={window.I18n.locale as any} translations={window.I18n.translations}>
         <OauthAppsTable {...props} />
      </I18nProvider>,
   );
};
