import template from "./assignment-statuses.pug";
import "./assignment-statuses.styl";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import * as ko from "knockout";

// Auth, Real-Time & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { StatusStore } from "@/stores/status-store.core";
import { SettingsStore } from "@/stores/settings-store.core";

// Modals
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { CreateEditStatusPane } from "@/views/settings/modals/create-edit-status-pane";
import { ConfirmDeleteStatusPaneViewModel as ConfirmDeleteStatusPane } from "@/views/settings/modals/confirm-delete-status-pane";

// Models
import type { StatusData } from "@/models/status";
import { Status } from "@/models/status";
import { PermissionLevel } from "@/models/permission-level";

// Utils
import { ValidationUtils } from "@/lib/utils/validation";

// Api
import type { UpdateStatusPayload } from "@laborchart-modules/lc-core-api/dist/api/statuses/update-status";

export class AssignmentStatusesViewModel extends PageContentViewModel {
   private readonly canManageStatusSettings: boolean;
   private readonly searchQuery = ko.observable<string | null>();
   private readonly statuses: ko.ObservableArray<Status>;
   private readonly displayStatuses: ko.PureComputed<Status[]>;
   private readonly orderChangeMade = ko.observable(false);
   private readonly searchBarActive: ko.PureComputed<boolean>;

   constructor() {
      super(template(), "Settings - Assignment / Request Statuses");

      this.canManageStatusSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_STATUS_SETTINGS,
      );
      this.searchQuery = ko.observable();
      this.statuses = ko.observableArray();
      this.displayStatuses = ko.pureComputed(() => {
         if (!ValidationUtils.validateInput(this.searchQuery())) return this.statuses();

         const filteredStatuses = [];
         for (const status of this.statuses()) {
            if (status.name().toLowerCase().indexOf(this.searchQuery()!.toLowerCase()) != -1)
               filteredStatuses.push(status);
         }
         return filteredStatuses;
      });
      this.searchBarActive = ko.pureComputed(() =>
         ValidationUtils.validateInput(ko.unwrap(this.searchQuery)),
      );

      this.loadStatusData();
   }

   private setSearchQuery = (query: ko.Observable<string>) => {
      this.searchQuery(query());
   };

   private showNewStatusModal() {
      const pane1 = new CreateEditStatusPane();
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<StatusData>(
         modal,
         null,
         { class: "create-edit-tag-modal" },
         async (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") return;
            const statusData = observableData.data;
            try {
               await StatusStore.createStatus(statusData).payload;
               this.loadStatusData();
            } catch (err) {
               console.log("error: ", err);
            }
         },
      );
   }

   private editStatus = (originalStatus: Status) => {
      const pane1 = new CreateEditStatusPane(originalStatus.clone(Status));
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<StatusData>(
         modal,
         null,
         { class: "create-edit-tag-modal" },
         async (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") return;
            const status = observableData.data;
            const update: UpdateStatusPayload = {};

            if (status.name != originalStatus.name()) update["name"] = status.name;
            if (status.abbreviation != originalStatus.abbreviation())
               update["abbreviation"] = status.abbreviation;
            if (status.color != originalStatus.color()) update["color"] = status.color;

            if (Object.keys(update).length === 0) return;

            try {
               const result = await StatusStore.updateStatus(originalStatus.id, update).payload;
               return originalStatus.mapProperties(new Status(result.data));
            } catch (err) {
               return console.log("error: ", err);
            }
         },
      );
   };

   private deleteStatus = (status: Status) => {
      const pane1 = new ConfirmDeleteStatusPane(status.name());
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal(
         modal,
         null,
         { class: "confirm-delete-status-modal" },
         async (modal, modalStatus) => {
            if (modalStatus === "cancelled") return;
            try {
               await StatusStore.deleteStatus(status.id).payload;
               this.statuses.remove(status);
            } catch (err) {
               console.log("error: ", err);
            }
         },
      );
   };

   private async loadStatusData() {
      this.statuses([]);

      try {
         const stream = await SettingsStore.findStatusesStream({}).stream;
         for await (const row of stream) this.statuses.push(new Status(row));
      } catch (err) {
         console.log("error: ", err);
      }
   }

   private statusDrop = (element: HTMLElement) => {
      const elementData = ko.dataFor<Status>(element);
      let loopingEl: HTMLElement | null = element;
      let i = 0;
      while ((loopingEl = loopingEl.previousSibling as HTMLElement) != null) {
         // Filter out virtual elements
         if (loopingEl.innerHTML == null) {
            continue;
         }
         i++;
      }
      const previousSequence = elementData.sequence();
      elementData.sequence(i);
      this.maybeUpdateStatusSequences(elementData, previousSequence, () =>
         this.orderChangeMade(true),
      );
   };

   private maybeUpdateStatusSequences(
      updatedStatus: Status,
      previousSequence: number,
      next?: () => void,
   ) {
      this.statuses().forEach((status, index) => {
         if (status.id === updatedStatus.id) return;

         if (updatedStatus.sequence() - previousSequence > 0) {
            if (index > previousSequence && index <= updatedStatus.sequence()) {
               status.sequence(index - 1);
            }
         } else {
            if (index >= updatedStatus.sequence() && index < previousSequence) {
               status.sequence(index + 1);
            }
         }
      });

      this.statuses(this.statuses().sort((a, b) => a.sequence() - b.sequence()));

      if (next != null) {
         next();
      }
   }

   private async saveStatusOrder() {
      const orderData: { [id: string]: number } = {};
      for (const status of this.statuses()) {
         orderData[status.id] = status.sequence();
      }

      const orderDataArray: Array<{ id: string; sequence: number }> = [];
      Object.keys(orderData).forEach((id) =>
         orderDataArray.push({
            id: id,
            sequence: orderData[id],
         }),
      );
      orderDataArray.sort((a, b) => a.sequence - b.sequence);
      const orderDataCore = orderDataArray.reduce<{ [sequence: number]: string }>(
         (acc, status, index) => {
            acc[index] = status.id;
            return acc;
         },
         {},
      );

      try {
         await StatusStore.reorderStatuses(orderDataCore).stream;
         this.orderChangeMade(false);
      } catch (err) {
         if (err != null) return console.log("error: ", err);
      }
   }

   private undoStatusOrder() {
      this.orderChangeMade(false);
      this.loadStatusData();
   }
}
