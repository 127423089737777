import "./permissions.styl";
import template from "./permissions.pug";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import type { Observable } from "knockout";
import { observable, observableArray, pureComputed, unwrap } from "knockout";

/* Auth, Real-Time, & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { SettingsStore } from "@/stores/settings-store.core";
import { PermissionStore } from "@/stores/permission-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";

/* Modals */
import { Modal } from "@/lib/components/modals/modal";
import type { ModalExitStatus } from "@/lib/managers/modal-manager";
import { modalManager as legacyModalManager } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { FyiInfoPaneViewModel as FyiInfoPane } from "@/lib/components/modals/fyi-info-pane";
import { ConfirmActionPaneViewModel as ConfirmActionPane } from "@/lib/components/modals/confirm-action-pane";
import { PermissionUserListPane } from "@/lib/components/modals/permission-user-list-pane";
import {
   PermissionLevelModal,
   PermissionLevelModalType,
} from "@/lib/components/modals/permission-level-modal/permission-level-modal";

/* Models */
import { PermissionLevel } from "@/models/permission-level";
import { CustomField } from "@/models/custom-field";

/* UI Assets */
import { MultiDropDownItem } from "@/lib/components/drop-downs/multi-drop-down";

/* Utils */
import { ValidationUtils } from "@/lib/utils/validation";
import type { Callback } from "@/lib/type-utils";

/* Modules */
import type { SerializedPermissionLevel } from "@laborchart-modules/common/dist/rethink/serializers/permission-level-serializer";
import type { UpdateSensitiveFieldsPayload } from "@laborchart-modules/lc-core-api/dist/api/company/update-sensitive-fields";

export class PermissionsViewModel extends PageContentViewModel {
   // Member variable that can be used in the pug template.
   private readonly canManagePermissionSettings: boolean;
   private readonly searchQuery = observable<string | null>(null);
   private readonly permissions = observableArray<PermissionLevel>();
   private readonly displayPermissions = pureComputed(() => {
      if (!ValidationUtils.validateInput(this.searchQuery())) {
         return this.permissions().sort((a, b) => b.enabledCount() - a.enabledCount());
      }

      const filteredPermissions = [];
      for (const permission of this.permissions()) {
         if (permission.name().toLowerCase().indexOf(this.searchQuery()!.toLowerCase()) !== -1) {
            filteredPermissions.push(permission);
         }
      }
      return filteredPermissions.sort((a, b) => b.enabledCount() - a.enabledCount());
   });
   private readonly peopleFieldOptions = observableArray([
      new MultiDropDownItem("Gender", "is_male", false),
      new MultiDropDownItem("Email", "email", false),
      new MultiDropDownItem("Phone", "phone", false),
      new MultiDropDownItem("Address 1", "address_1", false),
      new MultiDropDownItem("Address 2", "address_2", false),
      new MultiDropDownItem("City/Town", "city_town", false),
      new MultiDropDownItem("State/Province", "state_province", false),
      new MultiDropDownItem("Country", "country", false),
      new MultiDropDownItem("Date of Hire", "hired_date", false),
      new MultiDropDownItem("Date of Birth", "dob", false),
      new MultiDropDownItem("Emergency Contact Name", "emergency_contact_name", false),
      new MultiDropDownItem("Emergency Contact Email", "emergency_contact_email", false),
      new MultiDropDownItem("Emergency Contact Phone", "emergency_contact_number", false),
      new MultiDropDownItem("Emergency Contact Relation", "emergency_contact_relation", false),
   ]);

   private readonly selectedPeopleFieldOptions = observableArray<MultiDropDownItem<string>>();
   private readonly peopleFieldChips = observableArray<MultiDropDownItem<string>>();

   private readonly projectFieldOptions = observableArray([
      new MultiDropDownItem("Address 1", "address_1", false),
      new MultiDropDownItem("Address 2", "address_2", false),
      new MultiDropDownItem("City/Town", "city_town", false),
      new MultiDropDownItem("State/Province", "state_province", false),
      new MultiDropDownItem("Zipcode", "zipcode", false),
      new MultiDropDownItem("Country", "country", false),
      new MultiDropDownItem("Percent Complete", "percent_complete", false),
      new MultiDropDownItem("Customer", "customer_name", false),
      new MultiDropDownItem("Project Type", "project_type", false),
   ]);
   private readonly selectedProjectFieldOptions = observableArray<MultiDropDownItem<string>>();
   private readonly projectsFieldChips = observableArray<MultiDropDownItem<string>>();

   private readonly existingPeopleFields = observableArray<string>();
   private readonly existingProjectFields = observableArray<string>();

   private readonly companyFieldsLoaded = observable(false);
   private readonly customFieldsLoaded = observable(false);

   private readonly canSaveSensitiveFields = pureComputed<boolean>(() => {
      if (this.peopleFieldChips().length !== this.existingPeopleFields().length) return true;
      if (this.projectsFieldChips().length !== this.existingProjectFields().length) return true;
      for (const field of this.peopleFieldChips()) {
         if (!this.existingPeopleFields().includes(field.value())) return true;
      }
      for (const field of this.projectsFieldChips()) {
         if (!this.existingProjectFields().includes(field.value())) return true;
      }
      return false;
   });

   constructor() {
      super(template(), "Settings - Permissions");
      this.canManagePermissionSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_PERMISSION_SETTINGS,
      );

      this.loadData();
   }

   private handlePeopleFieldSelected = (item: MultiDropDownItem<string>) => {
      if (item.selected()) this.peopleFieldChips.push(item);
      else this.peopleFieldChips.remove(item);
   };

   private removePeopleFieldChip = (item: MultiDropDownItem<string>) => {
      item.selected(false);
      this.peopleFieldChips.remove(item);
      this.selectedPeopleFieldOptions.remove(item);
   };

   private handleProjectFieldSelected = (item: MultiDropDownItem<string>) => {
      if (item.selected()) this.projectsFieldChips.push(item);
      else this.projectsFieldChips.remove(item);
   };

   private removeProjectFieldChip = (item: MultiDropDownItem<string>) => {
      item.selected(false);
      this.projectsFieldChips.remove(item);
      this.selectedProjectFieldOptions.remove(item);
   };

   private deletePermission(permission: PermissionLevel) {
      try {
         PermissionStore.deletePermissionLevel(permission.id);
      } catch (err) {
         console.log("Error: ", err);
      }
      this.permissions(this.permissions().filter((item) => item.id !== permission.id));
   }

   private showDeleteModal = (permission: PermissionLevel) => {
      if (permission.appliedPeople().length > 0) {
         const message =
            'You can\'t delete a permission level that is applied to people.<br><br> Click the "info" icon next to the permission level to see who currently has this permission and who you would need to remove it from.';
         const pane1 = new FyiInfoPane("Can't Delete", message);
         const modal = new Modal();
         modal.setPanes([pane1]);
         legacyModalManager.showModal(modal, null, {
            class: "tag-settings__confirm-category-disabel-modal",
         });
      } else {
         const message =
            "Are you sure you want to delete this permission level?<br> This can not be undone.";
         const pane1 = new ConfirmActionPane("Delete", message, "Delete Permission");
         const modal = new Modal();
         modal.setPanes([pane1]);
         const callback = (modal: Modal<unknown>, modalStatus: ModalExitStatus) => {
            if (modalStatus === "cancelled") {
               return;
            }
            this.deletePermission(permission);
         };
         legacyModalManager.showModal(
            modal,
            null,
            { class: "tag-settings__confirm-category-disabel-modal" },
            callback,
         );
      }
   };

   private pushNewPermissionLevel = (permissionLevel: SerializedPermissionLevel) => {
      this.permissions.push(new PermissionLevel(permissionLevel));
   };

   private replacePermissionLevel = (permissionLevel: SerializedPermissionLevel) => {
      const oldPermissionIndex = this.permissions().findIndex((p) => p.id == permissionLevel.id);
      const newPermission = new PermissionLevel({
         ...permissionLevel,
         applied_people: this.permissions()[oldPermissionIndex].appliedPeople(),
      });
      this.permissions.splice(oldPermissionIndex, 1, newPermission);
   };

   private showEditModal = (permissionToEdit: PermissionLevel) => {
      const modal = PermissionLevelModal.factory({
         activeEditPermission: this.transformToSerializedPermissionLevel(permissionToEdit),
         modalType: PermissionLevelModalType.EDIT_PERMISSION,
         afterSave: this.replacePermissionLevel,
         requestSize: modalManager.requestSize,
      });
      modalManager.setModal({ modal });
      modalManager.requestSize({ height: 600, width: 1000 });
   };

   private showCreateModal = () => {
      const modal = PermissionLevelModal.factory({
         activeEditPermission: null,
         modalType: PermissionLevelModalType.CREATE_PERMISSION,
         afterSave: this.pushNewPermissionLevel,
         requestSize: modalManager.requestSize,
      });
      modalManager.setModal({ modal });
      modalManager.requestSize({ height: 600, width: 1000 });
   };

   private transformToSerializedPermissionLevel = (
      level: PermissionLevel,
   ): SerializedPermissionLevel => {
      return {
         id: level.id,
         company_id: level.companyId(),
         is_admin: level.isAdmin(),
         name: level.name(),
         ...(unwrap(level.visibleStatusIds!) !== undefined
            ? { visible_status_ids: [...level.visibleStatusIds!()] }
            : {}),
         // Permissions
         access_gantt_page: level.accessGanttPage(),
         access_map_page: level.accessMapPage(),
         access_totals_page: level.accessTotalsPage(),
         allow_exporting_data: level.allowExportingData(),
         can_unlock_users: level.canUnlockUsers(),
         can_view_all_statuses: level.canViewAllStatuses(),
         create_messages: level.createMessages(),
         create_people: level.createPeople(),
         create_project: level.createProject(),
         delete_people: level.deletePeople(),
         delete_project: level.deleteProject(),
         edit_people_attachments: level.editPeopleAttachments(),
         edit_people_details: level.editPeopleDetails(),
         edit_people_notes: level.editPeopleNotes(),
         edit_people_permissions: level.editPeoplePermissions(),
         edit_people_sensitive: level.editPeopleSensitive(),
         edit_people_tags: level.editPeopleTags(),
         edit_people_timeoff: level.editPeopleTimeoff(),
         edit_project_attachments: level.editProjectAttachments(),
         edit_project_categories: level.editProjectCategories(),
         edit_project_custom_alerts: level.editProjectCustomAlerts(),
         edit_project_default_recipients: level.editProjectDefaultRecipients(),
         edit_project_details: level.editProjectDetails(),
         edit_project_notes: level.editProjectNotes(),
         edit_project_roles: level.editProjectRoles(),
         edit_project_sensitive: level.editProjectSensitive(),
         edit_project_tags: level.editProjectTags(),
         edit_project_wage_overrides: level.editProjectWageOverrides(),
         manage_alerts_settings: level.manageAlertsSettings(),
         manage_alerts: level.manageAlerts(),
         manage_assignments: level.manageAssignments(),
         manage_company_settings: level.manageCompanySettings(),
         manage_costing_settings: level.manageCostingSettings(),
         manage_custom_fields_settings: level.manageCustomFieldsSettings(),
         manage_group_settings: level.manageGroupSettings(),
         manage_notifications_settings: level.manageNotificationsSettings(),
         manage_others_requests: level.manageOthersRequests(),
         manage_permission_settings: level.managePermissionSettings(),
         manage_position_settings: level.managePositionSettings(),
         manage_qr_codes_settings: level.manageQrCodesSettings(),
         manage_requests: level.manageRequests(),
         manage_status_settings: level.manageStatusSettings(),
         manage_tags_settings: level.manageTagsSettings(),
         view_alerts_settings: level.viewAlertsSettings(),
         view_alerts: level.viewAlerts(),
         view_assignments: level.viewAssignments(),
         view_company_settings: level.viewCompanySettings(),
         view_costing_settings: level.viewCostingSettings(),
         view_custom_fields_settings: level.viewCustomFieldsSettings(),
         view_group_settings: level.viewGroupSettings(),
         view_notifications_settings: level.viewNotificationsSettings(),
         view_people_activity: level.viewPeopleActivity(),
         view_people_attachments: level.viewPeopleAttachments(),
         view_people_financials: level.viewPeopleFinancials(),
         view_people_notes: level.viewPeopleNotes(),
         view_people_sensitive: level.viewPeopleSensitive(),
         view_people_tags: level.viewPeopleTags(),
         view_people_timeoff: level.viewPeopleTimeoff(),
         view_people: level.viewPeople(),
         view_permission_settings: level.viewPermissionSettings(),
         view_position_settings: level.viewPositionSettings(),
         view_project_activity: level.viewProjectActivity(),
         view_project_attachments: level.viewProjectAttachments(),
         view_project_default_recipients: level.viewProjectDefaultRecipients(),
         view_project_financials: level.viewProjectFinancials(),
         view_project_notes: level.viewProjectNotes(),
         view_project_roles: level.viewProjectRoles(),
         view_project_sensitive: level.viewProjectSensitive(),
         view_project_tags: level.viewProjectTags(),
         view_project_wage_overrides: level.viewProjectWageOverrides(),
         view_project: level.viewProject(),
         view_qr_codes_settings: level.viewQrCodesSettings(),
         view_requests_notes: level.viewRequestsNotes(),
         view_requests: level.viewRequests(),
         view_status_settings: level.viewStatusSettings(),
         view_tags_settings: level.viewTagsSettings(),
         view_unassociated_projects: level.viewUnassociatedProjects!(),
      } as SerializedPermissionLevel; // Remove casting onces flags are completely removed from permission levels
   };

   private setSearchQuery = (query: Observable<string | null>) => {
      this.searchQuery(unwrap(query));
   };

   private showUserListModal(data: PermissionLevel) {
      const pane1 = new PermissionUserListPane(data.appliedPeople());
      const modal = new Modal();
      modal.setPanes([pane1]);
      legacyModalManager.showModal(modal, null, { class: "permission-user-list-modal" });
   }

   private saveSensitiveFields = async () => {
      const data = {
         people_sensitive_fields: this.peopleFieldChips().map((item) => item.value()),
         projects_sensitive_fields: this.projectsFieldChips().map((item) => item.value()),
      };
      await this.updateSensitiveFields(data, (err, success) => {
         if (err) {
            return console.log("Error: ", err);
         }
         if (success) {
            this.existingPeopleFields(this.peopleFieldChips().map((i) => i.value()));
            this.existingProjectFields(this.projectsFieldChips().map((i) => i.value()));
         }
      });
   };

   private loadData = async () => {
      this.getPermissionLevels((err, permissionLevels) => {
         if (err) {
            return console.log("Error: ", err);
         }
         this.permissions(permissionLevels);
      });

      this.getCustomFields(async (err, data) => {
         if (err) {
            return console.log("Error: ", err);
         }
         if (data == null) {
            return console.log("Error loading custom fields.");
         }
         for (const field of data) {
            if (field.onPeople()) {
               this.peopleFieldOptions.push(
                  new MultiDropDownItem(field.name(), field.integrationName(), false),
               );
            }

            if (field.onProjects()) {
               this.projectFieldOptions.push(
                  new MultiDropDownItem(field.name(), field.integrationName(), false),
               );
            }
         }

         this.customFieldsLoaded(true);

         await this.getSensitiveFields((err, data) => {
            if (err) {
               return console.log("Error: ", err);
            }
            if (data == null) {
               return console.log("Error loading sensitive fields.");
            }
            this.existingPeopleFields(data.peopleSensitiveFields);
            this.existingProjectFields(data.projectsSensitiveFields);

            for (const field of this.peopleFieldOptions()) {
               if (this.existingPeopleFields().indexOf(field.value()) !== -1) {
                  field.selected(true);
                  if (this.selectedPeopleFieldOptions().indexOf(field) === -1)
                     this.selectedPeopleFieldOptions.push(field);
                  if (this.peopleFieldChips().indexOf(field) === -1)
                     this.peopleFieldChips.push(field);
               }
            }

            for (const field of this.projectFieldOptions()) {
               if (this.existingProjectFields().indexOf(field.value()) !== -1) {
                  field.selected(true);
                  if (this.selectedProjectFieldOptions().indexOf(field) === -1)
                     this.selectedProjectFieldOptions.push(field);
                  if (this.projectsFieldChips().indexOf(field) === -1)
                     this.projectsFieldChips.push(field);
               }
            }

            this.companyFieldsLoaded(true);
         });
      });
   };

   private async getPermissionLevels(callback: Callback<PermissionLevel[]>) {
      const permissionLevels: PermissionLevel[] = [];
      try {
         const query = {};
         const stream = await PermissionStore.findPermissionLevelsStream(query).stream;
         for await (const row of stream) {
            permissionLevels.push(new PermissionLevel(row));
         }
         callback(null, permissionLevels);
      } catch (err) {
         return callback(err as Error);
      }
   }

   private async getCustomFields(callback: Callback<CustomField[]>) {
      const fields: CustomField[] = [];
      try {
         const query = {};
         const stream = await CustomFieldStore.findCustomFieldsStream(query).stream;
         for await (const row of stream) {
            fields.push(new CustomField(row));
         }
         callback(null, fields);
      } catch (err) {
         return callback(err as Error);
      }
   }

   private async getSensitiveFields(
      callback: Callback<{ peopleSensitiveFields: string[]; projectsSensitiveFields: string[] }>,
   ) {
      try {
         const sensitiveFields = (await SettingsStore.getSensitiveFields().payload).data;
         const formattedSensitiveFields = {
            peopleSensitiveFields: sensitiveFields.people_sensitive_fields,
            projectsSensitiveFields: sensitiveFields.projects_sensitive_fields,
         };
         callback(null, formattedSensitiveFields);
      } catch (err) {
         return callback(err as Error);
      }
   }

   private async updateSensitiveFields(
      data: UpdateSensitiveFieldsPayload,
      callback: Callback<boolean>,
   ) {
      try {
         await SettingsStore.updateSensitiveFields(data).payload;
         callback(null, true);
      } catch (err) {
         callback(err as Error);
      }
   }
}
