import { Modal } from "@/lib/components/modals/modal";
import { modalManager } from "@/lib/managers/modal-manager";
import { Group } from "@/models/group";
import { GroupStore } from "@/stores/group-store.core";
import type { CreateGroupPayload } from "@laborchart-modules/lc-core-api/dist/api/groups/create-group";
import type { UpdateGroupPayload } from "@laborchart-modules/lc-core-api/dist/api/groups/update-group";
import type { Observable, ObservableArray, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import { observableArray } from "knockout";
import { ConfirmDeleteGroupPaneViewModel } from "../../modals/confirm-delete-group-pane";
import { CreateEditGroupPane } from "../../modals/create-edit-group-pane";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import template from "./groups.pug";
import { PermissionLevel } from "@/models/permission-level";
import { authManager } from "@/lib/managers/auth-manager";
import { ValidationUtils } from "@/lib/utils/validation";
import { Format } from "@/lib/utils/format";

class GroupsViewModelCoreState {
   readonly groups: ObservableArray<Group>;
   readonly canManageGroupSettings: boolean;
   readonly searchQuery: Observable<string | null>;
   readonly displayGroups = pureComputed(() => {
      if (ValidationUtils.validateInput(this.searchQuery()) == false) {
         return Format.keyableSort(this.groups(), "name");
      }
      const filteredGroups = [];
      for (const group of this.groups()) {
         if (
            group
               .name()
               .toLowerCase()
               .indexOf((this.searchQuery() ?? "").toLowerCase()) != -1
         ) {
            filteredGroups.push(group);
         }
      }
      return Format.keyableSort(filteredGroups, "name");
   });
   readonly isLoading: Observable<any>;
   readonly nextStartingAfter: Observable<string | null>;
   readonly isLastGroup: PureComputed<boolean>;

   constructor() {
      this.groups = observableArray();
      this.canManageGroupSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_GROUP_SETTINGS,
      );
      this.searchQuery = observable(null);
      this.nextStartingAfter = observable(null);
      this.isLoading = observable(false);
      this.isLastGroup = pureComputed(() => {
         return this.groups().length <= 1;
      });
   }

   setSearchQuery = (query: Observable<string>) => {
      this.searchQuery(query());
   };

   showNewGroupModal = (): void => {
      const pane1 = new CreateEditGroupPane();
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<CreateGroupPayload>(
         modal,
         null,
         { class: "create-edit-group-modal" },
         async (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") return;
            const newGroup = await GroupStore.createGroup(observableData.data).payload;
            this.groups.push(new Group(newGroup.data));
         },
      );
   };

   deleteGroup = (group: Group): void => {
      const pane1 = new ConfirmDeleteGroupPaneViewModel(group);
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<void>(
         modal,
         null,
         { class: "confirm-delete-group-modal" },
         async (modal, modalStatus) => {
            if (modalStatus === "cancelled") return;
            await GroupStore.deleteGroup(group.id).payload;
            this.groups.remove(group);
         },
      );
   };

   async loadGroupsData(): Promise<void> {
      try {
         this.isLoading(true);
         const results = await GroupStore.findGroupsSettingsPaginated({ limit: 20 }).payload;
         const groupResults: Group[] = results.data.map((group) => {
            return new Group(group);
         });
         this.nextStartingAfter(results.pagination.next_starting_after);
         this.groups(groupResults);
      } catch (error) {
         console.log("Error: ", error);
      } finally {
         this.isLoading(false);
      }
   }

   readonly loadMoreGroups = async (pageSize = 20): Promise<void> => {
      if (this.isLoading() || this.nextStartingAfter() == null) {
         return;
      }

      const lastGroup = this.groups().slice(-1);
      if (lastGroup == null) {
         return;
      }

      try {
         this.isLoading(true);
         const results = await GroupStore.findGroupsSettingsPaginated({
            limit: pageSize,
            starting_after: this.nextStartingAfter() ?? undefined,
         }).payload;

         const newGroups = results.data.map((group) => {
            return new Group(group);
         });
         this.nextStartingAfter(results.pagination.next_starting_after);
         this.groups.push(...newGroups);
         setTimeout(this.maybeFillView, 0);
      } catch (error) {
         return console.log("Error: ", error);
      } finally {
         this.isLoading(false);
      }
   };

   readonly maybeFillView = (): void => {
      const CONTENT_CONTAINER = "settings-content__list-container";
      const SCROLLING_CONTENT = "settings-content__item-list";
      const parent = document.getElementsByClassName(CONTENT_CONTAINER)[0];
      const child = document.getElementsByClassName(SCROLLING_CONTENT)[0];
      if (parent && child) {
         const parentHeight = parent.scrollHeight;
         const childHeight = child.scrollHeight;
         if (childHeight <= parentHeight) {
            this.loadMoreGroups();
         }
      }
   };
}

export class GroupsViewModelCore extends PageContentViewModel {
   private readonly state: GroupsViewModelCoreState;
   constructor() {
      super(template(), "Settings - Groups");
      this.state = new GroupsViewModelCoreState();

      this.state.loadGroupsData();
   }

   editGroup(originalGroup: Group): void {
      const pane1 = new CreateEditGroupPane(originalGroup.clone(Group));
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal<UpdateGroupPayload>(
         modal,
         null,
         { class: "create-edit-group-modal" },
         async (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") return;
            const editedGroup = observableData.data;
            const update = {
               ...(editedGroup.name != originalGroup.name() ? { name: editedGroup.name } : {}),
               ...(editedGroup.timezone != originalGroup.timezone()
                  ? { timezone: editedGroup.timezone }
                  : {}),
               ...(editedGroup.color != originalGroup.color() ? { color: editedGroup.color } : {}),
               ...(editedGroup.pic_url != originalGroup.picUrl()
                  ? { pic_url: editedGroup.pic_url }
                  : {}),
               ...(editedGroup.icon_name != originalGroup.iconName()
                  ? { icon_name: editedGroup.icon_name }
                  : {}),
               ...(editedGroup.address_1 != originalGroup.address1()
                  ? { address_1: editedGroup.address_1 }
                  : {}),
               ...(editedGroup.address_2 != originalGroup.address2()
                  ? { address_2: editedGroup.address_2 }
                  : {}),
               ...(editedGroup.city_town != originalGroup.cityTown()
                  ? { city_town: editedGroup.city_town }
                  : {}),
               ...(editedGroup.state_province != originalGroup.stateProvince()
                  ? { state_province: editedGroup.state_province }
                  : {}),
               ...(editedGroup.zipcode != originalGroup.zipcode()
                  ? { zipcode: editedGroup.zipcode }
                  : {}),
               ...(editedGroup.country != originalGroup.country()
                  ? { country: editedGroup.country }
                  : {}),
               ...(editedGroup.contact_name != originalGroup.contactName()
                  ? { contact_name: editedGroup.contact_name }
                  : {}),
               ...(editedGroup.contact_phone != originalGroup.contactPhone()
                  ? { contact_phone: editedGroup.contact_phone }
                  : {}),
               ...(editedGroup.contact_email != originalGroup.contactEmail()
                  ? { contact_email: editedGroup.contact_email }
                  : {}),
            };
            if (Object.keys(update).length === 0) return;
            const updatedGroup = await GroupStore.updateGroup(originalGroup.id, update).payload;
            originalGroup.mapProperties(new Group(updatedGroup.data));
         },
      );
   }
}
