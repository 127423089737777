var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;pug_mixins["icon-carrot"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-carrot\" width=\"14px\" height=\"10px\" viewbox=\"0 0 14 10\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-carrot\"\u003E\u003Cpolygon id=\"icon-carrot__shape\" fill=\"#7C8084\" transform=\"translate(7.000000, 5.000000) scale(1, -1) translate(-7.000000, -5.000000) \" points=\"7 0 14 10 0 10\"\u003E\u003C\u002Fpolygon\u003E\u003Crect id=\"icon-carrot__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"14\" height=\"10\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-download"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-download\" width=\"16px\" height=\"20px\" viewbox=\"0 0 16 20\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-download\"\u003E\u003Crect class=\"icon__bg\" id=\"icon-download__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"16\" height=\"20\"\u003E\u003C\u002Frect\u003E\u003Cpath class=\"icon__shape\" id=\"icon-download__shape\" d=\"M16,7.05882353 L11.4285714,7.05882353 L11.4285714,0 L4.57142857,0 L4.57142857,7.05882353 L0,7.05882353 L8,15.2941176 L16,7.05882353 Z M0,17.6470588 L0,20 L16,20 L16,17.6470588 L0,17.6470588 Z\" fill=\"#7C8084\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};












pug_mixins["icon-gear"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-gear\" width=\"16px\" height=\"16px\" viewbox=\"0 0 16 16\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-gear\"\u003E\u003Cpath class=\"icon__shape\" id=\"icon-gear__shape\" d=\"M14.2107198,7.89371429 C14.2107198,7.57142857 14.1790412,7.25771429 14.1326545,6.94857143 L15.9779381,5.87257143 L13.7151747,1.91371429 L11.9620987,2.98742857 C11.4761703,2.59428571 10.9930703,2.27542857 10.4273794,2.04228571 L10.4273794,0 L5.90185264,0 L5.90185264,2.04228571 C5.33616179,2.27542857 4.67769764,2.59485714 4.1917692,2.98742857 L2.30519021,1.91371429 L0.0203648706,5.87257143 L1.85433461,6.94857143 C1.80794796,7.25771429 1.77117805,7.57142857 1.77117805,7.89371429 C1.77117805,8.21485714 1.80002828,8.52857143 1.84584924,8.83771429 L0,9.91371429 L2.26106633,13.8725714 L4.19063782,12.7988571 C4.67656626,13.1925714 5.33616179,13.5108571 5.90185264,13.744 L5.90185264,16 L10.4273794,16 L10.4273794,13.7445714 C10.9930703,13.5114286 11.4761703,13.1931429 11.9632301,12.7994286 L13.7592985,13.8731429 L16,9.91428571 L14.1439683,8.83828571 C14.1897893,8.52914286 14.2107198,8.21542857 14.2107198,7.89371429 L14.2107198,7.89371429 Z M8,11.2 C6.23232,11.2 4.8,9.76768 4.8,8 C4.8,6.23232 6.23232,4.8 8,4.8 C9.76768,4.8 11.2,6.23232 11.2,8 C11.2,9.76768 9.76704,11.2 8,11.2 L8,11.2 Z\" fill=\"#7C8084\"\u003E\u003C\u002Fpath\u003E\u003Crect class=\"icon__bg\" id=\"icon-gear__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"16\" height=\"16\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_mixins["icon-save"] = pug_interp = function(){
var block = (this && this.block), attributes = (this && this.attributes) || {};
pug_html = pug_html + "\u003C?xml version=\"1.0\" encoding=\"UTF-8\"?\u003E\u003Csvg class=\"icon-save\" width=\"18px\" height=\"18px\" viewbox=\"0 0 18 18\" version=\"1.1\" xmlns=\"http:\u002F\u002Fwww.w3.org\u002F2000\u002Fsvg\" xmlns:xlink=\"http:\u002F\u002Fwww.w3.org\u002F1999\u002Fxlink\"\u003E\u003Cg id=\"Page-1\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"\u003E\u003Cg id=\"icon-save\"\u003E\u003Cpath id=\"icon-save__shape\" d=\"M14,0 L2,0 C0.89,0 0,0.9 0,2 L0,16 C0,17.1 0.89,18 2,18 L16,18 C17.1,18 18,17.1 18,16 L18,4 L14,0 Z M9,16 C7.34,16 6,14.66 6,13 C6,11.34 7.34,10 9,10 C10.66,10 12,11.34 12,13 C12,14.66 10.66,16 9,16 Z M12,6 L2,6 L2,2 L12,2 L12,6 Z\" fill=\"#666A6E\" fill-rule=\"nonzero\"\u003E\u003C\u002Fpath\u003E\u003Crect id=\"icon-save__bg\" fill=\"#D8D8D8\" opacity=\"0\" x=\"0\" y=\"0\" width=\"18\" height=\"18\"\u003E\u003C\u002Frect\u003E\u003C\u002Fg\u003E\u003C\u002Fg\u003E\u003C\u002Fsvg\u003E";
};
pug_html = pug_html + "\u003Cdiv class=\"totals-toolbar\"\u003E\u003Cdiv class=\"totals-toolbar__text-btn totals-toolbar__view-config-btn\" data-bind=\"popup:          totalsConfigPopupWrapper\"\u003E\u003Cspan class=\"totals-toolbar__text-btn__text\"\u003EConfigure\u003C\u002Fspan\u003E";
pug_mixins["icon-gear"]();
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Csearch-bar params=\"query: projectQuery, placeholder:'Search Projects',          callback: loadData, showInfoPopup: true,          searchableFields: ['Project Name', 'Project Number']\"\u003E\u003C\u002Fsearch-bar\u003E\u003Cdiv class=\"totals-toolbar--right-btns-container\"\u003E\u003C!-- ko if: allowExportingData --\u003E\u003Ca class=\"totals-toolbar__download-icon\" data-bind=\"clickBubble: false,          click: showExportModal, css: {'totals-toolbar__download-icon--disabled': exportsDisabled}\"\u003E";
pug_mixins["icon-download"]();
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C!-- \u002Fko --\u003E\u003Cdate-selector params=\"selectedDate: viewStartDate\"\u003E\u003C\u002Fdate-selector\u003E\u003Cdiv class=\"totals-toolbar__range-selector\" data-bind=\"popup: rangePopupWrapper\"\u003E\u003Cspan class=\"totals-toolbar__range-title\" data-bind=\"text: rangeTitle\"\u003E\u003C\u002Fspan\u003E\u003Cspan\u003E";
pug_mixins["icon-carrot"]();
pug_html = pug_html + "\u003C\u002Fspan\u003E\u003C\u002Fdiv\u003E\u003Ca class=\"totals-toolbar__btn\" data-bind=\"clickBubble: false,          click: showSaveViewModal\"\u003E";
pug_mixins["icon-save"]();
pug_html = pug_html + "\u003C\u002Fa\u003E\u003C\u002Fdiv\u003E\u003C\u002Fdiv\u003E\u003Cchip-filter class=\"gantt-page__chip-filter\" params=\"labeledOptions: labeledFilterOptions,       defaultChips: defaultChips, chips: filterChips,       mediator: chipFilterMediator,       mainFilterTitle: 'Project Filters'\"\u003E\u003C\u002Fchip-filter\u003E\u003C!-- ko if: noResultsFound --\u003E\u003Cdiv class=\"gantt-no-results-message\"\u003ENo Matching Results\u003C\u002Fdiv\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- ko ifnot: loadingData --\u003E\n\u003C!-- ko ifnot: noResultsFound --\u003E\u003Cdiv id=\"totals-rollup__x-axis\"\u003E\u003C\u002Fdiv\u003E\u003Ch3 id=\"totals-current-count\" data-bind=\"text: currentActiveResourceTitle\"\u003E\u003C\u002Fh3\u003E\u003Cdiv id=\"totals-rollup-chart-wrapper\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv class=\"totals-paging-container\" data-bind=\"visible: showingPager\"\u003E\u003Cpaging-control params=\"pageIndex: pageIndex, totalCount: pagableResultCount,          increment: pageDrawLimit\"\u003E\u003C\u002Fpaging-control\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"totals-breakdown__x-axis\"\u003E\u003C\u002Fdiv\u003E\u003Cdiv id=\"totals-wrapper\" data-bind=\"totalsChart: {data: totalsData,       options: defaultOptions, mediator: totalsChartMediator},       css: {'totals-wrapper--short': showingPager}\"\u003E\u003C\u002Fdiv\u003E\u003C!-- \u002Fko --\u003E\n\u003C!-- \u002Fko --\u003E\u003Cdiv class=\"totals-loading-container\" data-bind=\"visible: loadingData\"\u003E\u003Cdiv class=\"totals-loading-message\" data-bind=\"html: loadingHtmlText\"\u003E\u003C\u002Fdiv\u003E\u003Cprogress-bar params=\"mediator: loadingMediator,          progressPercentage: progressPercentage\"\u003E\u003C\u002Fprogress-bar\u003E\u003C\u002Fdiv\u003E";;return pug_html;};
module.exports = template;