import "./confirm-delete-group-pane.styl"
import template from "./confirm-delete-group-pane.pug"
import { ProjectStore } from "@/stores/project-store.core"
import { PersonStore } from "@/stores/person-store.core"
import { authManager } from "@/lib/managers/auth-manager"
import { PositionStore } from "@/stores/position-store.core"
import { TagStore } from "@/stores/tag-store.core"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

import ko from "knockout"

export class ConfirmDeleteGroupPaneViewModel extends ModalPane
   constructor: (group) ->
      assertArgs(arguments, Object)
      super("Delete Group", "", template())
      @checkingGroupApplication = ko.observable(true)
      @allowDelete = ko.observable(false)
      @usingPeopleNames = ko.observableArray()
      @usingProjectNames = ko.observableArray()
      @usingPositionNames = ko.observableArray()
      @usingTagNames = ko.observableArray()
      @group = group
      @checkGroupApplication()

      @confirmationText = ko.observable()
      @canDelete = ko.pureComputed =>
         return if @checkingGroupApplication()
         return unless @confirmationText()?
         return @confirmationText().toLowerCase() == "yes"

   confirmDelete: ->
      return unless @canDelete()
      modalManager.modalFinished()

   getProjectNames: =>
      projects = await ProjectStore.findProjectsStream({ group_id: @group.id }).stream
      projectNames = []
      for await project from projects
         projectNames.push(project.name)
      return projectNames
   
   getPeopleNames: =>
      isLastNameFirst = authManager.authedUser().preferences().displayLastNamesFirst()
      people = await PersonStore.findPeopleStream({ group_id: @group.id }).stream
      peopleNames = []

      for await person from people
         # Check if Admin
         if person.is_admin || !person.group_ids.includes(@group.id)
            continue
         if isLastNameFirst
            peopleNames.push("#{person.name.last}, #{person.name.first}")
         else
            peopleNames.push("#{person.name.first} #{person.name.last}")
      return peopleNames
   
   getPositionNames: =>
      positions = await PositionStore.findPositionsStream({ group_id: @group.id }).stream
      positionNames = []
      for await position from positions
         if position.group_ids.includes(@group.id)
            positionNames.push(position.name)
      return positionNames
   
   getTagNames: =>
      tags = await TagStore.findTagsStream({ group_id: @group.id }).stream
      tagNames = []
      for await tag from tags
         if tag.group_ids.includes(@group.id)
            tagNames.push(tag.name)
      return tagNames

   checkGroupApplication: =>
      [projectNames, peopleNames, positionNames, tagNames] = await Promise.all([
         @getProjectNames(),
         @getPeopleNames(),
         @getPositionNames(),
         @getTagNames(),
      ])
      if projectNames.length == 0 and peopleNames.length == 0 and positionNames.length == 0 and tagNames.length == 0
         @allowDelete(true)
      else
         @usingProjectNames(projectNames)
         @usingPeopleNames(peopleNames)
         @usingPositionNames(positionNames)
         @usingTagNames(tagNames)
      @checkingGroupApplication(false)
