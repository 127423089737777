import "./confirm-delete-cost-code-pane.styl"
import template from "./confirm-delete-cost-code-pane.pug"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { ProjectStore } from "@/stores/project-store.core"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"
import { modalManager } from "@/lib/managers/modal-manager"

export class ConfirmDeleteCostCodePaneViewModel extends ModalPane
   constructor: (projectId, costCode) ->
      assertArgs(arguments, String, Object)
      super("Delete Category", "", template())
      @checkingExistingAssignments = ko.observable(true)
      @allowDelete = ko.observable(false)
      @existingAssignments = ko.observableArray()
      @projectId = projectId
      @costCode = costCode
      @checkCostCodeForAssignments()

      @confirmationText = ko.observable()
      @canDelete = ko.pureComputed =>
         return if @checkingExistingAssignments()
         return unless @confirmationText()?
         return @confirmationText().toLowerCase() == "yes"

   getAssignmentString: (data) ->
      formattedStartDate = DateUtils.formatDetachedDay(data.startDay, defaultStore.getDateFormat())
      formattedEndDate = DateUtils.formatDetachedDay(data.endDay, defaultStore.getDateFormat())
      return "#{data.resourceName}: #{formattedStartDate} - #{formattedEndDate}"

   confirmDelete: ->
      return unless @canDelete()
      modalManager.modalFinished()

   checkCostCodeForAssignments: =>
      dayFilter = DateUtils.getDetachedDay(new Date())
      try
         existingAssignments = await ProjectStore.checkCostCodeForAssignments(@projectId, @costCode.id, dayFilter).payload
         formattedAssignmentsData = existingAssignments.data.map (item) ->
            return {
               startDay: item.start_day
               endDay: item.end_day
               resourceName: "#{item.resource_name.first} #{item.resource_name.last}"
            }
         
         if formattedAssignmentsData.length == 0
            @allowDelete(true)
         else
            @existingAssignments(formattedAssignmentsData)
         @checkingExistingAssignments(false)
         
      catch err
         return console.log "error: ", err if err
