import { Model } from "@/models/model"
import { Position } from "@/models/position"
import { Tag } from "@/models/tag"
import  { Status } from "@/models/status"
import ko from "knockout"

export class Placeholder extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.cost_code_id, nullable(String))
         assertOfType(data.created_at, Number)
         assertOfType(data.end_day, optional(Number))
         assertOfType(data.id, String)
         assertOfType(data.label_id, nullable(String))
         assertOfType(data.percent_allocated, optional(Number))
         assertOfType(data.project_id, String)
         assertOfType(data.start_day, optional(Number))
         assertOfType(data.status, nullable(Object))
         assertOfType(data.work_days, Object)

         # Optional
         assertOfType(data.creator_name, optional(String))
         assertOfType(data.end_time, optional(Number))
         assertOfType(data.instruction_text, optional(String))
         assertOfType(data.position_id, optional(String))
         assertOfType(data.position, optional(Object))
         assertOfType(data.start_time, optional(Number))
         assertOfType(data.tags, optional(arrayOf(Object)))
         assertOfType(data.work_scope_text, optional(String))

      ###------------------------------------------------
         Model ID & Meta
      ------------------------------------------------###
      @id = data.id
      @createdAt = ko.observable(data.created_at)
      @modifiedAt = ko.observable(data.modified_at)

      ###------------------------------------------------
         Knockout Observables
      ------------------------------------------------###
      @comments = ko.observableArray(data.comments)
      @costCodeId = ko.observable(data.cost_code_id)
      @creatorId = ko.observable(data.creator_id)
      @creatorName = ko.observable(data.creator_name)
      @endDay = ko.observable(data.end_day)
      @endTime = ko.observable(data.end_time)
      @groupIds = ko.observableArray(data.group_ids)
      @instructionText = ko.observable(data.instruction_text or null)
      @isPending = ko.observable(data.is_pending)
      @labelId = ko.observable(data.label_id)
      @pendingId = ko.observable(data.pending_id)
      @percentAllocated = ko.observable(data.percent_allocated)
      @position = ko.observable if data.position? then new Position(data.position, disableTypes) else null
      @positionId = ko.observable(data.position_id or null)
      @projectId = ko.observable(data.project_id)
      @startDay = ko.observable(data.start_day)
      @startTime = ko.observable(data.start_time)
      @status = ko.observable if data.status? then new Status(data.status, disableTypes) else null
      @tags = if !data.tags? then ko.observableArray() else ko.observableArray data.tags.map (tag) ->
         return new Tag(tag, disableTypes)
      @tbdEndDate = ko.observable(data.tbd_end_date)
      @workDays = ko.observable(data.work_days)
      @workScopeText = ko.observable(data.work_scope_text or null)
