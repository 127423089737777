import "./time-off-occurrences-pane.styl"
import template from "./time-off-occurrences-pane.pug"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { TimeOffStore } from "@/stores/time-off-store.core"

### Modals ###
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Modals ###
import { TimeOff } from "@/models/time-off"

export class TimeOffPaneOccurrencesViewModel extends ModalPane
   constructor: (personId, editingTimeOff) ->
      assertArgs(arguments, String, optional(TimeOff))
      super("Time Off Occurrences", "", template())
      @personId = personId
      @editingTimeOff = editingTimeOff or null
      @isActionEnabled(false)
      @selectedPageOption = ko.observable()
      @pageOptions = ko.observableArray()
      @pageCount = Math.ceil(editingTimeOff.instances().length / 14)
      processingCount = 0
      while processingCount < @pageCount
         processingCount++
         @pageOptions.push(processingCount)
      @selectedPageOption(@pageOptions()[0])
      @filteredItems = ko.pureComputed =>
         startIndex = (@selectedPageOption() - 1) * 14
         endIndex = (@selectedPageOption() * 14)
         return @editingTimeOff.instances().slice(startIndex, endIndex)
      @pagingContainerOffset = ko.observable("0px")

   selectPage: (count) =>
      if count > 16 then @pagingContainerOffset("-#{((count - 16) * 28)}px") else @pagingContainerOffset("0px")
      @selectedPageOption(count)

   pageForward: ->
      unless @selectedPageOption() == @pageOptions().length
         newVal = @selectedPageOption() + 1
         if newVal > 16 then @pagingContainerOffset("-#{((newVal - 16) * 28)}px") else @pagingContainerOffset("0px")
         @selectedPageOption(newVal)

   pageback: ->
      newVal = @selectedPageOption() - 1
      if newVal > 16 then @pagingContainerOffset("-#{((newVal - 16) * 28)}px") else @pagingContainerOffset("0px")
      @selectedPageOption(newVal) unless @selectedPageOption() == 1

   getRowCount: (index) =>
      return "#{(14 * @selectedPageOption()) - (14 - index()) + 1}"

   getDateString: (day) ->
      newDate = DateUtils.getAttachedDate(day)
      weekDay = DateUtils.getWeekDay(newDate)
      month = DateUtils.getMonth(newDate)
      date = newDate.getDate()
      year = newDate.getFullYear()
      return "#{weekDay} #{month} #{date}, #{year}"

   deleteOccurrence: (occurrence) =>
      try
         await TimeOffStore.deleteOccurrence(this.editingTimeOff.id, occurrence.id)
         this.editingTimeOff.instances.remove(occurrence)
      catch error
         console.error error
