import "./manage-notification-profile-pane.styl"
import template from "./manage-notification-profile-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"
validateInput = validateInputParent.validateInput

### Auth, Real-Time & Stores ###
import { SettingsStore } from "@/stores/settings-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### Models ###
import { NotificationProfile } from "@/models/notification-profile"

### Framework Includes ###
import ko from "knockout"

export class ManageNotificationProfilePane extends ModalPane
   constructor: (editingProfile) ->
      super("Create Notification Profile", "Save", template())

      # Using this to prevent issue with nested observables around the profile.
      @renderUi = ko.observable(false)
      @title("Edit Notification Profile") if editingProfile?
      @overflowable(true)
      @editing = false
      if editingProfile?
         @editing = true
         @profile = editingProfile 
      else 
         @profile = new NotificationProfile({
            person_existence_changed: true
            person_groups_changed: true,
            person_status_changed: true,
            person_tag_warning: true,
            person_tag_expiring: true,
            project_existence_changed: true
            project_groups_changed: true,
            project_status_changed: true,
            assigned_as_project_role: true
            assignment_created: "groups",
            assignment_edited: "groups",
            assignment_deleted: "groups",
            assignment_starting: "groups",
            assignment_ending: "groups",
            request_created: "groups",
            request_edited: "groups",
            request_filled: "groups",
            request_deleted: "groups",
            request_starting: "groups",
            request_ending: "groups",
            time_off_created: "groups",
            time_off_edited: "groups",
            time_off_deleted: "groups",
            time_off_starting: "groups"
            time_off_ending: "groups"
         }, true)

      @enabledCount = ko.observable(0)
      if @profile?
         @enabledCount = @profile.enabledCount

      @renderUi(true)

   maybeShowValidationError: (next) =>
      return @displayingNotice(ManageNotificationProfilePane.Notice.NAME) unless validateInput(@profile.name())
      @displayingNotice(null)
      next()

   countNotifications: =>
      actionCount = 0
      for key, val of @profile
         rawVal = ko.unwrap(val)
         continue unless typeof rawVal == "boolean" or typeof rawVal == "string"
         continue if key == "companyId" or key == "id" or key == "name"
         continue if rawVal == "none" or !rawVal
         actionCount++

      @enabledCount(actionCount)

   actionBtnIntercept: () =>
      assertArgs(arguments, Function)
      @displayingNotice(ManageNotificationProfilePane.Notice.SAVING)
      @maybeShowValidationError =>
         data = @profile.allToJson()
         if @editing
            try
               response = await SettingsStore.updateNotificationProfile(data.id, data).payload
               updatedProfile = new NotificationProfile(response.data)
               @data.set("updatedProfile", updatedProfile)
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               # TODO: show notice
               console.log("Error:", err)
         else
            try
               response = await SettingsStore.createNotificationProfile(data).payload
               newProfile = new NotificationProfile(response.data)
               @data.set("newProfile", newProfile)
               @displayingNotice(null)
               modalManager.modalFinished()
            catch err
               # TODO: show notice
               console.log("Error:", err)

ManageNotificationProfilePane.Notice = {
   NAME: {
      text: 'Your profile needs a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   SAVING: {
      text: 'Processing Message...'
      info: null
      color: 'green'
      dissmissable: false
   }
}