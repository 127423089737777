import template from "./custom-fields.pug";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import { textSearch } from "@/lib/utils/text-search";
import type { Observable, ObservableArray } from "knockout";
import { observable, observableArray, pureComputed, unwrap } from "knockout";

/* Auth, Real-Time & Stores */
import { authManager } from "@/lib/managers/auth-manager";
import { CustomFieldStore } from "@/stores/custom-field-store.core";

/* Modals */
import type { ModalExitStatus } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { CreateCustomFieldPane } from "@/views/settings/modals/create-custom-field-pane";
import { ConfirmActionPaneViewModel } from "@/lib/components/modals/confirm-action-pane";

/* Models */
import { CustomField } from "@/models/custom-field";
import { PermissionLevel } from "@/models/permission-level";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import { CustomFieldType } from "@laborchart-modules/common";
import type { ObservableData } from "@/lib/utils/observable-data";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";

/* Managers */
import {
   Icons,
   Notification,
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";

export class CustomFieldsViewModel extends PageContentViewModel {
   readonly canManageCustomFieldsSettings: boolean;
   readonly searchQuery: Observable<string | null>;
   readonly fields: ObservableArray<SerializedCustomField>;
   readonly displayFields = pureComputed(() => {
      return textSearch({
         items: this.fields(),
         textProvider: (item) => {
            return unwrap(item.name);
         },
         search: this.searchQuery(),
      });
   });

   constructor() {
      super(template(), "Settings - Custom Fields");

      this.canManageCustomFieldsSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_CUSTOM_FIELDS_SETTINGS,
      );
      this.searchQuery = observable(null);
      this.fields = observableArray();

      this.loadData();
   }

   setSearchQuery = (query: Observable<string>): void => {
      this.searchQuery(query());
   };

   getFieldsType = (field: SerializedCustomField): string => {
      switch (field.type) {
         case CustomFieldType.TEXT:
            return "(Text)";
         case CustomFieldType.NUMBER:
            return "(Number)";
         case CustomFieldType.SELECT:
            return "(Drop Down)";
         case CustomFieldType.MULTI_SELECT:
            return "(Multi-Select Drop Down)";
         case CustomFieldType.DATE:
            return "(Date)";
         case CustomFieldType.BOOL:
            return "(Checkbox)";
         case CustomFieldType.PARAGRAPH:
            return "(Paragraph Text)";
         case CustomFieldType.CURRENCY:
            return "(Currency)";
         case CustomFieldType.HEX_COLOR:
            return "(Color)";
      }
   };

   getFieldsEntityString = (field: SerializedCustomField): string => {
      let str = "- ";
      if (field.on_assignments) {
         str += "Assignments, ";
      }
      if (field.on_people) {
         str += "People, ";
      }
      if (field.on_projects) {
         str += "Project, ";
      }
      if (field.on_requests) {
         str += "Requests, ";
      }
      return str.slice(0, -2);
   };

   showNewFieldModal = (): void => {
      const pane1 = new CreateCustomFieldPane();
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal(
         modal,
         null,
         {
            class: "create-custom-field-modal",
         },
         async (
            modal,
            modalStatus,
            observableData: ObservableData<{ newField: SerializedCustomField }>,
         ) => {
            if (modalStatus === "cancelled") {
               return;
            }

            try {
               const response = await CustomFieldStore.createCustomField(
                  observableData.data.newField,
               ).payload;
               const field = response.data;
               if (field != null) {
                  const index = this.fields().findIndex(
                     (f) => f.name.localeCompare(field.name) != -1,
                  );
                  const insertionIndex = index == -1 ? 0 : index;
                  this.fields.splice(insertionIndex, 0, field);
                  notificationManagerInstance.show(
                     new Notification({
                        text: `Created new custom field: ${field.name}`,
                        icon: Icons.SUCCESS,
                        duration: 5, // TODO: Change this to accept milliseconds...
                     }),
                  );
               }
            } catch (err) {
               console.log("Error: ", err);
            }
         },
      );
   };

   deleteField = (field: SerializedCustomField): void => {
      const message =
         "Deleting this field will also remove it from all records it is applied to. This action can not be undone.";
      const pane1 = new ConfirmActionPaneViewModel("Delete", message, "Confirm Deletion");
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-delete-custom-field-modal",
         },
         async (modal, modalStatus) => {
            if (modalStatus === "cancelled") {
               return;
            }
            try {
               await CustomFieldStore.deleteCustomField(field.id).payload;
               this.fields.remove(field);
               notificationManagerInstance.show(
                  new Notification({
                     text: `Deleted custom field: ${field.name}`,
                     icon: Icons.SUCCESS,
                     duration: 5, // TODO: Change this to accept milliseconds...
                  }),
               );
            } catch (error) {
               notificationManagerInstance.show(
                  new Notification({
                     text: "Something went wrong.",
                     icon: Icons.WARNING,
                     duration: 5, // TODO: Change this to accept milliseconds...
                  }),
               );
            }
         },
      );
   };

   editField = (field: SerializedCustomField): void => {
      const legacyField = new CustomField({
         ...field,
         on_positions: false,
      });

      const pane1 = new CreateCustomFieldPane(legacyField);
      const modal = new Modal();
      modal.setPanes([pane1]);
      modalManager.showModal(
         modal,
         null,
         {
            class: "create-custom-field-modal",
         },
         async (
            modal,
            modalStatus,
            observableData: ObservableData<{ updateField: SerializedCustomField }>,
         ) => {
            if (modalStatus === "finished") {
               const confirm = await this.confirmEntityRemoval(
                  field,
                  observableData.data.updateField,
               );
               if (confirm == "cancelled") return;

               try {
                  await CustomFieldStore.updateCustomField(
                     field.id,
                     observableData.data.updateField,
                  ).payload;
               } catch (err) {
                  console.log("Error: ", err);
               }
               const updateField = {
                  ...field,
                  ...observableData.data.updateField,
               };

               const fieldIndex = this.fields.indexOf(field);
               this.fields.splice(fieldIndex, 1, updateField);
               notificationManagerInstance.show(
                  new Notification({
                     text: `Updated custom field: ${observableData.data.updateField.name}`,
                     icon: Icons.SUCCESS,
                     duration: 5, // TODO: Change this to accept milliseconds...
                  }),
               );
            }
         },
      );
   };

   confirmEntityRemoval(
      oldField: SerializedCustomField,
      newField: SerializedCustomField,
   ): Promise<ModalExitStatus> {
      const removedEntityList = [];
      if (oldField.on_assignments == true && newField.on_assignments == false)
         removedEntityList.push("Assignments");
      if (oldField.on_people == true && newField.on_people == false)
         removedEntityList.push("People");
      if (oldField.on_projects == true && newField.on_projects == false)
         removedEntityList.push("Projects");
      if (oldField.on_requests == true && newField.on_requests == false)
         removedEntityList.push("Requests");
      if (removedEntityList.length == 0) return Promise.resolve("finished");

      const entityList =
         removedEntityList.length == 1
            ? removedEntityList[0]
            : removedEntityList.slice(0, -1).join(", ") +
              ` & ${removedEntityList[removedEntityList.length - 1]}`;

      const message = `Removing this field from
         ${entityList} will remove field data
         from all ${entityList} records it is applied to.
         This action can not be undone.`;

      const pane1 = new ConfirmActionPaneViewModel("Confirm", message, "Confirm Entity Removal");
      const modal = new Modal();
      modal.setPanes([pane1]);
      return new Promise((resolve) => {
         modalManager.showModal(
            modal,
            null,
            {
               class: "confirm-delete-custom-field-modal",
            },
            async (modal, modalStatus) => {
               if (modalStatus === "cancelled") {
                  resolve("cancelled");
               }
               resolve("finished");
            },
         );
      });
   }

   async loadData(): Promise<void> {
      const stream = await CustomFieldStore.findCustomFieldsStream({
         sort_direction: Order.ASCENDING,
      }).stream;

      for await (const field of stream) {
         this.fields.push(field);
      }
   }
}
