import "./assignment-alerts.styl"
import template from "./assignment-alerts.pug"
import { App } from "@/views/app"
import { ValidationUtils } from "@/lib/utils/validation"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { DateUtils } from "@/lib/utils/date"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { AlertStore } from "@/stores/alert-store.core"
import { Alert } from "@/models/alert"
import { defaultStore } from "@/stores/default-store"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane"
import { ViewParagraphPane } from "@/lib/components/modals/view-paragraph-pane"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { AlertsFilterPane } from "@/lib/components/popup/alerts-filter-pane"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"

DATE_FORMAT = {
   weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
   dayFormat: DateUtils.DayFormat.ONE_DIGIT,
   monthFormat: DateUtils.MonthFormat.ABBREV,
   yearFormat: DateUtils.YearFormat.FULL,
}
      

export class AssignmentAlerts extends PageContentViewModel
   constructor: (queryParams) ->
      assertArgs(arguments, nullable(Object))
      super(template(), "Assignment Alerts")
      @searchQuery = ko.observable(queryParams?.search or null)

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManageAlerts = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_ALERTS)

      @selectedContext = ko.observable(queryParams?.context or "open")
      @alerts = ko.observableArray()
      @alreadyPromptedThisSession = ko.observableArray()

      @pageIndex = ko.observable(if queryParams?.page then Number(queryParams.page) else 0)
      @pageIndex.subscribe (newVal) =>
         router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "page", newVal)
         @loadAlerts()

      @totalCount = ko.observable(0)
      @pagingIncrement = 50

      sentNewestOption = new DropDownItem('Sent At - Newest', AssignmentAlerts.SortBy.SENT_NEWEST)
      sentOldestOption = new DropDownItem('Sent At - Oldest', AssignmentAlerts.SortBy.SENT_OLDEST)
      mostRecentReplyOption = new DropDownItem('Most Recent Reply', AssignmentAlerts.SortBy.RECENT_REPLY)
      closedNewestOption = new DropDownItem('Closed - Newest', AssignmentAlerts.SortBy.CLOSED_NEWEST)
      # closedOldestOption = new DropDownItem('Closed - Oldest', AssignmentAlerts.SortBy.CLOSED_OLDEST)
      createdNewestOption = new DropDownItem('Created - Newest', AssignmentAlerts.SortBy.CREATED_NEWEST)
      createdOldestOption = new DropDownItem('Created - Oldest', AssignmentAlerts.SortBy.CREATED_OLDEST)

      calculateFirstSortByForContext = (context) ->
         return switch context
            when "open" then sentNewestOption
            when "scheduled" then createdNewestOption
            when "drafts" then createdNewestOption
            when "closed" then sentNewestOption

      @sortByOptions = ko.pureComputed =>
         return switch @selectedContext()
            when "open"
               [sentNewestOption, sentOldestOption, mostRecentReplyOption]
            when "scheduled"
               [createdNewestOption, createdOldestOption]
            when "drafts"
               [createdNewestOption, createdOldestOption]
            when "closed"
               [sentNewestOption, sentOldestOption, mostRecentReplyOption, closedNewestOption, createdOldestOption]
      @selectedSortBy = ko.observable(@sortByOptions()[0])
      @disposableSortByBlock = false
      @selectedSortBy.subscribe =>
         return @disposableSortByBlock = false if @disposableSortByBlock
         @loadAlerts()

      @selectedContext.subscribe (newVal) =>
         @disposableSortByBlock = true
         # console.log "@sortByOptions()[0]: ", @sortByOptions()[0].value()
         @selectedSortBy(calculateFirstSortByForContext(newVal))
         @loadAlerts()

      # Filters
      @hasReplyOne = ko.observable(false)
      if queryParams?.r1 == "true"
         @hasReplyOne(true)
      @hasReplyOne.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r1", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r1")

      @hasReplyTwo = ko.observable(false)
      if queryParams?.r2 == "true"
         @hasReplyTwo(true)
      @hasReplyTwo.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r2", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r2")

      @hasReplyThree = ko.observable(false)
      if queryParams?.r3 == "true"
         @hasReplyThree(true)
      @hasReplyThree.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r3", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "r3")

      @recievedAllReplies = ko.observable(false)
      if queryParams?.ar == "true"
         @recievedAllReplies(true)
      @recievedAllReplies.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "ar", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "ar")

      @partiallyReplied = ko.observable(false)
      if queryParams?.pr == "true"
         @partiallyReplied(true)
      @partiallyReplied.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "pr", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "pr")

      @noReplies = ko.observable(false)
      if queryParams?.nr == "true"
         @noReplies(true)
      @noReplies.subscribe (newVal) ->
         if newVal
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "nr", true, null)
         else
            router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "nr")

      @selectedProjectIds = ko.observableArray()
      if queryParams?.spif? and ValidationUtils.validateInput(queryParams.spif)
         @selectedProjectIds(queryParams.spif.split(","))
      @selectedProjectIds.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, 'spif') if newVal.length == 0
         router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "spif", newVal.join(','))

      @showReplyBasedOptions = ko.pureComputed =>
         return @selectedContext() == "open" or @selectedContext() == "closed"

      @appliedFilterCount = ko.pureComputed =>
         count = 0
         count++ if @hasReplyOne()
         count++ if @hasReplyTwo()
         count++ if @hasReplyThree()
         count++ if @recievedAllReplies()
         count++ if @partiallyReplied()
         count++ if @noReplies()
         count = count + @selectedProjectIds().length
         return count

      @contextTitle = ko.pureComputed =>
         return switch @selectedContext()
            when "open" then "Open"
            when "scheduled" then "Scheduled"
            when "drafts" then "Drafts"
            when "closed" then "Closed"

      # Popups
      @contextListPopupBuilder = =>
         handleConextSelection = (context) =>
            @alerts([])
            @selectedContext(context)
            router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "context", context)

         items = [
            new PopupListItem({title: "Open", clickCallback: handleConextSelection, callbackData: 'open'})
            new PopupListItem({title: "Scheduled", clickCallback: handleConextSelection, callbackData: 'scheduled'})
            new PopupListItem({title: "Drafts", clickCallback: handleConextSelection, callbackData: 'drafts'})
            new PopupListItem({title: "Closed", clickCallback: handleConextSelection, callbackData: 'closed'})
         ]
         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new PopupListPane("", items, {showArrows: false})],
            ['assignment-alerts-page__context-control', 'assignment-alerts-page__context-control__text'], ["assignment-alerts__popup--context"])

      @contextListPopupWrapper = {
         popupBuilder: @contextListPopupBuilder
         options: {triggerClasses: ['assignment-alerts-page__context-control']}
      }

      @alertsFilterPopupBuilder = =>
         filterOptions = {
            showReplyBasedOptions: @showReplyBasedOptions
            hasReplyOne: @hasReplyOne
            hasReplyTwo: @hasReplyTwo
            hasReplyThree: @hasReplyThree
            recievedAllReplies: @recievedAllReplies
            partiallyReplied: @partiallyReplied
            noReplies: @noReplies
            selectedProjectIds: @selectedProjectIds
         }
         return new Popup "Filters", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new AlertsFilterPane(filterOptions)], ['list-view__toolbar-btn--icon', '.icon-filter'],
            ["assignment-alerts__popup--filter"], (err, exitStatus) =>
               return if exitStatus == Popup.ExitStatus.CANCELED
               @loadAlerts()

      @alertsFilterPopupWrapper = ko.observable({
         popupBuilder: @alertsFilterPopupBuilder,
         options: {triggerClasses: ['list-view__toolbar-btn--icon']}
      })

      @loadAlerts()

   getAssignmentDate: (detachedDay) ->
      return "TBD" unless detachedDay?
      return DateUtils.formatDetachedDay(detachedDay, defaultStore.getDateFormat())

   getReplyCount: (alert) ->
      return "#{alert.responses().length}/#{alert.recipientIds().length}"

   getProjectString: (alert) ->
      projectString = alert.baggage().project_name
      if alert.baggage().cost_code_name?
         projectString += " (#{alert.baggage().cost_code_name}"
         projectString += " - #{alert.baggage().label_name}" if alert.baggage().label_name?
         projectString += ")"
      return projectString

   getRecipientString: (recipient) ->
      return '' unless recipient?
      recipientString = "#{recipient.name.first} #{recipient.name.last}"
      recipientString += " - #{recipient.position_name}" if recipient.position_name?
      return recipientString

   getReplyText: (recipient, alert) ->
      for reply in alert.responses()
         if reply.authorId() == recipient.id
            return reply.word() or 'Invalid Reply'
      return ''

   getReplyColor: (recipient, alert) ->
      for reply in alert.responses()
         if reply.authorId() == recipient.id
            return reply.color or ''
      return ''

   getScheduledText: (alert) ->
      date = new Date(alert.sendAt())
      return "Scheduled for: #{DateUtils.getShortNumericDate(date, defaultStore.getDateFormat())} at #{DateUtils.getTime(date)}"

   getAlertMetaString: (alert) =>
      switch @selectedContext()
         when "open"
            firstName = alert.baggage().sender_name.first
            lastName = alert.baggage().sender_name.last
            date = new Date(alert.sentAt())
            formattedDate = DateUtils.formatDate(date, defaultStore.getDateFormat(), DATE_FORMAT)
            timeStamp = "#{formattedDate} at #{DateUtils.getTime(date)}"
            return "Sent by: #{firstName} #{lastName} on #{timeStamp}"
         when "closed"
            firstName = alert.baggage().closer_name.first
            lastName = alert.baggage().closer_name.last
            date = new Date(alert.closedAt())
            formattedDate = DateUtils.formatDate(date, defaultStore.getDateFormat(), DATE_FORMAT)
            timeStamp = "#{formattedDate} at #{DateUtils.getTime(date)}"
            return "Closed by: #{firstName} #{lastName} on #{timeStamp}"
         else
            firstName = alert.baggage().sender_name.first
            lastName = alert.baggage().sender_name.last
            date = new Date(alert.createdAt())
            formattedDate = DateUtils.formatDate(date, defaultStore.getDateFormat(), DATE_FORMAT)
            timeStamp = "#{formattedDate} at #{DateUtils.getTime(date)}"
            return "Created by: #{firstName} #{lastName} on #{timeStamp}"

   closeAlert: (alert) =>
      try
         await AlertStore.closeAlert(alert.id).payload
         @alerts.remove(alert)
      catch err
         return console.error "closeAlert error: ", err

   deleteAlert: (alert) =>
      try
         await AlertStore.deleteAlert(alert.id).payload
         @alerts.remove(alert)
      catch err
         return console.error "deleteAlert error: ", err
         
   sendAlertNow: (alert) =>
     try
         await AlertStore.sendAlertNow(alert.id).payload
         @alerts.remove(alert)
      catch err
         return console.error "sendAlertNow error: ", err

   editAlert: (alert) =>
      alertData = {
         subject: alert.subject()
         content: alert.content()
         includeSignature: alert.includeSignature()
         existingDateTime: alert.sendAt() or null
      }
      existingData = {
         recipientIds: alert.recipientIds()
         alertId: alert.id
         context: alert.context()
      }
      pane1 = new CreateMessageOrAlertPane(alertData, 'Edit Alert', null, false, existingData, null, true)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'create-message-modal'}, (modal, modalStatus, observableData) =>
         return unless observableData.data.updatedAlert?
         updatedAlert = observableData.data.updatedAlert
         alert.content(updatedAlert.content())
         alert.subject(updatedAlert.subject())
         alert.recipientIds(updatedAlert.recipientIds())
         alert.sendAt(updatedAlert.sendAt()) if updatedAlert.sendAt()?
         newBaggage = alert.baggage()
         newBaggage.recipients = updatedAlert.baggage().recipients
         # To trigger UI updates.
         alert.baggage(newBaggage)

   viewAlert: (alert) =>
      displayText = "SUBJECT:\n" + alert.subject() + "\n\nCONTENT:\n" + alert.content()
      pane1 = new ViewParagraphPane('Alert Content', displayText)
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'view-paragraph-modal--alerts'}, (modal, modalStatus, observableData) =>
         return { modal, modalStatus, observableData}

   promptAlert: (alert, event) =>
      return if @alreadyPromptedThisSession().indexOf(alert.id) != -1
      try
         await AlertStore.promptAlert(alert.id).payload
         @alreadyPromptedThisSession.push(alert.id)
         event.currentTarget.classList.add("assignment-alerts-row__btn--prompt-clicked")
      catch err
         return console.error "promptAlert error: ", err

   handleSeachExecution: =>
      if ValidationUtils.validateInput(@searchQuery())
         router.updateUrlQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "search", @searchQuery())
      else
         router.removeQueryParam(App.RouteName.ASSIGNMENT_ALERTS, "search")
      @loadAlerts()

   loadAlerts: =>
      params = {
         sortBy: @selectedSortBy().value()
         skip: @pageIndex() * @pagingIncrement
         limit: @pagingIncrement
      }
      params['search'] = @searchQuery() if ValidationUtils.validateInput(@searchQuery())
      # Only bother if one is differnet.
      if @hasReplyOne() != @hasReplyTwo() or @hasReplyOne() != @hasReplyThree()
         params['replyOne'] = true if @hasReplyOne()
         params['replyTwo'] = true if @hasReplyTwo()
         params['replyThree'] = true if @hasReplyThree()

      if @recievedAllReplies() != @partiallyReplied() or @recievedAllReplies() != @noReplies()
         params['allReplies'] = true if @recievedAllReplies()
         params['someReplies'] = true if @partiallyReplied()
         params['noReplies'] = true if @noReplies()

      if @selectedProjectIds().length > 0
         params['projectIds'] = @selectedProjectIds()

      try
         request = {
            params: {
               context: @selectedContext()
            },
            query: params
         }
         response = await AlertStore.getAlerts(request).payload
         mappedAlerts = response.data.map (item) -> return new Alert(item)
         @alerts(mappedAlerts)
         @totalCount(response.total_count)
      catch error
         console.error("Error getting alerts:", error)   

AssignmentAlerts.SortBy = {
   SENT_NEWEST: "sent-newest"
   SENT_OLDEST: "sent-oldest"
   RECENT_REPLY: "recent-reply"
   CLOSED_NEWEST: "closed-newest"
   CLOSED_OLDEST: "closed-oldest"
   CREATED_NEWEST: "created-newest"
   CREATED_OLDEST: "created-oldest"
}