import "./create-custom-field-pane.styl"
import ko from "knockout"
import template from "./create-custom-field-pane.pug"
import { ValidationUtils as validateInputParent } from "@/lib/utils/validation"

validateInput = validateInputParent.validateInput

### Models ###
import { CustomField } from "@/models/custom-field"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { ModalPane } from "@/lib/components/modals/modal-pane"

### UI Assets ###
import { DropDownItem } from "@/lib/components/drop-downs/drop-down"
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class CreateCustomFieldPane extends ModalPane
   constructor: (editingField) ->
      super("Create Custom Field", "Save", template())

      @title("Edit Custom Field") if editingField?
      @overflowable(true)
      @editingField = editingField
      
      @name = ko.observable(null)
      @integrationFieldName = ko.pureComputed =>
         return "... enter field name first" unless @name()?
         str = @name().trim().replace(/\s/g, "_")
         return str.replace(/[^a-zA-Z0-9_]/g, '').toLowerCase()

      @fieldTypes = [
         new DropDownItem("Text", CustomField.Type.TEXT)
         new DropDownItem("Number", CustomField.Type.NUMBER)
         new DropDownItem("Drop Down", CustomField.Type.SELECT)
         new DropDownItem("Multi-Select Drop Down", CustomField.Type.MULTI_SELECT)
         new DropDownItem("Date", CustomField.Type.DATE)
         new DropDownItem("Checkbox", CustomField.Type.BOOL)
         new DropDownItem("Color", CustomField.Type.HEX_COLOR)
         new DropDownItem("Paragraph Text", CustomField.Type.PARAGRAPH)
         new DropDownItem("Currency", CustomField.Type.CURRENCY)
      ]
      
      @selectedFieldType = ko.observable()

      @dropDownSortOptions = [
         new SegmentedControllerItem("Alphanumeric", "alpha")
         new SegmentedControllerItem("Order Listed", "listed")
      ]
      @selectedDropDownSort = ko.observable(@dropDownSortOptions[0])

      @needsOptions = ko.pureComputed =>
         return false unless @selectedFieldType()?
         return @selectedFieldType().value() == CustomField.Type.SELECT or @selectedFieldType().value() == CustomField.Type.MULTI_SELECT

      @rawFieldOptions = ko.observable("")
      @fieldOptionsTitle = ko.pureComputed =>
         str = "Field Options"
         if validateInput(@rawFieldOptions())
            options = @rawFieldOptions().split(",")
            optionCount = options.filter((i) -> return validateInput(i)).length
            if optionCount == 1
               str += " - 1 Option"
            else if optionCount > 1
               str += " - #{optionCount} Options"
         return str

      @onAssignments = ko.observable(false)
      @onPeople = ko.observable(false)
      @onProjects = ko.observable(false)
      @onRequests = ko.observable(false)
      @onPositions = ko.observable(false)

      @integrationOnly = ko.observable(false)
      @canFilter = ko.observable(false)

      @canFilterType = ko.pureComputed =>
         if (!@selectedFieldType()? or @selectedFieldType().value() == CustomField.Type.HEX_COLOR or 
         @selectedFieldType().value() == CustomField.Type.PARAGRAPH)
            @canFilter(false)
            return false
         else
            true

      @description = ko.observable()

      if @editingField?
         @name(@editingField.name())
         for type in @fieldTypes
            if type.value() == @editingField.type()
               @selectedFieldType(type)
               break
         if @editingField.type() == CustomField.Type.SELECT or @editingField.type() == CustomField.Type.MULTI_SELECT
            @rawFieldOptions(@editingField.values().join(", "))

            for option in @dropDownSortOptions
               if option.value() == @editingField.sortBy()
                  @selectedDropDownSort(option)
                  break

         @onAssignments(@editingField.onAssignments())
         @onPeople(@editingField.onPeople())
         @onProjects(@editingField.onProjects())
         @onRequests(@editingField.onRequests())
         @onPositions(@editingField.onPositions())

         @integrationOnly(@editingField.integrationOnly())
         @canFilter(@editingField.canFilter())

         @description(@editingField.description())

   actionBtnIntercept: () =>
      @displayingNotice(CreateCustomFieldPane.Notice.SAVING)
      @maybeShowValidationError =>
         fieldData = {
            name: @name()
            integration_name: @integrationFieldName()
            type: @selectedFieldType().value()
            can_filter: @canFilter()
            description: @description() or null
            integration_only: @integrationOnly()
            sort_by: null,
            values: null,

            on_people: @onPeople()
            on_projects: @onProjects()
            on_positions: @onPositions()
         }
         fieldData.on_assignments = @onAssignments()
         fieldData.on_requests = @onRequests()

         if fieldData.type == CustomField.Type.SELECT or fieldData.type == CustomField.Type.MULTI_SELECT
            values = @rawFieldOptions().split(",")
            formattedOptions = []
            for option in values
               formattedOptions.push(option.trim()) if validateInput(option)
            fieldData['values'] = formattedOptions
            fieldData['sort_by'] = @selectedDropDownSort().value()

         if @editingField?
            @data.set("updateField", fieldData)
         else
            @data.set("newField", fieldData)

         @displayingNotice(null)
         modalManager.modalFinished()


   maybeShowValidationError: (next) =>
      return @displayingNotice(CreateCustomFieldPane.Notice.NAME) unless validateInput(@name())
      unless CreateCustomFieldPane.ILLEGAL_FIELD_NAMES.indexOf(@integrationFieldName()) == -1
         return @displayingNotice(CreateCustomFieldPane.Notice.MATCHES_EXISTING)
      return @displayingNotice(CreateCustomFieldPane.Notice.TYPE) unless @selectedFieldType()?
      unless @onAssignments() or @onProjects() or @onPeople() or @onPositions() or @onRequests()
         return @displayingNotice(CreateCustomFieldPane.Notice.AVAILABLE_TO)
      if (@selectedFieldType().value() == CustomField.Type.SELECT or 
      @selectedFieldType().value() == CustomField.Type.MULTI_SELECT)
         values = @rawFieldOptions().split(",")
         unless values[0]? and validateInput(values[0])
            return @displayingNotice(CreateCustomFieldPane.Notice.OPTIONS)
      next()

# TODO: This needs to be handled in a centralized way.
CreateCustomFieldPane.ILLEGAL_FIELD_NAMES = [
   'actual_rate',
   'address_1',
   'address_2',
   'assignment_ids',
   'assignment_reminder_message_id',
   'assignments'
   'attachments',
   'bid_rate',
   'can_recieve_email',
   'can_recieve_sms',
   'categories',
   'category_id',
   'city_town',
   'closed_date',
   'color',
   'company_id',
   'cost_code_id',
   'cost_codes',
   'country',
   'custom_fields',
   'customer_name',
   'daily_end_time',
   'daily_start_time',
   'default_recipients',
   'delete_assignment_message_id',
   'dob',
   'edit_assignment_message_id',
   'email',
   'emergency_contact_email',
   'emergency_contact_name',
   'emergency_contact_number',
   'emergency_contact_relation',
   'employee_number',
   'est_end_date',
   'ethnicity',
   'group_ids',
   'hired_date',
   'hourly_wage',
   'id',
   'invite_pending',
   'is_assignable',
   'is_male',
   'is_user',
   'job_number',
   'language',
   'lat',
   'long',
   'name',
   'new_assignment_message_id',
   'notes',
   'percent_complete',
   'permission',
   'phone',
   'placeholders_expanded',
   'placeholders',
   'position_id',
   'position',
   'preferences',
   'profile_pic_url',
   'project_type',
   'qr_id',
   'roles',
   'send_daily_recap',
   'signature',
   'start_date',
   'state_province',
   'status',
   'subcategory_id',
   'subcategories',
   'tag_ids',
   'tag_instances',
   'timezone',
   'total_burn',
   'transfer_assignment_message_id',
   'uncategorized_assignments_open',
   'uncategorized_assignments',
   'wage_overrides',
   'zipcode',
]

CreateCustomFieldPane.Notice = {
   NAME: {
      text: 'Your Custom Field must have a name.'
      info: null
      color: 'red'
      dissmissable: true
   }
   MATCHES_EXISTING: {
      text: 'Your Custom Field must not match existing Workforce Planning fields'
      info: null
      color: 'red'
      dissmissable: true
   }
   TYPE: {
      text: 'You must select a type for your Custom Field.'
      info: null
      color: 'red'
      dissmissable: true
   }
   AVAILABLE_TO: {
      text: 'Your Custom Field needs to be available to at least 1 entity.'
      info: null
      color: 'red'
      dissmissable: true
   }
   OPTIONS: {
      text: "Drop Down type field's need at least 1 valid option."
      info: null
      color: 'red'
      dissmissable: true
   }
   SAVING: {
      text: "Saving..."
      info: null
      color: 'green'
      dissmissable: false
   }
   DUPE_NAME: {
      text: "Your field's name can't match existing custom fields"
      info: null
      color: 'red'
      dissmissable: true
   }
}

CreateCustomFieldPane.RecievableError = {
   DUPE_NAME: 'dupeName'
}