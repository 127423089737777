import template from "./integration-settings.pug";
import renderReactComponent from "@/react/render-react-component";

import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

export class IntegrationSettingsViewModel extends PageContentViewModel {
   constructor() {
      super(template(), "Settings - Integration Settings");
   }

   addedToParent(): void {
      renderReactComponent("integration-settings-react-mount", "IntegrationSettingsContainer", {});
   }
}
