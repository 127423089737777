import { Model } from "@/models/model"
import { observable, observableArray } from "knockout"

export class TimeOffInstance extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.start_day, Number)
         assertOfType(data.end_day, Number)

      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @startDay = observable(data.start_day)
      @endDay = observable(data.end_day)


export class TimeOff extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.person_id, String)
         assertOfType(data.batch_start_time, Number)
         assertOfType(data.batch_end_time, Number)
         assertOfType(data.reason, String)
         assertOfType(data.is_paid, Boolean)
         assertOfType(data.apply_to_saturday, Boolean)
         assertOfType(data.apply_to_sunday, Boolean)
         assertOfType(data.repeat, String)
         if data.repeat != "never"
            assertOfType(data.cadence, Number)
            assertOfType(data.repeat_end_day, Number)

         assertOfType(data.instances, arrayOf(Object))
         for instance in data.instances
            assertOfType(instance.id, String)
            assertOfType(instance.start_day, Number)
            assertOfType(instance.end_day, Number)
            assertOfType(instance.daily_start_time, optional(Number))
            assertOfType(instance.daily_end_time, optional(Number))
      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @createdAt = observable(data.created_at)
      @personId = observable(data.person_id)
      @batchStartTime = observable(data.batch_start_time)
      @batchEndTime = observable(data.batch_end_time)
      @reason = observable(data.reason)
      @isPaid = observable(data.is_paid)
      @applyToSaturday = observable(data.apply_to_saturday)
      @applyToSunday = observable(data.apply_to_sunday)
      @repeat = observable(data.repeat)

      @cadence = observable(data.cadence)
      @repeatEndDay = observable(data.repeat_end_day)

      @instances = observableArray data.instances.map (instance) ->
         return new TimeOffInstance(instance)

export class TimeOffRange extends Model
   constructor: (data, disableTypes) ->
      assertArgs(arguments, optional(Object), optional(Boolean))
      data = {} unless data?
      super(data)
      disableTypes = false unless disableTypes?
      unless disableTypes
         # Required
         assertOfType(data.id, String)
         assertOfType(data.batch_id, String)
         assertOfType(data.reason, String)
         assertOfType(data.start_day, Number)
         assertOfType(data.end_day, Number)
         assertOfType(data.daily_start_time, Number)
         assertOfType(data.daily_end_time, Number)
         assertOfType(data.created_at, Number)
         assertOfType(data.apply_to_saturday, Boolean)
         assertOfType(data.apply_to_sunday, Boolean)
      ###------------------------------------
         Model ID
      ------------------------------------###
      @id = data.id

      ###------------------------------------
         Knockout Observables
      ------------------------------------###
      @batchId = observable(data.batch_id)
      @reason = observable(data.reason)
      @startDay = observable(data.start_day)
      @endDay = observable(data.end_day)
      @dailyStartTime = observable(data.daily_start_time)
      @dailyEndTime = observable(data.daily_end_time)
      @createdAt = observable(data.created_at)
      @applyToSaturday = observable(data.apply_to_saturday)
      @applyToSunday = observable(data.apply_to_sunday)


TimeOff.Repeat = {
   NEVER: "never"
   WEEKLY: "weekly"
   MONTHLY: "monthly"
   YEARLY: "yearly"
}

TimeOff.TimeOffRange = TimeOffRange
TimeOff.TimeOffInstance = TimeOffInstance