import ko from "knockout";

export class ChipFilterMediator
   constructor: (initCallback) ->
      @initCallback = initCallback
      @isInitialized = ko.observable(false)

   updateVisibleFilters: -> console.log("WARNING: updateVisibleFilters not set")


   initialize: (component) =>
      @updateVisibleFilters = component.updateVisibleFilters if component.updateVisibleFilters?

      @isInitialized(true)
      @initCallback() if @initCallback?

