import type {
   RequestDetailsModalStagedUpdate,
   StagedRequest,
} from "@/lib/components/modals/request-details-modal/request-details-modal";
import {
   RequestDetailsModal,
   RequestDetailsModalPane,
} from "@/lib/components/modals/request-details-modal/request-details-modal";
import { authManager } from "@/lib/managers/auth-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { PermissionLevel } from "@/models/permission-level";
import { Placeholder } from "@/models/placeholder";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { RequestStore } from "@/stores/request-store.core";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import type { CreateRequestPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type { SerializedFindRequest } from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { Observable, PureComputed } from "knockout";
import { observable, pureComputed } from "knockout";
import {
   notificationManagerInstance,
   Icons,
   Notification,
} from "@/lib/managers/notification-manager";
import type {
   SerializedPosition,
   SerializedTag,
} from "@laborchart-modules/common/dist/rethink/serializers";
import { TagStore } from "@/stores/tag-store.core";
import { PositionStore } from "@/stores/position-store.core";
import { formatName } from "@/lib/utils/preferences";
import type { FilteredRequestBaggage } from "@/stores/request-store";
import { ProjectStore } from "@/stores/project-store.core";
import { StatusStore } from "@/stores/status-store.core";
import type {
   AssignmentDetailsModalStagedUpdate,
   StagedAssignment,
} from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import {
   AssignmentDetailsModal,
   AssignmentDetailsModalPane,
   AssignmentDetailsModalType,
} from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api/dist/api/assignments/find-assignments";
import type { CreateAssignmentPayload } from "@laborchart-modules/lc-core-api/dist/api/assignments/create-assignment";
import type { GetAssignmentDetailReponse } from "@laborchart-modules/lc-core-api/dist/api/assignments/get-assignment";
import { Assignment2Store } from "@/stores/assignment-2-store.core";
import { Assignment } from "@/models/assignment";
import { PersonStore } from "@/stores/person-store.core";
import type { ModalData } from "@/lib/managers/alert-manager";
import { alertManager } from "@/lib/managers/alert-manager";
import { getLastWorkDate, getNextWorkDate } from "@/lib/utils/date-2";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { LegacyStore } from "@/stores/legacy-store.core";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

class GanttPageState {
   private readonly internalState: {
      assignmentCustomFields: Observable<SerializedCustomField[]>;
      assignmentSupportData: Observable<AssignmentSupportData | null>;
      requestCustomFields: Observable<SerializedCustomField[]>;
      isReady: Observable<boolean>;
      tags: Observable<SerializedTag[]>;
   };

   private readonly legacyCreateEditDeleteAssignmentCallback: GanttPageParams["legacyCreateEditDeleteAssignmentCallback"];
   private readonly legacyCreateEditDeleteRequestCallback: GanttPageParams["legacyCreateEditDeleteRequestCallback"];

   readonly assignmentCustomFields = pureComputed(() =>
      this.internalState.assignmentCustomFields(),
   );
   readonly assignmentSupportData: NestedComputedAssignmentSupportData = {
      companyTbdWeeks: pureComputed(
         () => this.internalState.assignmentSupportData()?.companyTbdWeeks ?? 0,
      ),
      groupedCostCodeOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.groupedCostCodeOptions ?? {},
      ),
      groupedLabelOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.groupedLabelOptions ?? {},
      ),
      overtimeDayRates: pureComputed(
         () => this.internalState.assignmentSupportData()?.overtimeDayRates ?? null,
      ),
      paidShiftHours: pureComputed(
         () => this.internalState.assignmentSupportData()?.paidShiftHours ?? 8,
      ),
      projectOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.projectOptions ?? [],
      ),
      resourceOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.resourceOptions ?? [],
      ),
      statusOptions: pureComputed(
         () => this.internalState.assignmentSupportData()?.statusOptions ?? [],
      ),
   };
   readonly requestCustomFields = pureComputed(() => this.internalState.requestCustomFields());
   readonly isReady = pureComputed(() => this.internalState.isReady() ?? false);
   readonly tags = pureComputed(() => this.internalState.tags());

   constructor({
      legacyCreateEditDeleteAssignmentCallback,
      legacyCreateEditDeleteRequestCallback,
   }: {
      legacyCreateEditDeleteAssignmentCallback: GanttPageParams["legacyCreateEditDeleteAssignmentCallback"];
      legacyCreateEditDeleteRequestCallback: GanttPageParams["legacyCreateEditDeleteRequestCallback"];
   }) {
      this.internalState = {
         assignmentCustomFields: observable<SerializedCustomField[]>([]),
         assignmentSupportData: observable<AssignmentSupportData | null>(null),
         requestCustomFields: observable<SerializedCustomField[]>([]),
         isReady: observable(false),
         tags: observable<SerializedTag[]>([]),
      };

      const isLoadComplete = observable(false);
      this.load(isLoadComplete);

      const allLoaded = pureComputed(() => {
         // Even if the load function completes, we're still waiting until the assignmentSupportData is finished updating.
         // You'd think the first ensures the second, but for some reason initialization of th assignmentSupportData was
         // lagging behind, creating a race condition that was sometimes causing people's gantt saved-views to load with
         // all black assignment bars. This pureComputed, plus a subscription to @ganttPage.state.isReady in gantt-2.coffee resolves
         // that issue.
         return isLoadComplete() && this.internalState.assignmentSupportData();
      });
      allLoaded.subscribe((newVal) => {
         if (newVal) this.internalState.isReady(true);
      });

      this.legacyCreateEditDeleteAssignmentCallback = legacyCreateEditDeleteAssignmentCallback;
      this.legacyCreateEditDeleteRequestCallback = legacyCreateEditDeleteRequestCallback;
   }

   private async load(callback: Observable<boolean>) {
      await Promise.all([this.loadAdmSupportData(), this.loadCustomFields(), this.loadTags()]);
      callback(true);
   }

   private async loadAdmSupportData() {
      let result: any = null;
      const group_id =
         authManager.selectedGroupId() === "my-groups" ? undefined : authManager.selectedGroupId();

      const loadAttempt = async (id: string | undefined) => {
         result = await LegacyStore.getAssignmentCreationSupportData({ group_id: id }).payload;
         this.internalState.assignmentSupportData(
            LegacyStore.formatAssignmentCreationSupportData(result.data),
         );
      };

      const logToBugsnag = (err: unknown, context: string) => {
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = context;
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("getAssignmentCreationSupportData", { result });
            event.addMetadata("group_id", { group_id });
         });
      };

      // First attempt: load support data for the specific group
      try {
         await loadAttempt(group_id);
      } catch (err) {
         // Second attempt: load support data for the specific group
         try {
            await new Promise((res) => setTimeout(res, 250));
            await loadAttempt(group_id);
            console.info("second attempt to load support data was successful");
         } catch (err) {
            logToBugsnag(err, "gantt-page__loadAdmSupportData__1__group-attempt");

            // Third attempt: If we can't load the specific group support data, load all support data
            try {
               await new Promise((res) => setTimeout(res, 250));
               await loadAttempt(undefined);
               console.info("third attempt to load support data was successful");
            } catch (err) {
               console.error("third attempt to load support data failed:", err);
               logToBugsnag(err, "gantt-page__loadAdmSupportData__2__global-attempt");

               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "An unexpected error occurred while loading data.",
                  }),
               );
            }
         }
      }
   }

   private async loadCustomFields() {
      const stream = await CustomFieldStore.findCustomFieldsStream({
         is_on_entities: [CustomFieldEntity.ASSIGNMENT, CustomFieldEntity.REQUEST],
      }).stream;

      const assignmentFields = [];
      const requestFields = [];

      for await (const f of stream) {
         if (f.on_assignments == true) assignmentFields.push(f);
         if (f.on_requests == true) requestFields.push(f);
      }
      this.internalState.assignmentCustomFields(assignmentFields);
      this.internalState.requestCustomFields(requestFields);
   }

   private async loadTags() {
      const stream = await TagStore.findTagsStream({}).stream;

      const tags = [];

      for await (const t of stream) {
         tags.push(t);
      }
      this.internalState.tags(tags);
   }

   readonly createAssignment = async (
      assignment: CreateAssignmentPayload,
      showAlertModal?: boolean,
   ) => {
      const [response, project] = await Promise.all([
         Assignment2Store.createAssignment(assignment).payload,
         ProjectStore.getProject(assignment.project_id).payload,
      ]);
      modalManager.clearModal();

      // NOTE: Alerting must be AFTER creation occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: response.data.id,
            notifyProjectId: response.data.project_id,
            notifyResourceId: response.data.resource_id,
            notifyContext: "assignment-new",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }

      // The below code is all for legacy callback purposes.
      const [personResponse, statusResponse] = await Promise.all([
         PersonStore.getPerson(assignment.resource_id).payload,
         response.data.status_id != null
            ? StatusStore.getStatus(response.data.status_id).payload
            : null,
      ]);
      const [person, status] = [personResponse.data, statusResponse?.data];

      const position =
         person.job_title != null
            ? (await PositionStore.getPosition(person.job_title.id).payload).data
            : null;

      const assignmentModel = new Assignment({
         cost_code_id: response.data.category_id,
         created_at: response.data.created_at,
         end_day: response.data.end_day,
         end_time: response.data.end_time,
         id: response.data.id,
         label_id: response.data.subcategory_id,
         percent_allocated: response.data.percent_allocated,
         project_id: response.data.project_id,
         resource_id: response.data.resource_id,
         start_day: response.data.start_day,
         start_time: response.data.start_time,
         status:
            status != null
               ? {
                    id: status.id,
                    name: status.name,
                    abbreviation: status.abbreviation ?? "",
                    color: status.color,
                    sequence: status.sequence,
                 }
               : null,
         work_days: response.data.work_days,
      });
      const category =
         project.data.categories.find((cat) => cat.id == assignment.category_id) ?? null;
      const subcategory =
         category?.subcategories.find((sc) => sc.id == assignment.subcategory_id) ?? null;
      (assignmentModel as any).baggage = observable({
         cost_code_name: category?.name ?? null,
         employee_number: person.employee_number,
         group_ids: person.group_ids, // TODO: Confirm that this is correct.
         hourly_wage: person.hourly_wage, // TODO: Does this need to account for overrides?
         job_number: project.data.job_number,
         label_name: subcategory?.name ?? null,
         person_assignable_group_ids: person.assignable_group_ids,
         person_group_ids: person.group_ids,
         person_name: person.name,
         position_color: position?.color ?? null,
         position_rate: position?.hourly_rate ?? null,
         position_sequence: position?.sequence ?? null,
         project_color: project.data.color,
         project_name: project.data.name,
         project_status: project.data.status,
         wage_overrides: project.data.wage_overrides,
      });
      this.legacyCreateEditDeleteAssignmentCallback([assignmentModel], []);
   };

   deleteAssignment = async ({
      assignment,
      totalAssignmentRange,
      showAlertModal,
   }: {
      assignment: SerializedFindAssignment;
      totalAssignmentRange: AssignmentRange;
      showAlertModal?: boolean;
   }) => {
      const addedAssignments = [];
      if (totalAssignmentRange == null) {
         await Assignment2Store.deleteAssignment(assignment.id).payload;
      } else {
         const assignments = await this.breakoutAssignmentEditDelete({
            assignment,
            totalAssignmentRange,
            editOrDelete: "delete",
         });
         for (const a of assignments) {
            addedAssignments.push(a);
         }
      }
      const removedAssignmentData: GanttPageRemovedAssignmentData = {
         costCodeId: assignment.category_id,
         id: assignment.id,
         labelId: assignment.subcategory_id,
         projectId: assignment.project_id,
         resourceId: assignment.resource_id,
      };

      // NOTE: Alerting must be AFTER the deletion occurs.
      if (showAlertModal == true) {
         const modalData: ModalData = {
            notifyBatchId: assignment.id,
            notifyProjectId: assignment.project_id,
            notifyResourceId: assignment.resource_id,
            notifyContext: "assignment-delete",
            ...(totalAssignmentRange
               ? {
                    removedAssignmentInfo: {
                       startDay: assignment.start_day,
                       endDay: assignment.end_day,
                    },
                 }
               : {}),
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }

      this.legacyCreateEditDeleteAssignmentCallback(addedAssignments, [removedAssignmentData]);
   };

   showAssignmentMessageModal = (modalData: ModalData, callback: () => void): void => {
      alertManager.showAssignmentMessageModal(modalData, null, callback);
   };

   private async breakoutAssignmentEditDelete({
      assignment,
      editOrDelete,
      totalAssignmentRange,
      update = {},
   }: {
      assignment: SerializedFindAssignment;
      editOrDelete: "edit" | "delete";
      totalAssignmentRange: Exclude<AssignmentRange, null>;
      update?: AssignmentDetailsModalStagedUpdate;
   }) {
      /**
       * Standard flow for breakout-assignment updates:
       * 1. Truncate current assignment.
       *    1a. If the occurrence is at the beginning of the total assignment, set start_day
       *       to the next valid work day after the occurence.
       *    1b. Else, set end_day to last valid work day before the occurence.
       * 2. Handle changes to the current assignment occurrence.
       *    2a. If editing, create a new assignment with all of the changes reflected.
       *    2b. If deleting, just don't create a new assignment.
       * 3. Create new assignment for the week after the occurance, if necessary.
       */
      const previousEndDate = getLastWorkDate({
         fromDate: getAttachedDate(assignment.start_day),
         workDays: assignment.work_days ?? update.work_days,
      });
      const previousEndDay = previousEndDate != null ? getDetachedDay(previousEndDate) : null;
      if (previousEndDay == null) {
         Bugsnag.notify("Tried to get an invalid previousEndDay.", (event) => {
            event.context = "gantt-page_breakoutAssignmentEditDelete";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata(BUGSNAG_META_TAB.ASSIGNMENT, assignment);
            event.addMetadata("edit or delete", { editOrDelete: editOrDelete });
         });
         throw new Error("An unexpected error occurred.");
      }
      const nextStartDate = getNextWorkDate({
         fromDate: getAttachedDate(assignment.end_day),
         workDays: update.work_days ?? assignment.work_days,
      });
      const nextStartDay = nextStartDate != null ? getDetachedDay(nextStartDate) : null;
      if (nextStartDay == null) {
         Bugsnag.notify("Tried to get an invalid nextStartDay.", (event) => {
            event.context = "gantt-page_breakoutAssignmentEditDelete";
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata(BUGSNAG_META_TAB.ASSIGNMENT, assignment);
            event.addMetadata("edit or delete", { editOrDelete: editOrDelete });
         });
         throw new Error("An unexpected error occurred.");
      }

      /**
       * 1. Truncate current assignment.
       *    1a. If the occurrence is at the beginning of the total assignment, set start_day
       *       to the next valid work day after the occurrence.
       *    1b. Else, set end_day to last valid work day before the occurrence.
       */
      const updateData =
         previousEndDay < totalAssignmentRange.startDay
            ? { start_day: nextStartDay }
            : { end_day: previousEndDay };
      const newAssignments: CreateAssignmentPayload[] = [];

      /**
       * 2. Handle changes to the current assignment occurrence.
       *    2a. If editing, create a new assignment with all of the changes reflected.
       *    2b. If deleting, just don't create a new assignment.
       */
      if (editOrDelete === "edit") {
         newAssignments.push({
            category_id:
               update.category_id !== undefined ? update.category_id : assignment.category_id,
            end_day: update.end_day ?? assignment.end_day,
            subcategory_id:
               update.subcategory_id !== undefined
                  ? update.subcategory_id
                  : assignment.subcategory_id,
            overtime: update.overtime !== undefined ? update.overtime : assignment.overtime,
            overtime_rates:
               update.overtime_rates !== undefined
                  ? update.overtime_rates
                  : assignment.overtime_rates,
            pay_split: update.pay_split !== undefined ? update.pay_split : assignment.pay_split,
            project_id: update.project_id ?? assignment.project_id,
            resource_id: update.resource_id ?? assignment.resource_id,
            start_day: update.start_day ?? assignment.start_day,
            work_days: update.work_days ?? assignment.work_days,

            status_id: update.status_id !== undefined ? update.status_id : assignment.status_id,
            percent_allocated:
               update.percent_allocated !== undefined
                  ? update.percent_allocated
                  : update.start_time != null || update.end_time != null
                  ? null
                  : assignment.percent_allocated,
            end_time:
               update.end_time !== undefined
                  ? update.end_time
                  : update.percent_allocated != null
                  ? null
                  : assignment.end_time,
            start_time:
               update.start_time !== undefined
                  ? update.start_time
                  : update.percent_allocated != null
                  ? null
                  : assignment.start_time,

            custom_fields: assignment.custom_fields
               .map((field) => ({ [field.field_id]: field.value }))
               .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
         });
      }

      /**
       * 3. Create new assignment for the week after the occurrence, if necessary.
       */
      if (updateData.start_day == null && totalAssignmentRange.endDay > assignment.end_day) {
         newAssignments.push({
            category_id: assignment.category_id,
            custom_fields: assignment.custom_fields
               .map((field) => ({ [field.field_id]: field.value }))
               .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
            end_day: totalAssignmentRange.endDay,
            end_time: assignment.end_time,
            overtime_rates: assignment.overtime_rates,
            overtime: assignment.overtime,
            pay_split: assignment.pay_split,
            percent_allocated: assignment.percent_allocated,
            project_id: assignment.project_id,
            resource_id: assignment.resource_id,
            start_day: nextStartDay,
            start_time: assignment.start_time,
            status_id: assignment.status_id,
            subcategory_id: assignment.subcategory_id,
            work_days: assignment.work_days,
         });
      }

      return await LegacyStore.breakoutAssignments({
         newAssignmentsPayload: newAssignments,
         originalAssignment: assignment,
         updateData,
      });
   }

   private async proxyUpdateAssignment({
      assignment,
      totalAssignmentRange,
      update,
   }: {
      assignment: SerializedFindAssignment;
      totalAssignmentRange: AssignmentRange;
      update: AssignmentDetailsModalStagedUpdate;
      // Will probably have to change return type.
   }): Promise<Assignment[]> {
      if (totalAssignmentRange == null) {
         const response = await Assignment2Store.updateSingleAssignment({
            assignmentId: assignment.id,
            update,
         }).payload;

         // The following code is all for legacy data-fit purposes.
         const resourceId = update.resource_id ?? assignment.resource_id;
         const [personData, statusData] = await Promise.all([
            PersonStore.getPerson(resourceId).payload,
            response.data.status_id != null
               ? StatusStore.getStatus(response.data.status_id).payload
               : null,
         ]);
         const [person, status] = [personData.data, statusData?.data ?? null];
         const addedAssignment = new Assignment({
            cost_code_id: response.data.category_id,
            created_at: response.data.created_at,
            end_day: response.data.end_day,
            end_time: response.data.end_time,
            id: response.data.id,
            label_id: response.data.subcategory_id,
            percent_allocated: response.data.percent_allocated,
            project_id: response.data.project_id,
            resource_id: response.data.resource_id,
            start_day: response.data.start_day,
            start_time: response.data.start_time,
            status:
               status != null
                  ? {
                       id: status.id,
                       name: status.name,
                       abbreviation: status.abbreviation ?? "",
                       color: status.color,
                       sequence: status.sequence,
                    }
                  : null,
            work_days: response.data.work_days,
         });
         const project = update.project ?? assignment.project;

         const category =
            project.categories.find((cat) => cat.id == response.data.category_id) ?? null;
         const subcategory =
            category?.subcategories.find((sc) => sc.id == response.data.subcategory_id) ?? null;

         const position = person.job_title ?? (await this.safeGetPosition(person.job_title_id));

         (addedAssignment as any).baggage = observable({
            cost_code_name: category?.name ?? null,
            employee_number: person.employee_number,
            group_ids: person.group_ids, // TODO: Confirm that this is correct.
            hourly_wage: person.hourly_wage, // TODO: Does this need to account for overrides?
            job_number: project.job_number,
            label_name: subcategory?.name ?? null,
            person_assignable_group_ids: person.assignable_group_ids,
            person_group_ids: person.group_ids,
            person_name: person.name,
            position_color: position?.color ?? null,
            position_rate: position?.hourly_rate ?? null,
            position_sequence: position?.sequence ?? null,
            project_color: project.color,
            project_name: project.name,
            project_status: project.status,
            status:
               status != null
                  ? {
                       id: status.id,
                       name: status.name,
                       abbreviation: status.abbreviation ?? "",
                       color: status.color,
                       sequence: status.sequence,
                    }
                  : null,
            wage_overrides: project.wage_overrides,
         });
         return [addedAssignment];
      } else {
         return this.breakoutAssignmentEditDelete({
            assignment,
            totalAssignmentRange,
            editOrDelete: "edit",
            update,
         });
      }
   }

   /**
    * We use this method wherever we would like to swallow errors from getPosition
    * and continue moving forward as if nothing happened.
    * Still shows an "Unexpected Network Error" Notification to the user (via the store).
    */
   private async safeGetPosition(jobTitleId: string | null): Promise<SerializedPosition | null> {
      try {
         return jobTitleId != null
            ? (await PositionStore.getPosition(jobTitleId).payload).data
            : null;
      } catch (error) {
         if (process.env.NODE_ENV == "production") {
            Bugsnag.notify("Tried to retrieve invalid job title id.", (event) => {
               event.context = "gantt-page_safeGetPosition";
               event.addMetadata(
                  BUGSNAG_META_TAB.USER_DATA,
                  buildUserData(authManager.authedUser()!, authManager.activePermission),
               );
               event.addMetadata("job title id", { id: jobTitleId });
            });
         }
         return null;
      }
   }

   updateAssignment = async ({
      assignment,
      totalAssignmentRange,
      update,
      showAlertModal,
   }: {
      assignment: SerializedFindAssignment;
      totalAssignmentRange: AssignmentRange;
      update: AssignmentDetailsModalStagedUpdate;
      showAlertModal?: boolean;
   }) => {
      const addedAssignments = await this.proxyUpdateAssignment({
         assignment,
         totalAssignmentRange,
         update,
      });
      modalManager.clearModal();

      // NOTE: Alerting must be AFTER the update occurs.
      if (showAlertModal == true) {
         /*
         For notifyBatchId, the reason we are doing a length check is to handle editing occurrences vs editing the entire assignment.
         When editing an entire assignment, the index of the newly edited portion is always [0].
         When editing an **occurrence**, the index of the newly edited portion is always [1].
         Editing total assignment: length == 1
         Editing mid-assignment occurrence: length == 3
         Editing First assignment occurrence: length == 2
         Editing last assignment occurrence: length == 2
         */
         const modalData: ModalData = {
            notifyBatchId:
               addedAssignments.length > 1 ? addedAssignments[1].id : addedAssignments[0].id,
            notifyProjectId: update.project_id ?? assignment.project_id,
            notifyResourceId: update.resource_id ?? assignment.resource_id,
            notifyContext: "assignment-edit",
         };
         // Callback does not need to do anything.
         this.showAssignmentMessageModal(modalData, function () {});
      }

      // The below code is all for legacy callback purposes.
      const removedAssignment: GanttPageRemovedAssignmentData = {
         costCodeId: assignment.category_id,
         id: assignment.id,
         labelId: assignment.subcategory_id,
         projectId: assignment.project_id,
         resourceId: assignment.resource_id,
      };

      this.legacyCreateEditDeleteAssignmentCallback(addedAssignments, [removedAssignment]);
   };

   readonly createRequest = async (request: CreateRequestPayload) => {
      const [response, project] = await Promise.all([
         RequestStore.createRequest(request).payload,
         ProjectStore.getProject(request.project_id).payload,
      ]);
      modalManager.clearModal();

      // The below code is all for legacy callback purposes.
      if (response.data.length == 0) return;

      const [jobTitleData, statusData] = await Promise.all([
         response.data[0].job_title_id != null
            ? PositionStore.getPosition(response.data[0].job_title_id).payload
            : null,
         response.data[0].status_id != null
            ? StatusStore.getStatus(response.data[0].status_id).payload
            : null,
      ]);

      const placeholders = response.data.map((newRequest) => {
         const placeholder = new Placeholder({
            cost_code_id: newRequest.category_id,
            created_at: newRequest.created_at,
            creator_id: authManager.authedUserId(),
            creator_name: authManager.authedUser.name,
            end_day: newRequest.end_day,
            end_time: newRequest.end_time,
            id: newRequest.id,
            instruction_text: newRequest.instruction_text,
            label_id: newRequest.subcategory_id,
            percent_allocated: newRequest.percent_allocated,
            position_id: newRequest.job_title_id,
            position: jobTitleData?.data ?? null,
            project_id: newRequest.project_id,
            start_day: newRequest.start_day,
            start_time: newRequest.start_time,
            status:
               statusData != null
                  ? {
                       abbreviation: statusData.data.abbreviation ?? "",
                       color: statusData.data.color,
                       id: statusData.data.id,
                       name: statusData.data.name,
                       sequence: statusData.data.sequence,
                    }
                  : null,
            work_days: newRequest.work_days,
            work_scope_text: newRequest.work_scope_text,

            // Would require another network request to get tag data, but
            // this doesn't appear to be used so we're sending blanks for now.
            // Required by the Placeholder frontend model.
            tags: [],
         });
         const category =
            project.data.categories.find((cat) => cat.id == request.category_id) ?? null;
         const subcategory =
            category?.subcategories.find((sc) => sc.id == request.subcategory_id) ?? null;
         (placeholder as any).baggage = observable({
            cost_code_name: category?.name ?? null,
            job_number: project.data.job_number,
            label_name: subcategory?.name ?? null,
            project_color: project.data.color,
            project_name: project.data.name,
            project_status: project.data.status,
         });
         return placeholder as Placeholder<FilteredRequestBaggage>;
      });
      this.legacyCreateEditDeleteRequestCallback(placeholders, []);
   };

   deleteRequest = async (request: SerializedFindRequest) => {
      await RequestStore.deleteRequest(request.id).payload;
      const removedPlaceholder: GanttPageRemovedRequestData = {
         costCodeId: request.category_id,
         id: request.id,
         labelId: request.subcategory_id,
         projectId: request.project_id,
      };

      this.legacyCreateEditDeleteRequestCallback([], [removedPlaceholder]);
   };

   updateRequest = async ({
      request,
      update,
   }: {
      request: SerializedFindRequest;
      update: RequestDetailsModalStagedUpdate;
   }) => {
      const response = await RequestStore.updateSingleRequest(request.id!, update).payload;
      const project = update.project ?? request.project;
      modalManager.clearModal();

      // The below code is all for legacy callback purposes.
      const [jobTitleData, statusData] = await Promise.all([
         response.data.job_title_id != null
            ? PositionStore.getPosition(response.data.job_title_id).payload
            : null,
         response.data.status_id != null
            ? StatusStore.getStatus(response.data.status_id).payload
            : null,
      ]);
      const removedPlaceholder: GanttPageRemovedRequestData = {
         costCodeId: request.category_id,
         id: request.id,
         labelId: request.subcategory_id,
         projectId: request.project_id,
      };
      const addedPlaceholder = new Placeholder({
         ...(request.creator ? { creator_name: formatName(request.creator.name) } : {}),
         cost_code_id: response.data.category_id,
         created_at: response.data.created_at,
         creator_id: response.data.creator_id,
         end_day: response.data.end_day,
         end_time: response.data.end_time,
         id: response.data.id,
         instruction_text: response.data.instruction_text,
         label_id: response.data.subcategory_id,
         percent_allocated: response.data.percent_allocated,
         position_id: response.data.job_title_id,
         position: jobTitleData?.data ?? null,
         project_id: response.data.project_id,
         start_day: response.data.start_day,
         start_time: response.data.start_time,
         status:
            statusData != null
               ? {
                    abbreviation: statusData.data.abbreviation ?? "",
                    color: statusData.data.color,
                    id: statusData.data.id,
                    name: statusData.data.name,
                    sequence: statusData.data.sequence,
                 }
               : null,
         work_days: response.data.work_days,
         work_scope_text: response.data.work_scope_text,

         // Would require another network request to get tag data, but
         // this doesn't appear to be used so we're sending blanks for now.
         // Required by the Placeholder frontend model.
         tags: [],
      });
      const category =
         project.categories.find((cat) => cat.id == response.data.category_id) ?? null;
      const subcategory =
         category?.subcategories.find((sc) => sc.id == response.data.subcategory_id) ?? null;
      (addedPlaceholder as any).baggage = observable({
         cost_code_name: category?.name ?? null,
         job_number: project.job_number,
         label_name: subcategory?.name ?? null,
         project_color: project.color,
         project_name: project.name,
         project_status: project.status,
      });
      this.legacyCreateEditDeleteRequestCallback(
         [addedPlaceholder as Placeholder<FilteredRequestBaggage>],
         [removedPlaceholder],
      );
   };
}

export type GanttPageRemovedAssignmentData = {
   costCodeId: string | null;
   id: string;
   labelId: string | null;
   projectId: string;
   resourceId: string;
};

export type GanttPageRemovedRequestData = {
   costCodeId: string | null;
   id: string;
   labelId: string | null;
   projectId: string;
};

export type GanttPageParams = {
   legacyCreateEditDeleteAssignmentCallback: (
      addedAssignments: Assignment[],
      removedAssignmentsData: GanttPageRemovedAssignmentData[],
   ) => void;
   legacyCreateEditDeleteRequestCallback: (
      addedRequests: Array<Placeholder<FilteredRequestBaggage>>,
      removedRequestsData: GanttPageRemovedRequestData[],
   ) => void;
};

export type AssignmentRange = {
   startDay: number;
   endDay: number;
} | null;

export class GanttPage {
   private readonly state: GanttPageState;
   private readonly canManageAssignments: boolean;
   private readonly canManageRequests: boolean;

   constructor({
      legacyCreateEditDeleteAssignmentCallback = () => {},
      legacyCreateEditDeleteRequestCallback = () => {},
   }: GanttPageParams) {
      this.state = new GanttPageState({
         legacyCreateEditDeleteAssignmentCallback,
         legacyCreateEditDeleteRequestCallback,
      });
      this.canManageAssignments = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_ASSIGNMENTS,
      );
      this.canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS);
   }

   createEditDeleteAssignment = ({
      assignment,
      totalAssignmentRange,
      newAssignmentSeedData,
      initialPane = AssignmentDetailsModalPane.EDIT_ASSIGNMENT,
   }: {
      assignment: PureComputed<SerializedFindAssignment> | null;
      totalAssignmentRange: AssignmentRange;
      newAssignmentSeedData?: Partial<Pick<StagedAssignment, "project">>;
      initialPane?: AssignmentDetailsModalPane;
   }): void => {
      if (!this.canManageAssignments) return;
      modalManager.setModal({
         modal: AssignmentDetailsModal.factory({
            assignment,
            // TODO: Adjust dependent on what this is doing.
            assignmentDetailsModalType:
               assignment == null
                  ? AssignmentDetailsModalType.CREATE_ASSIGNMENT
                  : AssignmentDetailsModalType.EDIT_ASSIGNMENT,
            createAssignment: this.state.createAssignment,
            customFields: this.state.assignmentCustomFields,
            deleteAssignment: (params) => {
               return this.state.deleteAssignment({
                  ...params,
                  totalAssignmentRange,
               });
            },
            initialPane,
            newAssignmentSeedData,
            requestSize: modalManager.requestSize,
            supportData: this.state.assignmentSupportData,
            updateAssignment: (params) => {
               return this.state.updateAssignment({
                  ...params,
                  totalAssignmentRange,
               });
            },
         }),
      });
   };

   createEditDeleteRequest({
      newRequestSeedData,
      request,
      initialPane = RequestDetailsModalPane.EDIT_REQUEST,
   }: {
      newRequestSeedData?: Partial<Pick<StagedRequest, "project">>;
      request: PureComputed<SerializedFindRequest> | null;
      initialPane?: RequestDetailsModalPane;
   }): void {
      if (!this.canManageRequests) return;

      modalManager.setModal({
         modal: RequestDetailsModal.factory({
            createRequest: this.state.createRequest,
            customFields: this.state.requestCustomFields,
            deleteRequest: this.state.deleteRequest,
            initialPane,
            newRequestSeedData,
            request,
            requestSize: modalManager.requestSize,
            supportData: this.state.assignmentSupportData,
            tags: this.state.tags,
            updateRequest: this.state.updateRequest,
         }),
      });
   }

   async getSingleFindAssignment(assignmentId: string): Promise<SerializedFindAssignment | null> {
      const getAssignmentDetailsResponse: GetAssignmentDetailReponse =
         await Assignment2Store.getAssignmentDetails(assignmentId).payload;

      return getAssignmentDetailsResponse.data ?? null;
   }

   async getSingleFindRequest(requestId: string): Promise<SerializedFindRequest | null> {
      const foundRequest: SerializedFindRequest = (
         await RequestStore.findRequestsPaginated({
            limit: 1,
            sort_by: "id",
            starting_at: requestId,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         }).payload
      ).data[0];

      return foundRequest ?? null;
   }
}
