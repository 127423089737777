import template from "./company.pug";
import * as ko from "knockout";
import { Flag } from "../../../../flags";

// Auth, Real-Time & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { defaultStore } from "@/stores/default-store";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import { CompanyStore } from "@/stores/company-store.core";
import { SettingsStore } from "@/stores/settings-store.core";

// Popups
import type { PopupArrowLocation, PopupFrameType } from "@/lib/components/popup/popup";
import { Popup } from "@/lib/components/popup/popup";
import { ColorSelectorPane } from "@/lib/components/popup/color-selector-pane";

// Models
import type { CompanyData } from "@/models/company";
import { Branding, Company } from "@/models/company";
import { PermissionLevel } from "@/models/permission-level";

// UI Assets
import { DropDownItem } from "@/lib/components/drop-downs/drop-down";

// Modules
import type { UpdateCompanyPayload } from "@laborchart-modules/lc-core-api/dist/api/company/update-company";
import type { SupportedCurrency as Currency } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import { DateFormat } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

const DEFAULT_BRANDING_LOGO_URL = "/images/lc-logo-hor-small.png";
const DEFAULT_BRANDING_PRIMARY_COLOR = "#ff7508";
const DEFAULT_BRANDING_SECONDARY_COLOR = "#FF5100";

export class CompanyViewModel extends PageContentViewModel {
   private readonly canManageCompanySettings: boolean;
   private readonly company: ko.Observable<Company | null>;
   private readonly editingCompany: ko.Observable<Company | null>;
   private readonly selectedTimezone: ko.Observable<string | undefined>;
   private readonly timezoneFrameLocation: PopupFrameType;
   private readonly timezoneArrowLocation: PopupArrowLocation;
   private readonly isLoading: ko.Observable<boolean>;
   private readonly brandingSettingsEnabled: boolean;
   private readonly isSupportUser: boolean;
   private readonly dateFormatOptions: ko.ObservableArray<DropDownItem<DateFormat>>;
   private readonly selectedDateFormat: ko.Observable<DropDownItem<DateFormat> | undefined>;
   // TODO: Use the Currency enum instead of string.
   private readonly currencyOptions: ko.ObservableArray<DropDownItem<string>>;
   private readonly selectedCurrency: ko.Observable<DropDownItem<string> | undefined>;
   private readonly hasBranding: ko.PureComputed<boolean>;
   private readonly haveBrandingColorsChanged: ko.PureComputed<boolean>;
   private readonly isDefaultBrandingLogo: ko.PureComputed<boolean>;
   private readonly hasChangedBrandingLogo: ko.PureComputed<boolean>;
   private readonly hasBrandingChanged: ko.PureComputed<boolean>;
   private readonly hasDefaultLogoAndChangedColors: ko.PureComputed<boolean>;
   private readonly logoValidationMessage: ko.PureComputed<
      "Must change logo to save color changes." | ""
   >;
   private readonly canSaveGeneral: ko.PureComputed<boolean>;
   private readonly primaryColorSelectorPopupBuilder: () => Popup;
   private readonly primaryColorSelectorPopupWrapper: {
      popupBuilder: () => Popup;
      options: {
         triggerClasses: string[];
      };
   };
   private readonly secondaryColorSelectorPopupBuilder: () => Popup;
   private readonly secondaryColorSelectorPopupWrapper: {
      popupBuilder: () => Popup;
      options: {
         triggerClasses: string[];
      };
   };

   constructor() {
      super(template(), "Settings - Company");
      /*------------------------------------
         Permissions
      ------------------------------------*/
      this.canManageCompanySettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_COMPANY_SETTINGS,
      );

      // General Panel
      this.company = ko.observable(null);
      this.editingCompany = ko.observable(null);
      this.selectedTimezone = ko.observable();
      this.timezoneFrameLocation = Popup.FrameType.ABOVE;
      this.timezoneArrowLocation = Popup.ArrowLocation.BOTTOM_LEFT;
      this.isLoading = ko.observable(false);
      this.brandingSettingsEnabled = Flag.ENABLE_BRANDING_SETTINGS;
      this.isSupportUser = authManager.isSupportUser();
      // Date Format
      this.dateFormatOptions = ko.observableArray<DropDownItem<DateFormat>>([
         new DropDownItem<DateFormat>(DateFormat.MM_DD_YYYY, DateFormat.MM_DD_YYYY),
         new DropDownItem<DateFormat>(DateFormat.DD_MM_YYYY, DateFormat.DD_MM_YYYY),
         new DropDownItem<DateFormat>(DateFormat.YYYY_MM_DD, DateFormat.YYYY_MM_DD),
         new DropDownItem<DateFormat>(DateFormat.YYYY_DD_MM, DateFormat.YYYY_DD_MM),
         new DropDownItem<DateFormat>(DateFormat.DD_MM_YYYY_DASHED, DateFormat.DD_MM_YYYY_DASHED),
         new DropDownItem<DateFormat>(DateFormat.MM_DD_YYYY_DASHED, DateFormat.MM_DD_YYYY_DASHED),
         new DropDownItem<DateFormat>(DateFormat.YYYY_MM_DD_DASHED, DateFormat.YYYY_MM_DD_DASHED),
      ]);
      this.selectedDateFormat = ko.observable();
      // Currency
      this.currencyOptions = ko.observableArray([
         new DropDownItem("USD", "usd"),
         new DropDownItem("AUD", "aud"),
         new DropDownItem("CAD", "cad"),
         new DropDownItem("Danish Krone", "danish-krone"),
         new DropDownItem("Euro", "euro"),
         new DropDownItem("Norwegian Krone", "norwegian-krone"),
         new DropDownItem("Pound", "pound"),
         new DropDownItem("Swedish Krona", "swedish-krona"),
      ]);
      this.selectedCurrency = ko.observable();
      this.loadCompany();
      this.hasBranding = ko.pureComputed(
         () => this.editingCompany()?.branding() != null && this.company()?.branding() != null,
      );
      this.haveBrandingColorsChanged = ko.pureComputed(() => {
         if (!this.hasBranding()) {
            return false;
         }
         return (
            this.editingCompany()?.branding()?.primaryColor() !==
               this.company()?.branding()?.primaryColor() ||
            this.editingCompany()?.branding()?.secondaryColor() !==
               this.company()?.branding()?.secondaryColor()
         );
      });
      this.isDefaultBrandingLogo = ko.pureComputed(() => {
         if (!this.hasBranding()) {
            return false;
         }
         return this.editingCompany()?.branding()?.logoUrl() === DEFAULT_BRANDING_LOGO_URL;
      });
      this.hasChangedBrandingLogo = ko.pureComputed(() => {
         if (!this.hasBranding() || this.isDefaultBrandingLogo()) {
            return false;
         }
         return (
            this.editingCompany()?.branding()?.logoUrl() !== this.company()?.branding()?.logoUrl()
         );
      });
      this.hasBrandingChanged = ko.pureComputed(() => {
         if (!this.hasBranding()) {
            return false;
         }
         if (this.hasChangedBrandingLogo()) {
            return true;
         }
         if (this.haveBrandingColorsChanged() && !this.isDefaultBrandingLogo()) {
            return true;
         }
         return false;
      });
      this.hasDefaultLogoAndChangedColors = ko.pureComputed(() => {
         if (!this.hasBranding()) {
            return false;
         }
         if (this.isDefaultBrandingLogo() && this.haveBrandingColorsChanged()) {
            return true;
         }
         return false;
      });
      this.logoValidationMessage = ko.pureComputed(() => {
         if (this.hasDefaultLogoAndChangedColors()) {
            return "Must change logo to save color changes.";
         }
         return "";
      });
      this.canSaveGeneral = ko.pureComputed(() => {
         const company = this.company();
         const editingCompany = this.editingCompany();
         if (company == null || editingCompany == null) {
            return false;
         }
         return (
            editingCompany.name() !== company.name() ||
            editingCompany.address1() !== company.address1() ||
            editingCompany.address2() !== company.address2() ||
            editingCompany.cityTown() !== company.cityTown() ||
            editingCompany.stateProvince() !== company.stateProvince() ||
            editingCompany.zipcode() !== company.zipcode() ||
            editingCompany.country() !== company.country() ||
            (this.selectedDateFormat() != null &&
               this.selectedDateFormat()!.value() !== company.dateFormatPreference()) ||
            (this.selectedCurrency() != null &&
               this.selectedCurrency()!.value() !== company.currency()) ||
            editingCompany.contactName() !== company.contactName() ||
            editingCompany.contactPhone() !== company.contactPhone() ||
            editingCompany.contactEmail() !== company.contactEmail() ||
            editingCompany.tbdWeeks() !== company.tbdWeeks() ||
            this.selectedTimezone() !== editingCompany.timezone() ||
            this.hasBrandingChanged()
         );
      });
      this.primaryColorSelectorPopupBuilder = () => {
         return new Popup(
            "Primary Color",
            Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_LEFT,
            [
               new ColorSelectorPane(
                  this.editingCompany()?.branding()?.primaryColor ?? ko.observable(""),
               ),
            ],
            ["settings-pane__color-btn", "settings-pane__color-btn__color"],
            ["settings-pane__color-popup", "popup--color-selector"],
         );
      };
      this.primaryColorSelectorPopupWrapper = {
         popupBuilder: this.primaryColorSelectorPopupBuilder,
         options: {
            triggerClasses: ["settings-pane__color-btn__color"],
         },
      };
      this.secondaryColorSelectorPopupBuilder = () => {
         return new Popup(
            "Accent Color",
            Popup.FrameType.BELOW,
            Popup.ArrowLocation.TOP_LEFT,
            [
               new ColorSelectorPane(
                  this.editingCompany()?.branding()?.secondaryColor ?? ko.observable(""),
               ),
            ],
            ["settings-pane__color-btn", "settings-pane__color-btn__color"],
            ["settings-pane__color-popup", "popup--color-selector"],
         );
      };
      this.secondaryColorSelectorPopupWrapper = {
         popupBuilder: this.secondaryColorSelectorPopupBuilder,
         options: {
            triggerClasses: ["settings-pane__color-btn__color"],
         },
      };
   }

   private clearObservable(observableToClear: ko.Observable) {
      return observableToClear(null);
   }

   private formatDisplayPhoneNumber(number: number) {
      const n = String(number);
      return `${n.slice(0, 2)}-${n.slice(2, 5)}-${n.slice(5, 8)}-${n.slice(8, 12)}`;
   }

   private cancelGeneralPanelChanges() {
      const company = this.company();
      const editingCompany = this.editingCompany();
      if (company == null || editingCompany == null) {
         return;
      }
      const tempBranding = editingCompany.branding();
      editingCompany.mapProperties(company);
      editingCompany.branding(tempBranding);
      editingCompany
         .branding()
         ?.logoUrl(company.branding()?.logoUrl() ?? DEFAULT_BRANDING_LOGO_URL);
      editingCompany
         .branding()
         ?.primaryColor(company.branding()?.primaryColor() ?? DEFAULT_BRANDING_PRIMARY_COLOR);
      editingCompany
         .branding()
         ?.secondaryColor(company.branding()?.secondaryColor() ?? DEFAULT_BRANDING_SECONDARY_COLOR);

      for (const option of this.dateFormatOptions()) {
         if (option.value() == company.dateFormatPreference()) {
            this.selectedDateFormat(option);
         }
      }

      for (const option of this.currencyOptions()) {
         if (option.value() == company.currency()) {
            this.selectedCurrency(option);
         }
      }
   }

   private async saveGeneralPanelChanges() {
      const data: Partial<CompanyData> = {};
      const company = this.company();
      const editingCompany = this.editingCompany();
      if (company == null || editingCompany == null) {
         return;
      }
      if (editingCompany.name() !== company.name()) {
         data["name"] = editingCompany.name();
      }
      if (editingCompany.address1() !== company.address1()) {
         data["address_1"] = editingCompany.address1();
      }
      if (editingCompany.address2() !== company.address2()) {
         data["address_2"] = editingCompany.address2();
      }
      if (editingCompany.cityTown() !== company.cityTown()) {
         data["city_town"] = editingCompany.cityTown();
      }
      if (editingCompany.stateProvince() !== company.stateProvince()) {
         data["state_province"] = editingCompany.stateProvince();
      }
      if (editingCompany.zipcode() !== company.zipcode()) {
         data["zipcode"] = editingCompany.zipcode();
      }
      if (editingCompany.country() !== company.country()) {
         data["country"] = editingCompany.country();
      }
      if (this.selectedDateFormat()?.value() !== company.dateFormatPreference()) {
         data["date_format_preference"] = this.selectedDateFormat()?.value();
      }
      if (this.selectedCurrency()?.value() !== company.currency()) {
         data["currency"] = this.selectedCurrency()?.value();
      }
      if (editingCompany.contactName() !== company.contactName()) {
         data["contact_name"] = editingCompany.contactName();
      }
      if (editingCompany.contactPhone() !== company.contactPhone()) {
         data["contact_phone"] = editingCompany.contactPhone();
      }
      if (editingCompany.contactEmail() !== company.contactEmail()) {
         data["contact_email"] = editingCompany.contactEmail();
      }
      if (this.selectedTimezone() !== company.timezone()) {
         data["timezone"] = this.selectedTimezone();
      }
      if (editingCompany.tbdWeeks() !== company.tbdWeeks()) {
         data["tbd_weeks"] =
            Number(editingCompany.tbdWeeks()) > 0 ? Number(editingCompany.tbdWeeks()) : 1;
      }
      if (this.hasBrandingChanged()) {
         const branding = editingCompany.branding()!;
         data["branding"] = {
            logo_url: branding.logoUrl(),
            primary_color: branding.primaryColor(),
            secondary_color: branding.secondaryColor(),
         };
      }
      if (Object.keys(data).length === 0) {
         return;
      }
      try {
         const formattedData: UpdateCompanyPayload = {
            ...data,
            currency: data.currency as Currency,
         };
         const companyResponse = (await SettingsStore.updateCompany(formattedData).payload).data;
         const company = new Company({
            ...companyResponse,
            alert_config: companyResponse.alert_config ?? null,
            contact_email: companyResponse.contact_email ?? undefined,
            contact_name: companyResponse.contact_name ?? undefined,
            contact_phone: companyResponse.contact_phone ?? undefined,
            timezone: companyResponse.timezone ?? "",
            twilio_number: companyResponse.twilio_number ?? undefined,
         });
         this.ensureBrandingDefined(company);
         this.editingCompany(company.clone(Company));
         this.company(company);
         // Update in-app format preferences
         return defaultStore.setDateFormat(company.dateFormatPreference());
      } catch (err) {
         console.log("error: ", err);
      }
   }

   private async loadCompany() {
      try {
         const companyResponse = (await CompanyStore.getCompany().payload).data;
         const company = new Company({
            ...companyResponse,
            alert_config: companyResponse.alert_config ?? null,
            contact_email: companyResponse.contact_email ?? undefined,
            contact_name: companyResponse.contact_name ?? undefined,
            contact_phone: companyResponse.contact_phone ?? undefined,
            timezone: companyResponse.timezone ?? "",
            twilio_number: companyResponse.twilio_number ?? undefined,
         });
         this.ensureBrandingDefined(company);
         this.editingCompany(company.clone(Company));
         this.selectedTimezone(company.timezone());
         this.company(company);
         // Compute any other vals.
         for (const option of this.dateFormatOptions()) {
            if (option.value() == company.dateFormatPreference()) {
               this.selectedDateFormat(option);
            }
         }

         for (const option of this.currencyOptions()) {
            if (option.value() == company.currency()) {
               this.selectedCurrency(option);
            }
         }
      } catch (err) {
         console.log("error: ", err);
      }
   }

   private ensureBrandingDefined = (company: Company) => {
      if (!company.branding()) {
         company.branding(
            new Branding({
               logo_url: DEFAULT_BRANDING_LOGO_URL,
               primary_color: DEFAULT_BRANDING_PRIMARY_COLOR,
               secondary_color: DEFAULT_BRANDING_SECONDARY_COLOR,
            }),
         );
      }
   };
}
