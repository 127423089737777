import type { BatchEditor } from "@/lib/components/batch-edit/batch-edit";
import type { FilteredPeopleAssignment } from "@/stores/people-store";
import { Person } from "@/models/person";
import type { Observable } from "knockout";
import { pureComputed } from "knockout";
import { observable } from "knockout";
import type { CurrencyEditorParams } from "@/lib/components/editors/currency-editor/currency-editor";
import { CurrencyEditor } from "@/lib/components/editors/currency-editor/currency-editor";
import type { ComponentArgs } from "@/lib/components/common";
import type { DetachedDayEditorParams } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import { DetachedDayEditor } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import type { TextEditorParams } from "@/lib/components/editors/text-editor/text-editor";
import { TextEditor } from "@/lib/components/editors/text-editor/text-editor";
import type { DropDownEditorParams } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { DropDownEditor } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { DefaultStoreCore } from "@/stores/default-store.core";
import { getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { PermissionDropDownPane } from "@/lib/components/drop-downs/panes/permission-drop-down-pane";
import { NotificationProfileDropDownPane } from "@/lib/components/drop-downs/panes/notification-profile-drop-down-pane";
import type { PersonNameEditorParams } from "@/lib/components/editors/person-name-editor/person-name-editor";
import { PersonNameEditor } from "@/lib/components/editors/person-name-editor/person-name-editor";
import { GridStoreRowsUpdater } from "@/lib/utils/grid-store/grid-store-rows-updater";
import { PersonStore } from "@/stores/person-store.core";
import type {
   FindPeoplePaginatedQueryParams,
   SerializedPeopleListPerson,
   StreamPersonUpdatesPayload,
   UpdatePersonPayload,
} from "@laborchart-modules/lc-core-api/dist/api/people";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import type { PhoneEditorParams } from "@/lib/components/editors/phone-editor/phone-editor";
import { PhoneEditor } from "@/lib/components/editors/phone-editor/phone-editor";
import { validateEmail } from "@/lib/utils/validation";
import { JobTitleDropDownPane } from "@/lib/components/drop-downs/panes/job-title-drop-down-pane";
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { Format } from "@/lib/utils/format";
import type { TagInstancesEditorParams } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import { TagInstancesEditor } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import { PermissionLevel } from "@/models/permission-level";
import type { Person as PersonSchema } from "@laborchart-modules/common/dist/rethink/schemas/people";
import { DateUtils } from "@/lib/utils/date";
import { Attachment } from "@/models/attachment";
import type { CustomFieldMeta } from "@/models/column-header";
import { customFieldUpdateRowApplier } from "@/lib/utils/custom-field-instance";
import { authManager } from "@/lib/managers/auth-manager";
import type {
   PersonTypeEditorParams,
   PersonTypeValue,
} from "@/lib/components/editors/person-type-editor/person-type-editor";
import { PersonTypeEditor } from "@/lib/components/editors/person-type-editor/person-type-editor";
import { ApiError, parseError, ValidationError } from "@/stores/common/store-errors";
import type { MultiSelectItem } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import type {
   GroupsEditorParams,
   GroupsEditorUpdate,
} from "@/lib/components/editors/groups-editor/groups-editor";
import { GroupsEditor, GroupType } from "@/lib/components/editors/groups-editor/groups-editor";
import { ValidationErrorCode } from "@laborchart-modules/lc-core-api/dist/api/errors";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers/tag-serializer";
import type { SerializedTagInstance } from "@laborchart-modules/common/dist/rethink/serializers/tag-instance-serializer";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";
import type { SerializedPosition } from "@laborchart-modules/common/dist/rethink/serializers";
import type { JobTitleType as PositionType } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type { CheckboxEditorParams } from "@/lib/components/editors/checkbox-editor/checkbox-editor";
import { CheckboxEditor } from "@/lib/components/editors/checkbox-editor/checkbox-editor";
import type { PersonStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/people";
import type { SupportedLanguage as Language } from "@laborchart-modules/common/dist/postgres/schemas/common/enums";
import type {
   KeysetGridStoreParams,
   ResponsePayload,
} from "@/lib/components/grid/keyset-grid-store";
import { KeysetGridStore } from "@/lib/components/grid/keyset-grid-store";
import type { StoreStreamResponse } from "@/stores/common/store.core";
import type { SerializedPermissionLevel } from "@laborchart-modules/common/dist/rethink/serializers/permission-level-serializer";
import type { SerializedNotificationProfile } from "@laborchart-modules/common/dist/rethink/serializers/notification-profile-serializer";
import type { DropDownPane } from "@/lib/components/drop-downs/drop-down-pane";
import type { BatchDeletePeoplePayload } from "@laborchart-modules/lc-core-api/dist/api/people/delete-person";

export interface PeopleList2GridStoreParams
   extends KeysetGridStoreParams<FindPeoplePaginatedQueryParams> {
   errorModalColumnGroups: Array<GridColumnGroup<SerializedPeopleListPerson>>;
}
export class PeopleList2GridStore extends KeysetGridStore<
   SerializedPeopleListPerson,
   FindPeoplePaginatedQueryParams
> {
   totalPossible: Observable<number> = observable(0);

   private readonly errorModalColumnGroups: Array<GridColumnGroup<SerializedPeopleListPerson>>;
   private readonly rowsUpdater: GridStoreRowsUpdater<
      SerializedPeopleListPerson,
      StreamPersonUpdatesPayload<AuthType.SESSION>
   >;
   private readonly rowsRemover: GridStoreRowsUpdater<
      SerializedPeopleListPerson,
      BatchDeletePeoplePayload
   >;

   constructor(params: PeopleList2GridStoreParams) {
      super(params);
      this.errorModalColumnGroups = params.errorModalColumnGroups;
      this.rowsUpdater = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => PersonStore.updatePeopleStream(update),
         errorModalColumnGroups: this.errorModalColumnGroups,
         errorMessageProvider: (error) => {
            const parsedError = parseError(error);

            // Handle known validation errors.
            if (parsedError instanceof ValidationError) {
               if (
                  parsedError.validation.some(
                     (v) => v.params?.code == ValidationErrorCode.CONFLICT_EMAIL,
                  )
               ) {
                  return "A person already exists with the same email.";
               }
               if (
                  parsedError.validation.some(
                     (v) => v.params?.code == ValidationErrorCode.CONFLICT_PHONE,
                  )
               ) {
                  return "A person already exists with the same phone number.";
               }
            }

            // Check for email conflicts originating from Gatekeeper.
            if (parsedError instanceof ApiError && parsedError.code == "ConflictEmail") {
               return "A person already exists with the same email.";
            }

            return null;
         },
      });
      this.rowsRemover = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => PersonStore.batchDelete(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
   }

   protected async loadRows(
      queryParams: FindPeoplePaginatedQueryParams,
   ): Promise<ResponsePayload<SerializedPeopleListPerson>> {
      const peopleList = await PersonStore.findPeopleList(queryParams).payload;
      this.totalPossible(peopleList.pagination.total_possible);
      return peopleList;
   }

   personTypeEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<PersonTypeEditorParams> => {
      const value = (() => {
         if (people.length != 1) {
            return {
               isAssignable: false,
               isUser: false,
               hasEmail: true,
               permission: null,
            };
         }
         const [person] = people;
         const permission = person.permission_level;
         return {
            isAssignable: person.is_assignable,
            isUser: person.is_user,
            hasEmail: Boolean(person.email?.length),
            permission: permission
               ? { id: permission.id, name: permission.name, isAdmin: permission.is_admin }
               : null,
         };
      })();
      return PersonTypeEditor.factory(() => ({
         title: "Person Type",
         value,
         saveProvider: async ({ isAssignable, isUser, permission }) => {
            await this.updateRows({
               people,
               update: {
                  is_assignable: isAssignable,
                  is_user: isUser,
                  permission_level_id: permission?.id || null,
               },
               rowApplier: (person) => {
                  const isNewUser = !person.is_user && isUser;
                  return {
                     ...person,
                     is_assignable: isAssignable,
                     is_user: isUser,
                     invite_pending: isNewUser,
                     permission_level: permission
                        ? ({
                             id: permission.id,
                             name: permission.name,
                             is_admin: permission.isAdmin,
                          } as SerializedPermissionLevel)
                        : null,
                  };
               },
            });
         },
      }))(people);
   };

   personNameEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<PersonNameEditorParams> => {
      const [person] = people;
      return PersonNameEditor.factory(() => ({
         title: "Name",
         value: { first: person.name.first, last: person.name.last },
         saveProvider: async (name: { first: string; last: string }) => {
            if (!name) return;
            await this.updateRows({
               people,
               update: { name },
               rowApplier: (person) => ({
                  ...person,
                  name: {
                     first: name.first,
                     last: name.last,
                  },
               }),
            });
         },
      }))(people);
   };

   emailEditorFactory = (people: SerializedPeopleListPerson[]): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      return TextEditor.factory(() => ({
         title: "Email",
         width: 250,
         value: isOne ? people[0].email ?? null : null,
         isRequired: isOne ? people[0].is_user : false,
         validators: [
            (email) => {
               if (email && !validateEmail(email))
                  return {
                     message: "Email is invalid.",
                     status: false,
                  };

               return {
                  message: null,
                  status: true,
               };
            },
         ],
         saveProvider: async (email) => {
            await this.updateRows({
               people,
               update: { email },
               rowApplier: (person) => ({
                  ...person,
                  email,
               }),
            });
         },
      }))(people);
   };

   hourlyWageEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<CurrencyEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].hourly_wage : null;
      return CurrencyEditor.factory(() => ({
         title: "Hourly Wage",
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         minVal: 1,
         saveProvider: async (hourlyWage) => {
            await this.updateRows({
               people,
               update: { hourly_wage: hourlyWage },
               rowApplier: (person) => ({
                  ...person,
                  hourly_wage: hourlyWage,
               }),
            });
         },
      }))(people);
   };

   employeeIdEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].employee_number : null;
      return TextEditor.factory(() => ({
         title: "Employee ID",
         width: 180,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (employeeNumber) => {
            await this.updateRows({
               people,
               update: { employee_number: employeeNumber },
               rowApplier: (person) => ({
                  ...person,
                  employee_number: employeeNumber,
               }),
            });
         },
      }))(people);
   };

   jobTitleEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<SerializedPosition>> => {
      const selectedItem = (() => {
         if (people.length > 1) return null;
         const position = people[0]?.position || null;
         if (!position) return null;
         return {
            id: position.id,
            color: position.color,
            name: position.name,
            sequence: position.sequence,
            type: position.type as PositionType | null,
            company_id: authManager.companyId(),
            group_ids: position.group_ids,
            globally_accessible: position.globally_accessible,
         };
      })();
      return DropDownEditor.factory(() => ({
         pane: new JobTitleDropDownPane({
            transformer: (position) => position,
         }) as any,
         cellFactory: ColorCircleTextCell.factory<SerializedPosition>((position) => {
            return {
               text: position.name,
               color: position.color,
            };
         }),
         title: "Job Title",
         value: selectedItem ? new Set([selectedItem.id]) : new Set(),
         selectedItem,
         isClearable: people.length > 1 || selectedItem != null,
         placeholder: "Select Job Title...",
         saveProvider: async ([position = null]) => {
            await this.updateRows({
               people,
               update: { job_title_id: position?.id ?? null },
               rowApplier: (person) => {
                  const newPosition =
                     position != null
                        ? ({
                             id: position.id,
                             name: position.name,
                             color: position.color,
                             // Values are defaulted if person does not already have a position.
                             globally_accessible: person.position
                                ? person.position!.globally_accessible
                                : false,
                             group_ids: person.position ? person.position!.group_ids : [],
                             type: person.position ? person.position!.type : "Unknown",
                             sequence: person.position ? person.position!.sequence : -1,
                             hourly_rate: person.position ? person.position!.hourly_rate : 0,
                          } as SerializedPosition)
                        : null;
                  return {
                     ...person,
                     position: newPosition,
                  };
               },
            });
         },
      }))(people);
   };

   statusEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<{ id: string; name: string }>> => {
      return DropDownEditor.factory(() => ({
         title: "Status",
         value: people.length == 1 ? new Set([people[0].status]) : new Set(),
         pane: new ArrayDropDownPane({
            items: Object.values(Person.Status)
               .filter((v) => v != "pending")
               .map((val) => ({
                  id: val as string,
                  name: Format.capitalize(val),
               })),
         }),
         cellFactory: TextCell.factory<{ id: string; name: string }>((item) => item.name),
         isRequired: true,
         placeholder: "Select Status...",
         saveProvider: async ([selected]) => {
            await this.updateRows({
               people,
               update: {
                  status: selected.id as PersonStatus,
               },
               rowApplier: (person) => ({
                  ...person,
                  status: selected.id as PersonStatus,
               }),
            });
         },
      }))(people);
   };

   tagInstancesEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TagInstancesEditorParams<SerializedPeopleListPerson>> => {
      return TagInstancesEditor.factory<SerializedPeopleListPerson>(() => ({
         title: "Tags",
         value: people,
         isExpirationDayEnabled: true,
         attachmentsProvider: async (personId: string, tagId: string) => {
            const result = await PersonStore.getPersonTagInstances(personId).payload;
            return (
               result.data
                  .find((tag) => tag.tag_id == tagId)
                  ?.attachments?.map((attachment) => {
                     return new Attachment(attachment);
                  }) || []
            );
         },
         saveProvider: async (update) => {
            await this.rowsUpdater.update({
               size: update.payload.length,
               updatePayload: update.payload,
               rowApplier: (person) => {
                  const tagInstances = person.tag_instances.slice();
                  if (update.addedTag != null && update.addedTagInstance != null) {
                     const tagIndexToReplace = tagInstances.findIndex(
                        (tagInstance) => tagInstance.tag_id == update.addedTagInstance?.tag_id,
                     );
                     const mergedTagAndTagInstance: SerializedTagInstance & { tag: SerializedTag } =
                        {
                           ...(update.addedTagInstance as SerializedTagInstance),
                           tag: update.addedTag,
                        };
                     if (tagIndexToReplace != -1)
                        tagInstances.splice(tagIndexToReplace, 1, mergedTagAndTagInstance);
                     else tagInstances.push(mergedTagAndTagInstance);
                  }
                  if (update.removedTagId) {
                     const tagInstanceIndexToRemove = tagInstances?.findIndex(
                        (instance) => instance.tag_id == update.removedTagId,
                     );
                     if (tagInstanceIndexToRemove) {
                        tagInstances!.splice(tagInstanceIndexToRemove, 1);
                     }
                  }
                  return {
                     ...person,
                     tag_instances: tagInstances,
                  };
               },
            });
         },
         recordTransformer: (person) => ({
            id: person.id,
            group_ids: person.permission_level?.is_admin ? null : new Set(person.group_ids),
            tagInstances: pureComputed(() => {
               this.rows(); // Included in order to update sync the record on the row with that in the tag instance editor.
               return (person.tag_instances ?? []).map(({ tag, ...tagInstance }) => tagInstance);
            }),
         }),
      }))(people);
   };

   genderEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<{ id: string; name: string }>> => {
      const gender = people.length == 1 ? people[0].gender : null;
      return DropDownEditor.factory(() => ({
         title: "Gender",
         value: gender ? new Set([gender]) : new Set(),
         pane: new ArrayDropDownPane({
            items: [
               { id: "male", name: "Male" },
               { id: "female", name: "Female" },
            ],
         }),
         cellFactory: TextCell.factory<{ id: string; name: string }>((item) => item.name),
         isClearable: people.length > 1 || gender != null,
         saveProvider: async ([selected = null]) => {
            const gender = selected ? (selected.id as "male" | "female") : null;
            await this.updateRows({
               people,
               update: { gender },
               rowApplier: (person) => ({
                  ...person,
                  gender,
               }),
            });
         },
      }))(people);
   };

   languageEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<{ id: string; name: string }>> => {
      const language = people.length == 1 ? people[0].language : null;
      const value = language ? new Set([language]) : new Set<string>();
      return DropDownEditor.factory(() => ({
         title: "Language",
         value,
         isSearchable: true,
         pane: new ArrayDropDownPane<{ id: string; name: string }>({
            items: new DefaultStoreCore().getDefaultLanguageOptions().map((data) => ({
               id: data.value,
               name: data.name,
            })),
            searchTextProvider: (item) => item.name,
         }),
         cellFactory: TextCell.factory<{ id: string; name: string }>((item) => item.name),
         isRequired: true,
         placeholder: "Select Language...",
         saveProvider: async ([language]) => {
            await this.updateRows({
               people,
               update: {
                  language: language.id as Language,
               },
               rowApplier: (person) => ({
                  ...person,
                  language: language.id as Language,
               }),
            });
         },
      }))(people);
   };

   hiredDateEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      const value =
         people.length === 1
            ? people[0].hired_date
               ? getDetachedDay(new Date(people[0].hired_date), true)
               : null
            : null;
      return DetachedDayEditor.factory(() => ({
         title: "Hired Date",
         value,
         isClearable: people.length > 1 || value != null,
         saveProvider: async (hiredDate) => {
            const timestamp = hiredDate ? DateUtils.getAttachedDate(hiredDate).getTime() : null;
            await this.updateRows({
               people,
               update: {
                  hired_date: timestamp,
               },
               rowApplier: (person) => ({
                  ...person,
                  hired_date: timestamp,
               }),
            });
         },
      }))(people);
   };

   birthDateEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      const value =
         people.length === 1
            ? people[0].dob
               ? getDetachedDay(new Date(people[0].dob), true)
               : null
            : null;
      return DetachedDayEditor.factory(() => ({
         title: "Birth Date",
         value,
         isClearable: people.length > 1 || value != null,
         saveProvider: async (birthDate) => {
            const timestamp = birthDate ? DateUtils.getAttachedDate(birthDate).getTime() : null;
            await this.updateRows({
               people,
               update: { dob: timestamp },
               rowApplier: (person) => ({
                  ...person,
                  dob: timestamp,
               }),
            });
         },
      }))(people);
   };

   addressEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].address_1 : null;
      return TextEditor.factory(() => ({
         title: "Address",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (address1) => {
            await this.updateRows({
               people,
               update: { address_1: address1 },
               rowApplier: (person) => ({
                  ...person,
                  address_1: address1,
               }),
            });
         },
      }))(people);
   };

   address2EditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].address_2 : null;
      return TextEditor.factory(() => ({
         title: "Address 2",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (address2) => {
            await this.updateRows({
               people,
               update: { address_2: address2 },
               rowApplier: (person) => ({
                  ...person,
                  address_2: address2,
               }),
            });
         },
      }))(people);
   };

   cityEditorFactory = (people: SerializedPeopleListPerson[]): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].city_town : null;
      return TextEditor.factory(() => ({
         title: "City",
         width: 200,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (city) => {
            await this.updateRows({
               people,
               update: { city_town: city },
               rowApplier: (person) => ({
                  ...person,
                  city_town: city,
               }),
            });
         },
      }))(people);
   };

   stateEditorFactory = (people: SerializedPeopleListPerson[]): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].state_province : null;
      return TextEditor.factory(() => ({
         title: "State",
         width: 150,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (state) => {
            await this.updateRows({
               people,
               update: { state_province: state },
               rowApplier: (person) => ({
                  ...person,
                  state_province: state,
               }),
            });
         },
      }))(people);
   };

   postalEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].zipcode : null;
      return TextEditor.factory(() => ({
         title: "Postal",
         width: 150,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (postalCode) => {
            await this.updateRows({
               people,
               update: { zipcode: postalCode },
               rowApplier: (person) => ({
                  ...person,
                  zipcode: postalCode,
               }),
            });
         },
      }))(people);
   };

   countryEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].country : null;
      return TextEditor.factory(() => ({
         title: "Country",
         width: 150,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (country) => {
            await this.updateRows({
               people,
               update: { country: country },
               rowApplier: (person) => ({
                  ...person,
                  country,
               }),
            });
         },
      }))(people);
   };

   canReceiveEmailEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<CheckboxEditorParams> => {
      const isOne = people.length === 1;
      return CheckboxEditor.factory(() => ({
         title: "Can Receive Email",
         label: "Receive Email",
         value: isOne ? people[0].can_receive_email : null,
         isClearable: false,
         saveProvider: async (value) => {
            await this.updateRows({
               people,
               update: { can_receive_email: value ?? false },
               rowApplier: (person) => ({
                  ...person,
                  can_receive_email: value ?? false,
               }),
            });
         },
      }))(people);
   };

   canReceiveMobileEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<CheckboxEditorParams> => {
      const isOne = people.length === 1;
      return CheckboxEditor.factory(() => ({
         title: "Can Receive Mobile",
         label: "Receive Mobile",
         value: isOne ? people[0].can_receive_mobile : null,
         isClearable: false,
         saveProvider: async (value) => {
            await this.updateRows({
               people,
               update: { can_receive_mobile: value ?? false },
               rowApplier: (person) => ({
                  ...person,
                  can_receive_mobile: value ?? false,
               }),
            });
         },
      }))(people);
   };

   canReceiveSmsEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<CheckboxEditorParams> => {
      const isOne = people.length === 1;
      return CheckboxEditor.factory(() => ({
         title: "Can Receive SMS",
         label: "Receive SMS",
         value: isOne ? people[0].can_receive_sms : null,
         isClearable: false,
         saveProvider: async (value) => {
            await this.updateRows({
               people,
               update: { can_receive_sms: value ?? false },
               rowApplier: (person) => ({
                  ...person,
                  can_receive_sms: value ?? false,
               }),
            });
         },
      }))(people);
   };

   emergencyContactNameEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].emergency_contact_name : null;
      return TextEditor.factory(() => ({
         title: "Emergency Contact Name",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (name) => {
            await this.updateRows({
               people,
               update: { emergency_contact_name: name },
               rowApplier: (person) => ({
                  ...person,
                  emergency_contact_name: name,
               }),
            });
         },
      }))(people);
   };

   emergencyContactEmailEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].emergency_contact_email : null;
      return TextEditor.factory(() => ({
         title: "Emergency Contact Email",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (email) => {
            await this.updateRows({
               people,
               update: { emergency_contact_email: email },
               rowApplier: (person) => ({
                  ...person,
                  emergency_contact_email: email,
               }),
            });
         },
      }))(people);
   };

   emergencyContactPhoneEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].emergency_contact_number : null;
      return TextEditor.factory(() => ({
         title: "Emergency Contact Phone",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (phone) => {
            await this.updateRows({
               people,
               update: { emergency_contact_number: phone },
               rowApplier: (person) => ({
                  ...person,
                  emergency_contact_number: phone,
               }),
            });
         },
      }))(people);
   };

   phoneEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<PhoneEditorParams> => {
      const isOne = people.length === 1;
      return PhoneEditor.factory(() => ({
         title: "Phone",
         width: 250,
         value: isOne ? people[0].phone ?? null : null,
         isRequired: false,
         saveProvider: async (phone) => {
            await this.updateRows({
               people,
               update: { phone },
               rowApplier: (person) => ({
                  ...person,
                  phone,
               }),
            });
         },
      }))(people);
   };

   emergencyContactRelationEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = people.length === 1;
      const currentValue = isOne ? people[0].emergency_contact_relation : null;
      return TextEditor.factory(() => ({
         title: "Emergency Contact Relation",
         width: 250,
         value: currentValue ?? null,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (relation) => {
            await this.updateRows({
               people,
               update: { emergency_contact_relation: relation },
               rowApplier: (person) => ({
                  ...person,
                  emergency_contact_relation: relation,
               }),
            });
         },
      }))(people);
   };

   permissionEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<SerializedPermissionLevel>> => {
      const selectedItem = (() => {
         if (people.length > 1) return null;
         const firstPersonValue = people[0]?.permission_level || null;
         if (!firstPersonValue) return null;
         return firstPersonValue;
      })();
      return DropDownEditor.factory(() => ({
         pane: new PermissionDropDownPane(),
         cellFactory: TextCell.factory<SerializedPermissionLevel>((item) => item.name),
         title: "Permission",
         value: selectedItem ? new Set([selectedItem.id]) : new Set(),
         selectedItem,
         isRequired: true,
         placeholder: "Select Permission...",
         saveProvider: async ([permissionLevel = null]) => {
            await this.updateRows({
               people,
               update: { permission_level_id: permissionLevel?.id ?? null },
               rowApplier: (person) => {
                  const isCurrentAdmin = person.permission_level?.is_admin;
                  const newPermissionLevel = permissionLevel ?? null;
                  const allGroupIds = Object.keys(authManager.companyGroupNames());
                  const groupsIds =
                     isCurrentAdmin && !permissionLevel?.is_admin
                        ? {
                             group_ids: allGroupIds,
                             ...(authManager.usingTypedGroups()
                                ? {
                                     access_group_ids: allGroupIds,
                                     assignable_group_ids: allGroupIds,
                                  }
                                : {}),
                          }
                        : !isCurrentAdmin && permissionLevel?.is_admin
                        ? {
                             group_ids: [],
                             ...(authManager.usingTypedGroups()
                                ? {
                                     access_group_ids: [],
                                     assignable_group_ids: [],
                                  }
                                : {}),
                          }
                        : {};
                  return {
                     ...person,
                     ...groupsIds,
                     permission_level: newPermissionLevel,
                  };
               },
            });
         },
      }))(people);
   };

   groupsInlineEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>>> => {
      return this.genericGroupsInlineEditorFactory({
         people,
         ...this.getGroupsEditorFactoryParams(GroupType.GROUP_IDS),
      });
   };

   accessGroupsInlineEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>>> => {
      return this.genericGroupsInlineEditorFactory({
         people,
         ...this.getGroupsEditorFactoryParams(GroupType.ACCESS_GROUP_IDS),
      });
   };

   assignableGroupsInlineEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>>> => {
      return this.genericGroupsInlineEditorFactory({
         people,
         ...this.getGroupsEditorFactoryParams(GroupType.ASSIGNABLE_GROUP_IDS),
      });
   };

   notificationProfileEditorFactory = (
      people: SerializedPeopleListPerson[],
   ): ComponentArgs<DropDownEditorParams<SerializedNotificationProfile>> => {
      const selectedItem = (() => {
         if (people.length > 1) return null;
         const firstPersonValue = people[0]?.notification_profile || null;
         return firstPersonValue ? firstPersonValue : null;
      })();
      return DropDownEditor.factory(() => ({
         pane: new NotificationProfileDropDownPane() as DropDownPane<SerializedNotificationProfile>,
         cellFactory: TextCell.factory<SerializedNotificationProfile>((item) => item.name),
         title: "Notification Profile",
         value: selectedItem ? new Set([selectedItem.id]) : new Set(),
         selectedItem,
         isClearable: people.length > 1 || selectedItem != null,
         placeholder: "Select Notification Profile...",
         saveProvider: async ([notificationProfile = null]) => {
            await this.updateRows({
               people,
               update: { notification_profile_id: notificationProfile?.id ?? null },
               rowApplier: (person) => ({
                  ...person,
                  notification_profile: notificationProfile,
               }),
            });
         },
      }))(people);
   };

   batchEditFields(
      isEditable: (property: string) => boolean,
   ): Array<BatchEditor<SerializedPeopleListPerson>> {
      const fields: Array<BatchEditor<SerializedPeopleListPerson> & { isEnabled: boolean }> = [
         {
            factory: this.personTypeEditorFactory,
            validator: (person: SerializedPeopleListPerson, value: PersonTypeValue) => {
               const selfResult = this.isSelfValidator("Person Type", person);
               if (!selfResult.status) return selfResult.message;
               if (value.isUser && !person.email) {
                  return "Email is required to become a user.";
               }
               return null;
            },
            isEnabled:
               isEditable("person_type") &&
               authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_PERMISSIONS),
         },
         {
            factory: this.jobTitleEditorFactory,
            isEnabled: isEditable("position_id"),
         },
         {
            factory: this.tagInstancesEditorFactory,
            isEnabled: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_TAGS),
            hasInternalSaveManagement: true,
         },
         {
            factory: this.statusEditorFactory,
            isEnabled: isEditable("status"),
            validator: (person: SerializedPeopleListPerson) => {
               const selfResult = this.isSelfValidator("Status", person);
               return !selfResult.status ? selfResult.message : null;
            },
         },
         {
            factory: this.employeeIdEditorFactory,
            isEnabled: isEditable("employee_number"),
         },
         {
            factory: this.permissionEditorFactory,
            isEnabled:
               isEditable("permission_level_id") &&
               authManager.checkAuthAction(PermissionLevel.Action.EDIT_PEOPLE_PERMISSIONS),
            validator: (person: SerializedPeopleListPerson) => {
               const selfResult = this.isSelfValidator("Permission", person);
               if (!selfResult.status) return selfResult.message;
               return !person.is_user
                  ? "Permission cannot be updated for person who is not a user."
                  : null;
            },
         },
         {
            factory: this.genderEditorFactory,
            isEnabled: isEditable("is_male"),
         },
         {
            factory: this.languageEditorFactory,
            isEnabled: isEditable("language"),
         },
         {
            factory: this.hiredDateEditorFactory,
            isEnabled: isEditable("hired_date"),
         },
         {
            factory: this.hourlyWageEditorFactory,
            isEnabled: isEditable("hourly_wage"),
         },
         {
            factory: this.addressEditorFactory,
            isEnabled: isEditable("address_1"),
         },
         {
            factory: this.address2EditorFactory,
            isEnabled: isEditable("address_2"),
         },
         {
            factory: this.cityEditorFactory,
            isEnabled: isEditable("city_town"),
         },
         {
            factory: this.stateEditorFactory,
            isEnabled: isEditable("state_province"),
         },
         {
            factory: this.postalEditorFactory,
            isEnabled: isEditable("zipcode"),
         },
         {
            factory: this.countryEditorFactory,
            isEnabled: isEditable("country"),
         },
         {
            factory: this.birthDateEditorFactory,
            isEnabled: isEditable("dob"),
         },
         {
            factory: this.canReceiveEmailEditorFactory,
            isEnabled: isEditable("can_recieve_email"),
         },
         {
            factory: this.canReceiveMobileEditorFactory,
            isEnabled: isEditable("can_recieve_mobile"),
         },
         {
            factory: this.canReceiveSmsEditorFactory,
            isEnabled: isEditable("can_recieve_sms"),
         },
         {
            factory: this.emergencyContactNameEditorFactory,
            isEnabled: isEditable("emergency_contact_name"),
         },
         {
            factory: this.emergencyContactEmailEditorFactory,
            isEnabled: isEditable("emergency_contact_email"),
         },
         {
            factory: this.emergencyContactPhoneEditorFactory,
            isEnabled: isEditable("emergency_contact_number"),
         },
         {
            factory: this.emergencyContactRelationEditorFactory,
            isEnabled: isEditable("emergency_contact_relation"),
         },
         {
            factory: (people) =>
               this.genericGroupsBatchEditorFactory({
                  people,
                  ...this.getGroupsEditorFactoryParams(GroupType.GROUP_IDS),
                  typedGroupValidation: () => null,
               }),
            isEnabled: isEditable("group_ids") && !authManager.usingTypedGroups(),
            hasInternalSaveManagement: true,
         },
         {
            factory: (people) =>
               this.genericGroupsBatchEditorFactory({
                  people,
                  ...this.getGroupsEditorFactoryParams(GroupType.ACCESS_GROUP_IDS),
                  typedGroupValidation: (person) =>
                     !person.is_user ? "Cannot specify access groups for non-users." : null,
               }),
            isEnabled: isEditable("access_group_ids") && authManager.usingTypedGroups(),
            hasInternalSaveManagement: true,
         },
         {
            factory: (people) =>
               this.genericGroupsBatchEditorFactory({
                  people,
                  ...this.getGroupsEditorFactoryParams(GroupType.ASSIGNABLE_GROUP_IDS),
                  typedGroupValidation: (person) =>
                     !person.is_assignable
                        ? "Cannot specify assignable groups for non-assignable people."
                        : null,
               }),
            isEnabled: isEditable("assignable_group_ids") && authManager.usingTypedGroups(),
            hasInternalSaveManagement: true,
         },
         {
            factory: this.notificationProfileEditorFactory,
            isEnabled: isEditable("notification_profile_id"),
            validator: (person: SerializedPeopleListPerson) => {
               return !person.is_user
                  ? "Notification Profile cannot be updated for person who is not a user."
                  : null;
            },
         },
      ];
      return fields.filter((field) => field.isEnabled).map(({ isEnabled, ...rest }) => rest);
   }

   async updateCustomFields(
      people: SerializedPeopleListPerson[],
      customFieldMeta: CustomFieldMeta,
      value: string | number | boolean | string[] | null,
   ): Promise<void> {
      await this.updateRows({
         people,
         update: {
            custom_fields: {
               [customFieldMeta.field_id]: value,
            },
         },
         rowApplier: (person) => {
            return customFieldUpdateRowApplier({
               customFieldMeta,
               row: person,
               value,
            });
         },
      });
   }

   private async updateRows({
      people,
      update,
      rowApplier,
   }: {
      people: SerializedPeopleListPerson[];
      update: Partial<UpdatePersonPayload<AuthType.SESSION>>;
      rowApplier: (person: SerializedPeopleListPerson) => SerializedPeopleListPerson | null;
   }) {
      await this.rowsUpdater.update({
         size: people.length,
         updatePayload: people.map((p) => ({ id: p.id, ...update })),
         rowApplier,
      });
   }

   async batchDeleteRows(assignments: SerializedPeopleListPerson[]): Promise<string[]> {
      const ids = assignments.map((a) => a.id);
      await this.rowsRemover.delete({ ids });
      this.totalPossible(this.totalPossible() - assignments.length);
      return ids;
   }

   canAccessAssignmentProject(assignment: FilteredPeopleAssignment): boolean {
      if (authManager.isAdmin()) return true;
      if (!authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)) return false;
      return assignment.project_group_ids.some((groupId) =>
         authManager.getContextAccessibleGroupIds().has(groupId),
      );
   }

   private isSelfValidator(field: string, person: SerializedPeopleListPerson) {
      if (person.id == authManager.authedUserId()) {
         return {
            status: false,
            message: `Batch editing ${field} for yourself is not allowed.`,
         };
      }
      return { status: true, message: null };
   }

   private genericGroupsInlineEditorFactory({
      people,
      title,
      groupFieldName,
      getPersonGroupIds,
      setPersonGroupIds,
   }: {
      people: SerializedPeopleListPerson[];
      title: string;
      groupFieldName: keyof PersonSchema;
      getPersonGroupIds: (person: SerializedPeopleListPerson) => string[];
      setPersonGroupIds: (person: SerializedPeopleListPerson, groupIds: Set<string>) => void;
   }): ComponentArgs<DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>>> {
      const person = people[0];
      const visibleGroupIds = authManager.getContextAccessibleGroupIds();
      const groupNames = authManager.companyGroupNames();
      return DropDownEditor.factory(() => {
         const value = new Set(getPersonGroupIds(person));
         const selectedIds = observable(value ? value : new Set<string>());
         const unsortedGroups = Object.entries(groupNames)
            .map(([key, val]) => ({ id: key, name: val }))
            .filter((group) => visibleGroupIds.has(group.id) || value.has(group.id));
         const sortedGroups = authManager.getVisibilitySortedGroupItems(
            unsortedGroups,
            (item) => item.id,
         );
         const pane = new MultiSelectDropDownPane({
            items: sortedGroups,
            selectedIds,
            textProvider: (item) => item.name,
            isDisabledItem: (item) => !visibleGroupIds.has(item.id),
         }) as any;
         const params: DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>> = {
            title,
            pane,
            isRequired: true,
            cellFactory: pane.cellFactory,
            selectedItemCellFactory: TextCell.factory<
               MultiSelectItem<{ id: string; name: string }>
            >((item) => {
               return (item.item as { id: string; name: string }).name;
            }),
            actionInterceptor: pane.actionInterceptor,
            saveProvider: async (update) => {
               const selectedGroupIds = new Set(Array.from(update).map((v) => v.id));
               const groupIdUpdateRecord = Array.from(visibleGroupIds)
                  .map((id) => ({ [id]: selectedGroupIds.has(id) }))
                  .reduce((acc, cur) => ({ ...acc, ...cur }), {});
               await this.updateRows({
                  people,
                  update: {
                     [groupFieldName]: groupIdUpdateRecord,
                  },
                  rowApplier: (person) => {
                     const ids = Array.from(
                        new Set([...getPersonGroupIds(person), ...selectedGroupIds]),
                     ).filter((id) => groupIdUpdateRecord[id] != false);
                     if (!ids.some((id) => authManager.isVisibleGroupId(id))) {
                        // Remove row by returning null.
                        return null;
                     }
                     setPersonGroupIds(person, new Set(ids));
                     return person;
                  },
               });
            },
            selectionDescriptionProvider: () =>
               selectedIds().size > 1 ? `${selectedIds().size} Selected` : null,
            value: pane.actionableSelectedIds,
            validators: [
               (value) => {
                  return value.size == 0 &&
                     !getPersonGroupIds(person).some((id) => !visibleGroupIds.has(id))
                     ? { valid: false, error: `${title} are required.` }
                     : {
                          valid: true,
                       };
               },
            ],
         };
         return params;
      })(people);
   }

   private genericGroupsBatchEditorFactory = ({
      people,
      groupType,
      title,
      groupFieldName,
      getPersonGroupIds,
      setPersonGroupIds,
      typedGroupValidation,
   }: {
      people: SerializedPeopleListPerson[];
      groupType: GroupType;
      title: string;
      groupFieldName: keyof PersonSchema;
      getPersonGroupIds: (person: SerializedPeopleListPerson) => string[];
      setPersonGroupIds: (person: SerializedPeopleListPerson, groupIds: Set<string>) => void;
      typedGroupValidation: (person: SerializedPeopleListPerson) => string | null;
   }): ComponentArgs<GroupsEditorParams<SerializedPeopleListPerson>> => {
      const validator = (person: SerializedPeopleListPerson, groupChange: GroupsEditorUpdate) => {
         const selfResult = this.isSelfValidator(title, person);
         if (!selfResult.status) return selfResult.message;
         if (person.permission_level?.is_admin)
            return "Admins cannot be added or removed from groups.";
         if (typedGroupValidation(person) != null) return typedGroupValidation(person);
         if (getPersonGroupIds(person).every((groupId) => groupChange[groupId] == false))
            return "Cannot remove a user from all groups.";
         return null;
      };

      return GroupsEditor.factory<SerializedPeopleListPerson>(() => ({
         title,
         groupType,
         value: people,
         isRequired: true,
         selectedItemCellFactory: TextCell.factory(
            (item: MultiSelectItem<{ id: string; name: string }>) => {
               return (item.item as { id: string; name: string }).name;
            },
         ),
         getRecordGroupIds: (person) => new Set(getPersonGroupIds(person)),
         isAccessibleGroupId: authManager.isAccessibleGroupId,
         isVisibleGroupId: authManager.isVisibleGroupId,
         setRecordGroupIds: (person, groupIds) =>
            setPersonGroupIds(
               person,
               new Set([
                  ...Array.from(groupIds),
                  ...getPersonGroupIds(person).filter((id) => !authManager.isVisibleGroupId(id)),
               ]),
            ),
         validator,
         conflictModalColumnGroups: this.errorModalColumnGroups,
         saveProvider: async (value) => {
            const validPeople = people.filter((p) => validator(p, value) == null);
            await this.updateRows({
               people: validPeople,
               update: { [groupFieldName]: value },
               rowApplier: (person) => {
                  const addedIds = Object.entries(value)
                     .filter(([_, included]) => included)
                     .map(([id, _]) => id);
                  const ids = Array.from(
                     new Set([...getPersonGroupIds(person), ...addedIds]),
                  ).filter((id) => value[id] != false);

                  // Handle removal of any rows that are no longer visible in the current view.
                  if (
                     addedIds.length == 0 &&
                     ids.every((id) => !authManager.isVisibleGroupId(id))
                  ) {
                     // Remove row by returning null.
                     return null;
                  }
                  setPersonGroupIds(person, new Set(ids));
                  return person;
               },
            });
         },
      }))(people);
   };

   private getGroupsEditorFactoryParams = (
      groupType: GroupType,
   ): {
      groupType: GroupType;
      title: string;
      groupFieldName: keyof PersonSchema;
      getPersonGroupIds: (person: SerializedPeopleListPerson) => string[];
      setPersonGroupIds: (person: SerializedPeopleListPerson, groupIds: Set<string>) => void;
   } => {
      return groupType == GroupType.ACCESS_GROUP_IDS
         ? {
              groupType: GroupType.ACCESS_GROUP_IDS,
              title: "Access Groups",
              groupFieldName: "access_group_ids",
              getPersonGroupIds: (person) =>
                 person.permission_level?.is_admin
                    ? Object.keys(authManager.companyGroupNames())
                    : person.access_group_ids ?? [],
              setPersonGroupIds: (person, groupIds) => (person.access_group_ids = [...groupIds]),
           }
         : groupType == GroupType.ASSIGNABLE_GROUP_IDS
         ? {
              groupType: GroupType.ASSIGNABLE_GROUP_IDS,
              title: "Assignable Groups",
              groupFieldName: "assignable_group_ids",
              getPersonGroupIds: (person) =>
                 person.permission_level?.is_admin
                    ? Object.keys(authManager.companyGroupNames())
                    : person.assignable_group_ids ?? [],
              setPersonGroupIds: (person, groupIds) =>
                 (person.assignable_group_ids = [...groupIds]),
           }
         : {
              groupType: GroupType.GROUP_IDS,
              title: "Groups",
              groupFieldName: "group_ids",
              getPersonGroupIds: (person) =>
                 person.permission_level?.is_admin
                    ? Object.keys(authManager.companyGroupNames())
                    : person.group_ids ?? [],
              setPersonGroupIds: (person, groupIds) => (person.group_ids = [...groupIds]),
           };
   };

   createLoadAllRowsStream(
      queryParams: FindPeoplePaginatedQueryParams,
   ): StoreStreamResponse<SerializedPeopleListPerson> {
      return PersonStore.findPeopleListStream(queryParams);
   }
}
