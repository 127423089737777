// Markup & Styles
import reactTemplate from "./react-project-list.pug";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import renderReactComponent from "@/react/render-react-component";
import type { Root } from "react-dom/client";

export class ProjectsList2 extends PageContentViewModel {
   private readonly reactEnabled: boolean;
   private reactRoot: Root | null;

   constructor() {
      super(reactTemplate(), "List");

      this.reactEnabled = true;
      this.reactRoot = null;
   }

   addedToParent(): void {
      if (this.reactEnabled) {
         this.reactRoot = renderReactComponent("react-project-list-mount", "ProjectListContainer", {
            setTitle: (title: string) => this.setTitle(title),
         });
      }
   }

   removedFromParent(): void {
      if (this.reactRoot) {
         this.reactRoot.unmount();
      }
   }
}
