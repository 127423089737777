import { unwrap } from "knockout";
import type { Filter, FilterOption } from "@/lib/components/chip-filter/chip-filter";
import { ANY } from "@/lib/components/chip-filter/chip-filter";
import type {
   Filter as SerializedFilter,
   FilterValueSet as SerializedFilterValueSet,
} from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/common";
import type { FilterClassifier } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import type { LegacyQueryFilter, LegacyQueryFilters } from "@/stores/common/legacy-filters";
import { noStatusFilter } from "@laborchart-modules/common/dist/reql-builder/procedures/common/find-records";

export const PROJECT_ROLES_PROPERTY = "project_roles";

export function buildDateFilterInstance(
   property: string,
   { disableSearch, customFieldId } = {
      disableSearch: true,
      customFieldId: null,
   },
): FilterOption {
   return {
      property,
      type: "date",
      customFieldId,
      disableSearch,
      classifiers: [
         { listLabel: "On or Before", chipLabel: "On or Before", value: "<=" },
         { listLabel: "On", chipLabel: "On", value: "=" },
         { listLabel: "On or After", chipLabel: "On or After", value: ">=" },
         { listLabel: "Within", chipLabel: "", value: "within" },
      ],
   };
}

export type SerializedFilterBase = Omit<SerializedFilter, "name">;

export function serializedFilters<K extends "filterName" | "name">(
   filters: Filter[],
   nameKey: K,
): Array<SerializedFilterBase & { [k in K]: string }> {
   type SerializedFilter = SerializedFilterBase & { [k in K]: string };
   const accumulatedFilters = filters.reduce((accumulator, filter) => {
      let name: string;
      if (filter.customFieldId) {
         name = filter.customFieldId;
      } else if (filter.property == "status_id" && filter.valueName == noStatusFilter.name) {
         name = noStatusFilter.name;
      } else {
         name = filter.filterName.indexOf(".") != -1 ? filter.property : filter.filterName;
      }
      const serializedFilter = {
         [nameKey]: name,
         property: filter.property,
         type: filter.type || "select",
         value_sets: [serializeFilterValueSet(filter)],
         ...(filter.customFieldId ? { custom_field_id: filter.customFieldId } : {}),
         ...(filter.enableRelativeDate ? { enable_relative_date: filter.enableRelativeDate } : {}),
      } as SerializedFilter;

      const existingFilter = accumulator[name];
      if (existingFilter) {
         existingFilter.value_sets.push(...serializedFilter.value_sets);
      } else {
         accumulator[name] = serializedFilter;
      }
      return accumulator;
   }, {} as { [name: string]: SerializedFilter });
   return Object.values(accumulatedFilters);
}

function serializeFilterValueSet(filter: Filter): SerializedFilterValueSet {
   if (filter.enableRelativeDate) {
      return {
         negation: filter.negation || false,
         // Query Param serialize can not handle nested arrays, so let the server handle the json data.
         value: JSON.stringify(filter.value),
         ...(filter.classifier ? { classifier: filter.classifier as FilterClassifier } : {}),
      };
   }
   if (filter.value instanceof Array) {
      return {
         negation: filter.negation || false,
         value: filter.value.map((i) => unwrap(i.value)),
      };
   }
   if (filter.property == PROJECT_ROLES_PROPERTY) {
      const value = [filter.classifier, filter.value].map((id) => (id == ANY ? null : id));
      return { negation: filter.negation || false, value };
   }
   return {
      negation: filter.negation || false,
      value: filter.value,
      ...(filter.classifier ? { classifier: filter.classifier as FilterClassifier } : {}),
   };
}

export function serializeLegacyFilters(filters: Filter[]): LegacyQueryFilters {
   return filters.reduce((accumulator, filter) => {
      let filterName: string;
      if (filter.customFieldId) {
         filterName = filter.customFieldId;
      } else {
         filterName = filter.filterName.indexOf(".") != -1 ? filter.property : filter.filterName;
      }

      let value: any;
      if (filter.enableRelativeDate) {
         value = JSON.stringify(filter.value);
      } else if (Array.isArray(filter.value)) {
         value = filter.value.map((value) => unwrap(value.value));
      } else if (filter.property == PROJECT_ROLES_PROPERTY) {
         value = [filter.classifier, filter.value].map((id) => (id == ANY ? null : id));
      } else {
         value = filter.value;
      }

      const serializedFilter = {
         filterName,
         property: filter.property,
         type: filter.type,
         negation: filter.negation,
         value,
         ...(filter.customFieldId ? { customFieldId: filter.customFieldId } : {}),
         ...(filter.classifier && filter.classifier != ANY
            ? { classifier: filter.classifier }
            : {}),
      } as LegacyQueryFilter;

      if (accumulator[filterName]) {
         accumulator[filterName].push(serializedFilter);
      } else {
         accumulator[filterName] = [serializedFilter];
      }
      return accumulator;
   }, {} as LegacyQueryFilters);
}
