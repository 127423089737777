import ko from 'knockout'
import { ValueSet } from "@/models/value-set"

export accumulateCustomFieldChipFilters = (accumulator, fields, options = {
  propertyName: 'custom_fields',
  sensitiveFields: [],
  canViewSensitiveFields: false,
  canViewFinancials: false,
}) ->
  for field in fields
     unless options.canViewSensitiveFields
        continue if options.sensitiveFields.indexOf(field.integration_name) != -1

     if field.type == "multi-select"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           values: field.values.map (i) -> return new ValueSet({name: i, value: i})
        })
     else if field.type == "select"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           values: field.values.map (i) -> return new ValueSet({name: i, value: i})
        })
     else if field.type == "bool"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           values: [
              new ValueSet({name: "TRUE", value: true})
              new ValueSet({name: "FALSE", value: false})
           ]
        })
     else if (options.canViewFinancials and field.type == "currency") or field.type == "number"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           disableSearch: true
           classifiers: [
              {listLabel: "< (Less Than)", chipLabel: "<", value: "<"},
              {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="},
              {listLabel: "= (Equal To)", chipLabel: "=", value: '='},
              {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
              {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
           ]
        })
     else if field.type == "date"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           enableRelativeDate: true
           disableSearch: true
           classifiers: [
              {listLabel: "On or Before", chipLabel: "On or Before", value: "<="},
              {listLabel: "On", chipLabel: "On", value: '='},
              {listLabel: "On or After", chipLabel: "On or After", value: ">="},
              {listLabel: "Within", chipLabel: "", value: "within" },
           ]
        })
     else if field.type == "text"
        accumulator[field.name] = ko.observable({
           property: options.propertyName
           customFieldId: field.id
           type: field.type
           disableSearch: true
        })
