import "./add-attachments-pane.styl"
import template from "./add-attachments-pane.pug"
import { PopupPane } from "@/lib/components/popup/popup-pane"
import { ObservableData } from "@/lib/utils/observable-data"

export class AddAttachmentPane extends PopupPane
   constructor: (attachments) ->
      super(template())
      @rightActionBtn = ko.observable({
         text: "Done"
         callback: @closeAttachmentsPopup
      })

      # Popup Properties
      @title = ko.observable()

      # Data
      @newAttachments = attachments

   closeAttachmentsPopup: ->
      @dismissHandler()