export function computeHourlyRate({
   personHourlyWage,
   positionHourlyRate,
   wageOverride,
}: {
   personHourlyWage: number | null;
   positionHourlyRate: number | null;
   wageOverride: number | null;
}): number | null {
   return wageOverride ?? personHourlyWage ?? positionHourlyRate ?? null;
}
