import { FormatMessage } from "@/lib/utils/message-formatter"
import { authManager } from "@/lib/managers/auth-manager";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

### Auth, Real-Time & Stores ###
import { projectStore as legacyProjectStore } from "@/stores/project-store"
import { ProjectStore } from "@/stores/project-store.core"
import { Icons, Notification, notificationManagerInstance as notificationManager } from "@/lib/managers/notification-manager"

### Models ###
import { Person } from "@/models/person"
import { CannedMessage } from "@/models/canned-message"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"

import { CreateMessageOrAlertPane } from "@/lib/components/modals/create-message-or-alert-pane"
import { ProcessingNoticePaneViewModel } from "@/lib/components/modals/processing-notice-pane"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

import ko from "knockout"

export class AlertManager
    showAssignmentMessageModal: (modalData, placeholder, callback) ->
        notifyProjectId = modalData.notifyProjectId
        notifyResourceId = modalData.notifyResourceId
        notifyBatchId = modalData.notifyBatchId
        notifyContext = modalData.notifyContext

        recipients = ko.observableArray()
        cannedMessage = null
        projectInfo = null
        assignmentBatchInfo = null

        processingModal = new Modal()
        processingModal.setPanes([new ProcessingNoticePaneViewModel("Creating Assignment Notification", true)])
        modalManager.showModal processingModal, null, {class: "processing-notice-modal"}, (modal, modalStatus) =>
            if modalStatus == "cancelled"
                callback(modalStatus) if callback
                return 

            if cannedMessage?
                FormatMessage.populatedMessage(cannedMessage)
                subject = cannedMessage.subject()
                message = cannedMessage.content()
            else
                [subject, message] = FormatMessage.populateAssignmentMessage(assignmentBatchInfo, projectInfo, notifyContext)

            workScopeText = ko.unwrap(placeholder.workScopeText) if placeholder?
            if workScopeText?
                message += "\nScope of Work:\n#{workScopeText}\n"

            instructionText = ko.unwrap(placeholder.instructionText) if placeholder?
            if instructionText?
                message += "\nAssignment Instructions:\n#{instructionText}\n"
                    
            messageData = {
                subject: subject
                content: message
                includeSignature: if cannedMessage? then cannedMessage.includeSignature() else false
                isPrivate: if cannedMessage? then cannedMessage.isPrivate() else true
                isGroup: if cannedMessage? then cannedMessage.isGroup() else false
            }
            supplementalMsgData = {
                projectId: notifyProjectId
                assignmentId: notifyBatchId
            }

            pane1 = new CreateMessageOrAlertPane(messageData, 'Assignment Alert', recipients, false, null, supplementalMsgData, true)
            modal.setPanes([pane1])
            modalManager.showModal modal, null, {class: 'create-message-modal'}, (modal, modalStatus) =>
                callback(modalStatus) if callback

        useCoreAPI = LaunchDarklyBrowser.getFlagValue("get-project-info-for-assingment-alerts-from-lc-core-api")
        if useCoreAPI
            data = await ProjectStore.getProjectInfoForAssignmentNotification(notifyProjectId, notifyResourceId, notifyBatchId, notifyContext).payload;
            # Legacy store does some data formatting, but moving it here to keep the core store clean.
            info = {
                recipients: data.recipients.map (person) ->
                    return new Person(person, true)
            }
            if data.assignment_batch?
                info['assignmentBatch'] = {
                    startDay: data.assignment_batch.start_day
                    endDay: data.assignment_batch.end_day
                    startTime: data.assignment_batch.start_time
                    endTime: data.assignment_batch.end_time
                    percentAllocated: data.assignment_batch.percent_allocated
                    workDays: data.assignment_batch.work_days
                    resourceName: data.assignment_batch.resource_name
                }

            if data.message?
                info['message'] = new CannedMessage(data.message, true)
            else
                info['project'] = {
                    name: data.project.name
                    address1: data.project.address_1 or null
                    address2: data.project.address_2 or null
                    cityTown: data.project.city_town or null
                    stateProvince: data.project.state_province or null
                    zipcode: data.project.zipcode or null
                    jobNumber: data.project.job_number or null
                    siteContacts: data.project.site_contacts
                }
            recipients(info.recipients)
            if info.message?
                cannedMessage = info.message
            else
                projectInfo = info.project
                assignmentBatchInfo = info.assignmentBatch
                if modalData.removedAssignmentInfo
                    assignmentBatchInfo["startDay"] = modalData.removedAssignmentInfo.startDay
                    assignmentBatchInfo["endDay"] = modalData.removedAssignmentInfo.endDay
            modalManager.modalFinished()
        else
            legacyProjectStore.getProjectInfoForAssignmentNotification notifyProjectId, notifyResourceId, notifyBatchId, notifyContext, (err, info) =>
                if err
                    console.error("Error", err);
                    Bugsnag.notify(err, (event) =>
                        event.context = "alert-manager__getProjectInfoForAssignmentNotification";
                        event.addMetadata(
                            BUGSNAG_META_TAB.USER_DATA,
                            buildUserData(authManager.authedUser(), authManager.activePermission),
                        );
                        event.addMetadata("params", {
                            notifyProjectId,
                            notifyResourceId,
                            notifyBatchId,
                            notifyContext,
                        });
                    );
                    return notificationManager.show(
                        new Notification({
                            icon: Icons.WARNING,
                            text: "Unable to load assignment's information. Please try again or contact support.",
                        })
                    )
                recipients(info.recipients)
                if info.message?
                    cannedMessage = info.message
                else
                    projectInfo = info.project
                    assignmentBatchInfo = info.assignmentBatch
                    if modalData.removedAssignmentInfo
                        assignmentBatchInfo["startDay"] = modalData.removedAssignmentInfo.startDay
                        assignmentBatchInfo["endDay"] = modalData.removedAssignmentInfo.endDay
                modalManager.modalFinished()

export alertManager = new AlertManager()