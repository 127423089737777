import "./activity.styl"
import template from "./activity.pug"
import { DateUtils } from "@/lib/utils/date"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import * as BrowserStorageUtils from "@/lib/utils/browser-storage"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { ActivityStore } from "@/stores/activity-store.core"
import { defaultStore } from "@/stores/default-store"

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"
import { ScrollbarMediator } from "@/lib/mediators/scrollbar-mediator"
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator"

### Models ###
import { Activity } from "@/models/activity"
import { ValueSet } from "@/models/value-set"
import { PermissionLevel } from "@/models/permission-level"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

import ko from "knockout"

export class ActivityViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Activity")

      ###------------------------------------
         Permissions
      ------------------------------------###
      # The item by item filtering has to happen on the backend.
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewPeopleActivity = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_ACTIVITY)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)
      @canViewProjectActivity = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_ACTIVITY)
      @needsEntitySelection = @canViewPeople and @canViewPeopleActivity and @canViewProject and @canViewProjectActivity

      @totalPossibleRecords = ko.observable()

      @verticalScrollbarMediator = new ScrollbarMediator()
      @chipFilterMediator = new ChipFilterMediator()

      @activityDates = ko.observable({})
      @groupedActivity = ko.pureComputed =>
         groups = []
         for key, val of @activityDates()
            groups.push({day: Number(key), records: val})
         return groups.sort (a, b) -> return b.day - a.day

      @entityFilterOptions = [
         new SegmentedControllerItem("All", "all")
         new SegmentedControllerItem("People", "people")
         new SegmentedControllerItem("Projects", "projects")
      ]

      @fullEntityKey = "#{BrowserStorageUtils.BrowserLocalStorageKey.GLOBAL_ACTIVITY_ENTITY}_/groups/#{authManager.selectedGroupId()}/activity"
      storedEntitySelection = BrowserStorageUtils.getBasicValue(@fullEntityKey)

      @selectedEntityFilter = ko.observable()
      if @canViewPeople and @canViewPeopleActivity and @canViewProject and @canViewProjectActivity
         if storedEntitySelection?
            for option in @entityFilterOptions
               if option.value() == storedEntitySelection
                  @selectedEntityFilter(option)
                  break
            @selectedEntityFilter(@entityFilterOptions[0]) unless @selectedEntityFilter()?
         else
            @selectedEntityFilter(@entityFilterOptions[0])
      else
         # They can only see one thing.
         if @canViewPeople and @canViewPeopleActivity
            @selectedEntityFilter(@entityFilterOptions[1])
         else if @canViewProject and @canViewProjectActivity
            @selectedEntityFilter(@entityFilterOptions[2])

      @selectedEntityFilter.subscribeChange (newVal) =>
         # Store Value
         key = BrowserStorageUtils.BrowserLocalStorageKey.GLOBAL_ACTIVITY_ENTITY
         fullEntityKey = "#{key}_/groups/#{authManager.selectedGroupId()}/activity"
         BrowserStorageUtils.storeBasicValue(fullEntityKey, newVal.value())
         # Evaluate Chips
         unless newVal.value() == "all"
            validChips = @filterChips().filter (chip) ->
               return chip.filterName == newVal.name() or chip.filterName == "Hide My Activity"

            @disposableChipFilterBlock = true
            @filterChips(validChips)
            BrowserStorageUtils.storePageFilterChips(@filterChips())
            @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))

         @loadData()

      @detachedToday = DateUtils.getDetachedDay(new Date())

      @groupIdSubscription = authManager.selectedGroupId.subscribeChange (newVal) =>
         if newVal?
            @fullEntityKey = "#{BrowserStorageUtils.BrowserLocalStorageKey.GLOBAL_ACTIVITY_ENTITY}_/groups/#{newVal}/activity"
            storedEntitySelection = BrowserStorageUtils.getBasicValue(@fullEntityKey)
            if storedEntitySelection?
               for option in @entityFilterOptions
                  if option.value() == storedEntitySelection
                     @selectedEntityFilter(option)
                     break
            else
               @selectedEntityFilter(@entityFilterOptions[0])

            @fullChipKey = "#{BrowserStorageUtils.BrowserLocalStorageKey.CHIP_FILTERS}_/groups/#{newVal}/activity"
            @storedChips = BrowserStorageUtils.getPageFilterChips(@fullChipKey)
            if @storedChips?
               @disposableChipFilterBlock = true
               @filterChips(@storedChips)
               @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
            else
               @disposableChipFilterBlock = true
               @filterChips([])
               @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))

            @loadData()

      @filterChips = ko.observableArray()
      @defaultChips = ActivityViewModel.DEFAULT_FILTER_CHIPS
      @labeledFilterOptions = ko.pureComputed =>
         if @selectedEntityFilter().value() == "all"
            return {
               "People": ko.observable({
                  property: "category"
                  values: [
                     new ValueSet({name: "Assignments", value: "person_assignments"})
                     new ValueSet({name: "Attachments", value: "person_attachments"})
                     new ValueSet({name: "Info", value: "person_info"})
                     new ValueSet({name: "Notes", value: "person_notes"})
                     new ValueSet({name: "Tags", value: "person_tags"})
                     new ValueSet({name: "Time Off", value: "person_time_off"})
                  ]
               })
               "Projects": ko.observable({
                  property: "category"
                  values: [
                     new ValueSet({name: "Attachments", value: "project_attachments"})
                     new ValueSet({name: "Categories", value: "project_cost_code"})
                     new ValueSet({name: "Requests", value: "project_placeholder"})
                     new ValueSet({name: "Info", value: "project_info"})
                     new ValueSet({name: "Notes", value: "project_notes"})
                     new ValueSet({name: "Roles", value: "project_roles"})
                     new ValueSet({name: "Tags", value: "project_tags"})
                     new ValueSet({name: "Wage Overrides", value: "project_wage_override"})

                  ]
               })
               "Hide My Activity": ko.observable({
                  property: "hide_my_activity"
                  values: [
                     new ValueSet({name: "TRUE", value: true})
                  ]
               })
            }
         else if @selectedEntityFilter().value() == "projects"
            return {
               "Projects": ko.observable({
                  property: "category"
                  values: [
                     new ValueSet({name: "Attachments", value: "project_attachments"})
                     new ValueSet({name: "Categories", value: "project_cost_code"})
                     new ValueSet({name: "Requests", value: "project_placeholder"})
                     new ValueSet({name: "Info", value: "project_info"})
                     new ValueSet({name: "Notes", value: "project_notes"})
                     new ValueSet({name: "Roles", value: "project_roles"})
                     new ValueSet({name: "Tags", value: "project_tags"})
                     new ValueSet({name: "Wage Overrides", value: "project_wage_override"})

                  ]
               })
               "Hide My Activity": ko.observable({
                  property: "hide_my_activity"
                  values: [
                     new ValueSet({name: "TRUE", value: true})
                  ]
               })
            }
         else if @selectedEntityFilter().value() == "people"
            return {
               "People": ko.observable({
                  property: "category"
                  values: [
                     new ValueSet({name: "Assignments", value: "person_assignments"})
                     new ValueSet({name: "Attachments", value: "person_attachments"})
                     new ValueSet({name: "Info", value: "person_info"})
                     new ValueSet({name: "Notes", value: "person_notes"})
                     new ValueSet({name: "Tags", value: "person_tags"})
                     new ValueSet({name: "Time Off", value: "person_time_off"})
                  ]
               })
               "Hide My Activity": ko.observable({
                  property: "hide_my_activity"
                  values: [
                     new ValueSet({name: "TRUE", value: true})
                  ]
               })
            }

      @disposableChipFilterBlock = false
      @filterChips.subscribe (newVal) =>
         return @disposableChipFilterBlock = false if @disposableChipFilterBlock
         BrowserStorageUtils.storePageFilterChips(newVal)
         @loadData()

      @fullChipKey = "#{BrowserStorageUtils.BrowserLocalStorageKey.CHIP_FILTERS}_/groups/#{authManager.selectedGroupId()}/activity"
      @storedChips = BrowserStorageUtils.getPageFilterChips(@fullChipKey)
      if @storedChips?
         @disposableChipFilterBlock = true
         @filterChips(@storedChips)

      @scrollDepth = 0
      @currentRequest = ko.observable()
      @hasMoreToLoad = true
      @showRequestMoreMessage = ko.observable(false)

      @nextStartingAfter = ko.observable(null)

      @progressLoadingMediator = new PageableLoadingMediator =>
         # INIT Load Call.
         @loadData()

   getProjectRecordName: (activity) ->
      name = activity.subject1Name()
      if activity.baggage()?.job_number?
         return "#{name} - #{activity.baggage().job_number}"
      return name

   getActivityIcon: (activity) ->
      return Activity.CategoryIcon[activity.category()]

   getCreatedDate: (activity) ->
      createdDate = new Date(activity.createdAt())
      formattedDate = DateUtils.formatDate(createdDate, defaultStore.getDateFormat(), {
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV,
         dayFormat: DateUtils.DayFormat.ONE_DIGIT,
         monthFormat: DateUtils.MonthFormat.ABBREV,
         yearFormat: DateUtils.YearFormat.FULL,
      })
      return " - #{formattedDate} at #{DateUtils.getTime(createdDate)}"

   getFormattedDay: (detachedDay) ->
      return "Today" if detachedDay == @detachedToday
      return DateUtils.formatDetachedDay(detachedDay, defaultStore.getDateFormat(), {
         yearFormat: DateUtils.YearFormat.FULL
         monthFormat: DateUtils.MonthFormat.ABBREV
         dayFormat: DateUtils.DayFormat.ONE_DIGIT
         weekdayFormat: DateUtils.WeekDayFormat.ABBREV
      })

   navigateToRecord: (record) =>
      entity = record.subject1Type()
      entityId = record.subject1Id()
      if entity == "people"
         return unless @canViewPeople
      else if entity == "projects"
         return unless @canViewProject
      else
         return
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/#{entity}/#{entityId}")

   maybeFillView: =>
      parent = document.getElementsByClassName(ActivityViewModel.ElementClass.CONTENT_CONTAINER)[0]
      child = document.getElementsByClassName(ActivityViewModel.ElementClass.SCROLLING_CONTENT)[0]
      if parent and child
         parentHeight = parent.offsetHeight
         childHeight = child.offsetHeight
         @getMoreRecords() if childHeight <= parentHeight

   getMoreRecords: =>
      return if @currentRequest() or !@hasMoreToLoad or @isLoadingActivities

      @progressLoadingMediator.showLoader()
      @progressLoadingMediator.startProgress()

      filters = {
         entity: @selectedEntityFilter().value()
         depth: @scrollDepth
         limit: ActivityViewModel.ITEMS_PER_REQUEST
         categories: @filterChips().filter((chip) ->
            return chip.property == "category"
         ).map (chip) -> return chip.value
         hide_my_activity: @filterChips().filter((chip) ->
            return chip.property == "hide_my_activity" and chip.negation == false
         ).length > 0
      }

      query = {
         included_categories: filters.categories
         limit: ActivityViewModel.ITEMS_PER_REQUEST
         starting_after: @nextStartingAfter()
      }
      if @selectedEntityFilter().value() != "all"
         query['entity_type'] = @selectedEntityFilter().value()
      if authManager.selectedGroupId() != "my-groups"
         query['group_ids'] = [authManager.selectedGroupId()]
      if filters.hide_my_activity == true
         query['hide_my_activity'] = true

      try
         @isLoadingActivities = true
         results = await ActivityStore.findActivityPaginated(query).payload
         activityResults = []
         for await activity from results.data
            activityResults.push(new Activity(activity))

         @progressLoadingMediator.appendData =>
            @hasMoreToLoad = results.data.length >= ActivityViewModel.ITEMS_PER_REQUEST
            @showRequestMoreMessage(!@hasMoreToLoad)
            @currentRequest(null)
            @nextStartingAfter(results.pagination.next_starting_after)

            @totalPossibleRecords(results.pagination.total_possible)

            for item in activityResults
               detachedCreated = DateUtils.getDetachedDay(new Date(item.createdAt()))
               if @activityDates()[detachedCreated]?
                  @activityDates()[detachedCreated].push(item)
               else
                  @activityDates()[detachedCreated] = [item]

            # Force computed to fire on changes ti nested properties.
            @activityDates(@activityDates())
            @verticalScrollbarMediator.checkContentSize()
            setTimeout(@maybeFillView, 0) if @hasMoreToLoad

         @currentRequest(results)
      catch err
         @currentRequest(null)
         console.log "Error: ", err
      finally
         @isLoadingActivities = false

   loadData: ->
      return if @isLoadingActivities

      @progressLoadingMediator.showLoader()
      @progressLoadingMediator.startProgress()

      if (currentRequest = @currentRequest())
         currentRequest.cancel()
         @currentRequest(null)

      @activityDates({})
      @scrollDepth = 0
      @totalPossibleRecords(0)
      filters = {
         entity: @selectedEntityFilter().value()
         depth: @scrollDepth
         limit: ActivityViewModel.ITEMS_PER_REQUEST
         categories: @filterChips().filter((chip) ->
            return chip.property == "category"
         ).map (chip) -> return chip.value
         hide_my_activity: @filterChips().filter((chip) ->
            return chip.property == "hide_my_activity" and chip.negation == false
         ).length > 0
      }
      @constructPaginatedActivity_(filters)
   
   constructPaginatedActivity_: (filters) =>
      query = {
         included_categories: filters.categories
         limit: ActivityViewModel.ITEMS_PER_REQUEST
      }
      if @selectedEntityFilter().value() != "all"
         query['entity_type'] = @selectedEntityFilter().value()
      if authManager.selectedGroupId() != "my-groups"
         query['group_ids'] = [authManager.selectedGroupId()]
      if filters.hide_my_activity == true
         query['hide_my_activity'] = true

      try
         @isLoadingActivities = true
         results = await ActivityStore.findActivityPaginated(query).payload
         activityResults = []
         for await activity from results.data
            activityResults.push(new Activity(activity))

         @progressLoadingMediator.appendData =>
            @hasMoreToLoad = results.pagination.total_possible >= ActivityViewModel.ITEMS_PER_REQUEST
            @showRequestMoreMessage(!@hasMoreToLoad)
            @currentRequest(null)
            @nextStartingAfter(results.pagination.next_starting_after)

            @totalPossibleRecords(results.pagination.total_possible)

            for item in activityResults
               detachedCreated = DateUtils.getDetachedDay(new Date(item.createdAt()))
               if @activityDates()[detachedCreated]?
                  @activityDates()[detachedCreated].push(item)
               else
                  @activityDates()[detachedCreated] = [item]

            # Force computed to fire on changes to nested properties.
            @activityDates(@activityDates())
            @verticalScrollbarMediator.checkContentSize()
            setTimeout(@maybeFillView, 0) if @hasMoreToLoad

         @currentRequest(results)
      catch
         @currentRequest(null)
         # TODO: Display error.
      finally
         @isLoadingActivities = false

ActivityViewModel.ITEMS_PER_REQUEST = 50

ActivityViewModel.ElementClass = {
   CONTENT_CONTAINER: "entity-detail__activity-container"
   SCROLLING_CONTENT: "activity-page__activity-records"
}

ActivityViewModel.DEFAULT_FILTER_CHIPS = []