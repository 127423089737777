import template from "./labor-plans.pug";
import "./labor-plans.styl";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import renderReactComponent from "@/react/render-react-component";
import { ProjectStore } from "@/stores/project-store.core";
import { CompanyStore } from "@/stores/company-store.core";
import { TotalsStore } from "@/stores/totals-store.core";
import { router } from "@/lib/router";
import type { LaborPlansContainerProps } from "@/react/components/labor-plans/prop-types";
import { PositionStore } from "@/stores/position-store.core";
import { RequestStore } from "@/stores/request-store.core";
import type { CreateRequestPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";

export class LaborPlansViewModel extends PageContentViewModel {
   private readonly projectId: string;
   private readonly groupId: string;
   private readonly fromGantt: boolean;

   constructor(projectId: string, groupId: string, fromGantt: boolean = false) {
      super(template());
      this.projectId = projectId;
      this.groupId = groupId;
      this.fromGantt = fromGantt;
   }
   addedToParent(): void {
      const props: LaborPlansContainerProps = {
         router: router,
         projectId: this.projectId,
         projectDetailsUrl: `/groups/${this.groupId}/projects/${this.projectId}`,
         fromGantt: this.fromGantt,
      };
      renderReactComponent("labor-plans-react-mount", "LaborPlansContainer", props);
   }

   async getProjectDetails(projectId: string): Promise<any> {
      try {
         const result = await ProjectStore.getProjectDetails(projectId).payload;
         return result.data;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async getProjectTotals(projectId: string): Promise<any> {
      try {
         const result = await TotalsStore.getProjectTotals(projectId, {
            getAssignments: "true",
            getRequests: "true",
            totalsUnit: "hours",
            viewBy: "project",
         }).payload;
         return result.data;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async getCompanyTbdWeeks(): Promise<any> {
      try {
         const result = await CompanyStore.getCompany().payload;
         return result.data.tbd_weeks;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async getProjectJobTitles(group_ids: string[]): Promise<any> {
      try {
         const result = (
            await PositionStore.findPositionsPaginated({ limit: 100, group_ids: group_ids }).payload
         ).data;
         return result;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async createRequest(request: CreateRequestPayload[]): Promise<void> {
      try {
         await Promise.all(
            request.map(async (r) => {
               await RequestStore.createRequest(r).payload;
            }),
         );
      } catch (error) {
         console.log("Error: ", error);
      }
   }
}
