import "./oauth-apps.styl";
import template from "./oauth-apps.pug";
import { OauthAppStore } from "@/stores/oauth-app-store.core";
import renderOauthApps from "@/react/render-oauth-apps";

import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import type { OauthAppData } from "@/models/oauth-app";

export class OauthAppsViewModel extends PageContentViewModel {
   constructor() {
      super(template(), "Settings - Oauth Apps");
   }

   addedToParent(): void {
      renderOauthApps({
         getData: this.getOauthApps,
         updateData: this.updateOauthApp,
         createData: this.createOauthApp,
         deleteData: this.deleteOauthApp,
      });
   }

   async getOauthApps(): Promise<OauthAppData[] | undefined> {
      try {
         const result = await OauthAppStore.getOauthApps({}).payload;
         const oauthAppsResult: OauthAppData[] = result.map((oauthApp) => {
            return oauthApp;
         });
         return oauthAppsResult;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async updateOauthApp(oauthApp: Partial<OauthAppData>): Promise<OauthAppData | undefined> {
      try {
         const result = await OauthAppStore.updateOauthApp(oauthApp).payload;
         return result;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async createOauthApp(oauthApp: Partial<OauthAppData>): Promise<OauthAppData | undefined> {
      try {
         const result = await OauthAppStore.createOauthApp(oauthApp).payload;
         return result;
      } catch (error) {
         console.log("Error: ", error);
      }
   }

   async deleteOauthApp(id: string): Promise<string | undefined> {
      try {
         const result = await OauthAppStore.deleteOauthApp(id).payload;
         return result;
      } catch (error) {
         console.log("Error: ", error);
      }
   }
}
