import React from "react";
import { createRoot } from "react-dom/client";
import { I18nProvider } from "@procore/core-react";
import ResetDataSettingsPage from "./components/reset-data-form";

export default (props: any) => {
   const container = document.getElementById("reset-data-react-mount");
   const root = createRoot(container!);
   root.render(
      <I18nProvider locale={window.I18n.locale as any} translations={window.I18n.translations}>
         <ResetDataSettingsPage {...props} />
      </I18nProvider>,
   );
};
