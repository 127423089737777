import template from "./positions.pug"
import { ValidationUtils } from "@/lib/utils/validation"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import ko from "knockout"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { PositionStore } from "@/stores/position-store.core"
import { SettingsStore } from "@/stores/settings-store.core"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { CreateEditPositionPane } from "@/views/settings/modals/create-edit-position-pane"
import { ConfirmDeletePositionPaneViewModel as ConfirmDeletePositionPane } from "@/views/settings/modals/confirm-delete-position-pane"
import { ConfirmPositionGroupChangePaneViewModel as ConfirmPositionGroupChangePane } from "@/views/settings/modals/confirm-position-group-change-pane"

### Models ###
import { Position } from "@/models/position"
import { PermissionLevel } from "@/models/permission-level"

export class PositionsViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Settings - Job Titles")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canManagePositionSettings = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_POSITION_SETTINGS)

      @searchQuery = ko.observable()
      @positions = ko.observableArray()
      @displayPositions = ko.pureComputed =>
         return @positions() unless ValidationUtils.validateInput(@searchQuery())
         filteredPositions = []
         for position in @positions()
            if position.name().toLowerCase().indexOf(@searchQuery().toLowerCase()) != -1
               filteredPositions.push(position)
         return filteredPositions

      @searchBarActive = ko.pureComputed =>
         return ValidationUtils.validateInput(ko.unwrap(@searchQuery))

      @orderChangeMade = ko.observable(false)

      @nextStartingAfter = ko.observable(null)
      @isLoading = ko.observable(false)
      @areAllPositionsLoaded = ko.pureComputed(() => @nextStartingAfter() == null)

      @loadPositionData()

   setSearchQuery: (query) =>
      assertArgs(arguments, Function)
      @searchQuery(query())
      @loadPositionData(@searchQuery())

   getPositionTitle: (position) ->
      name = position.name()
      if position.hourlyRate()?
         name += " ($#{position.hourlyRate()}/hr)"
      return name

   showNewPositionModal: ->
      pane1 = new CreateEditPositionPane()
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'create-edit-position-modal'}, (modal, modalStatus, observableData) =>
         return if modalStatus == "cancelled"
         position = observableData.data
         position['sequence']  = 0  # Makes sure new position will appear at the top of the Job Titles list
         @createPosition position, (err) =>
            return console.log "error: ", err if err
            @loadPositionData()

   editPosition: (originalPosition) =>
      assertOfType(originalPosition, Position)
      pane1 = new CreateEditPositionPane(originalPosition.clone(Position))
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'create-edit-position-modal'}, (modal, modalStatus, observableData) =>
         return if modalStatus == "cancelled"
         position = observableData.data
         update = {}
         update['name'] = position.name if position.name != originalPosition.name()
         update['type'] = position.type if position.type != originalPosition.type()
         update['hourly_rate'] = position.hourly_rate if position.hourly_rate != originalPosition.hourlyRate()
         update['color'] = position.color if position.color != originalPosition.color()
         update['globally_accessible'] = position.globally_accessible if position.globally_accessible != originalPosition.globallyAccessible()
         if position.group_ids.length != originalPosition.groupIds().length
            update['group_ids'] = position.group_ids
         else
            for id in originalPosition.groupIds()
               if position.group_ids.indexOf(id) == -1
                  update['group_ids'] = position.group_ids
                  break
         return unless Object.keys(update).length != 0
         executeUpdate = =>
            @updatePosition originalPosition, update, (err, newPosition) =>
               return console.log "error: ", err if err
               return originalPosition.mapProperties(newPosition)

         if (originalPosition.globally_accessible and !update.globally_accessible) or update.group_ids?
            pane1 = new ConfirmPositionGroupChangePane(position.name)
            modal = new Modal()
            modal.setPanes([pane1])
            modalManager.showModal modal, null, {}, (modal, modalStatus) =>
               return if modalStatus == "cancelled"
               executeUpdate()
         else
            executeUpdate()

   positionDelete: (position) =>
      assertOfType(position, Position)
      pane1 = new ConfirmDeletePositionPane(position.name())
      modal = new Modal()
      modal.setPanes([pane1])
      modalManager.showModal modal, null, {class: 'confirm-delete-position-modal'}, (modal, modalStatus) =>
         return if modalStatus == "cancelled"
         @deletePosition position.id, (err, positions) =>
            return console.log "error: ", err if err
            @positions(positions)
            modalManager.modalCancelled()

   positionDrop: (element) =>
      elementData = ko.dataFor(element)
      loopingEl = element
      i = 0
      while (loopingEl = loopingEl.previousSibling) != null
         # Filter out virtual elements
         continue unless loopingEl.innerHTML?
         i++
      previousSequence = elementData.sequence()
      elementData.sequence(i)
      @maybeUpdatePositionSequences elementData, previousSequence, =>
         @orderChangeMade(true)

   maybeUpdatePositionSequences: (updatedPosition, previousSequence, next) ->
      assertArgs(arguments, Object, Number, optional(Function))

      @positions().forEach (position, index) ->
         return if position == updatedPosition

         if (updatedPosition.sequence() - previousSequence) > 0
            if index > previousSequence  &&  index <= updatedPosition.sequence()
               position.sequence(index - 1)
         else
            if index >= updatedPosition.sequence() && index < previousSequence
               position.sequence(index + 1)
         
      @positions(@positions().sort((a, b) => a.sequence() - b.sequence()))

      next() if next?

   savePositionOrder: ->
      orderData = {}
      for position in @positions()
         orderData[position.id] = position.sequence()
      @reorderPositions orderData, (err) =>
         return console.log "error: ", err if err
         @orderChangeMade(false)

   undoPositionOrder: ->
      @orderChangeMade(false)
      @loadPositionData()

   loadPositionData: (searchQuery) ->
      assertArgs(arguments, optional(String))
      @getPositionsAvailableToUser searchQuery, (err, positions) =>
         return console.log "error: ", err if err
         @positions(positions)
         setTimeout(@maybeFillView, 0)
   
   getPositionsAvailableToUser: (searchQuery, callback) ->
      try
         @isLoading(true)
         query = if searchQuery? then {name: searchQuery} else {}
         result = await SettingsStore.findPositionsPaginated(query).payload
         positions = result.data.map (position) -> new Position(position)
         @nextStartingAfter(result.pagination.next_starting_after)
         callback(null, positions)
      catch err
         callback(err)
      finally
         @isLoading(false)

   maybeFillView: =>
      parent = document.getElementsByClassName(PositionsViewModel.ElementClass.CONTENT_CONTAINER)[0]
      child = document.getElementsByClassName(PositionsViewModel.ElementClass.SCROLLING_CONTENT)[0]
      if parent and child
         parentHeight = parent.offsetHeight
         childHeight = child.offsetHeight
         @loadMorePostions() if childHeight <= parentHeight

   loadMorePostions: (pageSize = 20) =>
      if @isLoading() == true || @nextStartingAfter() == null
         return

      lastPosition = @positions().slice(-1)

      if lastPosition == null
         return

      try
         @isLoading(true)
         result = await SettingsStore.findPositionsPaginated({
            limit: pageSize
            starting_after: @nextStartingAfter()
         }).payload
         newPositions = result.data.map (position) -> new Position(position)
         @nextStartingAfter(result.pagination.next_starting_after)
         @positions.push(...newPositions)
         setTimeout(@maybeFillView, 0)
      catch err
         return console.log "error: ", err if err
      finally
         @isLoading(false)

   loadAllPositions: () =>
      if @areAllPositionsLoaded() == true
         return

      loadingSubscription = @isLoading.subscribe (isLoading) =>
         if @areAllPositionsLoaded() == false && isLoading == false
            @loadMorePostions(100)

         if @areAllPositionsLoaded() == true
            loadingSubscription.dispose()

      @loadMorePostions(100)

   reorderPositions: (orderData, callback) ->
      orderDataArray = []
      Object.keys(orderData).forEach (id) =>
         orderDataArray.push
            id: id
            sequence: orderData[id]
         return
      orderDataArray.sort((a, b) => a.sequence - b.sequence)
      orderDataCore = orderDataArray.reduce(((acc, position, index) -> 
         acc[index] = position.id
         acc
         ), {})
      try
         await PositionStore.reorderPositions(orderDataCore).payload
         callback(null)
      catch err
         callback(err)

   createPosition: (position, callback) ->
      try
         result = await PositionStore.createPosition(position).payload
         callback(null, new Position(result.data))
      catch err
         callback(err)

   deletePosition: (positionId, callback) ->
      try
         await PositionStore.deletePosition(positionId).payload
         newPositions = @positions().filter (position) -> position.id != positionId
         callback(null, newPositions)
      catch err
         console.log(err)
         callback(err) 

   updatePosition: (originalPosition, update, callback) ->
      currentGroups = originalPosition.groupIds()
      groupIdDeltas = {}

      if update.group_ids?
         update.group_ids.forEach (groupId) ->
            if !currentGroups.includes(groupId)
               groupIdDeltas[groupId] = true
         currentGroups.forEach (groupId) ->
            if !update.group_ids.includes(groupId)
               groupIdDeltas[groupId] = false
         update.group_ids = groupIdDeltas
      try
         result = await PositionStore.updatePosition(originalPosition.id, update).payload
         callback(null, new Position(result.data))
      catch err
         callback(err)

PositionsViewModel.ElementClass = {
   CONTENT_CONTAINER: "settings-content__list-container"
   SCROLLING_CONTENT: "settings-content__item-list"
}