import { Url as UrlUtil } from "@/lib/utils/url"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { FanoutManager } from "@/lib/managers/fanout-manager"
import { fanoutManager } from "@/lib/managers/fanout-manager"
import { FanoutMessage } from "@/lib/managers/fanout-manager"
import { Store } from "@/stores/store"
import { ActivityStore } from "@/stores/activity-store.core";
import { ActivityStore as LegacyActivityStore} from "@/stores/activity-store"

### Models ###
import { Project } from "@/models/project"
import { ValueSet } from "@/models/value-set"
import { AssignmentCard } from "@/models/assignment-card"
import { Tag } from "@/models/tag"
import { Activity } from "@/models/activity"
import { Person } from "@/models/person"
import { CannedMessage } from "@/models/canned-message"

import ko from "knockout"

export class ProjectStore extends Store
   constructor: ->
      super()
      @vault = {
         "active-projects": null
         "project-detail": null
         "project-activity": null
      }

   createProject: (data, callback) ->
      assertArgs(arguments, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects"
         method: "POST"
         data: data
      }, (err, data) =>
         return callback(err) if err
         callback(null, data)

   updateProject: (projectId, data, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}"
         method: "POST"
         data: data
      }, (err, project) =>
         return callback(err) if err
         callback(null, project)

   deleteProject: (projectId, callback) ->
      assertArgs(arguments, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   addTag: (projectId, data, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/tags"
         method: "POST"
         data: data
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   addAttachmentIdToTag: (projectId, tagInstanceId, attachmentId, callback) ->
      assertArgs(arguments, String, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/tags/#{tagInstanceId}/attachments"
         method: "POST"
         data: {attachment_id: attachmentId}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   removeAttachmentIdFromTag: (projectId, tagInstanceId, attachmentId, callback) ->
      assertArgs(arguments, String, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/tags/#{tagInstanceId}/attachments/#{attachmentId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   deleteTag: (projectId, tagInstanceId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/tags/#{tagInstanceId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   addAttachmentId: (projectId, attachmentId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/attachments"
         method: "POST"
         data: {attachment_id: attachmentId}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   removeAttachmentIdFromProject: (projectId, attachmentId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/attachments/#{attachmentId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   createNote: (projectId, data, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/notes"
         method: "POST"
         data: data
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   deleteNote: (projectId, noteId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/notes/#{noteId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   updateCostCode: (projectId, costCodeId, data, callback) ->
      assertArgs(arguments, String, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes/#{costCodeId}"
         method: "POST"
         data: data
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   addCostCode: (projectId, data, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes"
         method: "POST"
         data: data
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   reorderCostCode: (projectId, orderData, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/reorder-cost-codes"
         method: "POST"
         data: orderData
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   deleteCostCode: (projectId, costCodeId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes/#{costCodeId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   archiveLabel: (projectId, costCodeId, labelId, callback) ->
      assertArgs(arguments, String, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes/#{costCodeId}/labels/#{labelId}/archive"
         method: "POST"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   updateWageOverride: (projectId, overrideId, overrideData, callback) ->
      assertArgs(arguments, String, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/wage-overrides/#{overrideId}"
         method: "POST"
         data: overrideData
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   deleteWageOverride: (projectId, overrideId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/wage-overrides/#{overrideId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   addWageOverride: (projectId, overrideData, callback) ->
      assertArgs(arguments, String, Object, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/wage-overrides"
         method: "POST"
         data: overrideData
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   subscribeToProject: (listenerData, listenerNamespace, forceRequest, callback) ->
      [listenerData, listenerNamespace, forceRequest, callback] = assertArgs(arguments, optional(Object), String, optional(Boolean), Function)
      @getProject = ((message, listenerData, forceRequest) ->
         [message, listenerData, forceRequest] = assertArgs(arguments, optional(FanoutMessage), optional(Object), optional(Boolean))
         @requestProject = =>
            requestData = fanoutManager.getListenerData(listenerNamespace, FanoutManager.Channel.PROJECTS) or listenerData
            @makeRequest {
               url: "/api/companies/#{authManager.companyId()}/projects/#{requestData.projectId}"
               method: "GET"
            }, (err, data) =>
               return callback(err) if err
               for attachment in data.attachments
                  attachment.percent_complete = 100
               project = new Project(data)
               @maybeSetVaultData(ProjectStore.VaultKey.PROJECT_DETAIL, project, Date.now(), callback)
               fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PROJECTS, @getProject)

         return @requestProject() if forceRequest
         timestamp = if message? then message.timestamp else 0
         @maybeGetVaultData ProjectStore.VaultKey.PROJECT_DETAIL, timestamp, (err, data) =>
            return @requestProject() if err == Store.Error.VAULT_OUTDATED
            return console.log "Error: ", err if err?
            callback(null, ko.unwrap(data))
            return fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PROJECTS, @getProject)
      ).bind(@)
      force = if forceRequest?  then forceRequest else false
      @getProject(listenerData, force)

   subscribeToProjectActivity: (listenerData, listenerNamespace, forceRequest, callback) =>
      [listenerData, listenerNamespace, forceRequest, callback] = assertArgs(arguments, optional(Object), String, optional(Boolean), Function)
      @getProjectActivity = ((message, listenerData, forceRequest) ->
         [message, listenerData, forceRequest] = assertArgs(arguments, optional(FanoutMessage), optional(Object), optional(Boolean))
         requestData = fanoutManager.getListenerData(listenerNamespace, FanoutManager.Channel.PROJECT_ACTIVITY, {projectId: listenerData.projectId}) or listenerData
         return @requestProjectActivity_(requestData, listenerNamespace, callback) if forceRequest
         timestamp = if message? then message.timestamp else 0
         @maybeGetVaultData ProjectStore.VaultKey.PROJECT_ACTIVITY, timestamp, (err, data) =>
            return @requestProjectActivity_(requestData, listenerNamespace, callback) if err == Store.Error.VAULT_OUTDATED
            return console.log "Error: ", err if err?
            callback(null, ko.unwrap(data))
            return fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PROJECT_ACTIVITY, {projectId: listenerData.projectId}, @getProjectActivity)
      ).bind(@)
      force = if forceRequest?  then forceRequest else false
      @getProjectActivity(listenerData, force)

   getProjectContactOptions: (groupIds, callback) ->
      assertArgs(arguments, Array, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/project-contact-options?groupIds=#{JSON.stringify(groupIds)}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         users = data.users.map (user) ->
            return new ValueSet(user)
         people = data.people.map (person) ->
            return new ValueSet(person)
         callback(null, {users: users, people: people})

   getBoardsProjects: (options, callback) ->
      params = UrlUtil.serializeParams(options)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/groups/#{authManager.selectedGroupId()}/boards-projects?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         projects = data.projects.map (item) -> return new Project(item)

         formattedData = {projects: projects, totalPossible: data.total_possible}
         formattedData['toSkip'] = data.to_skip if data.to_skip?
         callback(null, formattedData)

   # Used for refreshing project on RT.
   getSingleProjectBoards: (projectId, options, callback) ->
      assertArgs(arguments, String, Object, Function)
      params = UrlUtil.serializeParams(options)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/groups/#{authManager.selectedGroupId()}/boards-projects/#{projectId}?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         callback(null, new Project(data))

   subscribeToActiveProjects: (listenerData, listenerNamespace, forceRequest, callback) ->
      assertArgs(arguments, Object, String, Boolean, Function)
      @getActiveProjects = (message, listenerData, forceRequest) =>
         [message, listenerData, forceRequest] = assertArgs(arguments, optional(FanoutMessage), optional(Object), optional(Boolean))
         @requestActiveProjects = =>
            @makeRequest {
               url: ""
               method: "GET"
            }, (err, data) =>
               return callback(err) if err
               projects = data.map((project) -> new Project(project))
               @maybeSetVaultData(ProjectStore.VaultKey.ACTIVE_PROJECTS, projects, Date.now(), callback)
               fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PROJECTS, @getActiveProjects)

         return @requestActiveProjects() if forceRequest
         timestamp = if message? then message.timestamp else 0
         @maybeGetVaultData ProjectStore.VaultKey.ACTIVE_PROJECTS, timestamp, (err, data) =>
            return @requestActiveProjects() if err == Store.Error.VAULT_OUTDATED
            return console.log "Error: ", err if err?
            callback(null, ko.unwrap(data))
            return fanoutManager.getSubscription(listenerData, listenerNamespace, FanoutManager.Channel.PROJECTS, @getActiveProjects)
      force = if forceRequest?  then forceRequest else false
      @getActiveProjects(listenerData, force)

   getCostCodeAssignmentCards: (projectId, costCodeId, dayFilter, callback) ->
      assertArgs(arguments, String, String, Number, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes/#{costCodeId}/assignments?dayFilter=#{dayFilter}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         labeledAssignmentCards = data.map (group) ->
            assignmentCards = ko.observableArray group.reduction.map (card) -> new AssignmentCard(card)
            displayCards = ko.pureComputed =>
               return ko.observableArray(assignmentCards())
            return {
               labelId: group.group,
               labelCount: group.label_count
               assignmentCards: assignmentCards,
               displayCards: displayCards
            }
         callback(null, labeledAssignmentCards)

   getAssignableProjectOptions: (groupIds, callback) ->
      [groupIds, callback] = assertArgs(arguments, optional(arrayOf(String)), Function)
      groupIds = if groupIds? then groupIds else authManager.authedUser().groupIds()
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/assignable-options?groupIds=#{JSON.stringify(groupIds)}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         options = data.map (option) ->
            return new ValueSet(option)
         callback(null, options)

   getCostCodeOptions: (groupIds, callback) ->
      [groupIds, callback] = assertArgs(arguments, optional(arrayOf(String)), Function)
      groupIds = if groupIds? then groupIds else authManager.authedUser().groupIds()
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/cost-codes/options?groupIds=#{JSON.stringify(groupIds)}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         groupedOptions = {}
         for key, val of data
            groupedOptions[key] = val.map (option) ->
               return new ValueSet(option)
         callback(null, groupedOptions)

   getLabelOptions: (groupIds, callback) ->
      [groupIds, callback] = assertArgs(arguments, optional(arrayOf(String)), Function)
      groupIds = if groupIds? then groupIds else authManager.authedUser().groupIds()
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/labels/options?groupIds=#{JSON.stringify(groupIds)}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         groupedOptions = {}
         for key, val of data
            groupedOptions[key] = val.map (option) ->
               return new ValueSet(option)
         callback(null, groupedOptions)

   getProjectDetailSupportData: (projectId, options, callback) ->
      assertArgs(arguments, String, optional(Object), Function)
      url = "/api/companies/#{authManager.companyId()}/projects/#{projectId}/support-data"
      if options?
         params = UrlUtil.serializeParams(options)
         url = "#{url}?#{params}"

      @makeRequest {
         url: url
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         modeledData = {}
         modeledData['integratedFields'] = data.integrated_fields or []
         modeledData['groupOptions'] = data.group_options.map (option) ->
            return new ValueSet(option)
         modeledData['people'] = data.people.map (option) ->
            return new ValueSet(option)
         modeledData['tags'] = data.tags.map (tag) ->
            return new Tag(tag)
         modeledData['costCodeOptions'] = data.cost_code_options.map (option) ->
            return new ValueSet(option)
         modeledData['labelOptions'] = data.label_options.map (option) ->
            return new ValueSet(option)
         modeledData['positionOptions'] = data.position_options.map (option) ->
            return new ValueSet(option)

         if data.categorized_tags?
            categorizedTags = {}
            for item in data.categorized_tags
               category = if item.category? then item.category else "Uncategorized"
               categorizedTags[category] = item.tags.map (item) ->
                  return new ValueSet({name: item.name, value: item.id, color: item.color})

            modeledData['categorized_tags'] = categorizedTags

         callback(null, modeledData)

   getFilteredProjects: (params, callback) ->
      assertArgs(arguments, Object, Function)
      companyId = authManager.companyId()
      groupId = authManager.selectedGroupId()
      params = UrlUtil.serializeParams(params)
      return @makeRequest {
         url: "/api/companies/#{companyId}/groups/#{groupId}/projects/filter?#{params}"
         method: "GET"
      }, (err, result) =>
         return callback(err) if err
         data = {
            projects: result.data.map (project) ->
               return new Project(project)
            totalCount: result.total_count
         }
         callback(null, data)

   checkCostCodeForAssignments: (projectId, costCodeId, dayFilter, callback) ->
      assertArgs(arguments, String, String, Number, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/cost-codes/#{costCodeId}/assignments-check?dayFilter=#{dayFilter}"
         method: "GET"
      }, (err, existingAssignmentsData) =>
         return callback(err) if err
         formattedAssignmentsData = existingAssignmentsData.map (item) ->
            return {
               startDay: item.start_day
               endDay: item.end_day
               resourceName: "#{item.resource_name.first} #{item.resource_name.last}"
            }
         callback(null, formattedAssignmentsData)

   getProjectInfoForAssignmentNotification: (projectId, resourceId, batchId, messageContent, callback) ->
      assertArgs(arguments, String, String, String, String, Function)
      params = {
         batch_id: batchId
         message_context: messageContent
      }
      params = UrlUtil.serializeParams(params)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/resources/#{resourceId}/assignment-notification-info?#{params}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         formattedData = {
            recipients: data.recipients.map (person) ->
               return new Person(person, true)
         }

         if data.assignment_batch?
            formattedData['assignmentBatch'] = {
               startDay: data.assignment_batch.start_day
               endDay: data.assignment_batch.end_day
               startTime: data.assignment_batch.start_time
               endTime: data.assignment_batch.end_time
               percentAllocated: data.assignment_batch.percent_allocated
               workDays: data.assignment_batch.work_days
               resourceName: data.assignment_batch.resource_name
            }

         if data.message?
            formattedData['message'] = new CannedMessage(data.message, true)
         else
            formattedData['project'] = {
               name: data.project.name
               address1: data.project.address_1 or null
               address2: data.project.address_2 or null
               cityTown: data.project.city_town or null
               stateProvince: data.project.state_province or null
               zipcode: data.project.zipcode or null
               jobNumber: data.project.job_number or null
               siteContacts: data.project.site_contacts
            }

         callback(null, formattedData)

   addRole: (projectId, role, callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/roles"
         method: "POST"
         data: role
      }, (err, newRole) =>
         return callback(err) if err
         callback(null, new Project.Role(newRole))

   deleteRole: (projectId, roleId, callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/roles/#{roleId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   getDefaultTimes: (callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/project-time-defaults"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         formattedData = data.map (item) ->
            return {id: item.id, dailyStartTime: item.daily_start_time, dailyEndTime: item.daily_end_time}
         callback(null, formattedData)

   getAbbrvProject: (projectId, callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/abbrv"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         callback(null, new Project(data, true))

   addDefaultRecipientId: (projectId, recipientId, callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/default-recipients"
         method: "POST"
         data: {recipient_id: recipientId}
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   removeDefaultRecipientId: (projectId, recipientId, callback) ->
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/default-recipients/#{recipientId}"
         method: "DELETE"
      }, (err, success) =>
         return callback(err) if err
         callback(null, success)

   getProjectQrInfo: (companyQrId, projectQrId, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/ex-api/qr-companies/#{companyQrId}/qr-projects/#{projectQrId}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         callback(null, data)

   getProjectAlertInfo: (projectId, alertContext, callback) ->
      assertArgs(arguments, String, String, Function)
      @makeRequest {
         url: "/api/companies/#{authManager.companyId()}/projects/#{projectId}/alert-template?alertContext=#{alertContext}"
         method: "GET"
      }, (err, data) =>
         return callback(err) if err
         if data.template?
            # Disabling types as this may return the gobal default.
            template = new CannedMessage(data.template, true)
         else
            template = null
         callback(null, template)

   # async
   getSingleProject: (projectId) ->
      companyId = authManager.companyId()
      response = await (await fetch("/api/companies/#{companyId}/projects/#{projectId}",
      {
         method: 'GET',
      })).json()
      return new Project(response)

   serializeColumnHeader_: (columnHeaders) =>
      return columnHeaders.map (header) ->
         data = {
            key: header.key()
            sequence: header.sequence()
            sortable: header.sortable()
            meta: header.meta() or null
         }
         data.name = header.name() if header.name?
         data.key_id = header.keyId() if header.keyId() and header.key() == "positions"
         return data

   requestProjectActivity_: (requestData, listenerNamespace, callback) =>
      query = {
         entity_id: requestData.projectId,
         entity_type: LegacyActivityStore.ActivityEntityType.PROJECTS,
         limit: 25
      }
      if requestData.category != "all"
         if requestData.category == "project_cost_code"
            query['included_categories'] = ["project_cost_codes"]
         else
            query['included_categories'] = [requestData.category]
      try
         results = await ActivityStore.findActivityPaginated(query).payload
         activity = []
         for await item from results.data
            activity.push(new Activity(item))
         @maybeSetVaultData(ProjectStore.VaultKey.PROJECT_ACTIVITY, activity, Date.now(), callback)
      catch err
         return console.log "Error: ", err

      fanoutManager.getSubscription(requestData, listenerNamespace, FanoutManager.Channel.PROJECT_ACTIVITY, {projectId: requestData.projectId}, @getProjectActivity)


ProjectStore.VaultKey = {
   ACTIVE_PROJECTS: "active-projects"
   PROJECT_DETAIL: "project-detail"
   PROJECT_ACTIVITY: "project-activity"
}

export projectStore = new  ProjectStore()
