import "./tags.styl";
import template from "./tags.pug";
import * as ko from "knockout";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";

// Auth, Real-Time & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { SettingsStore } from "@/stores/settings-store.core";
import { TagStore } from "@/stores/tag-store.core";
import { GroupStore } from "@/stores/group-store.core";

// Modals
import { modalManager } from "@/lib/managers/modal-manager";
import { Modal } from "@/lib/components/modals/modal";
import { CreateEditTagPane } from "@/views/settings/modals/create-edit-tag-pane";
import { ConfirmDeleteTagPaneViewModel as ConfirmDeleteTagPane } from "@/views/settings/modals/confirm-delete-tag-pane";
import { ConfirmTagGroupChangePaneViewModel as ConfirmTagGroupChangePane } from "@/views/settings/modals/confirm-tag-group-change-pane";
import { ConfirmActionPaneViewModel as ConfirmActionPane } from "@/lib/components/modals/confirm-action-pane";

// Models
import type { TagData } from "@/models/tag";
import { Tag } from "@/models/tag";
import { PermissionLevel } from "@/models/permission-level";
import type { ValueSet as ValueSetType } from "@/models/value-set";
import { ValueSet } from "@/models/value-set";

// Utils
import { ValidationUtils } from "@/lib/utils/validation";
import { Format as FormatUtils } from "@/lib/utils/format";

// Api
import type { UpdateTagPayload } from "@laborchart-modules/lc-core-api/dist/api/tags/update-tag";

export class TagsViewModel extends PageContentViewModel {
   private readonly canManageTagsSettings: boolean;
   private readonly usingCategories: ko.Observable<boolean>;
   private readonly tagCategories: string[];
   private disposableUsingCategoriesBlock: boolean;
   private readonly tagQuery: ko.Observable<string | undefined>;
   private readonly tags: ko.ObservableArray<Tag>;
   private readonly displayTags: ko.PureComputed<Tag[]>;
   private readonly categoryQuery: ko.Observable<string | undefined>;
   private readonly categories: ko.ObservableArray<string>;
   private readonly displayCategories: ko.PureComputed<string[]>;
   private readonly newCategory: ko.Observable<string | null>;
   private readonly categoryEditingLabel: ko.Observable<"New:" | "Edit:">;
   private readonly editingCategory: ko.Observable<string | null>;
   private readonly newCategoryValid: ko.PureComputed<boolean>;
   private readonly uncategorizedText: ko.PureComputed<string>;

   constructor(tagCategories: string[]) {
      super(template(), "Settings - Tags");
      /*------------------------------------
       Permissions
      ------------------------------------*/
      this.canManageTagsSettings = authManager.checkAuthAction(
         PermissionLevel.Action.MANAGE_TAGS_SETTINGS,
      );
      this.usingCategories = ko.observable(false);
      this.tagCategories = tagCategories;
      this.disposableUsingCategoriesBlock = false;
      this.usingCategories.subscribe((newVal) => {
         if (this.disposableUsingCategoriesBlock) {
            return (this.disposableUsingCategoriesBlock = false);
         }
         if (!newVal) {
            const disableCategories = async () => {
               try {
                  await SettingsStore.disableTagCategories().payload;
                  this.categories([]);
                  for (const tag of this.tags()) {
                     tag.categories([]);
                  }
               } catch (err) {
                  console.log("Error: ", err);
               }
            };
            const message =
               "Disabling categories will delete all of your categories and remove them from all of your tags. It will not delete any tags. Are you sure you want to continue?";
            const pane1 = new ConfirmActionPane("Disable", message, "Disable Categories");
            const modal = new Modal();
            modal.setPanes([pane1]);
            return modalManager.showModal(
               modal,
               null,
               {
                  class: "tag-settings__confirm-category-disabel-modal",
               },
               (modal, modalStatus) => {
                  if (modalStatus === "cancelled") {
                     this.disposableUsingCategoriesBlock = true;
                     return this.usingCategories(true);
                  } else {
                     return disableCategories();
                  }
               },
            );
         }
      });
      this.tagQuery = ko.observable();
      this.tags = ko.observableArray();
      this.displayTags = ko.pureComputed(() => {
         const tagQuery = this.tagQuery();
         if (!ValidationUtils.validateInput(tagQuery)) {
            return FormatUtils.keyableSort(this.tags(), "name");
         }
         const filteredTags = [];
         for (const tag of this.tags()) {
            if (tag.name().toLowerCase().indexOf(tagQuery.toLowerCase()) != -1) {
               filteredTags.push(tag);
            }
         }
         return FormatUtils.keyableSort(filteredTags, "name");
      });
      this.categoryQuery = ko.observable();
      this.categories = ko.observableArray().extend({
         notify: "always",
      });
      this.displayCategories = ko.pureComputed(() => {
         const categories = [...new Set(this.categories())];
         const categoryQuery = this.categoryQuery();
         if (!ValidationUtils.validateInput(categoryQuery)) {
            return categories;
         }
         const filtered = [];
         for (const cat of categories) {
            if (cat.toLowerCase().indexOf(categoryQuery.toLowerCase()) != -1) {
               filtered.push(cat);
            }
         }
         return filtered;
      });
      this.newCategory = ko.observable(null);
      this.categoryEditingLabel = ko.observable<"New:" | "Edit:">("New:");
      this.editingCategory = ko.observable(null);
      this.newCategoryValid = ko.pureComputed(() => {
         if (this.newCategory() == null) {
            return false;
         }
         return ValidationUtils.validateInput(this.newCategory());
      });
      this.uncategorizedText = ko.pureComputed(() => {
         if (!this.usingCategories()) {
            return "";
         }
         let count = 0;
         for (const tag of this.tags()) {
            if (tag.categories().length == 0) {
               count++;
            }
         }

         if (count === 0) {
            return "All tags are categorized.";
         } else if (count === 1) {
            return "1 Uncategorized Tag.";
         } else {
            return `${count} Uncategorized Tags.`;
         }
      });
      this.loadTagData();
   }

   private setTagQuery = (query: ko.Observable<string | undefined>) => {
      this.tagQuery(query());
   };

   private setCategoryQuery = (query: ko.Observable<string | undefined>) => {
      this.categoryQuery(query());
   };

   private createNewCategory() {
      this.categoryEditingLabel("New:");
      this.newCategory("");
      this.editingCategory(null);
   }

   private cancelNewCategory() {
      this.newCategory(null);
      const editingCategory = this.editingCategory();
      if (editingCategory != null) {
         const cats = this.categories();
         cats.push(editingCategory);
         this.categories(cats.sort());
      }
      this.editingCategory(null);
   }

   private editCategory = (category: string) => {
      this.categoryEditingLabel("Edit:");
      this.editingCategory(category);
      this.newCategory(category);
      this.categories.remove(category);
   };

   private async saveNewCategory() {
      const newCategory = this.newCategory();
      if (newCategory == null) {
         return;
      }

      const editingCategory = this.editingCategory();
      const cats = this.categories();
      cats.push(newCategory);
      this.categories(cats.sort());
      if (editingCategory != null) {
         const data = {
            new_category: newCategory,
            old_category: editingCategory,
         };
         try {
            await SettingsStore.editTagCategory(data).payload;
            for (const tag of this.tags()) {
               if (tag.categories().indexOf(data.old_category) != -1) {
                  const position = tag.categories().indexOf(data.old_category);
                  tag.categories()[position] = data.new_category;
               }
            }
            // To Trigger recalculation of applied tags.
            const tmpCats = this.categories();
            this.newCategory(null);
            this.categories([]);
            return this.categories(tmpCats);
         } catch (err) {
            console.log("Error: ", err);
         }
      } else {
         try {
            await SettingsStore.updateTagCategories(this.categories()).payload;
            this.newCategory(null);
         } catch (err) {
            console.log("Error: ", err);
         }
      }
   }

   private deleteCategory = (category: string) => {
      const executeDelete = async () => {
         this.categories.remove(category);
         try {
            await SettingsStore.deleteTagCategory(category).payload;
            for (const tag of this.tags()) {
               if (tag.categories().indexOf(category) != -1) {
                  const position = tag.categories().indexOf(category);
                  tag.categories(tag.categories().splice(position, 1));
               }
            }
         } catch (err) {
            console.log("Error: ", err);
         }
      };
      let impactedTagCount = 0;
      for (const tag of this.tags()) {
         if (tag.categories().indexOf(category) != -1) {
            impactedTagCount++;
         }
      }
      const impactedText = impactedTagCount === 1 ? "1 Tag" : `${impactedTagCount} Tags`;
      const message = `Deleting this category will remove it from ${impactedText}. Do you wish to continue?`;
      const pane1 = new ConfirmActionPane("Delete", message, "Confirm Delete");
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "tag-settings__confirm-category-delete-modal",
         },
         async (modal, modalStatus) => {
            if (modalStatus === "cancelled") {
               return;
            }
            await executeDelete();
         },
      );
   };

   private getCategoryCount(category: string) {
      let count = 0;
      for (const tag of this.tags()) {
         if (tag.categories().indexOf(category) != -1) {
            count++;
         }
      }
      return count === 1 ? "- 1 Tag" : `- ${count} Tags`;
   }

   private showNewTagModal() {
      const categories = this.categories().length > 0 ? this.categories() : null;
      const pane1 = new CreateEditTagPane(null, categories);
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal<TagData>(
         modal,
         null,
         {
            class: "create-edit-tag-modal",
         },
         async (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") {
               return;
            }
            const tag = observableData.data;
            try {
               const transformedTag = {
                  ...tag,
                  abbreviation: tag.abbreviation ?? tag.name.slice(0, 5),
                  categories: tag.categories ?? [],
                  color: tag.color ?? "",
                  expr_days_warning:
                     tag.expr_days_warning != null ? Number(tag.expr_days_warning) : null,
                  globally_accessible: tag.globally_accessible ?? false,
                  group_ids: tag.group_ids ?? [],
                  require_expr_date: tag.require_expr_date ?? false,
               };

               const payload = await TagStore.createTag(transformedTag).payload;
               this.tags.push(new Tag(payload.data));
            } catch (err) {
               return console.log(`Error: ${err}`);
            }
         },
      );
   }

   private editTag = (originalTag: Tag) => {
      const categories = this.categories().length > 0 ? this.categories() : null;
      const pane1 = new CreateEditTagPane(originalTag.clone(Tag), categories);
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal<TagData>(
         modal,
         null,
         {
            class: "create-edit-tag-modal",
         },
         (modal, modalStatus, observableData) => {
            if (modalStatus === "cancelled") {
               return;
            }
            const tag = observableData.data;
            const update: UpdateTagPayload = {};
            if (tag.name !== originalTag.name()) {
               update["name"] = tag.name;
            }
            if (tag.color !== originalTag.color()) {
               update["color"] = tag.color;
            }
            if (tag.abbreviation !== originalTag.abbreviation()) {
               update["abbreviation"] = tag.abbreviation;
            }
            if (tag.require_expr_date !== originalTag.requireExprDate()) {
               update["require_expr_date"] = tag.require_expr_date;
            }
            if (
               tag.expr_days_warning != null &&
               tag.expr_days_warning !== originalTag.exprDaysWarning()
            ) {
               update["expr_days_warning"] = Number(tag.expr_days_warning);
            }
            if (tag.globally_accessible !== originalTag.globallyAccessible()) {
               update["globally_accessible"] = tag.globally_accessible;
            }
            if (tag.group_ids?.length !== originalTag.groupIds().length) {
               update["group_ids"] = tag.group_ids;
            } else {
               for (const id of originalTag.groupIds()) {
                  if (tag.group_ids.indexOf(id) == -1) {
                     update["group_ids"] = tag.group_ids;
                     break;
                  }
               }
            }
            if (this.usingCategories()) {
               if (originalTag.categories() != null && tag.categories == null) {
                  update["categories"] = [];
               } else if (originalTag.categories().length !== tag.categories?.length) {
                  update["categories"] = tag.categories;
               } else {
                  let foundDif = false;
                  for (const cat of originalTag.categories()) {
                     if (tag.categories.indexOf(cat) == -1) {
                        foundDif = true;
                        break;
                     }
                  }
                  if (foundDif) {
                     update["categories"] = tag.categories;
                  }
               }
            }
            if (Object.keys(update).length === 0) {
               return;
            }
            const executeUpdate = async () => {
               try {
                  if (update.group_ids != null) {
                     const groupOptions: Array<ValueSetType<string>> = [];
                     const results = await GroupStore.findGroupsSettingsStream({}).stream;
                     for await (const row of results) {
                        groupOptions.push(
                           new ValueSet({
                              name: row.name,
                              value: row.id,
                           }),
                        );
                     }
                     const groupIds = groupOptions.map((opt) => {
                        return opt.value();
                     });
                     const groupIdUpdateRecord: Record<string, boolean> = {};
                     for (const id of groupIds) {
                        if (Array.isArray(update.group_ids)) {
                           groupIdUpdateRecord[id] = update.group_ids.includes(id);
                        }
                     }
                     update.group_ids = groupIdUpdateRecord;
                  }
                  const payload = await TagStore.updateTag(originalTag.id, update).payload;
                  const newTag = new Tag(payload.data);
                  return originalTag.mapProperties(newTag);
               } catch (err) {
                  return console.log(`Error: ${err}`);
               }
            };
            if (
               (update.globally_accessible != null && !update.globally_accessible) ||
               update.group_ids != null
            ) {
               const pane2 = new ConfirmTagGroupChangePane(tag.name ?? "");
               modal = new Modal();
               modal.setPanes([pane2]);
               return modalManager.showModal(modal, null, {}, (modal, modalStatus) => {
                  if (modalStatus === "cancelled") {
                     return;
                  }
                  return executeUpdate();
               });
            } else {
               return executeUpdate();
            }
         },
      );
   };

   deleteTag = (tag: Tag): void => {
      const pane1 = new ConfirmDeleteTagPane(tag.name());
      const modal = new Modal();
      modal.setPanes([pane1]);
      return modalManager.showModal(
         modal,
         null,
         {
            class: "confirm-delete-tag-modal",
         },
         async (modal, modalStatus) => {
            let err;
            if (modalStatus === "cancelled") {
               return;
            }
            try {
               await TagStore.deleteTag(tag.id).payload;
               return this.tags.remove(tag);
            } catch (error) {
               err = error;
               if (err) {
                  return console.log("Error: ", err);
               }
            }
         },
      );
   };

   async loadTagData(): Promise<void> {
      try {
         const tagStream = await SettingsStore.findTagsStream({
            sort_direction: Order.ASCENDING,
         }).stream;
         const rawTags = [];
         for await (const tag of tagStream) {
            rawTags.push(tag);
         }
         const categories = [...new Set(this.tagCategories)];
         const tags = rawTags.map((tag) => new Tag(tag));
         this.tags(tags);
         // TODO: Get categories off of company, not off of tags.
         this.categories(categories.sort());
         this.usingCategories(categories.length > 0);
      } catch (err) {
         return console.log(`Error: ${err}`);
      }
   }
}
